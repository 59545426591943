import * as React from 'react'
import { z } from 'zod'
import type { ZodSchema } from '@sevenrooms/core/form'

export function useShiftReportPeriodsSettingsSchema() {
  return React.useMemo(
    () =>
      z.array(
        z.object({
          name: z.string().trim().min(1),
          isDefault: z.boolean(),
          breakfast: z.object({
            exists: z.boolean().default(true),
            startTime: z.number().nullable(),
            endTime: z.number().nullable(),
          }),
          brunch: z.object({
            exists: z.boolean().default(true),
            startTime: z.number().nullable(),
            endTime: z.number().nullable(),
          }),
          lunch: z.object({
            exists: z.boolean().default(true),
            startTime: z.number().nullable(),
            endTime: z.number().nullable(),
          }),
          dinner: z.object({
            exists: z.boolean().default(true),
            startTime: z.number().nullable(),
            endTime: z.number().nullable(),
          }),
          nightTime: z.object({
            exists: z.boolean().default(true),
            startTime: z.number().nullable(),
            endTime: z.number().nullable(),
          }),
        })
      ),
    []
  )
}

export type FormValues = ZodSchema<typeof useShiftReportPeriodsSettingsSchema>

import { fromJS } from 'immutable'
import {
  DELETE_EVENT_CONFIRM,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_INVENTORY_CONFIRM,
  DELETE_INVENTORY_SUCCESS,
  CROPPING,
  CROPPING_SAVE,
  UPDATE_VALID_LINK,
  UPDATE_EDITING_EVENT_START_DATE,
  UPDATE_EVENT,
  CLONE_EVENT,
  UPDATE_DAYS,
  RESET_EVENT,
  CHANGE_BOOKING_POLICY_TYPE,
  CHANGE_BOOKING_POLICY,
  CHANGE_CANCELLATION_POLICY_TYPE,
  CHANGE_CANCELLATION_POLICY,
  DELETE_PHOTO,
  UPDATE_PHOTO_TITLE,
  TOGGLE_3D_FLOORPLAN,
  MOVE_INVENTORY_UP,
  MOVE_INVENTORY_DOWN,
} from '../actions/ActionTypes'

const initialState = fromJS({
  cropping: false,
  photoId: null,
  saveLock: false,
  deletingEvent: null,
  deletingInventory: null,
  currencyCode: document.getElementById('currency_code').value,
  invalidExperienceSelected: false,
  editingEventStartDate: false,
  editingEvent: false,
})

const ux = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VALID_LINK:
      return state.set('invalidExperienceSelected', !action.value)
    case DELETE_INVENTORY_SUCCESS:
      return state.set('deletingInventory', null)
    case DELETE_INVENTORY_CONFIRM:
      return state.set('deletingInventory', action.id)
    case DELETE_EVENT_SUCCESS:
      return state.set('deletingEvent', null)
    case DELETE_EVENT_CONFIRM:
      return state.set('deletingEvent', action.id)
    case UPLOAD_PHOTO_PREVIEW:
      return state.set('saveLock', true)
    case UPLOAD_PHOTO_SUCCESS:
      return state.merge({ saveLock: false, editingEvent: true })
    case CROPPING:
      return state.merge({ cropping: action.url, photoId: action.photoId })
    case CROPPING_SAVE:
      return state.merge({ cropping: false, photoId: false, editingEvent: true })
    case UPDATE_EDITING_EVENT_START_DATE:
      return state.set('editingEventStartDate', action.isEditing)
    case UPDATE_EVENT:
    case CLONE_EVENT:
    case UPDATE_DAYS:
    case CHANGE_BOOKING_POLICY_TYPE:
    case CHANGE_BOOKING_POLICY:
    case CHANGE_CANCELLATION_POLICY_TYPE:
    case CHANGE_CANCELLATION_POLICY:
    case DELETE_PHOTO:
    case UPDATE_PHOTO_TITLE:
    case MOVE_INVENTORY_UP:
    case MOVE_INVENTORY_DOWN:
    case TOGGLE_3D_FLOORPLAN:
      return state.set('editingEvent', true)
    case RESET_EVENT:
      return state.set('editingEvent', false)
    default:
      return state
  }
}

export default ux

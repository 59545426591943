import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationCoverLimitLocales } from '../ReservationCoverLimit.locales'
import { ReservationCoverLimitTestId } from '../ReservationCoverLimit.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function ReservationCoverLimitReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()

  const { count, type, guaranteeBookings } = accessRule.reservationCoverLimit

  return (
    <ReportPart
      caption={formatMessage(ReservationCoverLimitLocales.title)}
      data-test={ReservationCoverLimitTestId.report}
      position={position}
    >
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.maxLimitPerDay)}>
        <Text>
          {count != null && type
            ? formatMessage(ReservationCoverLimitLocales.selectionSummary, { type, count })
            : formatMessage(ReservationCoverLimitLocales.defaultsValue)}
        </Text>
      </ReportLine>
      <ReportLine name={formatMessage(ReservationCoverLimitLocales.guaranteeBookingsReportKey)}>
        <Text>{formatMessage(guaranteeBookings ? commonMessages.yes : commonMessages.no)}</Text>
      </ReportLine>
    </ReportPart>
  )
}

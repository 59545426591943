import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import { actionEdit, actionDelete, actionClone, actionBlock } from '../assets/styles/buttons'
import { shadow, areYouSure, sureControls } from '../assets/styles/general'
import * as styles from '../assets/styles/inventoryList'
import { getTypeOptions } from '../utils/InventoryType'
import ControlBar from './ControlBar'
import { css } from 'styled-components'
import UtilButton from '../../../upsells/src/components/UtilButton'
import { connect } from 'react-redux'
import { moveInventoryDown, moveInventoryUp } from '../actions/inventory'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const ListInventory = ({
  eventId,
  eventListData,
  seatingAreas,
  onEditClick,
  onAddClick,
  deleteInventory,
  deleteInventoryConfirm,
  cloneInventory,
  ux,
  moveInventoryDown,
  moveInventoryUp,
}) => {
  const uxdata = ux.toJS()
  const typeOptions = getTypeOptions()
  const seatingAreasLookup = _.fromPairs(_.map(seatingAreas.toJS().seating_areas, x => [x.id, x.name]))
  const moveInventoryDownHandler = (event, inventory) => {
    event.preventDefault()
    moveInventoryDown(eventId, inventory.id)
  }
  const moveInventoryUpHandler = (event, inventory) => {
    event.preventDefault()
    moveInventoryUp(eventId, inventory.id)
  }
  const loadRow = (item, showUpButton, showDownButton) => {
    let rowStyle = styles.row
    if (item.hide_inventory) {
      rowStyle = { ...styles.row, background: '#f5f5f5' }
    }
    const note = _.filter([typeOptions[item.inventory_type], seatingAreasLookup[item.seating_area]]).join(', ')
    return (
      <div key={`inventory-edit-${item.id}`} style={rowStyle}>
        <div className="title" style={$.extend({}, styles.cell, styles.title)}>
          <h3
            style={$.extend({}, styles.info, {
              color: item.hide_inventory ? '#999' : '#0a0a0a',
            })}
          >
            {item.inventory_name}
          </h3>
          <p style={styles.note} className="note">
            {note}
          </p>
        </div>
        {item.hide_inventory ? (
          <div className="availability" style={$.extend({}, styles.cell, styles.availability)}>
            <p style={styles.hiddenText} className="note">
              Hidden
            </p>
          </div>
        ) : (
          <span>
            <div className="availability" style={$.extend({}, styles.cell, styles.availability)}>
              <h3 style={styles.info} className="sub">
                {item.inventory_type === 'REQUEST' ? 'n/a' : item.total_quantity}
              </h3>
              <p style={styles.note} className="note">
                available
              </p>
            </div>
            <div className="price" style={$.extend({}, styles.cell, styles.price)}>
              <h3 style={styles.info} className="sub">
                {item.inventory_type === 'REQUEST' ? 'n/a' : null}
                {item.inventory_type === 'GUESTLIST_TICKET_FREE' ? item.comp_text : null}
                {item.inventory_type === 'GUESTLIST_TICKET' || item.inventory_type === 'RESERVATION'
                  ? priceFormatter.format(item.price, {
                      code: uxdata.currencyCode,
                    })
                  : null}
              </h3>
              <p style={styles.note} className="note">
                {item.price_notes}
              </p>
            </div>
          </span>
        )}
        <ReactTooltip id={`tip-${item.id}`} effect="solid" />
        {eventId !== 'new' ? (
          <div className="actions" style={$.extend({}, styles.cell, styles.actions)}>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              style={actionClone}
              onClick={cloneInventory.bind(null, item.id)}
              data-for={`tip-${item.id}`}
              data-tip="Clone inventory"
              data-test="sr-button-clone_inventory"
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              style={actionEdit}
              onClick={onEditClick.bind(null, item.id)}
              data-for={`tip-${item.id}`}
              data-tip="Edit settings"
              data-test="sr-button-edit_setting_inventory"
            />
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <p
              style={actionDelete}
              onClick={deleteInventoryConfirm.bind(null, item.id)}
              data-for={`tip-${item.id}`}
              data-tip="Delete inventory"
              data-test="sr-button-delete_inventory"
            />
            {showUpButton && (
              <UtilButton
                onClick={event => moveInventoryDownHandler(event, item)}
                tooltip="Move down"
                imgSrc={`${window.globalInit.mediaUrl}images/icons/priority-down-button.png`}
                customButton={css`
                  background-size: 33px 37px;
                `}
              />
            )}
            {!showUpButton && showDownButton && <div style={actionBlock} />}
            {showUpButton && !showDownButton && <div style={actionBlock} />}
            {showDownButton && (
              <UtilButton
                onClick={event => moveInventoryUpHandler(event, item)}
                tooltip="Move up"
                imgSrc={`${window.globalInit.mediaUrl}images/icons/priority-up-button.png`}
                customButton={css`
                  background-size: 33px 37px;
                `}
              />
            )}
          </div>
        ) : null}
      </div>
    )
  }

  let deleteTitle = ''

  const event = eventListData && eventListData.find(item => item.id === eventId)
  const items = event && event.inventories.slice().sort((a, b) => a.view_order - b.view_order)

  const buildList = () => {
    // load these with corresponding ids

    if (!items) {
      return []
    }

    const itemRender = []
    let price_notes = []
    for (const [index, item] of items.entries()) {
      item.available = item.total_available - item.total_purchased
      item.type_display = typeOptions[item.type]
      price_notes = []
      if (item.charge_tax) {
        price_notes.push('+tax')
      }
      if (item.apply_service_charge) {
        let { service_charge_amount } = item
        if (item.service_charge_type === 'DEFAULT_SERVICE_CHARGE') {
          service_charge_amount = Number(document.getElementById('default_service_charge').value)
        }
        price_notes.push(`+${service_charge_amount}% service charge`)
      }
      if (item.charge_gratuity) {
        if (item.gratuity_amount_type === 'DEFAULT_FIXED') {
          const gratuity_amount = Number(document.getElementById('default_gratuity').value)
          price_notes.push(`+${gratuity_amount}% gratuity`)
        } else if (item.gratuity_amount_type === 'CUSTOM_FIXED') {
          price_notes.push(`+${item.gratuity_amount}% gratuity`)
        } else {
          price_notes.push('+gratuity')
        }
      }
      if (item.charge_additional_fee) {
        price_notes.push('+fee')
      }
      if (item.charge_additional_fee_tax) {
        price_notes.push('+fee tax')
      }
      item.price_notes = price_notes.join(', ')

      itemRender.push(loadRow(item, index !== items.length - 1, index !== 0))

      if (item.id === uxdata.deletingInventory) {
        deleteTitle = item.inventory_name
      }
    }

    return itemRender
  }

  const inventoryHeader = <p className="group-label">Inventory types</p>
  const warningStyle = { ...styles.note, marginBottom: 10, color: '#c00' }

  return (
    <div id="inventory-list">
      {inventoryHeader}
      {eventId === 'new' && items.length ? <p style={warningStyle}>Please save this event before managing inventory.</p> : null}
      {buildList()}
      <div style={{ clear: 'both' }}>
        <div className="button">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            style={styles.newInventory}
            data-test="sr-button-add_inventory"
            onClick={onAddClick.bind(null, 'new')}
          >
            <span style={styles.buttonAdd}>+</span> Add inventory
          </a>
        </div>
      </div>

      {uxdata.deletingInventory ? (
        <div>
          <div style={shadow} />
          <div style={areYouSure}>
            Are you sure you want to delete the inventory <em>{deleteTitle}</em>
            ?
            <ControlBar
              cancelAction={deleteInventoryConfirm.bind(null, null)}
              saveAction={deleteInventory.bind(null, uxdata.deletingInventory)}
              saveText="Delete"
              addStyles={sureControls}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  moveInventoryDown: (eventId, inventoryId) => {
    dispatch(moveInventoryDown(eventId, inventoryId))
  },
  moveInventoryUp: (eventId, inventoryId) => {
    dispatch(moveInventoryUp(eventId, inventoryId))
  },
})

export default connect(undefined, mapDispatchToProps)(ListInventory)

import { useTriggerConciergeAccessReportsMutation } from '@sevenrooms/core/api'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button, FormInput, Label, FormSelect, Form } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Header } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { conciergeAccessReportMessages } from '../locales'

const reportTypeOptions = [
  { id: 'whitelist_report', label: 'Whitelist' },
  { id: 'rules_report', label: 'Rules' },
  { id: 'availability_report', label: 'Availability' },
]

const accessReportForm = z.object({
  email: z.string(),
  reportType: z.string(),
  date: z.string(),
  startTime: z.string(),
  endTime: z.string(),
})

const accessFormDefaults = {
  email: '@sevenrooms.com',
  reportType: '',
  date: '',
  startTime: '00:01:00',
  endTime: '23:59:00',
}

type accessReportFormType = z.infer<typeof accessReportForm>

export function ConciergeAccessReportForm(props: { conciergeId: string }) {
  const { formatMessage } = useLocales()
  const { conciergeId } = props
  const [triggerReport, _mutationData] = useTriggerConciergeAccessReportsMutation()
  const form = useForm(accessReportForm, {
    defaultValues: accessFormDefaults,
    mode: 'onSubmit',
  })
  const { field } = form

  const onSubmit = async (data: accessReportFormType) => {
    const response = await triggerReport({ conciergeId, data })
    if ('data' in response && response.data.success) {
      notify({ content: 'Report triggered successfully.', type: 'success' })
    } else {
      notify({ content: 'Error triggering report. Check inputs and try again.', type: 'error' })
    }
  }

  return (
    <>
      <Header>{formatMessage(conciergeAccessReportMessages.title)}</Header>
      <Form {...form} onSubmit={onSubmit} onInvalid={() => {}}>
        <VStack>
          <HStack spacing="m" p="m">
            <Label width="100%" primary="Report Type">
              <FormSelect
                field={field.prop('reportType')}
                placeholder="Select Report Type"
                options={reportTypeOptions}
                searchable={false}
              />
            </Label>
          </HStack>
          <HStack spacing="m" p="m">
            <Label width="100%" primary="Date (formatted MM/DD/YY)">
              <FormInput fullWidth field={field.prop('date')} />
            </Label>
          </HStack>
        </VStack>

        <VStack>
          <HStack spacing="m" p="m">
            <Label width="50%" primary="Start time (formatted HH:MM:SS)">
              <FormInput fullWidth field={field.prop('startTime')} />
            </Label>
            <Label width="50%" primary="End time (formatted HH:MM:SS)">
              <FormInput fullWidth field={field.prop('endTime')} />
            </Label>
          </HStack>
        </VStack>

        <VStack>
          <HStack spacing="m" p="m">
            <Label width="100%" primary="Email">
              <FormInput fullWidth field={field.prop('email')} />
            </Label>
          </HStack>
        </VStack>
        <Button data-test="report-submit-button" variant="primary" type="submit">
          Run Report
        </Button>
      </Form>
    </>
  )
}

export function ConciergeAcessReport() {
  const nav = useNavigation()
  const params = nav.matchParams(routes.admin.conciergeAccessReports)
  const { conciergeId } = params as { conciergeId: string }
  return (
    <>
      <ConciergeAccessReportForm conciergeId={conciergeId} />
    </>
  )
}

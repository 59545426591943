// This file was automatically generated from admin.venue.dataimport.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueBulkDataImport = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueBulkDataImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueBulkDataImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import">');
  if (opt_data.content.error_msgs) {
    output.append('<div class="error-message">');
    var errorList2563 = opt_data.content.error_msgs;
    var errorListLen2563 = errorList2563.length;
    for (var errorIndex2563 = 0; errorIndex2563 < errorListLen2563; errorIndex2563++) {
      var errorData2563 = errorList2563[errorIndex2563];
      output.append('<span style="display:block">', soy.$$escapeHtml(errorData2563), '</span>');
    }
    output.append('</div>');
  }
  if (opt_data.content.success_msgs) {
    output.append('<div class="gold-message">');
    var successList2572 = opt_data.content.success_msgs;
    var successListLen2572 = successList2572.length;
    for (var successIndex2572 = 0; successIndex2572 < successListLen2572; successIndex2572++) {
      var successData2572 = successList2572[successIndex2572];
      output.append('<span style="display:block">', soy.$$escapeHtml(successData2572), '</span>');
    }
    output.append('</div>');
  }
  output.append('<br><div class="content-header">Bulk Data Import Tool</div><br><div class="content-header">Venue: ', soy.$$escapeHtml(opt_data.content.venue.name), '</div><br><div class="content-header">Upload Data File</div><form id="bulk-data-import" method="post" enctype="multipart/form-data" style="margin: 20px"><input name="file" type="file"><br><br><select id="select-adapter" name="adapter" form="bulk-data-import" required><option value="">Select import type</option>');
  var adapterList2581 = opt_data.content.adapters;
  var adapterListLen2581 = adapterList2581.length;
  for (var adapterIndex2581 = 0; adapterIndex2581 < adapterListLen2581; adapterIndex2581++) {
    var adapterData2581 = adapterList2581[adapterIndex2581];
    output.append('<option value="', soy.$$escapeHtml(adapterData2581), '">', soy.$$escapeHtml(adapterData2581), '</option>');
  }
  output.append('</select><span id="data-type-invalid" style="margin: 0 7px; display: none; color: red;">Must select a data type</span><br><br><label style="display: block; margin-bottom: 5px;" for="additional-parameters">Additional Parameters</label><textarea style="width: 240px; height: 50px;"id="additional-parameters" name="additional-parameters"></textarea><br><br><input type="submit" value="Upload"></form><script>var params = {rurl: location.protocol + \'//\' + location.host + \'/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/data/upload\'}; $.get( \'/upload-url\', params, (result) => {$(\'#bulk-data-import\').attr(\'action\', result.upload_url);});<\/script><br><br><div class="content-header">Data Import Files</div><br><style>td {text-align: center;}</style><table style="width:100%"><tr><th>File</th><th>Import Type</th><th>Check Header</th><th>Validate Data</th><th>Import Data</th></tr>');
  var file_detailsList2591 = opt_data.content.file_details_list;
  var file_detailsListLen2591 = file_detailsList2591.length;
  for (var file_detailsIndex2591 = 0; file_detailsIndex2591 < file_detailsListLen2591; file_detailsIndex2591++) {
    var file_detailsData2591 = file_detailsList2591[file_detailsIndex2591];
    output.append('<tr><td>', soy.$$escapeHtml(file_detailsData2591.filename), '</td><td>', soy.$$escapeHtml(file_detailsData2591.adapter), '</td><td><a href="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/data/', soy.$$escapeHtml(file_detailsData2591.validate_id), '/headers">Link</a></td><td>', (file_detailsData2591.awaiting_validation) ? '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2591.validate_id) + '/validate">Kick Off</a>' : '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2591.validate_id) + '/report">Report</a>', '</td><td>', (file_detailsData2591.awaiting_validation) ? '<span>Must validate first</span>' : (file_detailsData2591.awaiting_import) ? '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2591.import_id) + '/import">Kick Off</a>' : '<a href="/admin/venue/' + soy.$$escapeHtml(opt_data.content.venue.id) + '/data/' + soy.$$escapeHtml(file_detailsData2591.import_id) + '/report">Report</a>', '</td></tr>');
  }
  output.append('</table>', (opt_data.content.no_data) ? '<div style="background-color: lightgray; text-align: center; font-size: 15px; padding: 12px;">No files have been uploaded.</div>' : '', '</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueImportFileHeaderDetails = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.VenueImportFileHeaderDetailsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueImportFileHeaderDetailsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import-headers"><div class="content-header">Import File Header Fields</div><style>.descriptors {display: inline-block; margin: 7px 0; font-size: 14px;}td {text-align: center;}.headers {padding: 7px; font-size: 14px; font-weight: 600; display: table-cell; vertical-align: top; width: 50%;}.header-desc {font-style: italic; height: 51px;}.valid-fields {background-color: #f3f3f3;}.invalid-fields {background-color: #f3f3f3;}</style><br><span class=\'descriptors\'>Venue Name:  ', soy.$$escapeHtml(opt_data.content.venue.name), '</span><br><span class=\'descriptors\'>File Name:  ', soy.$$escapeHtml(opt_data.content.filename), '</span><br><span class=\'descriptors\'>Import type:  ', soy.$$escapeHtml(opt_data.content.adapter), '</span><br>');
  if (opt_data.content.missing_fields) {
    output.append('<div style="font-style: italic; margin: 30px 0; font-size: 15px;"><span>Missing fields</span><p>Below is a list of the fields that are missing from the import file. These fields are required, the import cannot proceed without the fields.</p><p style="color: red;">');
    var fieldList2651 = opt_data.content.missing_fields;
    var fieldListLen2651 = fieldList2651.length;
    for (var fieldIndex2651 = 0; fieldIndex2651 < fieldListLen2651; fieldIndex2651++) {
      var fieldData2651 = fieldList2651[fieldIndex2651];
      output.append((! (fieldIndex2651 == fieldListLen2651 - 1)) ? '<span>' + soy.$$escapeHtml(fieldData2651) + '</span>,  ' : '<span>' + soy.$$escapeHtml(fieldData2651) + '</span>');
    }
    output.append('</p></div>');
  }
  output.append('<div style="display: table-cell; margin-top: 5px;">');
  if (opt_data.content.used_fields) {
    output.append('<div class=\'headers valid-fields\'><h6>Valid Fields</h6><p class=\'header-desc\'>These fields from the import file will be used in the import process.</p><table style="margin: 0 auto;"><tr><th>Valid File Fields</th><tr>');
    var fieldList2667 = opt_data.content.used_fields;
    var fieldListLen2667 = fieldList2667.length;
    for (var fieldIndex2667 = 0; fieldIndex2667 < fieldListLen2667; fieldIndex2667++) {
      var fieldData2667 = fieldList2667[fieldIndex2667];
      output.append('<tr><td>', soy.$$escapeHtml(fieldData2667), '</td></tr>');
    }
    output.append('</table></div>');
  }
  if (opt_data.content.unused_fields) {
    output.append('<div class=\'headers invalid-fields\'><h6>Invalid Fields</h6><p class=\'header-desc\'>These fields from the import file do not have corresponding fields in the Sevenrooms system, and will not be pulled into the system when the file is imported.</p><table style="margin: 0 auto;"><tr><th>Invalid File Fields</th><tr>');
    var fieldList2676 = opt_data.content.unused_fields;
    var fieldListLen2676 = fieldList2676.length;
    for (var fieldIndex2676 = 0; fieldIndex2676 < fieldListLen2676; fieldIndex2676++) {
      var fieldData2676 = fieldList2676[fieldIndex2676];
      output.append('<tr><td>', soy.$$escapeHtml(fieldData2676), '</td></tr>');
    }
    output.append('</table></div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ImportJobDetails = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Admin.ImportJobDetailsBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.ImportJobDetailsBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-data-import-headers"><div class="content-header">Import Job</div><style>.summary-items {display: inline-block; margin: 3px 0; width: 50%;}</style><h3>', soy.$$escapeHtml(opt_data.content.venue.name), '</h3><h3>', soy.$$escapeHtml(opt_data.content.filename), '</h3><h3>', soy.$$escapeHtml(opt_data.content.job_type), '</h3><h6>Import job progress bar</h6><progress value="', soy.$$escapeHtml(opt_data.content.progress[0]), '" max="', soy.$$escapeHtml(opt_data.content.progress[1]), '"></progress><div style="background-color: #e4e3e3; padding: 7px; margin: 7px 0;"><h6 style="margin-bottom:7px;">Job Summary</h6>');
  var sumList2698 = opt_data.content.summary;
  var sumListLen2698 = sumList2698.length;
  for (var sumIndex2698 = 0; sumIndex2698 < sumListLen2698; sumIndex2698++) {
    var sumData2698 = sumList2698[sumIndex2698];
    output.append('<div class="summary-items">', soy.$$escapeHtml(sumData2698), '</div>');
  }
  output.append('</div><div style="background-color: #f9f8f8; padding: 7px; margin: 7px 0;"><h6 style="margin-bottom:7px;">Row Details</h6>');
  var detailList2704 = opt_data.content.details;
  var detailListLen2704 = detailList2704.length;
  for (var detailIndex2704 = 0; detailIndex2704 < detailListLen2704; detailIndex2704++) {
    var detailData2704 = detailList2704[detailIndex2704];
    output.append((detailData2704[0] == 'ERROR') ? '<div style="background-color: #ffbfbf;">' : (detailData2704[0] == 'WARNING') ? '<div style="background-color: #ffeebf;">' : '<div style="background-color: #d0e6fd;">', soy.$$escapeHtml(detailData2704[1]), '</div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import React, { useCallback, useMemo } from 'react'
import { useLocales, type FormatMessage } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'
import type { AccessListRowDuration, AccessListRowGuestDurationPicker } from '../../../views/AccessRulesList/rowInterfaces'

interface DurationFieldProps {
  durations: AccessListRowDuration
  guestDurationPicker?: AccessListRowGuestDurationPicker
  rowId: string
  isFieldShift: boolean
}

const formatDuration = (duration: number, formatMessage: FormatMessage): string => {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  const hoursPlural = hours !== 1
  const minutesPlural = minutes !== 1

  if (hours > 0 && minutes > 0) {
    return formatMessage(accessRulesListMessages.hoursAndMinutes, {
      hours,
      minutes,
      hoursPlural,
      minutesPlural,
    })
  }
  if (hours > 0) {
    return formatMessage(accessRulesListMessages.hours, { hours, plural: hoursPlural })
  }
  return formatMessage(accessRulesListMessages.minutes, { minutes, plural: minutesPlural })
}

function DurationField({ durations, guestDurationPicker, rowId, isFieldShift }: DurationFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_NON_DEFAULT_GROUPS = 1

  const renderDurationRows = useCallback(
    (partyDurations: Record<string, number>) => {
      const sortedDurations = Object.entries(partyDurations).sort(([a], [b]) => {
        const parseKey = (key: string) => (key.includes('+') ? 10 : parseInt(key, 10))
        return parseKey(a) - parseKey(b)
      })

      return sortedDurations.map(([partySize, duration]) => (
        <Box key={`${rowId}-${partySize}`} display="flex" gap="8px">
          <Box display="flex" alignItems="center" gap="4px">
            <PeopleOutlineOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
            <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'} noWrap width={32}>
              {partySize}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="4px">
            <AccessTimeOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
            <Typography color={isFieldShift ? 'textSecondary' : 'textPrimary'} variant="body2">
              {formatDuration(duration, formatMessage)}
            </Typography>
          </Box>
        </Box>
      ))
    },
    [formatMessage, isFieldShift, rowId]
  )

  const renderGuestDurationPicker = useCallback(
    (guestDurationPicker: AccessListRowGuestDurationPicker) => (
      <>
        <Box display="flex" gap="28px">
          <Box display="flex" alignItems="center" gap="4px">
            <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'} noWrap width={32}>
              {formatMessage(accessRulesListMessages.guestDurationMin)}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="4px">
            <AccessTimeOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
            <Typography color={isFieldShift ? 'textSecondary' : 'textPrimary'} variant="body2">
              {formatDuration(guestDurationPicker.min, formatMessage)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap="28px">
          <Box display="flex" alignItems="center" gap="4px">
            <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'} noWrap width={32}>
              {formatMessage(accessRulesListMessages.guestDurationMax)}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="4px">
            <AccessTimeOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
            <Typography color={isFieldShift ? 'textSecondary' : 'textPrimary'} variant="body2">
              {formatDuration(guestDurationPicker.max, formatMessage)}
            </Typography>
          </Box>
        </Box>
      </>
    ),
    [formatMessage, isFieldShift]
  )

  const timeKeys = useMemo(() => Object.entries(durations), [durations])

  if (guestDurationPicker) {
    return (
      <Box display="flex" flexDirection="column" gap="8px">
        <Box display="flex" flexDirection="column" gap="4px">
          <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
            {formatMessage(accessRulesListMessages.guestDurationPicker)}
          </Typography>
          {renderGuestDurationPicker(guestDurationPicker)}
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {timeKeys
        .filter(([timeKey]) => timeKey === 'default')
        .map(([timeKey, partyDurations]) => (
          <Box key={`${rowId}-${timeKey}`} display="flex" flexDirection="column" gap="4px">
            {renderDurationRows(partyDurations)}
          </Box>
        ))}

      {timeKeys
        .filter(([timeKey]) => timeKey !== 'default')
        .slice(0, MAX_NON_DEFAULT_GROUPS)
        .map(([timeKey, partyDurations]) => (
          <Box key={`${rowId}-${timeKey}`} display="flex" flexDirection="column" gap="4px">
            <Box
              key={`${rowId}-${timeKey}-timeslot`}
              sx={{
                height: 22,
                width: 52,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 1,
                py: 0.5,
                borderRadius: '4px',
                backgroundColor: blueGrey[100],
              }}
            >
              <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
                {TimeOnly.fromSafe(timeKey)?.formatSTime()}
              </Typography>
            </Box>
            {renderDurationRows(partyDurations)}
          </Box>
        ))}
      {timeKeys.filter(([timeKey]) => timeKey !== 'default').length > MAX_NON_DEFAULT_GROUPS && (
        <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
          {formatMessage(accessRulesListMessages.xMoreTimes, {
            count: timeKeys.filter(([timeKey]) => timeKey !== 'default').length - MAX_NON_DEFAULT_GROUPS,
          })}
        </Typography>
      )}
    </Box>
  )
}

export { DurationField }

import * as React from 'react'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Card } from '@sevenrooms/react-components/components/Card'
import { CardActions } from '@sevenrooms/react-components/components/CardActions'
import { CardContent } from '@sevenrooms/react-components/components/CardContent'
import { CardMedia } from '@sevenrooms/react-components/components/CardMedia'

interface MarketingTemplatesCardProps {
  templateName: string
  templateTeaser: string
  offerImage: string
  buttonText: string
  onClick: () => void
}

export function MarketingTemplatesCard({ templateName, templateTeaser, offerImage, buttonText, onClick }: MarketingTemplatesCardProps) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        width: 210,
        height: 190,
        padding: '12px',
        border: `1px solid ${theme.colors.borders}`,
        borderRadius: `${theme.borderRadius.s}`,
        boxShadow: 0,
      }}
    >
      <CardMedia
        sx={{ height: 62, borderRadius: 1, marginTop: 0, marginLeft: 0, marginRight: 0 }}
        image={offerImage}
        title="template-card-img"
      />
      <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <VStack spacing="s">
          <Text textStyle="body2Medium">{templateName}</Text>
          <Text textStyle="body2">{templateTeaser}</Text>
        </VStack>
      </CardContent>
      <CardActions sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Button noPadding variant="tertiary" size="xs" onClick={onClick} data-test="create-from-template-btn">
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  )
}

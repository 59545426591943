import { useEffect, useMemo, useState } from 'react'
import type { ReservationWidget as ReservationWidgetModel } from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import {
  type AvailabilityTimeWithUpSellCost,
  useReservationsRoute,
  useSingleDayAvailability,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
} from '../../hooks'
import { type PrivateEventsExperience, useExperiencesWithAvailability } from '../../hooks/useAvailability'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import {
  OtherDaysReservationAvailability,
  OtherVenuesAvailability,
  type OtherDaysReservationAvailabilityProps,
  type OtherVenueInfo,
} from '../AlternativeAvailability'
import { PrimaryAvailability } from '../PrimaryAvailability'
import { SkeletonBox } from '../SkeletonBox'

export interface AvailabilityResultsProps {
  venuesInfo?: ReservationWidgetModel.VenueInfo
  venueId: string
  startDate: Date
  haloTimeIntervalMinutes: number
  startTime: string
  partySize: number
  onAvailabilitySelect: OtherDaysReservationAvailabilityProps['onTimeSlotClick']
  handleOnDateClick: OtherDaysReservationAvailabilityProps['onDateClick']
  onPrivateEventsExploreClick?: () => void
  onPrivateEventsTimeSlotClick?: (slot: AvailabilityTimeWithUpSellCost, experience: PrivateEventsExperience) => void
  onPrivateEventsMoreDetailsClick?: (experience: PrivateEventsExperience) => void
  onClearTimeFilter: () => void
  isDayBlocked: (day: Date) => boolean
  clientId?: string
  showTabs: boolean
}

export function AvailabilityResults({
  venuesInfo,
  startDate,
  startTime,
  partySize,
  haloTimeIntervalMinutes,
  onAvailabilitySelect,
  handleOnDateClick,
  onClearTimeFilter,
  isDayBlocked,
  clientId,
  onPrivateEventsExploreClick,
  onPrivateEventsTimeSlotClick,
  onPrivateEventsMoreDetailsClick,
  showTabs,
}: AvailabilityResultsProps) {
  const venue = useVenue()
  const { channel } = useReservationsRoute()
  const widgetSettings = useWidgetSettings()
  const { formatMessage } = useLocales()
  const { selectedLanguage } = useWidgetLanguage()
  const [isOtherDatesSectionCollapsed, setIsOtherDatesSectionCollapsed] = useState(true)
  const [otherVenuesCollapsed, setOtherVenuesCollapsed] = useState(true)
  const { defaultSearchTime, enablePrivateEventsBanner } = widgetSettings
  const { startOfDayTime, urlKey, id: venueId } = venue
  const dateISO = DateOnly.fromDate(startDate).toIso()

  const {
    isFetching: isAvailabilityFetching,
    isClosed,
    data,
  } = useSingleDayAvailability({
    venueId,
    venue: urlKey,
    startDate: dateISO,
    startTime,
    partySize,
    selectedLangCode: selectedLanguage,
    haloTimeIntervalMinutes,
    channel: channel || ReservationWidget.SevenRoomsWidgetChannel,
    haloSizeInterval: ReservationWidget.WholeDayHaloSizeInterval,
    timeSlot: defaultSearchTime,
    startOfDayTime,
    skipRequests: false,
    clientId,
  })

  const { data: experiences, isFetching: isFetchingExperiences } = useExperiencesWithAvailability({
    venue: venue.id,
    partySize,
    startDate: startTime ? DateOnly.fromDate(startDate).toIso() : undefined,
    clientId,
    selectedLangCode: selectedLanguage,
    skipRequests: !showTabs,
    startTime,
    haloTimeIntervalMinutes,
    startOfDayTime,
  })

  const experiencesToDisplay = useMemo(() => experiences?.filter(experience => !!experience.availabilities?.length), [experiences])
  const showPrivateEventsBanner = showTabs && enablePrivateEventsBanner && !isFetchingExperiences && !experiencesToDisplay?.length

  useEffect(() => {
    // Should collapse when the filter values changes.
    setIsOtherDatesSectionCollapsed(true)
    setOtherVenuesCollapsed(true)
  }, [startDate, haloTimeIntervalMinutes, startTime, partySize])

  useEffect(() => {
    if (data?.reservationTimesInHalo.length === 0) {
      // Should collapse and expand to refresh data
      setIsOtherDatesSectionCollapsed(true)
      setTimeout(() => {
        setIsOtherDatesSectionCollapsed(false)
      })
    }
  }, [data])

  return (
    <>
      <PrimaryAvailability
        onClearTimeFilter={onClearTimeFilter}
        partySize={partySize}
        startDate={DateOnly.fromDate(startDate).toIso()}
        isDayBlocked={isDayBlocked}
        onClick={onAvailabilitySelect}
        venueCurrencyCode={venue.currencyCode}
        reservationTimesInHalo={data?.reservationTimesInHalo || []}
        requestTimeRange={data?.requestTimeRange || []}
        reservationTimes={data?.reservationTimes || []}
        experiences={experiencesToDisplay}
        isAvailabilityFetching={isAvailabilityFetching}
        isClosed={isClosed}
        showPrivateEventsBanner={showPrivateEventsBanner}
        onPrivateEventsExploreClick={onPrivateEventsExploreClick}
        onPrivateEventsTimeSlotClick={onPrivateEventsTimeSlotClick}
        onPrivateEventsMoreDetailsClick={onPrivateEventsMoreDetailsClick}
      />
      {venue.multiVenueReservationEnabled &&
        venuesInfo &&
        (isAvailabilityFetching ? (
          <SkeletonBox height="58px" mt="lm" />
        ) : (
          <OtherVenuesAvailability
            partySize={partySize}
            startTime={startTime}
            startDate={DateOnly.fromDate(startDate).toIso()}
            defaultSearchTime={defaultSearchTime}
            haloTimeIntervalMinutes={haloTimeIntervalMinutes}
            clientId={clientId}
            otherVenues={venuesInfo.venues.filter(v => v.urlKey !== urlKey)}
            selectedLangCode={selectedLanguage}
            startOfDayTime={venue.startOfDayTime}
            currencyCode={venue.currencyCode}
            title={formatMessage(reservationWidgetMessages.resWidgetOtherLocationsLabel)}
            onTimeSlotClick={(slot: AvailabilityTimeWithUpSellCost, venue: OtherVenueInfo) => onAvailabilitySelect(slot, undefined, venue)}
            collapsed={otherVenuesCollapsed}
            setCollapsed={setOtherVenuesCollapsed}
          />
        ))}
      <Box pt="lm">
        {isAvailabilityFetching ? (
          <SkeletonBox height="58px" />
        ) : (
          <OtherDaysReservationAvailability
            partySize={partySize}
            startTime={startTime}
            startDate={startDate}
            haloTimeIntervalMinutes={haloTimeIntervalMinutes}
            clientId={clientId}
            noAvailabilityMessage={formatMessage(reservationWidgetMessages.resWidgetNoAdditionalAvail)}
            onDateClick={handleOnDateClick}
            onTimeSlotClick={onAvailabilitySelect}
            title={formatMessage(reservationWidgetMessages.resWidgetOtherDatesLabel, { venue: venue.name })}
            venueCurrencyCode={venue.currencyCode}
            isCollapsed={isOtherDatesSectionCollapsed}
            onToggleCollapse={(isCollapsed: boolean) => {
              setIsOtherDatesSectionCollapsed(isCollapsed)
            }}
          />
        )}
      </Box>
    </>
  )
}

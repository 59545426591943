import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { useContext, useMemo } from 'react'
import {
  useGetAccessRulesOverviewQuery,
  useGetAccessRulesQuery,
  useGetShiftsScheduleQuery,
  useGetSeatingAreasTablesQueryNew,
  useGetAudienceHierarchyQuery,
  useGetExperiencesQuery,
  useGetUpsellsQuery,
  useGetShiftsSummaryQuery,
} from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, getDateFNSLocale, startOfWeek } from '@sevenrooms/core/timepiece'
import { SettingsPageContent, SettingsPageContext, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Divider } from '@sevenrooms/react-components/components/Divider'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { SevenRoomsProgress } from '@sevenrooms/react-components/components/SevenRoomsProgress'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { AccessRulesActionButtons } from '../../components/ActionButtons/AccessRulesActionButtons'
import { ActionsBar } from '../../components/ActionsBar/ActionsBar'
import { AccessRuleSlideoutProvider } from '../../contexts/AccessRuleSlideoutProvider'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams, useVenueTodayDate } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'
import { AccessRulesCalendar } from '../AccessRulesCalendar/AccessRulesCalendar'
import { AccessRulesList } from '../AccessRulesList/AccessRulesList'

function AccessRulesSettingsContainer({ children }: React.PropsWithChildren<{}>) {
  const headerHeight = useContext(SettingsPageContext)

  return (
    <Box height="100%" sx={{ backgroundColor: theme => theme.palette.common.white }}>
      <Stack height="100%" maxHeight={`calc(100vh - ${headerHeight}px)`} padding={4} boxSizing="border-box">
        {children}
      </Stack>
    </Box>
  )
}

export function AccessRulesSettings() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueLocale } = useAppContext()
  const today = useVenueTodayDate()
  const locale = getDateFNSLocale(venueLocale)

  const [{ view, range, date }] = useAccessRulesUrlParams()

  const [start, end, overviewDates] = useMemo(() => {
    const start = startOfWeek(date.toJsDate(), { weekStartsOn: 0 })
    return [
      start,
      new Date(new Date(start).setDate(start.getDate() + 6)),
      Array.from({ length: 7 }, (_, i: number) => new Date(new Date(start).setDate(start.getDate() + i))).map(x => DateOnly.fromDate(x)),
    ]
  }, [date])

  const useListOverviewQuery = range === AccessRuleRange.OVERVIEW && view === AccessRuleView.LIST

  const shifts = useGetShiftsScheduleQuery(
    {
      venueId: venue.id,
      startDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(start),
      endDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(end),
    },
    { skip: useListOverviewQuery }
  )

  const shiftsOverview = useGetShiftsSummaryQuery({ venueId: venue.id, date: today }, { skip: !useListOverviewQuery })

  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId: venue.id })
  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId: venue.id })
  const experiences = useGetExperiencesQuery({ venueId: venue.id })
  const upsells = useGetUpsellsQuery({ venueId: venue.id })

  // Note: Each of these queries returns a different structured object, so we need to handle them differently in child components.
  // It could either be an AccessRules object (for date ranges) or an array of AccessRule objects (when grabbing all).
  const accessRulesByRange = useGetAccessRulesQuery(
    {
      venueId: venue.id,
      startDate: range === AccessRuleRange.DAY ? date.toIso() : start.toISOString(),
      endDate: range === AccessRuleRange.DAY ? date.toIso() : end.toISOString(),
    },
    { skip: useListOverviewQuery }
  )
  const accessRulesOverview = useGetAccessRulesOverviewQuery({ venueId: venue.id }, { skip: !useListOverviewQuery })

  const accessRules = useListOverviewQuery ? accessRulesOverview : accessRulesByRange

  if (!venue) {
    return null
  }

  const isFetching =
    shifts.isFetching ||
    shiftsOverview.isFetching ||
    accessRules.isFetching ||
    seatingAreasTables.isFetching ||
    audienceHierarchy.isFetching ||
    experiences.isFetching ||
    upsells.isFetching

  return (
    <ThemeProvider theme={vmsTheme}>
      <LocalizationProvider adapterLocale={locale}>
        <AccessRuleSlideoutProvider>
          <SettingsPageMeta title={formatMessage(accessRulesSettingsMessages.title)} />
          <SettingsPageContent
            secondHeaderMaxWidth="100%"
            secondHeaderTextMaxWidth="90%"
            title={
              <Box width="200px" height="42px" sx={{ lineHeight: '42px' }}>
                {formatMessage(accessRulesSettingsMessages.title)}
                <Tooltip
                  title={
                    <Typography>
                      {formatMessage(accessRulesSettingsMessages.subtitle, {
                        a: (chunks: string[]) => (
                          <Link
                            data-test="access-rules-settings-help"
                            href="https://help.sevenrooms.com/hc/en-us/categories/360004583192-Availability"
                          >
                            {chunks}
                          </Link>
                        ),
                      })}
                    </Typography>
                  }
                >
                  <IconButton data-test="header-info-tooltip-trigger" aria-label="Info" sx={{ marginTop: '-4px' }}>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            actions={<AccessRulesActionButtons />}
          >
            <AccessRulesSettingsContainer>
              <ActionsBar />
              <Divider sx={{ marginBottom: 4, marginTop: 4 }} />
              {!isFetching &&
              ((shifts.data && range !== AccessRuleRange.OVERVIEW) || (shiftsOverview.data && range === AccessRuleRange.OVERVIEW)) &&
              accessRules.data &&
              seatingAreasTables.data &&
              audienceHierarchy.data &&
              experiences.data &&
              upsells.data ? (
                <>
                  {view === AccessRuleView.CALENDAR ? (
                    <Box width="100%" height="100%" sx={{ overflowY: 'auto' }}>
                      <AccessRulesCalendar overviewDates={overviewDates} shifts={shifts.data} accessRules={accessRulesByRange.data} />
                    </Box>
                  ) : (
                    <Box width="100%" height="100%" sx={{ overflowY: 'auto', position: 'relative' }}>
                      <AccessRulesList
                        accessRules={accessRules.data}
                        shifts={shifts.data}
                        shiftsOverview={shiftsOverview.data}
                        seatingAreas={seatingAreasTables.data}
                        audienceHierarchy={audienceHierarchy.data}
                        experiences={experiences.data}
                        upsells={upsells.data}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <SevenRoomsProgress aria-label="Loading" />
                </Box>
              )}
            </AccessRulesSettingsContainer>
          </SettingsPageContent>
        </AccessRuleSlideoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

/* global widgetInit */
import _ from 'lodash'
import { createSelector } from 'reselect'
import { getSelectedInventoryCart } from './inventoryCartSelector'

export const selectVenueGroupMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_venue_group_policy

export const selectVenueSpecificMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_policy

export const selectVenueSmsMarketingPolicy = () => widgetInit.venueInfo.marketing_opt_in_sms_policy

export const selectReservationSmsOptInPolicy = () => widgetInit.venueInfo.reservation_sms_opt_in_policy
export const selectTailoredCommunicationOptInPolicy = () => widgetInit.venueInfo.tailored_communication_opt_in
const selectedLanguageCode = () => widgetInit.venueInfo.selected_language_code
const paymentPolicyMap = () => widgetInit.venueInfo.payment_policy_map

const getUserSelection = state => state.userSelection

const getInventoryEntities = state => state.entities.inventory

const getEventEntities = state => state.entities.events

export const selectBookingPolicy = createSelector(
  [getUserSelection, getInventoryEntities, getEventEntities, paymentPolicyMap, selectedLanguageCode, getSelectedInventoryCart],
  (userSelection, inventoryEntities, eventEntities, paymentPolicyMap, selectedLanguageCode, selectedInventoryCart) => {
    const policies = []
    selectedInventoryCart.forEach(({ inventoryId }) => {
      const currInventory = inventoryEntities.get(inventoryId)

      if (currInventory.get('customCancellationPolicy')) {
        policies.push(currInventory.get('customCancellationPolicy'))
        return
      }

      const inventoryCancellationPolicyId = currInventory.get('cancellationPolicyId')
      if (paymentPolicyMap && inventoryCancellationPolicyId) {
        if (paymentPolicyMap[inventoryCancellationPolicyId]) {
          policies.push(paymentPolicyMap[inventoryCancellationPolicyId][selectedLanguageCode])
        } else {
          policies.push(_.values(paymentPolicyMap)[0][selectedLanguageCode])
        }
      }
    })

    if (selectedInventoryCart.length === policies.length) {
      return getFormattedPolicies(policies)
    }

    const { eventId } = userSelection.toJS()
    const selectedEvent = eventEntities.toJS()[eventId]

    if (selectedEvent.customCancellationPolicy) {
      policies.push(selectedEvent.customCancellationPolicy)
      return getFormattedPolicies(policies)
    }

    const eventCancellationPolicyId = selectedEvent.cancellationPolicyId
    if (paymentPolicyMap && eventCancellationPolicyId) {
      if (paymentPolicyMap[eventCancellationPolicyId]) {
        policies.push(paymentPolicyMap[eventCancellationPolicyId][selectedLanguageCode])
        return getFormattedPolicies(policies)
      }

      policies.push(_.values(paymentPolicyMap)[0][selectedLanguageCode])
      return getFormattedPolicies(policies)
    }

    policies.push(widgetInit.venueInfo.event_widget_purchase_policy)
    return getFormattedPolicies(policies)
  }
)

export const getFormattedPolicies = policies => {
  const uniquePolicies = [...new Set(policies)]
  return uniquePolicies.map(policy => `<p>${policy}</p>`).join('')
}

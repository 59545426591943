import { type ForwardedRef, forwardRef, useMemo, type PropsWithChildren } from 'react'
import type { AccessRuleInput } from '@sevenrooms/core/api'
import { type AccessRule, type ShiftCategory, getVenueTaxRates } from '@sevenrooms/core/domain'
import type { DeepPartial } from '@sevenrooms/core/form'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { isSeatingAreaToTables } from './AccessRule.types'
import { AccessRulesSlideoutForm, type AccessRulesSlideoutRef } from './AccessRulesSlideoutForm'
import type { AccessRuleForm } from './AccessRule.zod'
import type { AccessRulesSlideoutData } from './useAccessRulesSlideoutData'

export interface AccessRulesSlideoutProps {
  mode?: 'new-item'
  selectedDay: string
  onClose: () => void
  onRuleSave: (accessRule: AccessRule) => void
  onRuleDelete: () => void
  startDate: Date
  startTimeDisplay?: string
  endTimeDisplay?: string
  shiftCategories: ShiftCategory[]
  accessRulesSlideoutData: AccessRulesSlideoutData
  accessRule?: AccessRule
  disabledFields?: string[]

  // Used by Perks to override regular onRuleSave
  onRuleSaveOverride?: ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }) => void
  // Used by new access rule slide out to set default values
  defaultValues?: DeepPartial<AccessRuleForm>

  // Used by creating Experience from Template flow
  accessRuleFormDefaults?: AccessRuleForm
  slideoutHeader?: string
  isCustomizeAccessRule?: boolean
  onCustomizeAccessRule?: (accessRule: AccessRuleForm) => void

  // Used to help with focus issues that can crop up with some new mui elements
  handleFocusableElements?: boolean
}

export const AccessRulesSlideout = forwardRef<AccessRulesSlideoutRef, PropsWithChildren<AccessRulesSlideoutProps>>(
  AccessRulesSlideoutComponent
)

function AccessRulesSlideoutComponent(props: PropsWithChildren<AccessRulesSlideoutProps>, ref: ForwardedRef<AccessRulesSlideoutRef>) {
  const { venueId } = useVenueContext()
  const { accessRulesSlideoutData } = props

  const experiences = useMemo(() => Object.values(accessRulesSlideoutData.experiences ?? {}), [accessRulesSlideoutData.experiences])
  const seatingAreaToTables = useMemo(
    () => accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables.filter(isSeatingAreaToTables) ?? [],
    [accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables]
  )
  const taxRates = useMemo(() => getVenueTaxRates(accessRulesSlideoutData.taxRates, venueId), [accessRulesSlideoutData, venueId])

  const tagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.reservationTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.reservationTagGroups]
  )
  const clientTagGroups = useMemo(
    () =>
      new Map(
        accessRulesSlideoutData.clientTagGroups
          .filter(tagGroup => tagGroup.nameDisplay === 'SevenRooms App')
          .map(tagGroup => [
            tagGroup.id,
            {
              ...tagGroup,
              tags: tagGroup.tags.filter(tag => ['Room: Member', 'Room: Eligible'].includes(tagGroup.tagNameDisplays[tag] || '')),
            },
          ])
      ),
    [accessRulesSlideoutData.clientTagGroups]
  )

  return (
    <AccessRulesSlideoutForm
      ref={ref}
      context={{
        accessRule: props.accessRule,
        allShifts: accessRulesSlideoutData.allShifts,
        audienceHierarchy: accessRulesSlideoutData.audienceHierarchy,
        defaultBookingPolicyId: accessRulesSlideoutData.defaultPolicies.bookingPolicyId ?? 'default',
        defaultCancelPolicyId: accessRulesSlideoutData.defaultPolicies.cancelPolicyId ?? 'default',
        experiences,
        policies: accessRulesSlideoutData.policies,
        seatingAreaToTables,
        tagGroups,
        taxRates,
        upsells: accessRulesSlideoutData.upsells,
        widgetSettings: accessRulesSlideoutData.reservationWidgetSettings,
        clientTagGroups,
        defaultValues: props.defaultValues,
      }}
      params={{ date: props.selectedDay, venueId }}
      startDate={props.startDate}
      startTimeDisplay={props.startTimeDisplay}
      endTimeDisplay={props.endTimeDisplay}
      shiftCategories={props.shiftCategories}
      onClose={props.onClose}
      onRuleSave={props.onRuleSave}
      onRuleDelete={props.onRuleDelete}
      mode={props.mode}
      onRuleSaveOverride={props.onRuleSaveOverride}
      disabledFields={props.disabledFields}
      accessRuleFormDefaults={props.accessRuleFormDefaults}
      slideoutHeader={props.slideoutHeader}
      isCustomizeAccessRule={props.isCustomizeAccessRule}
      onCustomizeAccessRule={props.onCustomizeAccessRule}
      handleFocusableElements={props.handleFocusableElements}
    >
      {props.children}
    </AccessRulesSlideoutForm>
  )
}

import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

interface MarketingTemplatesCarouselProps {}

interface CarouselStateProps {
  currentSlide: number
  totalItems: number
  slidesToShow: number
  containerWidth: number
}

interface CarouselButtonGroupProps {
  next?: () => {}
  previous?: () => {}
  carouselState?: CarouselStateProps
}

export function MarketingTemplatesCarousel({ children }: React.PropsWithChildren<MarketingTemplatesCarouselProps>) {
  const responsive = {
    desktop1: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    desktop2: {
      breakpoint: { max: 1224, min: 928 },
      items: 3,
    },
    desktop3: {
      breakpoint: { max: 928, min: 0 },
      items: 2,
    },
  }

  return (
    <>
      <StyledCarouselItems>
        <Carousel
          responsive={responsive}
          partialVisible={false}
          showDots={false}
          infinite={false}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={<CarouselButtonGroup />}
          containerClass="carousel-container"
          dotListClass="custom-dot-list"
          itemClass="carousel-item"
        >
          {children}
        </Carousel>
      </StyledCarouselItems>
    </>
  )
}

function CarouselButtonGroup({ next, previous, ...rest }: CarouselButtonGroupProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    carouselState: { currentSlide, totalItems, slidesToShow, containerWidth },
  } = rest

  const [componentWidth, setComponentWidth] = useState<number>(containerWidth)
  useEffect(() => {
    if (containerWidth) {
      setComponentWidth(containerWidth)
    }
  }, [containerWidth])

  return (
    <CarouselCustomButtonGroup positionLeft={`${componentWidth - 81}px`}>
      <HStack spacing="s">
        <IconButton
          borderType="round"
          onClick={() => (previous ? previous() : {})}
          iconBackgroundColor="primaryBackground"
          disabled={currentSlide === 0}
          size="s"
          icon="VMSWeb-chevron-left"
          data-test="template-navigate-previous-btn"
        />
        <IconButton
          borderType="round"
          onClick={() => (next ? next() : {})}
          iconBackgroundColor="primaryBackground"
          disabled={slidesToShow - currentSlide === totalItems}
          size="s"
          icon="VMSWeb-chevron-right"
          data-test="template-navigate-next-btn"
        />
      </HStack>
    </CarouselCustomButtonGroup>
  )
}

const StyledCarouselItems = styled.div`
  & * {
    list-style: none;
  }
  & li {
    padding-right: 0;
  }
`

const CarouselCustomButtonGroup = styled.div<{ positionLeft: string }>`
  position: relative;
  bottom: 270px;
  left: ${props => props.positionLeft};
`

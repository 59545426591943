import { useMemo } from 'react'
import type {
  EmailCampaign,
  EmailCampaignTemplate,
  OneTimeSMSCampaignTemplate,
  OneTimeSMSCampaignType,
  OngoingEmailCampaignTemplate,
} from '@sevenrooms/core/api'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTagOptionForm } from '@sevenrooms/core/ui-kit/form'
import { getAdminAutoTagOptionsFromIds, getAutoTagOptionsFromIds } from '@sevenrooms/marketing'

export type AudienceFormData = ZodSchema<typeof useAudienceForm>
export type AudienceFormField = Field<AudienceFormData>

export function useAudienceForm() {
  const multiSelectTagOptionForm = useMultiSelectTagOptionForm()
  return useMemo(
    () =>
      z.object({
        recipientAutotags: multiSelectTagOptionForm,
        excludedAutotags: multiSelectTagOptionForm,
        excludeBlacklist: z.boolean().optional(),
        expressConsentOverride: z.boolean().optional(),
        isOperationalEmail: z.boolean().optional(),
      }),
    [multiSelectTagOptionForm]
  )
}

export const getAudienceDefaultValues = (
  source?: EmailCampaign | EmailCampaignTemplate | OneTimeSMSCampaignType | OneTimeSMSCampaignTemplate,
  clientTagGroups?: GenericTagGroup[],
  excludeBlacklist?: boolean
): AudienceFormData => {
  const excludedAutotags = getAutoTagOptionsFromIds(source?.recipientClientTagsExclude, clientTagGroups)
  const recipientAutotags = getAutoTagOptionsFromIds(source?.recipientClientTags, clientTagGroups)

  if (clientTagGroups && excludeBlacklist) {
    const blacklistClientTagGroup = clientTagGroups.find(tagGroup => tagGroup.name === 'Blacklist')
    const clientTypeClientTagGroup = clientTagGroups.find(tagGroup => tagGroup.name === 'Client Type')
    if (blacklistClientTagGroup) {
      excludedAutotags.push({
        id: [blacklistClientTagGroup.privacy, blacklistClientTagGroup.id, 'Blacklist', 'Blacklist'].join('##'),
        label: 'Blacklist',
        categoryId: blacklistClientTagGroup.id,
      })
    }
    if (clientTypeClientTagGroup) {
      excludedAutotags.push({
        id: [clientTypeClientTagGroup.privacy, clientTypeClientTagGroup.id, 'Client Type', 'Blacklist'].join('##'),
        label: 'Blacklist',
        categoryId: clientTypeClientTagGroup.id,
      })
    }
  }

  return {
    excludedAutotags,
    recipientAutotags,
  }
}

export const getAdminAudienceDefaultValues = (source?: OngoingEmailCampaignTemplate): AudienceFormData => ({
  recipientAutotags: getAdminAutoTagOptionsFromIds(source?.recipientClientTags),
  excludedAutotags: getAdminAutoTagOptionsFromIds(source?.recipientClientTagsExclude),
  excludeBlacklist: source?.excludeBlacklist ?? false, // BLACKLIST_HACK
})

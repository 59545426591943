import type { VenueClassEnum, VenueSettingsOptions } from '@sevenrooms/core/domain'
import { type Field, useController, useFieldArray } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { SectionBox } from '../components'
import { MealRangeSelector } from './MealRangeSelector'
import { ShriftReportingPeriodSettingsMessages } from './ShiftReportingPeriods.locales'
import type { FormValues } from './ShiftReportingPeriods.zod'

export interface ShriftReportingPeriodProps {
  field: Field<FormValues>
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
}
export function ShiftReportingPeriods({ field }: ShriftReportingPeriodProps) {
  const { fields: reportingPeriods, append: appendReportingPeriod, remove: removeReportingPeriod } = useFieldArray(field, '' as never)
  const controller = useController(field)
  const DEFAULT_REPORTING_PERIOD = {
    name: '',
    isDefault: false,
    breakfast: {
      exists: true,
      startTime: 0,
      endTime: 300,
    },
    brunch: {
      exists: true,
      startTime: 300,
      endTime: 500,
    },
    lunch: {
      exists: true,
      startTime: 500,
      endTime: 900,
    },
    dinner: {
      exists: true,
      startTime: 900,
      endTime: 1300,
    },
    nightTime: {
      exists: true,
      startTime: 1300,
      endTime: 1440,
    },
  }
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox
        title={formatMessage(ShriftReportingPeriodSettingsMessages.reportingPeriods, {
          icon: <Icon name="VMSWeb-action-stars" size="sm" color="attentionNew" />,
        })}
      >
        <VStack mt="s" spacing="s">
          {reportingPeriods.map((reportingPeriod, index) => (
            <VStack key={reportingPeriod.id} p="l" borderWidth="s">
              <HStack>
                <FormInput
                  field={field.prop(`${index}.name`)}
                  placeholder={formatMessage(ShriftReportingPeriodSettingsMessages.enterReportingPeriodsName)}
                  fullWidth
                />
                {!controller.field.value[index]?.isDefault && (
                  <Button
                    data-test="delete-reporting-period-button"
                    onClick={() => removeReportingPeriod(index)}
                    variant="tertiary"
                    size="s"
                  >
                    <Icon size="lg" name="VMSWeb-trash" color="primaryIcons" />
                  </Button>
                )}
              </HStack>
              <MealRangeSelector field={field.prop(`${index}`)} />
            </VStack>
          ))}
        </VStack>
        <Button variant="tertiary" onClick={() => appendReportingPeriod(DEFAULT_REPORTING_PERIOD)} data-test="add-reporting-period">
          {formatMessage(ShriftReportingPeriodSettingsMessages.addReportingPeriod)}
        </Button>
      </SectionBox>
    </VStack>
  )
}

import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { customPacingKeysSort } from '../../ViewMode/utils'
import { PacingLocales } from '../Pacing.locales'
import { PacingTestId } from '../Pacing.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function PacingReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { venueStartOfDayHour, venueSettings } = useAppContext()

  const { maxCoversPerSeatingInterval, customPacing, isPacingHeld, excludeFromShiftPacing } = accessRule.pacing
  const customPacingKeysSorted = customPacingKeysSort(customPacing, venueStartOfDayHour)

  return (
    <ReportPart caption={formatMessage(PacingLocales.title)} data-test={PacingTestId.report} position={position}>
      {maxCoversPerSeatingInterval === null ? (
        <ReportLine name={formatMessage(PacingLocales.pacing)}>
          <Text>{formatMessage(accessRulesMessages.followShift)}</Text>
        </ReportLine>
      ) : (
        <>
          <ReportLine name={formatMessage(PacingLocales.defaultPacingLabel)}>
            <Text>{formatMessage(PacingLocales.numCovers, { count: maxCoversPerSeatingInterval })}</Text>
          </ReportLine>
          {customPacingKeysSorted.map(
            minutes =>
              minutes !== undefined &&
              customPacing?.[minutes] !== null && (
                <ReportLine name={TimeOnly.from({ minutes }).formatSTime()} key={minutes}>
                  <Text>{formatMessage(PacingLocales.numCovers, { count: customPacing[minutes] ?? maxCoversPerSeatingInterval })}</Text>
                </ReportLine>
              )
          )}
          {isPacingHeld && (
            <ReportLine name={formatMessage(PacingLocales.isPacingHeldOption)}>
              <Text>{formatMessage(commonMessages.yes)}</Text>
            </ReportLine>
          )}
        </>
      )}
      {venueSettings?.excludeFromShiftPacingEnabled && excludeFromShiftPacing && (
        <ReportLine name={formatMessage(PacingLocales.excludeFromShiftPacing)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}

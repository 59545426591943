import type { AudienceSize } from '@sevenrooms/core/domain'
import { type InternationalizationText, useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { Venue } from '@sevenrooms/mgr-core'
import { Link } from '@sevenrooms/react-components/components/Link'
import { campaignBuilderMessages } from '../../../locales'

export interface AudienceSizeBannerProps {
  audienceSize: AudienceSize | null
  isLoading: boolean
  isActionDisabled: boolean
  onAction: () => void
  messages: {
    audienceSizeBannerDescriptionCalculated: InternationalizationText
  }
  venue?: Venue | null
  selectedRecipientTagIds?: Set<string>
  selectedExcludedTagIds?: Set<string>
  consent?: boolean
  isEmailCampaign?: boolean
}

export function AudienceSizeBanner({
  audienceSize,
  isLoading,
  isActionDisabled,
  onAction,
  messages,
  venue,
  selectedRecipientTagIds,
  selectedExcludedTagIds,
  consent,
  isEmailCampaign,
}: AudienceSizeBannerProps) {
  const { formatMessage } = useLocales()
  const receipentsTags: string[] = []
  selectedRecipientTagIds?.forEach(tag => {
    const tagId = tag.replace('PUBLIC##', '')
    receipentsTags.push(tagId)
  })

  const excludedTags: string[] = []
  selectedExcludedTagIds?.forEach(tag => {
    const tagId = tag.replace('PUBLIC##', '')
    excludedTags.push(tagId)
  })
  const baseUrl = `/manager/${venue?.urlKey}/clients?`
  const requiredParameters = 'column=total_visits&order=DESCENDING&filter_offset=0&any_client_tags=false&filter_contact_info=has_email&'
  const filterByTagsUrl = `${receipentsTags.map(item => `filter_tags%5B%5D=${encodeURIComponent(item)}`).join('&')}&${excludedTags
    .map(item => `exclude_tags%5B%5D=${encodeURIComponent(item)}`)
    .join('&')}`
  const filterMarketingOptInUrl = consent ? `&filter_marketing_opt_in=${venue?.id}` : ''

  return (
    <Banner
      type="info"
      title={formatMessage(campaignBuilderMessages.audienceSizeBannerTitle)}
      description={
        audienceSize ? (
          <>
            <Text color="darkFont">{formatMessage(messages.audienceSizeBannerDescriptionCalculated)}</Text>
            <Text fontWeight="bold">
              {formatMessage(campaignBuilderMessages.audienceSizeBannerDescriptionCalculatedValues, {
                matchingClients: audienceSize.matchingClients,
                totalClients: audienceSize.totalClients,
                percentage: Math.round((audienceSize.matchingClients / audienceSize.totalClients) * 100),
              })}
            </Text>
            <br />
            {isEmailCampaign && (
              <Link
                data-test="preview-audience"
                href={baseUrl + requiredParameters + filterByTagsUrl + filterMarketingOptInUrl}
                target="_blank"
              >
                {formatMessage(campaignBuilderMessages.previewAudience)}
              </Link>
            )}
          </>
        ) : (
          formatMessage(campaignBuilderMessages.audienceSizeBannerDescription)
        )
      }
      action={formatMessage(campaignBuilderMessages.audienceSizeBannerAction)}
      onAction={onAction}
      isActionDisabled={isActionDisabled}
      isLoading={isLoading}
    />
  )
}

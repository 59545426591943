// This file was automatically generated from admin.ssoorgs.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.SSOOrgList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.SSOOrgListBit'}), output);
  Nightloop.Templates.Admin.SSOOrgTableScript(null, output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SSOOrgListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('\n  <style type="text/css">\n    tr.group,\n    tr.group:hover {\n        background-color: #ddd !important;\n    }\n  </style>\n  <div id="ssoorglist-page"><div class="content-header">Single Sign On Organizations</div><div style="font-size:10px;">Tip: shift+click to sort by multiple columns</div><div class="right vpadding"><a id="ssoorg-add-new-link" class="ajaxify" href="/admin/ssoorg/create">+ create new</a></div><table id="ssoorg-list-table" style="width:100%; background:#fff;" class="display nowrap"><thead><td>Name</td><td>Identity Provider</td><td>ID</td><td class="right" width="110">Actions</td></thead><tbody>');
  var sso_orgList1642 = opt_data.content.sso_orgs;
  var sso_orgListLen1642 = sso_orgList1642.length;
  for (var sso_orgIndex1642 = 0; sso_orgIndex1642 < sso_orgListLen1642; sso_orgIndex1642++) {
    var sso_orgData1642 = sso_orgList1642[sso_orgIndex1642];
    Nightloop.Templates.Admin.SSOOrgRecord(soy.$$augmentData(opt_data, {sso_org: sso_orgData1642}), output);
  }
  output.append('</tbody></table></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SSOOrgRecord = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<tr class="container entity-record"><td><span class="ssoorg-name-only" ', (opt_data.sso_org.is_sevenrooms) ? 'style="font-weight: bold"' : '', '>', soy.$$escapeHtml(opt_data.sso_org.name), '</span></td><td>', soy.$$escapeHtml(opt_data.sso_org.identity_provider), '</td><td>', soy.$$escapeHtml(opt_data.sso_org.id), '</td><td class="right"><a class="ajaxify ssoorg-edit-link" href="/admin/ssoorg/', soy.$$escapeHtml(opt_data.sso_org.id), '/edit">[edit sso organization]</a></td></tr>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SSOOrgTableHeader = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<thead><td>Name</td><td>Identity Provider</td><td>ID</td><td class="right" width="110">Actions</td></thead>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SSOOrgTableScript = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<script type="text/javascript">\n      $(document).ready(function() {\n        $(\'#content\').width(1600);\n        var table = $(\'#ssoorg-list-table\').DataTable({\n          "displayLength": 100,\n          "lengthMenu": [[50, 100, 200, 500, -1], [50, 100, 200, 500, "All"]],\n        });\n        Pmp.Utils.attachDataTablesToSearch(table, \'q\')\n      });\n    <\/script>');
  return opt_sb ? '' : output.toString();
};

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, addDays, differenceInCalendarDays } from '@sevenrooms/core/timepiece'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { BaseSection, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import {
  useMultiDayAvailability,
  useReservationsRoute,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
  type AvailabilityTimeWithUpSellCost,
} from '../../../../hooks'
import { getRelativeFormattedDate } from '../../../ReservationDayPickerForm/getRelativeFormattedDate'
import { OtherAvailability } from '../OtherAvailability'
import { OtherDayReservationAvailabilitySkeleton } from './OtherDayReservationAvailabilitySkeleton'
import { otherDaysAvailabilityMessages } from './otherDaysAvailabilityMessages'
import type { ReservationAvailabilityProps } from '../../../PrimaryAvailability/ReservationAvailability'

const NUMBER_OF_DAYS_TO_FETCH_PER_CALL = 3
const MAX_DAYS_OUT = 30

export interface OtherDatesAvailability {
  date: string
  timeslots: ReservationAvailabilityProps['timeslots']
}

export interface OtherDaysReservationAvailabilityProps {
  title: string
  partySize: number
  startDate: Date
  startTime: string
  haloTimeIntervalMinutes: number
  clientId?: string
  noAvailabilityMessage: string
  onDateClick: (date: string) => void
  onTimeSlotClick: ReservationAvailabilityProps['onClick']
  isCollapsed: boolean
  onToggleCollapse: (isCollapsed: boolean) => void
  'data-test'?: string
  venueCurrencyCode: string
}

export function OtherDaysReservationAvailability({
  title,
  partySize,
  startDate,
  startTime,
  haloTimeIntervalMinutes,
  clientId,
  noAvailabilityMessage,
  onDateClick,
  onTimeSlotClick,
  venueCurrencyCode,
  isCollapsed,
  onToggleCollapse,
  'data-test': dataTest = 'other-reservation-availability',
}: OtherDaysReservationAvailabilityProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()
  const { startOfDayTime, urlKey, id: venueId } = venue
  const widgetSettings = useWidgetSettings()
  const { defaultSearchTime } = widgetSettings
  const { selectedLanguage } = useWidgetLanguage()
  const { channel } = useReservationsRoute()

  const [startDateToFetch, setStartDateToFetch] = useState(addDays(startDate, 1))
  const [shouldFetchMore, setShouldFetchMore] = useState(false)
  const isMobile = useMaxWidthBreakpoint('s')
  const baseSpacing = isMobile ? 'sm' : 'lm'

  const { data: availabilityDates } = useMultiDayAvailability({
    venueId,
    shouldSkip: isCollapsed || !shouldFetchMore,
    venue: urlKey,
    numDays: NUMBER_OF_DAYS_TO_FETCH_PER_CALL,
    startDate: DateOnly.fromDate(startDateToFetch).toIso(),
    startTime,
    partySize,
    haloTimeIntervalMinutes,
    clientId,
    selectedLangCode: selectedLanguage,
    channel: channel || ReservationWidget.SevenRoomsWidgetChannel,
    haloSizeInterval: ReservationWidget.WholeDayHaloSizeInterval,
    timeSlot: defaultSearchTime,
    startOfDayTime,
  })

  type availabilityDatesType = Exclude<typeof availabilityDates, typeof undefined>
  const [availabilities, setAvailabilities] = useState<availabilityDatesType>([])

  const { ref: lastElement, inView } = useInView({
    threshold: [1],
  })

  useEffect(() => {
    if (inView && !shouldFetchMore && isWithinMaxDaysOut(startDate, startDateToFetch)) {
      setShouldFetchMore(true)
      if (availabilities.length > 0) {
        setStartDateToFetch(currentDateToFetch => addDays(currentDateToFetch, NUMBER_OF_DAYS_TO_FETCH_PER_CALL))
      }
    }
    // Enable fetching more availabilities as inView changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    if (!availabilityDates || availabilityDates.length === 0) {
      return
    }

    const hasOldData = availabilities.some(avail => availabilityDates.some(availability => availability.date === avail.date))
    const isCurrentData = availabilityDates.every(availability =>
      isWithinThreeDaysFromStartDate(startDate, DateOnly.from(availability.date).toJsDate())
    )
    if (hasOldData || !isCurrentData) {
      return
    }

    const cleanedAvailabilityDates = availabilityDates.filter(date => date.timeslots && date.timeslots.length > 0)
    if (cleanedAvailabilityDates.length > 0) {
      setAvailabilities([...availabilities, ...cleanedAvailabilityDates])
    }

    if (inView && shouldFetchMore && isWithinMaxDaysOut(startDate, startDateToFetch)) {
      setStartDateToFetch(currentDateToFetch => addDays(currentDateToFetch, NUMBER_OF_DAYS_TO_FETCH_PER_CALL))
    } else {
      setShouldFetchMore(false)
    }
    // Should only call this useEffect when availabilityDates has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityDates])

  // This useEffect needs to be last so that the states that are reset here are not changed by the other useEffects in this component.
  useEffect(() => {
    // Reset when the search filter is changed.
    setShouldFetchMore(false)
    setAvailabilities([])
    setStartDateToFetch(addDays(startDate, 1))
  }, [partySize, startDate, startTime, haloTimeIntervalMinutes])

  return (
    <BaseSection
      title={title}
      collapsed={isCollapsed}
      onToggleCollapse={onToggleCollapse}
      data-test={dataTest}
      backgroundColor={isMobile ? 'secondaryBackground' : undefined}
      padding="none"
      ml={baseSpacing}
      mr={baseSpacing}
      mt="sm"
      mb="sm"
      maxWidth="100%"
      type="h3"
    >
      {isMobile && <DividerLine color="borders" />}
      <VStack pt={availabilities.length > 0 ? baseSpacing : 'sm'} pb={baseSpacing} pl={baseSpacing} pr={baseSpacing} spacing="lm">
        {availabilities.length > 0
          ? availabilities.map(({ date, timeslots }) => {
              const dateOnly = DateOnly.fromSafe(date)
              const jsDate = dateOnly?.toJsDate()

              if (!jsDate) {
                return null
              }

              return (
                <OtherAvailability
                  key={date}
                  title={getRelativeFormattedDate({ date: jsDate })}
                  scrollLeftLabel={formatMessage(otherDaysAvailabilityMessages.scrollLeftLabel, { date })}
                  scrollRightLabel={formatMessage(otherDaysAvailabilityMessages.scrollRightLabel, { date })}
                  currencyCode={venueCurrencyCode}
                  isMobile={isMobile}
                  onTitleClick={() => {
                    onDateClick(date)
                  }}
                  onTimeSlotClick={(slot: AvailabilityTimeWithUpSellCost) => {
                    onTimeSlotClick(slot, date)
                  }}
                  timeslots={timeslots}
                  titleDataTest={`other-day-availability-${date}`}
                  isFetching={false}
                />
              )
            })
          : !shouldFetchMore && !isWithinMaxDaysOut(startDate, startDateToFetch) && <Text textStyle="body1">{noAvailabilityMessage}</Text>}
        <StyledLastElement ref={lastElement}>&nbsp;</StyledLastElement>
        {shouldFetchMore && <OtherDayReservationAvailabilitySkeleton />}
      </VStack>
    </BaseSection>
  )
}

function isWithinMaxDaysOut(startDate: Date, targetDate: Date) {
  const nextStartDateToFetch = addDays(targetDate, NUMBER_OF_DAYS_TO_FETCH_PER_CALL)
  const maxDaysOut = addDays(startDate, MAX_DAYS_OUT)
  return nextStartDateToFetch <= maxDaysOut
}

function isWithinThreeDaysFromStartDate(startDate: Date, targetDate: Date) {
  return differenceInCalendarDays(startDate, targetDate) <= 3
}

const StyledLastElement = styled.div`
  height: 1px;
  && {
    margin-top: 0;
    margin-bottom: 0;
  }
`

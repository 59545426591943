import { useState } from 'react'
import { connect } from 'react-redux'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import { getSelectedInventoryCart, calculateSelectedInventoryPrices } from '../selectors/inventoryCartSelector'
import PolicyPopover from './PolicyPopover'

const IComponent = props => <i role="button" tabIndex="0" {...props} />
const AccessibleIComponent = accessibleComponentWrapper(IComponent, false)

function PricingDetails({
  selectedInventoryItems,
  totalServiceCharge,
  totalTax,
  totalRequiredTip,
  totalCustomTip,
  subTotal,
  totalAdditionalFeeAmount,
  totalAdditionalFeeTaxAmount,
  totalDiscount,
  total,
  languageCode,
  currencyCode,
  promoCodeEntity,
  textServiceFee,
  selectedCustomTip,
  onGratuityFieldChange,
  hasGratuityFieldError,
}) {
  const [shouldDisplayServiceChargeDetails, setShouldDisplayServiceChargeDetails] = useState(false)
  const [shouldDisplayTaxDetails, setShouldDisplayTaxDetails] = useState(false)
  const [shouldDisplayRequiredTipDetails, setShouldDisplayRequiredTipDetails] = useState(false)
  const [shouldDisplayCustomTipDetails, setShouldDisplayCustomTipDetails] = useState(false)
  const [shouldDisplayPromoCodeDetails, setShouldDisplayPromoCodeDetails] = useState(false)
  const [shouldDisplayAdditionalFeeDetails, setShouldDisplayAdditionalFeeDetails] = useState(false)
  const [shouldDisplayAdditionalFeeTaxDetails, setShouldDisplayAdditionalFeeTaxDetails] = useState(false)

  const currencyFormatter = new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencyCode })
  const adminFeeLabel = selectedInventoryItems[0]?.adminFeeLabel
  const hasSameTaxPercentage =
    selectedInventoryItems[0]?.taxPercent && selectedInventoryItems.every(item => item.taxPercent === selectedInventoryItems[0].taxPercent)
  const hasSameServiceChargePercentage =
    selectedInventoryItems[0]?.serviceChargePercent &&
    selectedInventoryItems.every(item => item.serviceChargePercent === selectedInventoryItems[0].serviceChargePercent)
  const hasSameAdditionalFeeTaxPercentage =
    selectedInventoryItems[0]?.additionalFeeTaxPercent &&
    selectedInventoryItems.every(item => item.additionalFeeTaxPercent === selectedInventoryItems[0].additionalFeeTaxPercent)
  const hasSameTipPercentage =
    selectedInventoryItems[0]?.tipPercent &&
    selectedInventoryItems.every(
      item => item.tipPercent === selectedInventoryItems[0].tipPercent && item.chargeGratuityType !== 'CUSTOMER_VARIABLE'
    )
  const hasMoreThanOneInventoryItem = selectedInventoryItems.length > 1

  function generatePricingDetails(listOfDetails) {
    return listOfDetails
      .filter(item => item.amount !== 0)
      .reduce((acc, { label, amount }, index, items) => {
        let detailsText = ''
        if (index === 0) {
          detailsText = `<ul>`
        }

        detailsText = `${detailsText}${acc}<li><strong>${label}:</strong> ${currencyFormatter.format(amount)}</li>`

        if (index === items.length - 1) {
          detailsText = `${detailsText}</ul>`
        }

        return detailsText
      }, '')
  }

  const serviceChargeDetails = generatePricingDetails(
    selectedInventoryItems.map(item => ({ label: `${item.inventoryName} (${item.serviceChargePercent}%)`, amount: item.serviceCharge }))
  )
  const taxDetails = generatePricingDetails(
    selectedInventoryItems.map(item => ({ label: `${item.inventoryName} (${item.taxPercent}%)`, amount: item.tax }))
  )
  const requiredTipDetails = generatePricingDetails(
    selectedInventoryItems
      .filter(item => item.requiredTip > 0)
      .map(item => ({ label: `${item.inventoryName} (${item.tipPercent}%)`, amount: item.requiredTip }))
  )
  const customTipDetails = generatePricingDetails(
    selectedInventoryItems.filter(item => item.customTip > 0).map(item => ({ label: item.inventoryName, amount: item.customTip }))
  )
  const additionalFeeDetails = generatePricingDetails(
    selectedInventoryItems.map(item => ({ label: item.inventoryName, amount: item.additionalFeeAmount }))
  )
  const additionalFeeTaxDetails = generatePricingDetails(
    selectedInventoryItems.map(item => ({ label: item.inventoryName, amount: item.additionalFeeTaxAmount }))
  )

  const customTipInventoryItems = selectedInventoryItems.filter(item => item.chargeGratuityType === 'CUSTOMER_VARIABLE')

  const promoCodeEligibleInventory = selectedInventoryItems
    .filter(({ isEligibleForPromoCode }) => isEligibleForPromoCode)
    .map(item => ({ label: item.inventoryName }))

  return (
    <div style={{ fontSize: '14px', padding: '12px 4.1791% 16px 4.47761%' }}>
      {selectedInventoryItems.map(({ inventoryId, inventoryName, basePrice, discount }, index) => (
        <div key={inventoryId} style={{ marginTop: index > 0 ? '8px' : '0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{`Event (${inventoryName})`}</div>
            <div>{currencyFormatter.format(basePrice)}</div>
          </div>
        </div>
      ))}
      {totalServiceCharge > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-service-charge-percent">{`${textServiceFee}${
            hasSameServiceChargePercentage ? ` (${selectedInventoryItems[0].serviceChargePercent}%)` : ''
          }`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayServiceChargeDetails(true)
                }}
                aria-label="Show Service Charge Details"
              />
            )}
            <div id="sr-service-charge-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalServiceCharge)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayServiceChargeDetails && (
        <PolicyPopover
          policyHeader={`${textServiceFee} Details`}
          policyDetails={serviceChargeDetails}
          togglePopover={() => {
            setShouldDisplayServiceChargeDetails(false)
          }}
        />
      )}
      {totalTax > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-tax-percent">{`Tax${hasSameTaxPercentage ? ` (${selectedInventoryItems[0].taxPercent}%)` : ''}`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayTaxDetails(true)
                }}
                aria-label="Show Tax Details"
              />
            )}
            <div id="sr-tax-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalTax)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayTaxDetails && (
        <PolicyPopover
          policyHeader="Tax Details"
          policyDetails={taxDetails}
          togglePopover={() => {
            setShouldDisplayTaxDetails(false)
          }}
        />
      )}
      {totalRequiredTip > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-tip-percent">{`Tip${hasSameTipPercentage ? ` (${selectedInventoryItems[0].tipPercent}%)` : ''}`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayRequiredTipDetails(true)
                }}
                aria-label="Show Tip Details"
              />
            )}
            <div id="sr-tip-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalRequiredTip)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayRequiredTipDetails && (
        <PolicyPopover
          policyHeader="Tip Details"
          policyDetails={requiredTipDetails}
          togglePopover={() => {
            setShouldDisplayRequiredTipDetails(false)
          }}
        />
      )}
      {customTipInventoryItems.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-gratuity-line">
            <span>{'Tip '}</span>
            <select
              onChange={onGratuityFieldChange}
              style={{
                WebkitAppearance: 'inherit',
                MozAppearance: 'none',
                textIndent: 2,
                width: 55,
                height: 21,
                textOverflow: '',
                color: 'inherit',
                borderColor: hasGratuityFieldError ? '#d01a21' : '#a6a6a6',
                borderRadius: 'inherit',
                backgroundColor: 'transparent',
                backgroundImage:
                  'linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc)',
                backgroundPosition: 'calc(100% - 10px) calc(.6em), calc(100% - 5px) calc(.6em), calc(100% - 5px) 2em',
                backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
                backgroundRepeat: 'no-repeat',
              }}
              defaultValue={selectedCustomTip}
            >
              <option value="" selected disabled>
                Select
              </option>
              {Array.from({ length: 41 }, (_, index) => index).map(val => (
                <option key={val} value={val}>
                  {val}%
                </option>
              ))}
            </select>
          </div>
          <div>
            {totalCustomTip > 0 && hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayCustomTipDetails(true)
                }}
                aria-label="Show Tip Details"
              />
            )}
            <div id="sr-tip-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalCustomTip)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayCustomTipDetails && (
        <PolicyPopover
          policyHeader="Tip Details"
          policyDetails={customTipDetails}
          togglePopover={() => {
            setShouldDisplayCustomTipDetails(false)
          }}
        />
      )}
      {subTotal !== total && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div>Order Subtotal</div>
          <div id="sr-price-subtotal">{currencyFormatter.format(subTotal)}</div>
        </div>
      )}
      {totalAdditionalFeeAmount > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-custom-fee-type">{`${adminFeeLabel || 'Admin Fee'}`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayAdditionalFeeDetails(true)
                }}
                aria-label="Show Admin Fee Details"
              />
            )}
            <div id="sr-custom-fee-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalAdditionalFeeAmount)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayAdditionalFeeDetails && (
        <PolicyPopover
          policyHeader={`${adminFeeLabel || 'Admin Fee'} Details`}
          policyDetails={additionalFeeDetails}
          togglePopover={() => {
            setShouldDisplayAdditionalFeeDetails(false)
          }}
        />
      )}
      {totalAdditionalFeeTaxAmount > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div id="sr-custom-fee-tax-type">{`${adminFeeLabel || 'Admin Fee'} Tax${
            hasSameAdditionalFeeTaxPercentage ? ` (${selectedInventoryItems[0].additionalFeeTaxPercent}%)` : ''
          }`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayAdditionalFeeTaxDetails(true)
                }}
                aria-label="Show Admin Fee Tax Details"
              />
            )}
            <div id="sr-custom-fee-tax-price" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(totalAdditionalFeeTaxAmount)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayAdditionalFeeTaxDetails && (
        <PolicyPopover
          policyHeader="Admin Fee Tax Details"
          policyDetails={additionalFeeTaxDetails}
          togglePopover={() => {
            setShouldDisplayAdditionalFeeTaxDetails(false)
          }}
        />
      )}
      {promoCodeEntity && totalDiscount > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div>{`Promo Code (${promoCodeEntity.code})`}</div>
          <div>
            {hasMoreThanOneInventoryItem && (
              <AccessibleIComponent
                style={{
                  width: '16px',
                  height: '16px',
                  fontSize: '15px',
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginRight: '4px',
                }}
                className="fa fa-info-circle fa-fw"
                onClick={() => {
                  setShouldDisplayPromoCodeDetails(true)
                }}
                aria-label="Show Promo Code Discount Details"
              />
            )}
            <div id="sr-price-discount" style={{ display: 'inline-block' }}>
              {currencyFormatter.format(-totalDiscount)}
            </div>
          </div>
        </div>
      )}
      {shouldDisplayPromoCodeDetails && (
        <PolicyPopover
          policyHeader="Eligible for Promo Code Discount"
          policyDetails={`<ul>${promoCodeEligibleInventory.reduce((acc, { label }) => `${acc}<li>${label}</li>`, '')}</ul>`}
          togglePopover={() => {
            setShouldDisplayPromoCodeDetails(false)
          }}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', fontWeight: 500 }}>
        <div>Order Total</div>
        <div id="sr-price-total">{currencyFormatter.format(total)}</div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const selectedInventoryItems = getSelectedInventoryCart(state).map(selectedInventoryItem => {
    const inventory = state.entities.inventory.get(selectedInventoryItem.inventoryId).toJS()
    return {
      ...selectedInventoryItem,
      inventoryName: inventory.inventoryName,
      chargeGratuityType: inventory.gratuityAmountType,
      adminFeeLabel: inventory.additionalFee?.feeLabel,
      isEligibleForPromoCode: inventory.enablePromocodes,
    }
  })
  const { serviceCharge, tax, requiredTip, customTip, subTotal, additionalFeeAmount, additionalFeeTaxAmount, discount, total } =
    calculateSelectedInventoryPrices(state)

  return {
    languageCode: state.app.language,
    selectedInventoryItems,
    totalServiceCharge: serviceCharge,
    totalTax: tax,
    totalRequiredTip: requiredTip,
    totalCustomTip: customTip,
    subTotal,
    totalAdditionalFeeAmount: additionalFeeAmount,
    totalAdditionalFeeTaxAmount: additionalFeeTaxAmount,
    totalDiscount: discount,
    total,
    currencyCode: state.venueInfo.currencyCode,
    promoCodeEntity: state.entities.promoCode,
    textServiceFee: state.widgetSettings.textServiceFee,
    selectedCustomTip: state.formFields.get('customTip'),
  }
}
export default connect(mapStateToProps)(PricingDetails)

import { useMemo, useCallback } from 'react'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { SupportedLanguageCodes } from '@sevenrooms/core/api'
import {
  type BillingAddress,
  type CountryCode,
  type RedemptionData,
  type SocialMediaLoginType,
  SocialMediaLoginTypeEnum,
} from '@sevenrooms/core/domain'
import { z, issueMessages } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useBirthdayDayInputForm, useBirthdayMonthInputForm } from '@sevenrooms/core/ui-kit/form'
import { usePaymentCardSchema } from '@sevenrooms/payments'
import { usePromoCodeFormSchema } from '../../components/PromoCodeCard/PromoCodeCard.zod'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export const useCheckoutFormSchema = ({
  birthdayType,
  hasAgeToConsent,
  hasReCaptcha,
  hasCustomCheckoutPolicy,
  postalCodeType,
  hasRequiredClientSelectableGratuity,
  hasBookingPolicy,
  hasGroupBookingsPolicy,
  hasGdprPolicy,
  hasBillingAddress,
  hasCustomPaymentForm,
  salutationType,
}: {
  birthdayType: 'Required' | 'Optional' | 'Hidden'
  hasReCaptcha: boolean
  postalCodeType: 'Required' | 'Optional' | 'Hidden'
  hasAgeToConsent: boolean
  hasCustomCheckoutPolicy: boolean
  hasRequiredClientSelectableGratuity: boolean
  hasBookingPolicy: boolean
  hasBillingAddress: boolean
  hasCustomPaymentForm: boolean
  hasGroupBookingsPolicy: boolean
  hasGdprPolicy: boolean
  salutationType: 'Required' | 'Optional' | 'Hidden'
}) => {
  const { formatMessage } = useLocales()
  const birthdayDay = useBirthdayDayInputForm(birthdayType !== 'Required')
  const birthdayMonth = useBirthdayMonthInputForm(birthdayType !== 'Required')
  const promoCode = usePromoCodeFormSchema()
  const paymentForm = usePaymentCardSchema({ shouldShowBillingAddress: true })
  const requiredErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldRequired)
  const invalidErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldInvalid)

  const requireTrue = useCallback(
    (val: boolean, ctx: z.RefinementCtx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredErrorMessage,
        })
      }
    },
    [requiredErrorMessage]
  )

  return useMemo(
    () =>
      z
        .object({
          birthdayDay,
          birthdayMonth,
          emailAddress: z.string().min(1, { message: requiredErrorMessage }).email({ message: invalidErrorMessage }),
          firstName: z.string().min(1, { message: requiredErrorMessage }),
          lastName: z.string().min(1, { message: requiredErrorMessage }),
          phoneNumber: z.string().min(1, { message: requiredErrorMessage }),
          billingAddress: z
            .custom<BillingAddress>()
            .optional()
            .refine(value => !hasBillingAddress || !!value, formatMessage(issueMessages.required)),
          phoneCountryCode: z.custom<CountryCode>(),
          phoneDialCode: z.string().min(1),
          socialMediaPicture: z.string().min(1).optional(),
          socialMediaUserId: z.string().min(1).optional(),
          socialMediaLoginSite: z.enum(Object.keys(SocialMediaLoginTypeEnum) as [SocialMediaLoginType]),
          socialMediaLocation: z.string().optional(),
          specialOccasionTags: z.array(z.string()),
          clientDietaryPreferenceTags: z.array(z.string()),
          partyDietaryPreferenceTags: z.array(z.string()),
          champagnePreferenceTags: z.array(z.string()),
          liquorPreferenceTags: z.array(z.string()),
          customQuestionAnswer: z.string(),
          postalCode: postalCodeType !== 'Required' ? z.string() : z.string().min(1, { message: requiredErrorMessage }),
          preferredLanguage: z.enum(SupportedLanguageCodes),
          reCaptcha: z
            .string()
            .nullable()
            .refine(value => !hasReCaptcha || !!value, formatMessage(issueMessages.required)),
          gratuityPercentage: hasRequiredClientSelectableGratuity
            ? z.number({ invalid_type_error: requiredErrorMessage }).min(0).max(39)
            : z.number().nullable(),
          promoCode,
          agreedToVenueGroupMarketingOptIn: z.boolean(),
          agreedToVenueSpecificMarketingOptIn: z.boolean(),
          agreedToVenueSmsMarketingOptIn: z.boolean(),
          agreedToReservationSmsOptIn: z.boolean(),
          agreedToTailoredCommunicationOptIn: z.boolean(),
          agreedToBookingPolicy: hasBookingPolicy ? z.boolean().superRefine(requireTrue) : z.boolean(),
          agreedToGroupBookingsPolicy: hasGroupBookingsPolicy ? z.boolean().superRefine(requireTrue) : z.boolean(),
          agreedToGdprDietaryOptIn: z.boolean(),
          agreedToAboveAgeConsentOn: hasAgeToConsent ? z.boolean().superRefine(requireTrue) : z.boolean(),
          agreedCustomCheckoutPolicy: hasCustomCheckoutPolicy ? z.boolean().superRefine(requireTrue) : z.boolean(),
          clientId: z.string().optional(),
          payment: hasCustomPaymentForm ? paymentForm : z.any(),
          salutation: salutationType !== 'Required' ? z.string().nullable() : z.string().min(1, { message: requiredErrorMessage }),
          redemptionData: z.custom<RedemptionData>().optional(),
        })
        .superRefine((val, ctx) => {
          if (!isPossiblePhoneNumber(val.phoneNumber)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: invalidErrorMessage,
              path: ['phoneNumber'],
            })
          }
        })
        .superRefine((val, ctx) => {
          if (hasGdprPolicy && val.clientDietaryPreferenceTags.length && !val.agreedToGdprDietaryOptIn) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: requiredErrorMessage,
              path: ['agreedToGdprDietaryOptIn'],
            })
          }
        }),
    [
      birthdayDay,
      birthdayMonth,
      requiredErrorMessage,
      invalidErrorMessage,
      formatMessage,
      postalCodeType,
      hasRequiredClientSelectableGratuity,
      promoCode,
      hasBookingPolicy,
      requireTrue,
      hasGroupBookingsPolicy,
      hasGdprPolicy,
      hasAgeToConsent,
      hasCustomCheckoutPolicy,
      hasCustomPaymentForm,
      paymentForm,
      salutationType,
      hasBillingAddress,
      hasReCaptcha,
    ]
  )
}
export type CheckoutForm = z.infer<ReturnType<typeof useCheckoutFormSchema>>

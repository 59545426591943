import { useEffect } from 'react'
import type { ReservationWidget as ReservationWidgetModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useMeasure } from '@sevenrooms/core/ui-kit/hooks'
import { ReservationBreadcrumb, WidgetPortal } from '../../components'
import { useReleaseReservationHold, useReservationsRoute, useVenue, useModifyReservationRoute, useAnalyticsSettings } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { useReservationFormState } from '../../store'
import { loadSearchPage } from '../../utils'
import { ReservationsTab } from './ReservationsTab'
import { ReservationsTabs } from './ReservationsTabs'
import { SearchContainer } from './SearchContainer'
import { VenueProfile } from './VenueProfile'

interface SearchProps {
  isGroupWidget: boolean
  showTabs: boolean
  venuesInfo?: ReservationWidgetModel.VenueInfo
}

export function Search({ isGroupWidget, showTabs, venuesInfo }: SearchProps) {
  const {
    formState: { actual },
  } = useReservationFormState()
  const venue = useVenue()
  const analyticsSettings = useAnalyticsSettings()
  const { formatMessage } = useLocales()
  const { clearQueryParams } = useReservationsRoute()
  const { releaseReservationHold } = useReleaseReservationHold()
  const { urlKey, name: venueName } = venue
  const [breadcrumbsRef, { height: breadcrumbsHeight }] = useMeasure({ debounce: 200 })

  const { isModifyRoute } = useModifyReservationRoute()

  useEffect(() => {
    // Send the Google Analytics event for search page load
    loadSearchPage(urlKey, analyticsSettings.fbPixelIdsAll, analyticsSettings.analyticsEventMapping.search)
  }, [urlKey, analyticsSettings.analyticsEventMapping.search, analyticsSettings.fbPixelIdsAll])

  useEffect(() => {
    releaseReservationHold()
    clearQueryParams('timeslot_id', 'timeslot_time')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SearchContainer.MainContent showBreadcrumbs={!!breadcrumbsHeight} showTabs={showTabs}>
        {showTabs ? (
          <SearchContainer.TabsContent>
            <ReservationsTabs venuesInfo={venuesInfo} isGroupWidget={isGroupWidget} />
          </SearchContainer.TabsContent>
        ) : (
          <SearchContainer.Content breadcrumbsHeight={breadcrumbsHeight}>
            <ReservationsTab isGroupWidget={isGroupWidget} venuesInfo={venuesInfo} showTabs={showTabs} />
          </SearchContainer.Content>
        )}
      </SearchContainer.MainContent>
      {isModifyRoute && (
        <WidgetPortal portalId="search-breadcrumbs">
          <ReservationBreadcrumb
            ref={breadcrumbsRef}
            venueName={venueName}
            partySize={actual?.maxGuests}
            timeIso={actual ? `${actual.date} ${actual.arrivalTime}` : undefined}
            label={formatMessage(reservationWidgetMessages.resWidgetCurrentReservationDetailsLabel)}
          />
        </WidgetPortal>
      )}
      {!isModifyRoute && (
        <SearchContainer.VenueDetailsContainer hasSpacer={!showTabs}>
          <VenueProfile
            mapLink={venue.gmapsLink ?? ''}
            name={venue.name}
            address={isGroupWidget ? undefined : venue.address}
            cityState={isGroupWidget ? undefined : venue.cityState}
            postalCode={isGroupWidget ? undefined : venue.postalCode}
            gmapsEmbedUrl={isGroupWidget ? undefined : venue.gmapsEmbedUrl}
            key={venue.urlKey}
            description={formatMessage(reservationWidgetMessages.resWidgetCrossSellVenueLongDescription)}
          />
        </SearchContainer.VenueDetailsContainer>
      )}
    </>
  )
}

/**
 */
var modules = modules || {}
modules.shiftWeekViewManager = (function () {
  var instantiated,
    media_url,
    venue_name,
    id,
    shifts,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    floorplan_data,
    venue_policy,
    venue_cancellation_policy,
    start_of_day_hour,
    can_save_card,
    default_service_charge,
    default_gratuity,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    show_shift_reporting_periods
  var upsells = {}
  var cellSize = 6

  var helpers = {
    overlayInterval: React.createClass({
      onMouseOver: function (e) {
        var currentZIndex = Number($(e.currentTarget).attr('data-zindex'))
        $(e.currentTarget).css({
          zIndex: currentZIndex + 100,
        })
      },

      onMouseOut: function (e) {
        var currentZIndex = Number($(e.currentTarget).attr('data-zindex')),
          originalWidth = Number($(e.currentTarget).attr('data-originalwidth'))
        $(e.currentTarget).css({
          zIndex: currentZIndex,
        })
      },

      shadeColor: function (color, percent) {
        var R = parseInt(color.substring(1, 3), 16),
          G = parseInt(color.substring(3, 5), 16),
          B = parseInt(color.substring(5, 7), 16)

        R = parseInt((R * (100 + percent)) / 100)
        G = parseInt((G * (100 + percent)) / 100)
        B = parseInt((B * (100 + percent)) / 100)

        R = R < 255 ? R : 255
        G = G < 255 ? G : 255
        B = B < 255 ? B : 255

        var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
        var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
        var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

        return '#' + RR + GG + BB
      },

      onMouseClick: function (e) {
        $(e.currentTarget).css({ 'background-color': '#CEDEFF', zIndex: 250 })
      },

      getDisplayBlock: function (startTime, endTime, name, calculatedHeight) {
        var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime)
        var endTimeDisplay = Pmp.Utils.timeWithLocale(endTime)
        return calculatedHeight >= 20 ? (
          <div>
            <div
              className="time"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                height: 10,
              }}
            >
              {startTimeDisplay + '-' + endTimeDisplay}
            </div>
            <div
              className="name"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                height: 10,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {name}
            </div>
          </div>
        ) : (
          <div>
            <div
              className="time"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                overflow: 'hidden',
                height: 10,
              }}
            >
              <span key="1" style={{ fontWeight: 'bold' }}>
                {startTimeDisplay + ' '}
              </span>
              <span key="2">{name}</span>
            </div>
          </div>
        )
      },
      render: function () {
        var colors = [
            '#A1BEFE',
            '#BA68C8',
            '#FDD835',
            '#FFF176',
            '#1DE9B6',
            '#B9F6CA',
            '#00BCD4',
            '#80DEEA',
            '#8C9EFF',
            '#A1BEFE',
            '#2C96FF',
            '#6AB8FF',
            '#FF5252',
            '#FF80AB',
            '#B0BEC5',
            '#CE93D8',
            '#EEEEEE',
          ],
          calculatedZIndex = 200 + Number(this.props.index ? this.props.index : 0),
          isPast = new Date(this.props.day) < Pmp.Manager.Global._venue_today_date,
          calculatedHeight = this.props.height ? this.props.height : 4 * cellSize - 4
        var selectedColor = colors[this.props.index ? this.props.index % _.size(colors) : 0],
          filteredSelectedColor = this.props.isOverride ? this.shadeColor(selectedColor, -15) : selectedColor,
          width = this.props.maxWidth + this.props.left >= 96 ? 96 - this.props.left : this.props.maxWidth,
          overlayIntervalCss = {
            position: 'absolute',
            zIndex: calculatedZIndex,
            backgroundColor: filteredSelectedColor,
            right: '1px',
            height: calculatedHeight - 1,
            top: '0px',
            opacity: isPast ? 0.6 : 1,
            width: width + '%',
            left: (this.props.left ? this.props.left : 0) + '%',
            padding: '2px',
            marginBottom: 1,
            boxShadow: '1px 1px 1px ' + this.shadeColor(selectedColor, -20),
            cursor: 'pointer',
            overflow: 'hidden',
          }
        return (
          <div
            className="overlay_interval"
            style={overlayIntervalCss}
            data-starttime={this.props.startTime}
            data-endtime={this.props.endTime}
            data-day={this.props.day}
            data-originalwidth={width}
            data-id={this.props.id}
            data-test={`sr-overlay-interval-${this.props.day}-${this.props.startTime}-${this.props.endTime}`}
            data-backgroundcolor={filteredSelectedColor}
            data-zindex={calculatedZIndex}
            onMouseOver={this.onMouseOver}
            onResize={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onClick={this.onMouseClick}
          >
            {this.getDisplayBlock(this.props.startTime, this.props.endTime, this.props.name, calculatedHeight)}
          </div>
        )
      },
    }),

    getNeighboringOverlayIntervals: function (currTarget, direction_down) {
      var currColumnNum = $(currTarget).prevAll('td').size(),
        effectiveRows = []
      if (direction_down) {
        effectiveRows = $(currTarget).closest('tr').nextAll('tr')
      } else {
        effectiveRows = $(currTarget).closest('tr').prevAll('tr')
      }
      return _.flatten(
        _.map(effectiveRows, function (row) {
          var currRow = $(row).find('td')[currColumnNum]
          return $(currRow).find('.overlay_interval')
        })
      )
    },

    // Specific to check if a target div gets overlapping any of the adjacent overlay
    doesOverlap: function (target, adjacentOverlay) {
      if (_.isEmpty(adjacentOverlay)) {
        return false
      }
      var divTop = $(target).offset().top,
        adjacentOverlayTop = $(adjacentOverlay).offset().top,
        adjacentOverlayHeight = adjacentOverlay.offsetHeight
      return divTop > adjacentOverlayTop && divTop < adjacentOverlayTop + adjacentOverlayHeight
    },

    resetResizableOverlays: function (startTime, endTime) {
      var startFractions = modules.weekViewManager.getHourFraction(startTime),
        endFractions = modules.weekViewManager.getHourFraction(endTime),
        calculatedDifference = endFractions.hour - startFractions.hour + (endFractions.fraction - startFractions.fraction) / 4

      calculatedDifference = calculatedDifference < 0 ? calculatedDifference + 24 : calculatedDifference
      var resizables = $('#' + helpers.id).find('.ui-resizable')
      $(resizables).each(function (i, overlay) {
        var selector = [
            "[data-day='",
            $(overlay).data('day'),
            "']",
            "[data-start='",
            startFractions.hour,
            "']",
            "[data-fraction='",
            startFractions.fraction,
            "']",
          ].join(''),
          targetNode = _.first($('#' + helpers.id).find(selector))
        $(overlay).detach().appendTo(targetNode)
        $(overlay).height(calculatedDifference * cellSize * 4 - 6)

        // HACK: Force stop -- there doesn't seem to be any other way
        if (i == resizables.length - 1) {
          var uiResizable = $(overlay).data('uiResizable')
          uiResizable.options.stop(event, uiResizable.ui())
        }
      })
    },

    onAddOverlay: function (id, d, hours, fraction, total_duration, isChecked) {
      if (isChecked) {
        var selector = ["[data-day='", d, "']", "[data-start='", hours, "']", "[data-fraction='", fraction, "']"].join(''),
          targetNode = $('#' + id).find(selector),
          anyResizable = (anyNeighborResizable = !!$('#' + id)
            .find('.ui-resizable')
            .size()),
          anyNeighborResizable = !!$('#' + id)
            .find('.ui-resizable')
            .filter(":not([data-day='" + d + "'])")
            .size()
        if (targetNode.size() && (!anyResizable || anyNeighborResizable)) {
          helpers.createResizable(targetNode, total_duration)
        } else {
        }
      } else {
        var targetNode = $("[data-day='" + d + "']").filter('.ui-resizable')
        if (targetNode) {
          React.unmountComponentAtNode(targetNode.parent()[0])
        }
      }
    },

    createResizable: function (currentTarget, hours, name) {
      var totalRows = 25,
        day = $(currentTarget).attr('data-day'),
        startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
        endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4)

      $(currentTarget).append("<div data-id=''/>")
      var x = $(currentTarget).find("[data-id='']")

      React.render(
        <helpers.overlayInterval name={name} index="100" startTime={startTime} endTime={endTime} day={day} maxWidth={96} />,
        x[0]
      )

      $(currentTarget)
        .find('.overlay_interval')
        .resizable({
          handles: 'n, s',
          grid: [0, cellSize],
          axis: 'y',
          minHeight: cellSize,
          maxHeight: totalRows * cellSize * 4 - 6,
          create: function (event, ui) {
            $(event.target).height(hours * 4 * cellSize - 4)
          },
          resize: _.throttle(
            _.bind(function (event, ui) {
              var currentElement = $(ui.element),
                currentTarget = $(ui.element).closest('.fraction'),
                remainingCells = helpers.getNeighboringOverlayIntervals(currentTarget, true),
                previousCells = helpers.getNeighboringOverlayIntervals(currentTarget, false),
                nextInterval = _.first(
                  _.filter(
                    _.map(remainingCells, function (x, k) {
                      return _.first($(x).find('.ui-resizable'))
                    })
                  )
                ),
                prevInterval = _.first(
                  _.filter(
                    _.map(previousCells, function (x, k) {
                      return _.first($(x).find('.ui-resizable'))
                    })
                  )
                )

              // Restrict northward movement
              if (prevInterval && $(currentElement).offset().top < $(prevInterval).offset().top + $(prevInterval).outerHeight()) {
                ui.position.top = ui.originalPosition.top
              }
              // Restrict southward movement
              if (nextInterval && $(currentElement).offset().top + $(currentElement).outerHeight() > $(nextInterval).offset().top) {
                ui.position.top = ui.originalPosition.top
              }
            }, this),
            100
          ),

          stop: function (event, ui) {
            var currentElement = $(ui.element),
              currentTarget = $(ui.element).closest('.fraction'),
              currentStartHour = $(currentTarget).data('start'),
              currentStartFraction = $(currentTarget).data('fraction'),
              fractionFromStart = Math.round($(currentElement).position().top / cellSize),
              totalFractions = Math.round(currentElement.outerHeight() / cellSize),
              startTime = modules.weekViewManager.getDisplayInterval(currentStartHour, currentStartFraction, fractionFromStart),
              endTime = modules.weekViewManager.getDisplayInterval(
                currentStartHour,
                currentStartFraction,
                totalFractions + fractionFromStart
              )

            // HACK: set the flyout values
            $('[name=start_time]').val(Pmp.Utils.timeWithLocale(startTime))
            $('[name=end_time]').val(Pmp.Utils.timeWithLocale(endTime))
            $('.last-seating-time').text(Pmp.Utils.timeWithLocale(endTime))

            // HACK: Invoke others
            $(currentElement)
              .closest('table')
              .find('.ui-resizable')
              .each(function (index, overlay) {
                helpers.replicateOverlay(overlay, currentElement, startTime, endTime)
              })
          },
        })

      var resizableCss = {
        'background-image': 'url(' + helpers.media_url + 'images/icons/icon-drag.png)',
        'background-size': '23px',
        left: '30%',
        width: '40%',
      }
      $(currentTarget)
        .find('div')
        .find('.ui-resizable-n')
        .css(_.extend({ top: '-2px' }, resizableCss))
      $(currentTarget)
        .find('div')
        .find('.ui-resizable-s')
        .css(_.extend({ bottom: '-2px' }, resizableCss))
    },

    replicateOverlay: function (element, sourceElement, startTime, endTime) {
      var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime),
        endTimeDisplay = Pmp.Utils.timeWithLocale(endTime)
      // Reset the time
      $(element)
        .find('.time')
        .html(startTimeDisplay + ' - ' + endTimeDisplay)
      $(element).height($(sourceElement).height())
      $(element).offset({ top: $(sourceElement).offset().top })

      // Update data attributes
      $(element).attr('data-starttime', startTime)
      $(element).attr('data-endtime', endTime)
    },

    createOverlay: function (currentTarget, hours, name, id, key, maxWidth, left, is_override) {
      var day = $(currentTarget).attr('data-day'),
        startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
        endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4)

      $(currentTarget).append('<div data-id=' + id + '/>')
      var x = $(currentTarget).find('[data-id=' + id.replace('.', '\\.') + ']')
      React.render(
        <helpers.overlayInterval
          key={id}
          index={key}
          height={cellSize * hours * 4 - 4}
          maxWidth={maxWidth}
          left={left}
          isOverride={is_override}
          name={name}
          startTime={startTime}
          endTime={endTime}
          day={day}
          id={id}
        />,
        x[0]
      )
    },

    getSelectedDays: function (day_of_week) {
      // Adjust mon -> sun => sun -> sat
      var rotated_day_of_week = [].concat(day_of_week)
      rotated_day_of_week.unshift(rotated_day_of_week.pop())
      var days = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']
      return _.map(
        _.reject(
          _.map(rotated_day_of_week, function (k, i) {
            return k ? i : undefined
          }),
          _.isUndefined
        ),
        function (i) {
          return days[i]
        }
      )
    },

    hourIntervalView: React.createClass({
      onClickHandler: function (e) {
        var currentTarget = $(e.currentTarget),
          target = $(e.target).closest('.overlay_interval'),
          isTargetOverlay = $(target).size(),
          isClickable = $(currentTarget).data('clickable'),
          selectedDay = $(currentTarget).data('day'),
          isFutureDate = new Date(selectedDay) >= Pmp.Manager.Global._venue_today_date,
          doesResizableExist = !!$(this.getDOMNode()).closest('table').find('.ui-resizable').size()
        // If target element is clickable, proceed
        if (isTargetOverlay) {
          this.props.onClickOverlay(target)
          // Ensure that no resizable already exists
        } else if (!!isClickable && !doesResizableExist) {
          // Only create slideout if the date is future date
          if (isFutureDate) {
            helpers.createResizable(currentTarget, 3)
            this.props.onClickOverlay($(currentTarget).find('.overlay_interval'))
          }
        } else {
        }
      },

      onMouseOver: function (e) {
        if (!!$(this.getDOMNode()).closest('table').find('.ui-resizable').size() || $(e.target).filter('.overlay_interval').size() > 0) {
          return
        }
        var currentTarget = $(e.currentTarget)
        if (!this.props.isPassedDay) {
          $(currentTarget).css({
            'background-color': '#DFDFDF',
          })
        }
      },

      resetColor: function (targetTD) {
        $(targetTD).css({
          'background-color': '#FAFAFA',
        })
      },

      onMouseOut: function (e) {
        this.resetColor(e.currentTarget)
      },

      render: function () {
        var isFutureDate = new Date(this.props.day) >= Pmp.Manager.Global._venue_today_date,
          borderTop = this.props.start == '6' && this.props.fraction == '0' ? '1px solid #DADADA' : 0,
          intervalCss = {
            width: '14.28%',
            height: cellSize,
            padding: 0,
            position: 'relative',
            borderTop: borderTop,
            borderBottom: 0,
            cursor: !this.props.isPassedDay ? 'pointer' : '',
            borderLeft: '1px solid #DADADA',
            borderRight: 0,
            backgroundColor: isFutureDate ? '#FAFAFA' : '#EAEAEA',
          },
          fractionCss = {
            position: 'relative',
            height: cellSize,
            width: '100%',
          },
          interval_minutes = 15,
          interval_multiplier = interval_minutes / 15

        var fractions = _.times(
          4,
          function (fraction) {
            var applicableStyle = _.extend(
                {},
                fractionCss,
                fraction == 3
                  ? {
                      borderBottom: '1px dotted #CACACA',
                      height: cellSize - 1,
                    }
                  : fraction == 0 && this.props.start == start_of_day_hour
                  ? {
                      borderTop: '1px solid #DADADA',
                    }
                  : {}
              ),
              isClickable = isFutureDate ? (this.props.start * 4 + fraction) % interval_multiplier == 0 : false

            return (
              <div
                className="fraction"
                style={applicableStyle}
                data-clickable={!!isClickable}
                onMouseOver={isClickable ? this.onMouseOver : undefined}
                onMouseOut={isClickable ? this.onMouseOut : undefined}
                data-start={this.props.start}
                data-end={this.props.end}
                onClick={this.onClickHandler}
                data-day={this.props.day}
                data-test={`sr-fraction-${this.props.day}-${this.props.start}-${this.props.end}`}
                data-fraction={fraction}
                key={this.props.day + '-' + fraction}
              />
            )
          },
          this
        )

        return (
          <td style={intervalCss} data-start={this.props.start} data-end={this.props.end} data-day={this.props.day}>
            {fractions}
          </td>
        )
      },
    }),

    LeftMark: React.createClass({
      render: function () {
        var calculatedHeight = -(10 + cellSize)
        var topcellCss = {
            width: 50,
            padding: 0,
            top: calculatedHeight,
            position: 'relative',
            fontSize: 10,
            height: 8,
          },
          tdCss = {
            color: '#C1C1C1',
            border: 0,
          },
          bottomCss = {}

        _.extend(bottomCss, topcellCss, {
          top: 2,
          height: '5px',
        })

        var startInterval = modules.weekViewManager.getDisplayInterval(this.props.start)
        var endInterval = modules.weekViewManager.getDisplayInterval(this.props.end)
        var startTimeDisplay = Pmp.Utils.timeWithLocale(startInterval)
        var endTimeDisplay = Pmp.Utils.timeWithLocale(endInterval)
        return !this.props.is_last ? (
          <td style={tdCss}>
            <div style={topcellCss}>{startTimeDisplay}</div>
          </td>
        ) : (
          <td style={tdCss}>
            <div style={topcellCss}>{startTimeDisplay}</div>
            <div style={bottomCss}>{endTimeDisplay}</div>
          </td>
        )
      },
    }),

    RowView: React.createClass({
      render: function () {
        var intervals = _.map(
            this.props.days,
            function (x, k) {
              return (
                <helpers.hourIntervalView
                  start={this.props.start_interval}
                  isPassedDay={Pmp.Manager.Global._venue_today_date > new Date(x)}
                  end={this.props.end_interval}
                  index={k}
                  key={k}
                  day={x}
                  onClickOverlay={this.props.onClickOverlay}
                  intervals={this.props.intervals}
                />
              )
            },
            this
          ),
          leftMark = (
            <helpers.LeftMark
              start={this.props.start_interval}
              end={this.props.end_interval}
              index={0}
              key={0}
              is_last={this.props.is_last}
            />
          )

        return (
          <tr className="row">
            {leftMark}
            {intervals}
          </tr>
        )
      },
    }),

    bindCalendarBehavior: function (divElement, selectedDate, callback) {
      var jsDate = $.datepicker.parseDate('mm/dd/yy', selectedDate)
      $(divElement).datepicker({
        dateFormat: 'mm/dd/yy',
        minDate: $.datepicker.parseDate('mm/dd/yy', ''),
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow: function () {
          $('#ui-datepicker-div').addClass('customize')
        },
        onSelect: function (dateText, selObj) {
          callback(dateText)
        },
        prependZero: function (num) {
          return ('0' + num).slice(-2)
        },
      })
      $(divElement).datepicker('setDate', jsDate, true)
    },

    calendarView: React.createClass({
      render: function () {
        return (
          <input
            readOnly="true"
            type="text"
            name="date"
            className="date-selector hasDatePicker"
            style={{
              backgroundImage: 'url(' + helpers.media_url + 'images/icons/calendar-negative.png)',
              backgroundSize: 16,
              height: 16,
              backgroundColor: '#A7A7A7',
              width: 16,
              float: 'right',
              margin: 5,
              color: 'rgba(127, 127, 127, 0)',
              padding: 0,
              cursor: 'pointer',
            }}
          />
        )
      },
    }),

    controlView: React.createClass({
      getSelectedWeekDisplay: function (dateText) {
        var selectedDate = new Date(dateText),
          saturday = selectedDate.addDays(-selectedDate.getDay()),
          sunday = new Date(saturday)
        saturday.addDays(6)

        var sameYear = sunday.getYear() == saturday.getYear(),
          sameMonth = sunday.getMonth() == saturday.getMonth()
        if (sameMonth) {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            '-',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        } else if (sameYear) {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            '-',
            saturday.toLocaleString('default', { month: 'short' }),
            ' ',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        } else {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            ' ',
            sunday.getFullYear(),
            '-',
            saturday.toLocaleString('default', { month: 'short' }),
            ' ',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        }
      },

      onThisWeek: function () {
        this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', new Date()))
      },

      onBackOneWeek: function () {
        this.updateDateByDays(-7)
      },

      isSameWeekAsCurrentWeek() {
        return new Date(this.props.selectedDate).getWeek() == new Date().getWeek()
      },

      onForwardOneWeek: function () {
        this.updateDateByDays(7)
      },

      updateDateByDays: function (n) {
        var d = new Date(this.props.selectedDate)
        d.addDays(n)
        this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', d))
      },
      goToSummary: function () {
        var path = window.location.pathname + '/summary'
        window.location = window.location.origin + path
      },
      goToReportingPeriods: function () {
        window.location = window.location.origin + '/manager2/' + venue_name + '/settings/venue?activeTab=shriftReportingPeriods'
      },
      render: function () {
        var backgroundColor = this.isSameWeekAsCurrentWeek() ? '#D8D9D9' : 'blue'
        return (
          <div
            style={{
              borderTop: '1px solid lightgrey',
              borderBottom: '1px solid lightgrey',
              overflow: 'hidden',
              color: '#D8D9D9',
              fontFamily: 'Roboto',
            }}
          >
            <div
              className="layout"
              onClick={this.goToSummary}
              style={{
                float: 'left',
                backgroundImage: 'url(' + helpers.media_url + 'images/icons/icon-list.png)',
                width: 20,
                height: 16,
                backgroundSize: '100% 100%',
                margin: 7,
                cursor: 'pointer',
              }}
            ></div>
            <div className="layout" style={{ borderLeft: '1px solid', height: 23, float: 'left', margin: '3px 0px' }} />
            <div
              className="layout"
              style={{
                float: 'left',
                backgroundImage: 'url(' + helpers.media_url + 'images/icons/icon-grid-view.png)',
                width: 16,
                height: 16,
                backgroundSize: '100% 100%',
                margin: 7,
              }}
            ></div>
            <div
              className="selected-date"
              style={{
                float: 'left',
                color: '#878787',
                paddingLeft: 10,
                fontSize: 14,
                height: 30,
                lineHeight: '30px',
                borderLeft: '1px solid lightgrey',
              }}
            >
              <b>{this.getSelectedWeekDisplay(this.props.selectedDate)}</b>
            </div>
              {
                  show_shift_reporting_periods && (<div
                      className="layout"
                      onClick={this.goToReportingPeriods}
                      style={{
                          float: 'left',
                          color: '#878787',
                          paddingLeft: 10,
                          margin: 7,
                          cursor: 'pointer',
                      }}
                  >Shift Reporting Periods</div>)
              }
              <helpers.calendarView/>
              <div
                  className="selection"
                  onClick={this.onThisWeek}
                  style={{
                      float: 'right',
                      color: backgroundColor,
                      width: 70,
                      height: 30,
                      lineHeight: '30px',
                      fontSize: 11,
                      fontFamily: 'Roboto',
                      marginLeft: 20,
                cursor: 'pointer',
                borderRight: '1px solid lightgrey',
              }}
            >
              This week
            </div>
            <div
              className="selection"
              style={{
                float: 'right',
                height: 30,
                fontSize: 14,
                color: 'black',
                cursor: 'pointer',
                lineHeight: '30px',
                borderLeft: '1px solid lightgrey',
                borderRight: '1px solid lightgrey',
              }}
            >
              <div
                onClick={this.onBackOneWeek}
                style={{
                  float: 'left',
                  width: 30,
                  textAlign: 'center',
                  height: 30,
                  lineHeight: '30px',
                }}
              >
                &lt;
              </div>
              <div
                onClick={this.onForwardOneWeek}
                style={{
                  float: 'left',
                  width: 30,
                  textAlign: 'center',
                  height: 30,
                  borderLeft: '1px solid lightgrey',
                  lineHeight: '30px',
                }}
              >
                &gt;
              </div>
            </div>
          </div>
        )
      },
    }),

    HeaderView: React.createClass({
      getDateDisplay: function (d) {
        try {
          var dt = new Date(d)
          var dateDisplay = Pmp.Utils.isMonthDayDateFormat()
            ? dt.getMonth() + 1 + '/' + dt.getDate()
            : dt.getDate() + '/' + (dt.getMonth() + 1)
          return dt.toLocaleString('default', { weekday: 'long' }).toUpperCase() + ' ' + dateDisplay
        } catch (e) {
          // Korean formatted date doesn't parse back into new Date(d)
          return d
        }
      },

      render: function () {
        var days = (this.props.days || []).sort(function (a, b) {
            return new Date(a) > new Date(b) ? 1 : -1
          }),
          displayDates = _.map(
            days,
            function (d) {
              return this.getDateDisplay(d)
            },
            this
          ),
          headers = _.map(
            displayDates,
            function (d, k) {
              return <helpers.headerCellMenu key={k} disp={d} media_url={helpers.media_url} />
            },
            this
          )
        return (
          <thead>
            <tr>
              <th style={{ border: 0 }}></th>
              {headers}
            </tr>
          </thead>
        )
      },
    }),

    TableView: React.createClass({
      propTypes: {
        selectedDate: React.PropTypes.string,
        selectedIntervals: React.PropTypes.array,
        intervals: React.PropTypes.array,
        days: React.PropTypes.array,
        venueName: React.PropTypes.string.isRequired,
      },

      getDefaultProps: function () {
        return {
          intervals: [],
          selectedIntervals: [],
          days: [],
        }
      },

      componentWillMount: function () {
        var date = $.datepicker.formatDate('mm/dd/yy', new Date())
        this.props.intervals = helpers.generateIntervals(start_of_day_hour)
        this.props.days = helpers.generateDefaultDays(date)
        this.onDateUpdate(date)
        this.forceUpdate()
      },

      getInitialState: function () {
        var date = this.props.date ? this.props.date : $.datepicker.formatDate('mm/dd/yy', new Date())
        return {
          selectedDate: date,
          hasCheckedInitialShift: false,
          hasUpsellsLoaded: false,
        }
      },

      postRenderHook: function (headDiv) {
        $(headDiv).find('.overlay_interval').remove()
        helpers.unMountOverlays(headDiv)
        helpers.applyOverlays(headDiv, this.props.selectedIntervals, this.props.intervals)
      },

      postDataUpdate: function (intervalOverlays, date) {
        this.props.selectedIntervals = intervalOverlays
        this.props.days = _.keys(this.props.selectedIntervals)
        this.setState({ selectedDate: date })
        if (!this.state.hasCheckedInitialShift) {
          this.checkShouldOpenInitialShift()
        }
      },

      onDateUpdate: function (date) {
        if (!this.state.hasUpsellsLoaded) {
          this.props.fetchUpsells(this.props.venueName, () => this.props.fetchData(this.props.venueName, date, this.postDataUpdate))
          this.setState({ hasUpsellsLoaded: true })
        } else {
          this.props.fetchData(this.props.venueName, date, this.postDataUpdate)
        }
      },

      componentDidMount: function () {
        this.postRenderHook($(this.getDOMNode()))
        helpers.bindCalendarBehavior($(this.getDOMNode()).find('.date-selector'), this.state.selectedDate, this.onDateUpdate)
      },

      componentDidUpdate: function () {
        this.postRenderHook($(this.getDOMNode()))
      },

      checkShouldOpenInitialShift: function () {
        const urlParams = new URLSearchParams(window.location.search)
        const shiftPersistentId = urlParams.get('selected_shift_persistent_id')
        const date = urlParams.get('date')
        if (shiftPersistentId && date) {
          showShiftData(floorplan_data, shiftPersistentId, date, upsells.categories)
        }
        this.setState({ hasCheckedInitialShift: true })
      },

      render: function () {
        var tableCss = {
            width: '98%',
            marginTop: '1%',
            marginRight: '3%',
            borderCollapse: 'separate',
          },
          rows = _.map(
            this.props.intervals,
            function (x, k) {
              var is_last = k == _.size(this.props.intervals) - 1
              return (
                <helpers.RowView
                  start_interval={_.first(x)}
                  end_interval={_.last(x)}
                  onClickOverlay={this.props.onClickOverlay}
                  key={k}
                  is_last={is_last}
                  days={this.props.days}
                  className="cell"
                  intervals={this.props.intervals}
                />
              )
            },
            this
          ),
          tHeader = <helpers.HeaderView days={this.props.days} media_url={this.props.media_url} onDateUpdate={this.onDateUpdate} />

        return (
          <div>
            <helpers.controlView onDateUpdate={this.onDateUpdate} selectedDate={this.state.selectedDate} />
            <table className="table" style={tableCss}>
              {tHeader}
              {rows}
            </table>
          </div>
        )
      },
    }),

    unMountOverlays: function (targetDiv) {
      // This needs to be done because overlays are mounted not as part of jsx render
      $(targetDiv)
        .find('[data-id]')
        .each(function (i, d) {
          React.unmountComponentAtNode(d)
        })
    },

    applyOverlays: function (headDiv, intervalOverlays, intervals) {
      for (d in intervalOverlays) {
        if (!_.isEmpty(intervalOverlays[d])) {
          var currentShifts = intervalOverlays[d],
            all_overlaps = [],
            current_overlaps = [],
            prevParentShift = undefined
          currentShifts.sort(function (a, b) {
            var firstStartDate = new Date(d + ' ' + a.start_time_display),
              firstEndDate = new Date(d + ' ' + a.end_time_display),
              secondStartDate = new Date(d + ' ' + b.start_time_display),
              secondEndDate = new Date(d + ' ' + b.end_time_display)
            a.start_date_time = firstStartDate
            a.end_date_time = firstEndDate
            b.start_date_time = secondStartDate
            b.end_date_time = secondEndDate
            return firstStartDate > secondStartDate
              ? 1
              : firstStartDate < secondStartDate
              ? -1
              : firstEndDate - firstStartDate - (secondEndDate - secondStartDate)
          })
          for (var i = 0; i < currentShifts.length; i++) {
            var currentShift = currentShifts[i],
              prevParentShift = prevParentShift ? prevParentShift : currentShift,
              doesOverlap = prevParentShift && currentShift.start_date_time < prevParentShift.end_date_time
            if (doesOverlap) {
              current_overlaps.push(currentShift)
            } else {
              if (current_overlaps.length) {
                all_overlaps.push(current_overlaps)
                current_overlaps = []
              }
              current_overlaps.push(currentShift)
              prevParentShift = currentShift
            }
          }
          if (current_overlaps.length) {
            all_overlaps.push(current_overlaps)
          }

          for (var i = 0; i < all_overlaps.length; i++) {
            var current_overlaps = all_overlaps[i],
              hasOverlappingShifts = current_overlaps.length > 1,
              totalOverlappingShifts = current_overlaps.length + 1,
              maxWidth = hasOverlappingShifts ? (96 / totalOverlappingShifts) * 2 : 96
            current_overlaps.map(function (s, key) {
              var start_time = s['start_time_display'],
                end_time = s['end_time_display'],
                start_time_parts = modules.weekViewManager.getHourFraction(start_time),
                end_time_parts = modules.weekViewManager.getHourFraction(end_time),
                name = s['name'] || s['category'],
                id = s['persistent_id'],
                intervalHours = end_time_parts.hour - start_time_parts.hour + (end_time_parts.fraction - start_time_parts.fraction) / 4,
                left = key == 0 ? 0 : (key * 96) / totalOverlappingShifts

              intervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours
              var tdSelector = [
                "[data-day='",
                d,
                "']",
                "[data-start='",
                start_time_parts.hour,
                "']",
                "[data-fraction='",
                start_time_parts.fraction,
                "']",
              ].join('')
              helpers.createOverlay(
                $(headDiv).find(tdSelector),
                intervalHours,
                name,
                id,
                key,
                key == 0 ? 96 : maxWidth,
                left,
                s['is_override']
              )
            })
          }
        }
      }
    },

    generateIntervals: function (start_of_day_hour) {
      return _.times(24, function (n) {
        return [(n + start_of_day_hour) % 24, (n + start_of_day_hour + 1) % 24]
      })
    },

    generateDefaultDays: function (date) {
      var d = new Date(date)
      d.addDays(-d.getDay() - 1)
      return _.times(7, function (i) {
        return d
          .addDays(1)
          .toLocaleDateString()
          .replace(/\u200E/g, '')
        //Work around for MSIE (https://connect.microsoft.com/IE/feedback/details/837517/javascript-date-object-method-tolocaledatestring-and-unicode-character-u200e)
      })
    },

    generateGrid: function (id, venue_name, media_url, date, HeaderCellMenu, onClickOverlay, fetchData, fetchUpsells) {
      helpers.media_url = media_url
      helpers.venue_name = venue_name
      helpers.id = id
      helpers.headerCellMenu = HeaderCellMenu
      React.render(
        <helpers.TableView
          selectedDate={date}
          venueName={venue_name}
          onClickOverlay={onClickOverlay}
          fetchData={fetchData}
          fetchUpsells={fetchUpsells}
        />,
        document.getElementById(id)
      )
    },
  }

  var showShiftData = function (floorplanData, shiftPersistentId, selectedDay, upsellCategories) {
    $.ajax({
      url: '/api-yoa/shifts/get',
      data: {
        shift_id: shiftPersistentId,
        venue: venue_name,
        date: selectedDay,
      },
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          modules.shiftslideout.view(
            'flyout-form',
            venue_name,
            media_url,
            floorplanData,
            response.data.shift,
            selectedDay,
            _.partial(helpers.onAddOverlay, id),
            _.partial(resetStateOfAllOverlays, id),
            payment_setup,
            tax_rate,
            tax_groups,
            currency_symbol,
            venue_policy,
            venue_cancellation_policy,
            upsellCategories,
            response.data.shift.upsell_categories,
            can_save_card,
            default_service_charge,
            default_gratuity,
            venue_default_booking_policy_id,
            venue_default_cancellation_policy_id
          )
        }
      },
      error: function (jqXHR, textStatus, error) {
        var response = JSON.parse(jqXHR.responseText)
        Interface._alert(response.msg)
      },
    })
  }

  var onClickOverlay = function (target) {
    var currentShift = $(target),
      shiftId = $(currentShift).data('id'),
      selectedShift = _.findWhere(_.flatten(_.values(shifts)), { id: shiftId }),
      shift = _.extend({}, selectedShift, {
        selectedDay: $(currentShift).data('day'),
        selectedDate: new Date($(currentShift).data('day')),
        startTime: $(currentShift).data('starttime'),
        endTime: $(currentShift).data('endtime'),
        shiftId: $(currentShift).data('id'),
      })
    if (shift.shiftId) {
      showShiftData(floorplan_data, shift.shiftId, shift.selectedDay, upsells.categories)
    } else {
      var existing_shift = {}
      var selected_upsells = []
      modules.shiftslideout.add(
        'flyout-form',
        venue_name,
        media_url,
        floorplan_data,
        shift.name,
        shift.category,
        [modules.shiftslideout.weekDaysOptions[shift.selectedDate.toLocaleString('default', { weekday: 'long' })]],
        shift.selectedDay,
        shift.startTime,
        shift.endTime,
        _.partial(helpers.onAddOverlay, id),
        _.partial(onCloseSlideout, id),
        venue_policy,
        venue_cancellation_policy,
        payment_setup,
        tax_rate,
        tax_groups,
        currency_symbol,
        shift.internal_cutoff_type,
        shift.internal_cutoff_num,
        shift.internal_cutoff_hour_display,
        upsells.categories,
        can_save_card,
        existing_shift,
        selected_upsells,
        default_service_charge,
        default_gratuity,
        venue_default_booking_policy_id,
        venue_default_cancellation_policy_id
      )
    }
  }

  var fetchData = function (venue_name, date, onFetchData) {
    $.ajax({
      url: '/api-yoa/shifts/schedule',
      data: {
        venue: venue_name,
        week_of: date,
      },
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          var createdShifts = _.object(
            _.map(response.data.shifts, function (v, d) {
              var date = new Date(d),
                reformatted_date = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
              return [reformatted_date, v]
            })
          )
          shifts = createdShifts
          onFetchData(createdShifts, date)
        }
      },
      error: function (jqXHR, textStatus, error) {
        Interface._alert('Unknown error happened. Please try later.')
      },
    })
  }

  var fetchUpsells = function (venue_name, callback) {
    $.ajax({
      url: '/api-yoa/venue/upsells/list',
      data: {
        venue: venue_name,
      },
      success: function (response) {
        if (response.status != '200') {
          Interface._alert(response.msg)
        } else {
          upsells.categories = _.reduce(
            response.data.categories,
            (result, category) => {
              const categoryLabel = category.is_required ? `${category.name} (required)` : category.name
              result[category.id] = {
                label: categoryLabel,
                isSelected: false,
              }
              return result
            },
            {}
          )
          if (callback) {
            callback()
          }
        }
      },
      error: function (jqXHR, textStatus, error) {
        Interface._alert('Unknown error happened. Please try later.')
      },
    })
  }

  var resetStateOfAllOverlays = function (id) {
    $('#' + id)
      .find('.overlay_interval')
      .each(function (index, element) {
        var originalColor = $(element).data('backgroundcolor'),
          originalZIndex = $(element).data('zindex')
        $(element).css({ backgroundColor: originalColor, zIndex: originalZIndex })
      })
  }

  var onCloseSlideout = function (id) {
    $('#' + id)
      .find('.ui-resizable')
      .remove()
    resetStateOfAllOverlays(id)
  }

  var renderInstance = function (date) {
    if (!instantiated) {
      return
    }
    helpers.generateGrid(id, venue_name, media_url, date, HeaderCellMenu, onClickOverlay, fetchData, fetchUpsells)
  }

  var refreshInstance = function (date) {
    // HACK: refresh via calendar - since we can't change state from outside
    $('#' + id)
      .find('.date-selector')
      .data('datepicker')
      .settings.onSelect(date)
  }

  var HeaderCellMenu = React.createClass({
    onClickHandler: function () {
      var displayElement = $(this.getDOMNode()).find('.utildrop')
      if (displayElement.css('display') == 'none') {
        displayElement.css('display', 'block')
      } else {
        displayElement.css('display', 'none')
      }
    },

    SaveDateAsCustomLink: React.createClass({
      getInitialState: function () {
        return {
          mode: 'view',
        }
      },

      onSave: function () {
        this.setState({
          mode: 'saving',
        })
        $.get(
          '/api-yoa/booking_access?venue=whale&week_of=11%2F08%2F2015',
          _.bind(function (data) {
            this.setState({ mode: 'view' })
          }, this)
        )
      },

      onEdit: function () {
        this.setState({ mode: 'edit' })
      },

      render: function () {
        var viewMode = (
          <li
            onClick={this.onEdit}
            style={{
              padding: 7,
              listStyle: 'none',
              cursor: 'pointer',
            }}
          >
            Save date as custom schedule
          </li>
        )
        var editMode = (
          <span
            style={{
              padding: 7,
            }}
          >
            <input
              style={{
                width: 100,
                border: '1px solid lightgrey',
                margin: 5,
              }}
              disabled={this.state.mode == 'saving'}
              type="text"
            />
            <button onClick={this.onSave} disabled={this.state.mode == 'saving'}>
              Apply
            </button>
          </span>
        )
        return this.state.mode == 'view' ? viewMode : editMode
      },
    }),

    render: function () {
      var displayStyle = { display: 'none' },
        applicableUtilStyle = _.extend({}, displayStyle, {
          backgroundColor: 'white',
          position: 'absolute',
          border: '1px solid #D1D1D1',
          zIndex: 400,
          fontSize: 11,
          color: '#818181',
          fontFamily: 'Roboto',
        }),
        liStyle = {
          padding: 7,
          listStyle: 'none',
        },
        applyStyle = {
          padding: 7,
          borderTop: '1px solid #D1D1D1',
          color: '#D1D1D1',
          fontSize: 10,
          listStyle: 'none',
        }

      return (
        <th key={this.props.key} style={{ border: 0 }}>
          <span style={{ fontSize: 10 }}>{this.props.disp}</span>
          {/*
                 <span className="dropdown-arrow"
                 onClick={this.onClickHandler}
                 style={{cursor: 'pointer', padding: '0px 0px 0px 10px'}}>
                 <img src={this.props.media_url + "images/down-arrow.png"} />
                 </span>
                 <div className="utildrop"
                 style={applicableUtilStyle}
                 >
                 <span className="pin-arrow"></span>
                 <ul>
                 <this.SaveDateAsCustomLink disp={this.props.disp} />
                 <li style={liStyle}>Close all shifts on this date</li>
                 <li style={applyStyle}>APPLY CUSTOM SCHEDULE</li>
                 </ul>
                 </div>
                 */}
        </th>
      )
    },
  })

  var init = function (
    domId,
    name,
    url,
    date,
    _payment_setup,
    _tax_rate,
    _tax_groups,
    _currency_symbol,
    _start_of_day_hour,
    _can_save_card,
    _default_service_charge,
    _default_gratuity,
    _show_shift_reporting_periods
  ) {
    var floorplan_def = new $.Deferred()
    $.ajax({
      url: '/api-yoa/floorplan_data',
      data: {
        venue: name,
      },
      success: function (response) {
        floorplan_def.resolve(response)
      },
      error: function (jqXHR, textStatus, error) {
        floorplan_def.reject('Floorplan fetch failed.')
        Interface._alert('Unknown error happened. Please try later.')
      },
    })
    var venue_info_def = new $.Deferred()
    $.ajax({
      url: '/booking/venue_info',
      data: {
        venue: name,
      },
      success: function (response) {
        venue_info_def.resolve(response)
      },
      error: function (jqXHR, textStatus, error) {
        venue_info_def.reject('Venue info fetch failed.')
        Interface._alert('Unknown error happened. Please try later.')
      },
    })

    instantiated = true
    venue_name = name
    media_url = url
    id = domId
    payment_setup = _payment_setup
    tax_rate = _tax_rate
    show_shift_reporting_periods = _show_shift_reporting_periods === "True"
    tax_groups = _tax_groups
    can_save_card = _can_save_card
    currency_symbol = _currency_symbol
    start_of_day_hour = Number(_start_of_day_hour)
    default_service_charge = _default_service_charge
    default_gratuity = _default_gratuity
    $.when(floorplan_def, venue_info_def).done(function (floorplan_resp, venue_info_resp) {
      floorplan_data = floorplan_resp.data
      venue_policy = venue_info_resp.policy
      venue_cancellation_policy = venue_info_resp.cancellation_policy
      venue_default_booking_policy_id = venue_info_resp.booking_policy_id
      venue_default_cancellation_policy_id = venue_info_resp.cancellation_policy_id
      renderInstance(date)
    })
  }

  return {
    render: renderInstance,
    init: init,
    refresh: refreshInstance,
    resetResizableOverlays: helpers.resetResizableOverlays,
  }
})()

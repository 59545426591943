import { useMemo } from 'react'
import { createZodSchema, type InferZodSchema } from '@sevenrooms/core/form'
import { useBackwriterSettingsSchema } from './Backwriter/Backwriter.zod'
import { useChargesDiningForm, useChargesNightlifeForm } from './Charges'
import { useGuestEmailSMSForm } from './GuestEmailSMS'
import { useInternalTeamEmailsForm } from './InternalTeamEmails'
import { usePoliciesAndVerbiageForm } from './PoliciesAndVerbiage'
import { useReservationsDiningForm, useReservationsNightlifeForm } from './Reservations'
import { useShiftReportPeriodsSettingsSchema } from './ShiftReportingPeriods/ShiftReportingPeriods.zod'

export type VenueSettingsDiningForm = InferZodSchema<typeof useVenueSettingsDiningForm>

export const useVenueSettingsDiningForm = createZodSchema(z => {
  const internalTeamEmails = useInternalTeamEmailsForm()
  const guestEmailSMS = useGuestEmailSMSForm()
  const reservationsDining = useReservationsDiningForm()
  const chargesDining = useChargesDiningForm()
  const policiesAndVerbiage = usePoliciesAndVerbiageForm()
  const backwriter = useBackwriterSettingsSchema()
  const shiftReportingPeriods = useShiftReportPeriodsSettingsSchema()

  return useMemo(
    () =>
      z.object({
        internalTeamEmails,
        guestEmailSMS,
        reservationsDining,
        chargesDining,
        policiesAndVerbiage,
        backwriter,
        shiftReportingPeriods,
      }),
    [z, internalTeamEmails, guestEmailSMS, reservationsDining, chargesDining, policiesAndVerbiage, backwriter, shiftReportingPeriods]
  )
})

export type VenueSettingsNightlifeForm = InferZodSchema<typeof useVenueSettingsNightlifeForm>

export const useVenueSettingsNightlifeForm = createZodSchema(z => {
  const internalTeamEmails = useInternalTeamEmailsForm()
  const guestEmailSMS = useGuestEmailSMSForm()
  const reservationsNightlife = useReservationsNightlifeForm()
  const chargesNightlife = useChargesNightlifeForm()
  const policiesAndVerbiage = usePoliciesAndVerbiageForm()
  const backwriter = useBackwriterSettingsSchema()

  return useMemo(
    () =>
      z.object({
        internalTeamEmails,
        guestEmailSMS,
        reservationsNightlife,
        chargesNightlife,
        policiesAndVerbiage,
        backwriter,
      }),
    [z, internalTeamEmails, guestEmailSMS, reservationsNightlife, chargesNightlife, policiesAndVerbiage, backwriter]
  )
})

import { defineMessages } from '@sevenrooms/core/locales'

export const venueSettingsMessages = defineMessages({
  title: {
    id: 'settings.general.title',
    defaultMessage: 'Venue Settings',
  },
  error: {
    id: 'settings.general.error',
    defaultMessage: 'Something went wrong...',
  },
  success: {
    id: 'settings.general.success',
    defaultMessage: 'Changes to General Settings have been saved.',
  },
  description: {
    id: 'settings.general.description',
    defaultMessage:
      'Manage your internal and guest-facing email and SMS messages, reservation details, associated charges and standard verbiage, and shift reporting periods',
  },
  addAnotherSendTime: {
    id: 'settings.general.addAnotherSendTime',
    defaultMessage: 'Add another send time',
  },
  tabInternalTeamEmails: {
    id: 'settings.general.tab.internalTeamEmails',
    defaultMessage: 'Internal Team Emails',
  },
  tabGuestEmailSMS: {
    id: 'settings.general.tab.guestEmailSMS',
    defaultMessage: 'Guest Email / SMS',
  },
  tabReservations: {
    id: 'settings.general.tab.reservations',
    defaultMessage: 'Reservations',
  },
  tabCharges: {
    id: 'settings.general.tab.charges',
    defaultMessage: 'Charges',
  },
  tabShriftReportingPeriods: {
    id: 'settings.general.tab.tabShriftReportingPeriods',
    defaultMessage: 'Shift Reporting Periods',
  },
  tabBackwriter: {
    id: 'settings.general.tab.backwriter',
    defaultMessage: '{icon} AI Responses',
  },
  tabPoliciesAndVerbiage: {
    id: 'settings.general.tab.policiesAndVerbiage',
    defaultMessage: 'Policies and Verbiage',
  },
  additionalConfig: {
    id: 'settings.general.additionalConfig',
    defaultMessage: 'Additional Configurations',
  },
} as const)

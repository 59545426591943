import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded'
import * as React from 'react'
import { createContext, type PropsWithChildren, type ReactElement, useEffect } from 'react'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Form, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Step } from '@sevenrooms/react-components/components/Step'
import { Stepper } from '@sevenrooms/react-components/components/Stepper'
import { Typography } from '@sevenrooms/react-components/components/Typography'

interface StepProps {
  title: string
  component: ReactElement
  isValid: () => void
  handleBack?: () => void
  replaceHandleBack?: boolean
  handleNext?: () => void
  replaceHandleNext?: boolean
}

export interface Steps {
  [key: string]: StepProps
}

interface StepperIconProps {
  index: number
  activeStep: number
}
interface StepperFlowProps<T extends z.ZodType> {
  form: UseForm<z.infer<T>>
  steps: Steps
  onCancel: () => {} | void
  onFlowSubmit: () => {} | void
  isFlowSubmitLoading: boolean
  flowStep: number | undefined
  setFlowStep: (step: number | undefined) => void
  fullFooterMenu?: boolean
  isFlowCompleted?: boolean
  setFlowCompleted?: (isFlowCompleted: boolean) => void
  useSaveButton?: boolean
  setUseSaveButton?: (useSaveButton: boolean) => void
}

interface StepperFlowActionsContextContent {
  handleNext: () => void
  handleBack: () => void
}

export const StepperFlowActionsContext = createContext<StepperFlowActionsContextContent>({
  handleNext: () => {},
  handleBack: () => {},
})

export function StepperFlow<T extends z.ZodType>({
  form,
  steps,
  onCancel,
  onFlowSubmit,
  isFlowSubmitLoading,
  fullFooterMenu = true,
  isFlowCompleted = false,
  setFlowCompleted,
  flowStep,
  setFlowStep,
  useSaveButton,
  setUseSaveButton,
}: StepperFlowProps<T>) {
  const { formatMessage } = useLocales()
  const theme = useTheme()

  const flowSteps: string[] = Object.keys(steps)
  const flowStepsLength = flowSteps.length
  const [activeStep, setActiveStep] = React.useState(0)

  const currentStepComponent = (activeStepIndex: number): string | undefined => flowSteps[activeStepIndex]

  useEffect(() => {
    if (flowStep !== undefined) {
      setActiveStep(flowStep)
      setFlowStep(undefined)
      if (setFlowCompleted) {
        setFlowCompleted(false)
      }
    }
  }, [flowStep, setFlowCompleted, setFlowStep])

  const handleNext = async () => {
    const step = steps[currentStepComponent(activeStep) as keyof typeof steps]
    const isValid = await step?.isValid()
    if (!isValid) {
      return
    }

    if (useSaveButton) {
      setActiveStep(flowStepsLength - 1)
      if (setUseSaveButton) {
        setUseSaveButton(false)
      }
    } else {
      if (step?.handleNext) {
        step.handleNext()
        if (step?.replaceHandleNext) {
          return
        }
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (setFlowCompleted) {
      setFlowCompleted(false)
    }
    const step = steps[currentStepComponent(activeStep) as keyof typeof steps]
    if (step?.handleBack) {
      step.handleBack()
      if (step?.replaceHandleBack) {
        return
      }
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: '-webkit-center',
      }}
    >
      <Box sx={{ pb: 4, borderBottom: `1px solid ${theme.colors.borders}`, flexShrink: 0 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {flowSteps.map((step, index) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={steps[step]?.title || ''} {...stepProps}>
                <Stack direction="column" alignItems="center">
                  <StepperIcon index={index} activeStep={activeStep} />
                  <StepperLabel index={index} activeStep={activeStep}>
                    {steps[step]?.title}
                  </StepperLabel>
                </Stack>
              </Step>
            )
          })}
        </Stepper>
      </Box>
      {activeStep === flowStepsLength ? (
        <Typography sx={{ mt: 2, mb: 1, pl: 8 }}>{formatMessage(messages.finalPageTitle)}</Typography>
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
              overflowY: 'auto',
              flexGrow: 1,
            }}
          >
            <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
              <StepperFlowActionsContext.Provider value={{ handleNext, handleBack }}>
                {steps[currentStepComponent(activeStep) as keyof typeof steps]?.component}
              </StepperFlowActionsContext.Provider>
            </Form>
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              pt: '16px',
              pb: '16px',
              borderTop: `1px solid ${theme.colors.borders}`,
              borderRadius: `${theme.borderRadius.s}`,
              flexShrink: 0,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', pl: 8 }}>
              <BackButton onClick={handleBack} disabled={isFlowSubmitLoading} activeStep={activeStep}>
                {formatMessage(messages.backButtonText)}
              </BackButton>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pr: 8, justifyContent: 'end' }}>
              <Button data-test="cancel-btn" variant="text" disabled={isFlowSubmitLoading} onClick={onCancel}>
                {formatMessage(messages.cancelButtonText)}
              </Button>
              {isFlowCompleted ? (
                <LoaderButton data-test="handle-finish-btn" loading={isFlowSubmitLoading} onClick={onFlowSubmit}>
                  {formatMessage(messages.finishButtonText)}
                </LoaderButton>
              ) : (
                <ForwardButton useArrow={!useSaveButton} onClick={handleNext} disabled={!fullFooterMenu}>
                  {useSaveButton ? formatMessage(messages.saveButtonText) : formatMessage(messages.nextButtonText)}
                </ForwardButton>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

function StepperIcon({ index, activeStep }: StepperIconProps) {
  if (index === activeStep) {
    return <PanoramaFishEyeRoundedIcon color="primary" />
  } else if (index < activeStep) {
    return <CheckCircleOutlineRounded color="primary" />
  }
  return <PanoramaFishEyeRoundedIcon color="disabled" />
}

function StepperLabel({ index, activeStep, children }: PropsWithChildren<StepperIconProps>) {
  const theme = useTheme()
  const textColor = index <= activeStep ? `${theme.colors.primaryFont}` : `${theme.colors.secondaryFont}`
  return <Typography sx={{ pt: 3, pl: 1, fontSize: 14, fontWeight: 400, color: textColor }}>{children}</Typography>
}

function ForwardButton({
  useArrow,
  onClick,
  disabled,
  children,
}: {
  useArrow: boolean | undefined
  onClick: () => {}
  disabled: boolean
  children: PropsWithChildren<string>
}) {
  return (
    <Button
      data-test="handle-next-btn"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{ boxShadow: 'none', border: 'none' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Typography>{children}</Typography>
        {useArrow && <ArrowForwardIosIcon sx={{ fontSize: '14px', alignSelf: 'center' }} />}
      </Box>
    </Button>
  )
}

function BackButton({
  onClick,
  disabled,
  activeStep,
  children,
}: {
  onClick: () => void
  disabled: boolean
  activeStep: number
  children: PropsWithChildren<string>
}) {
  return (
    <Button data-test="handle-back-btn" onClick={onClick} disabled={disabled} sx={{ mr: 1, display: activeStep === 0 ? 'none' : 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <ArrowBackIosNewIcon sx={{ fontSize: '14px', alignSelf: 'center' }} />
        <Typography>{children}</Typography>
      </Box>
    </Button>
  )
}

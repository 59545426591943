import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Table, TableBody, TableRow, TableCell } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import type { BookingAccessOverrideListItem } from '@sevenrooms/lib-override-series-list'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from './AccessRules.locales'
import { formatDates, formatShifts, formatTimes } from './components/Schedule/utils'

interface AccessRuleOverridesProps {
  overrides: BookingAccessOverrideListItem[]
  allShifts: Shift[]
}

export function AccessRuleOverrides({ allShifts, overrides }: AccessRuleOverridesProps) {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  return (
    <Table>
      <thead>
        <tr>
          <TableCell isHeader isInFirstRow>
            {formatMessage(accessRulesMessages.overrideARNameHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(accessRulesMessages.overrideScheduleHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(accessRulesMessages.overrideShiftCategoryHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(accessRulesMessages.overridePartySizeHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(accessRulesMessages.overrideEditSettingsHeader)}
          </TableCell>
        </tr>
      </thead>

      <TableBody>
        {overrides.map(ar => (
          <TableRow key={ar.id}>
            <TableCell>{ar.name}</TableCell>
            <TableCell>{`${formatMessage(accessRulesMessages.overrideDateAtTimes, {
              date: formatDates(formatMessage, ar.date.toJsDate(), ar.isIndefinite, ar.endDate?.toJsDate()),
              times: formatTimes(ar.accessTimeType, ar.specificTimes, allShifts, ar.shiftCategories, ar.startTime, ar.endTime),
            })}`}</TableCell>
            <TableCell>{formatShifts(ar.shiftCategories, allShifts, ar.restrictToShifts, formatMessage)}</TableCell>
            <TableCell>{`${ar.partySizeMin ?? ''} - ${ar.partySizeMax ?? ''}`}</TableCell>
            <TableCell>
              <Link
                target="_blank"
                to={
                  `/manager/${venueUrlKey}/manage/capacity/accessrules` +
                  `?selected_access_rule_id=${ar.id}&date=${ar.date.formatNYearNMonthNDay()}`
                }
              >
                <Icon name="VMSWeb-open-in-new" />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

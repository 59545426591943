import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useCallback, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { DatePicker } from '@sevenrooms/react-components/components/DatePicker'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'

function AccessDatePicker() {
  const [{ date, view, range }, setParams] = useAccessRulesUrlParams()
  const [initialDate] = useState(date.toJsDate())
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const { formatMessage } = useLocales()

  const isDisabled = view === AccessRuleView.LIST && range === AccessRuleRange.OVERVIEW

  const onChange = useCallback(
    async (d: Date | null) => {
      if (d == null) {
        return
      }
      const newDate = DateOnly.fromDate(d)
      setParams({ date: newDate })
    },
    [setParams]
  )

  const goToPrevious = () => {
    if (isDisabled) {
      return
    }
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() - (range === AccessRuleRange.WEEK ? 7 : 1))
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  const goToNext = () => {
    if (isDisabled) {
      return
    }
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() + (range === AccessRuleRange.WEEK ? 7 : 1))
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  const handleClickToday = () => {
    if (isDisabled) {
      return
    }
    const today = DateOnly.fromDate(new Date())
    setParams({ date: today })
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box width="70px" pr={4}>
        <Button
          size="small"
          data-test="today-btn"
          variant="outlined"
          fullWidth
          sx={{ py: '8px !important' }}
          onClick={handleClickToday}
          disabled={isDisabled}
        >
          {formatMessage(accessRulesSettingsMessages.today)}
        </Button>
      </Box>
      <IconButton size="small" data-test="prev-day-arrow" onClick={goToPrevious} aria-label="Previous day" disabled={isDisabled}>
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        data-test="next-day-arrow"
        onClick={goToNext}
        aria-label="Next day"
        sx={{ mr: '12px' }}
        disabled={isDisabled}
      >
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
      <DatePicker
        open={datePickerOpen}
        onOpen={() => !isDisabled && setDatePickerOpen(true)}
        onClose={() => setDatePickerOpen(false)}
        defaultValue={initialDate}
        value={date.toJsDate()}
        onChange={onChange}
        aria-label="Access Rules date picker"
        data-test="access-rules-date-picker"
        disabled={isDisabled}
        slots={{
          textField: params => (
            <Box
              ref={params.InputProps.ref} // Anchor the popper here
              onClick={e => {
                if (!isDisabled) {
                  params.inputProps.onClick?.(e) // Trigger built-in click handler
                  setDatePickerOpen(!datePickerOpen)
                }
              }}
              onFocus={params.inputProps.onFocus}
              onBlur={params.inputProps.onBlur}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: isDisabled ? 'default' : 'pointer',
                padding: '8px 0',
                borderRadius: '0px',
                outline: 'none',
                height: 36,
                py: 0,
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>{date.formatNYearSMonthNDay()}</Typography>
              <KeyboardArrowDownIcon fontSize="small" sx={{ height: 16, width: 16, ml: 1 }} />
            </Box>
          ),
        }}
      />
    </Stack>
  )
}

export { AccessDatePicker }

import React, { useMemo, useState } from 'react'
import type { PaymentRuleType } from '@sevenrooms/core/domain'
import type { UseForm, z, Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { type RadioChoice, RadioGroup } from '@sevenrooms/core/ui-kit/form'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SaveForLater } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/components/radio'
import { ChargeAtBooking } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/components/radio/ChargeAtBooking'
import { PaymentPolicyLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.locales'
import type { PaymentPolicyForm } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.zod'
import { messages } from '../../locales/offerTemplates.locales'
import { RecommendationTip } from '../RecommendationTip'
import type { CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

export function NewAccessRulePayment({ form }: { form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>> }) {
  const { formatMessage } = useLocales()
  const { field, getValues } = form
  const values = getValues()
  const [selectedRule, setSelectedRule] = useState<PaymentRuleType | 'none'>(values.accessRule.paymentPolicy.paymentRule)

  return (
    <VStack pt="xxl" pb="xxl" spacing="l" width="496px" data-test="create-new-access-rule-payment-step-content">
      <Text textStyle="h2" textAlign="center">
        {formatMessage(messages.newAccessRulePaymentStepTitle, {
          offerName: (
            <Text textStyle="h2" fontWeight="bold">
              "{values.name}"
            </Text>
          ),
        })}
      </Text>
      <RecommendationTip
        header={formatMessage(messages.recommendationTipBestPractice)}
        description={formatMessage(messages.newAccessRulePaymentBestPractices, {
          b: (str: string[]) => <Text fontWeight="bold">{str}</Text>,
        })}
        headerIcon="bestPractices"
      />
      <VStack>
        <VStack spacing="xs" borderWidth="s" borderTopLeftRadius="s" borderTopRightRadius="s" borderColor="dividerLines" p="m">
          <PaymentRuleOptions
            field={field.prop('accessRule').prop('paymentPolicy')}
            primaryLabel={formatMessage(messages.newAccessRulePaymentMethod)}
            onChange={setSelectedRule}
          />
        </VStack>
        <PaymentRuleDetails
          selectedRule={selectedRule}
          field={field.prop('accessRule').prop('paymentPolicy')}
          allowChannelsWithoutCCHolds={field.prop('accessRule').prop('allowChannelsWithoutCCHolds')}
        />
      </VStack>
    </VStack>
  )
}

function PaymentRuleOptions({
  field,
  primaryLabel,
  onChange,
}: {
  field: Field<PaymentPolicyForm>
  primaryLabel: string
  onChange: (choice: PaymentRuleType | 'none') => void
}) {
  const { formatMessage } = useLocales()
  const choices: RadioChoice<PaymentPolicyForm['paymentRule']>[] = useMemo(
    () => [
      {
        value: 'advanced_payment',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceImmediately),
      },
      {
        value: 'none',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceNone),
      },
      {
        value: 'save_for_later',
        label: formatMessage(PaymentPolicyLocales.paymentChoiceLater),
        description: <Text color="secondaryFont">{formatMessage(PaymentPolicyLocales.paymentChoiceLaterDescription)}</Text>,
      },
    ],
    [formatMessage]
  )

  return (
    <VStack spacing="s">
      <Text textStyle="body1Bold">{primaryLabel}</Text>
      <RadioGroup
        data-test="payment-method-selector"
        choices={choices}
        field={field.prop('paymentRule')}
        onChange={choice => onChange(choice.value)}
      />
    </VStack>
  )
}

function PaymentRuleDetails({
  selectedRule,
  field,
  allowChannelsWithoutCCHolds,
}: {
  selectedRule: PaymentRuleType | 'none'
  field: Field<PaymentPolicyForm>
  allowChannelsWithoutCCHolds: Field<boolean>
}) {
  const { formatMessage } = useLocales()

  if (selectedRule === 'none') {
    return <></>
  }
  return (
    <VStack
      spacing="s"
      borderWidth="s"
      backgroundColor="secondaryBackground"
      borderLeftColor="dividerLines"
      borderRightColor="dividerLines"
      borderBottomColor="dividerLines"
      borderBottomRightRadius="s"
      borderBottomLeftRadius="s"
      p="m"
    >
      {selectedRule === 'advanced_payment' ? (
        <>
          <Text textStyle="body1Bold">{formatMessage(messages.newAccessRulePaymentDetails)}</Text>
          <ChargeAtBooking field={field} />
        </>
      ) : (
        <SaveForLater field={field} allowChannelsWithoutCCHolds={allowChannelsWithoutCCHolds} showDescription={false} />
      )}
    </VStack>
  )
}

import Carousel from 'nuka-carousel'
import { useState } from 'react'
import type { RevenueDashboard, RevenueSource } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { Flex, Box, HStack, Clickable } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { reportingMessages } from '../../locales'
import { formatCurrency, getRevenueTypeInfo } from '../../utils'
import { RevenueOpportunityCard } from '../RevenueOpportunityCard'

function NavigationArrow({ icon, disabled }: { icon: IconType; disabled: boolean }) {
  const color = disabled ? 'deactivated' : 'secondaryFont'
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="32px"
      height="32px"
      backgroundColor="primaryBackground"
      borderRadius="l"
      borderColor="deactivated"
      borderWidth="s"
      cursor="pointer"
    >
      <Icon color={color} name={icon} size="lg" />
    </Flex>
  )
}

export function RevenueOpportunities({ data }: { data: RevenueDashboard | undefined }) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const [slide, setSlide] = useState<number>(0)
  const slideCount = 6
  const updateSlide = (change: number) => {
    if (slide + change < 0) {
      setSlide(0)
    } else if (slide + change === slideCount) {
      setSlide(slideCount - 1)
    } else {
      setSlide(slide + change)
    }
  }

  const sortByPotential = (a: RevenueSource, b: RevenueSource) => (a.benchmark - a.revenue > b.benchmark - b.revenue ? -1 : 1)
  const sortByLock = (a: RevenueSource, b: RevenueSource) => {
    const lockA = getRevenueTypeInfo(a.type, venue, venueSettings).locked
    const lockB = getRevenueTypeInfo(b.type, venue, venueSettings).locked

    if (lockA === lockB) {
      return 0
    }
    return lockB ? -1 : 1
  }

  const filterByStatus = (r: RevenueSource) => !r.activated

  const cappedList = data ? data.revenueSources.filter(filterByStatus).sort(sortByPotential).slice(0, 5) : []
  const sortedOpps = cappedList.sort(sortByLock)

  return (
    <Box pl="xl" pr="xl" pt="xxl" backgroundColor="secondaryBackground">
      <HStack>
        <Box mr="auto">
          <Header type="h1" data-test="revenue-youve-earned">
            {formatMessage(reportingMessages.moneyOnTable, {
              amount: (
                <Text fontWeight="bold" fontSize="xxl" color="attentionNew">
                  {data ? formatCurrency(venue, data.summary.benchmarkMissed) : ''}
                </Text>
              ),
            })}
          </Header>
          <Box mt="s" mb="l">
            <SecondaryText>{formatMessage(reportingMessages.opportunityExplanation)}</SecondaryText>
          </Box>
        </Box>
        <HStack spacing="s">
          <Clickable data-test="opportunities-next-slide" onClick={() => updateSlide(-1)}>
            <NavigationArrow icon="VMSWeb-chevron-left" disabled={slide === 0} />
          </Clickable>
          <Clickable data-test="opportunities-previous-slide" onClick={() => updateSlide(1)}>
            <NavigationArrow icon="VMSWeb-chevron-right" disabled={slide === slideCount - 1} />
          </Clickable>
        </HStack>
      </HStack>
      <Box>
        <Carousel slideIndex={slide} slideWidth="530px" withoutControls>
          {sortedOpps.map(opportunity => (
            <RevenueOpportunityCard
              key={opportunity.type}
              revenueType={opportunity.type}
              missedBenchmark={opportunity.benchmark - opportunity.revenue}
            />
          ))}
        </Carousel>
      </Box>
    </Box>
  )
}

/* eslint import/no-extraneous-dependencies: 0, no-useless-escape: 0 */
/* globals widgetInit */
import camelcaseKeys from 'camelcase-keys'
import { Map } from 'immutable'
import _ from 'lodash'
import Theme from 'mgr/layout/Theme'
import { decodeQueryString } from '@sevenrooms/core/api'
import camelCaseObject from './camelCaseObject'

let queryObj = camelCaseObject(decodeQueryString())
if (Object.prototype.hasOwnProperty.call(queryObj, '')) {
  queryObj = {}
}

const venueInfo = _.assign({}, camelCaseObject(widgetInit.venueInfo), {
  venueUrlKey: window.location.pathname.split('/')[2],
})

const clientInfo = camelCaseObject(widgetInit.clientInfo)
const clientTagGroups = {}
const reservationTagGroups = {}
const tagGroups = _.mapValues(widgetInit.venueInfo.tag_groups, (tagGroup, tagGroupId) => {
  const formatTagGroup = camelCaseObject(_.omit(tagGroup, 'tag_name_displays'))
  formatTagGroup.tagNameDisplays = tagGroup.tag_name_displays
  formatTagGroup.tags = _.keyBy(formatTagGroup.tags, tagName => tagName)
  formatTagGroup.selectedTags = {}
  const camelCaseType = _.camelCase(formatTagGroup.type)
  if (formatTagGroup.domain === 'VenueGroupClient') {
    clientTagGroups[camelCaseType] = tagGroupId
  } else if (formatTagGroup.domain === 'ReservationActual') {
    reservationTagGroups[camelCaseType] = tagGroupId
  }
  return formatTagGroup
})
const tags = Map().merge({
  tagGroups,
  clientTagGroups,
  reservationTagGroups,
})

export const parseBoolean = val => {
  if (typeof val === 'boolean') {
    return val
  }
  return val === 'true'
}

const widgetSettings = _.assign({}, camelCaseObject(widgetInit.settings), {
  hideCalendar: parseBoolean(queryObj.hideCalendar),
  baseUrl: window.location.origin || `${window.location.protocol}//${window.location.host}`,
  isVenueGroupMarketingPolicyDefaultOn: parseBoolean(widgetInit.settings.isVenueGroupMarketingPolicyDefaultOn),
  isTailoredCommunicationPolicyDefaultOn: parseBoolean(widgetInit.settings.isTailoredCommunicationPolicyDefaultOn),
  isTailoredCommunicationPolicyDisabled: parseBoolean(widgetInit.settings.isTailoredCommunicationPolicyDisabled),
  isVenueSpecificMarketingPolicyDefaultOn: parseBoolean(widgetInit.settings.isVenueSpecificMarketingPolicyDefaultOn),
  isVenueSmsMarketingPolicyDefaultOn: parseBoolean(widgetInit.settings.isVenueSmsMarketingPolicyDefaultOn),
  enableSocialMediaLogin: parseBoolean(widgetInit.settings.enableSocialMediaLogin),
})

const widgetTheme = _.pick(widgetSettings, [
  'colorActionBarBackground',
  'colorBackground',
  'colorButton',
  'colorCellBackground',
  'colorCheckoutCellBackground',
  'colorError',
  'colorHover',
  'colorLines',
  'colorModalBackground',
  'colorPrimary',
  'colorSummaryBar',
  'colorWidgetBackground',
  'fontsColorActionBar',
  'fontsColorButton',
  'fontsColorCheckoutActive',
  'fontsColorCheckoutInactive',
  'fontsColorInactive',
  'fontsColorLinks',
  'fontsColorPrimary',
  'fontsColorSecondary',
  'fontsColorSummaryBar',
])

const theme = {
  ...widgetTheme,
  ...Theme,
}

const app = {}
app.env = widgetInit.app.env

const isMobile = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true
    }
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}
app.isMobile = isMobile()
app.language = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

const venueCountryCode = venueInfo.municipality.countryCode.length === 2 ? venueInfo.municipality.countryCode.toLowerCase() : 'us'

const formFields = Map({
  formErrors: Map({}),
  firstName: queryObj.firstName || '',
  firstNameFieldStatus: queryObj.firstName ? 'preset' : 'unedited',
  lastName: queryObj.lastName || '',
  lastNameFieldStatus: queryObj.lastName ? 'preset' : 'unedited',
  email: queryObj.email || '',
  emailFieldStatus: queryObj.email ? 'preset' : 'unedited',
  phoneNumber: queryObj.phoneNumber ? `+1${queryObj.phoneNumber}` : '',
  phoneNumberLocale: venueCountryCode,
  phoneNumberFieldStatus: queryObj.phoneNumber ? 'preset' : 'unedited',
  message: '',
  cardFirstName: '',
  cardFirstNameFieldStatus: 'unedited',
  cardLastName: '',
  cardLastNameFieldStatus: 'unedited',
  cardNum: '',
  cardNumFieldStatus: 'unedited',
  cardMonthExp: '',
  cardMonthExpFieldStatus: 'unedited',
  cardYearExp: '',
  cardYearExpFieldStatus: 'unedited',
  cardCvv: '',
  cardCvvFieldStatus: 'unedited',
  cardZipCode: '',
  cardZipCodeFieldStatus: 'unedited',
  checkoutStatus: 'awaiting',
  agreedToBookingPolicy: false,
  bookingPolicyStatus: 'unedited',
  agePolicyStatus: 'unedited',
  displayBookingPolicy: false,
  displaySpecialAttentionMessage: false,
  displayVenueGroupMarketingOptInButton: parseBoolean(widgetSettings.venueGroupMarketingOn),
  displayTailoredCommunicationOptInButton: parseBoolean(widgetSettings.tailoredCommunicationOn),
  displayVenueSpecificMarketingOptInButton: parseBoolean(widgetSettings.venueSpecificMarketingOn),
  displayVenueSmsMarketingOptInButton: parseBoolean(widgetSettings.venueSmsMarketingOn) && parseBoolean(venueInfo.isSmsMarketingEnabled),
  displayReservationSmsOptInButton: parseBoolean(widgetSettings.displayReservationSmsOptIn),
  displayVenueGroupMarketingOptInPolicy: false,
  displayTailoredCommunicationOptInPolicy: false,
  displayVenueSpecificMarketingOptInPolicy: false,
  displayVenueSmsMarketingOptInPolicy: false,
  displayReservationSmsOptInPolicy: false,
  agreedToVenueGroupMarketingOptIn: widgetSettings.isVenueGroupMarketingPolicyDefaultOn,
  agreedToTailoredCommunicationOptIn: widgetSettings.isTailoredCommunicationPolicyDefaultOn && widgetSettings.tailoredCommunicationOn,
  agreedToVenueSpecificMarketingOptIn: widgetSettings.isVenueSpecificMarketingPolicyDefaultOn,
  agreedToVenueSmsMarketingOptIn: parseBoolean(venueInfo.isSmsMarketingEnabled) && widgetSettings.isVenueSmsMarketingPolicyDefaultOn,
  agreedToReservationSmsOptIn:
    parseBoolean(widgetSettings.displayReservationSmsOptIn) && parseBoolean(widgetSettings.reservationSmsOptInOn),
  showSpecialAttentionMessage: parseBoolean(widgetSettings.showSpecialAttentionMessage),
  agreedToAboveAgeConsentOn: false,
  firstNameFocus: false,
  lastNameFocus: false,
  emailFocus: false,
  messageFocus: false,
  firstNameCCFocus: false,
  lastNameCCFocus: false,
  cardNumFocus: false,
  cardMonthFocus: false,
  cardYearFocus: false,
  cardCVVFocus: false,
  cardZipCodeFocus: false,
  time: '',
  timeFieldStatus: widgetSettings.enableFieldTime ? 'unedited' : 'valid',
  birthday: '',
  salutation: '',
  birthdayFieldStatus: widgetSettings.enableFieldBirthday ? 'unedited' : 'valid',
  birthdayType: widgetSettings.birthdayType,
  partySize: '',
  partySizeFieldStatus: widgetSettings.partySizeType === 'TOTAL' ? 'unedited' : 'valid',
  numMales: '',
  numMalesFieldStatus: widgetSettings.partySizeType === 'MALE_FEMALE' ? 'unedited' : 'valid',
  numFemales: '',
  numFemalesFieldStatus: widgetSettings.partySizeType === 'MALE_FEMALE' ? 'unedited' : 'valid',
  recaptcha: null,
  recaptchaOn: parseBoolean(widgetSettings.recaptchaOn),
  completeReCaptcha: true,
  reCaptchaFieldStatus: 'unedited',
  venueCountryCode,
  displayAboveAgeConsentPolicy: parseBoolean(widgetSettings.aboveAgeConsentOn),
  ageToConsent: widgetSettings.ageToConsent,
  textVenueGroupMarketingOptIn: widgetSettings.textVenueGroupMarketingOptIn,
  textVenueSpecificMarketingOptIn: widgetSettings.textVenueSpecificMarketingOptIn,
  textVenueSmsMarketingOptIn: widgetSettings.textVenueSmsMarketingOptIn,
  textReservationSmsOptIn: widgetSettings.textReservationSmsOptIn,
  textTailoredCommunicationOptInLabel: widgetSettings.textTailoredCommunicationOptInLabel,
  textTailoredCommunicationOptInHeader: widgetSettings.textTailoredCommunicationOptInHeader,
  textTailoredCommunicationOptInBody: widgetSettings.textTailoredCommunicationOptInBody,
  promoCode: queryObj.promoCode || '',
  isFocusedPromoCode: false,
  enablePromoCodes: parseBoolean(widgetSettings.enablePromoCodes),
  isVerifyingPromoCode: false,
  validPromoCode: null,
})

let stage = Map({ current: 0, active: 0, isGuest: false })
let userSelection = Map({
  date: null,
  eventId: null,
})

if (queryObj.eventId && queryObj.date) {
  stage = stage.merge({ current: 1, active: 1 })
  userSelection = userSelection.merge({
    date: queryObj.date,
    eventId: queryObj.eventId,
  })
}

if (widgetInit.successBypass || widgetInit.errorDisplay) {
  stage = stage.merge({ current: 3 })
}

const { actualInfo } = widgetInit

const venueLanguages = JSON.parse(venueInfo.venueLanguagesJson)
const languageStrings = JSON.parse(venueInfo.languageStringsJson)
const selectedLanguage = venueInfo.selectedLanguageCode
const languages = {
  venueLanguages,
  selectedLanguage,
  languageStrings,
  selectedLanguageStrings: camelcaseKeys(languageStrings[selectedLanguage] || {}),
  tagLanguageStrings: JSON.parse(venueInfo.tagLanguageStringsJson || '{}'),
}

export default {
  queryObj,
  venueInfo,
  actualInfo,
  clientInfo,
  formFields,
  entities: { tags },
  app,
  widgetSettings,
  theme,
  stage,
  userSelection,
  languages,
}

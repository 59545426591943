import { useEffect } from 'react'
import { type Field, useWatch } from '@sevenrooms/core/form'
import type { AccessRuleForm } from '../AccessRule.zod'

export function useGeneratedInternalDisplayColor(field: Field<AccessRuleForm>) {
  const internalDisplayColor = useWatch(field.prop('internalDisplayColor'))
  useEffect(() => {
    if (!internalDisplayColor) {
      const color = assignInternalDisplayColor()
      field.prop('internalDisplayColor').set(color)
    }
  }, [field, internalDisplayColor])
}

export function assignInternalDisplayColor(): string {
  // Randomly assign color as placeholder, need to find current place of AR in calendar and pick an appropriate color
  const bucket = COLOR_PALETTE[Math.floor(Math.random() * COLOR_PALETTE.length)] as string[]
  return bucket[Math.floor(Math.random() * bucket.length)] as string
}

const COLOR_PALETTE = [
  // Blue
  ['#a9e2fe', '#e5f6ff', '#c8edff', '#9ed3ed', '#7ca6ba', '#6a8ea0', '#608090', '#d7f2ff', '#b8e8ff', '#a4dbf6', '#84b0c6'],
  // Purple
  ['#e9d2f4', '#faf0ff', '#f5e0ff', '#dac4e3', '#ab9ab3', '#938499', '#84778a', '#f8e8ff', '#f0d9fb', '#e2cbec', '#b5a4be'],
  // Pink
  ['#fbcedd', '#fff0f5', '#ffdfea', '#ebc0ce', '#b897a2', '#9e828b', '#8f757d', '#ffe8ef', '#ffd6e3', '#f3c7d6', '#c4a0ac'],
  // Orange
  ['#ffd1ad', '#fff1e6', '#ffe2cb', '#f7c196', '#c29776', '#a68265', '#96755b', '#ffead9', '#ffdabd', '#ffc89d', '#cea17d'],
  // Yellow
  ['#d9ddb9', '#f2f7cf', '#e6ebc5', '#cacead', '#9fa288', '#888b75', '#7b7d69', '#ecf1ca', '#dfe4bf', '#d2d6b3', '#a9ac90'],
  // Green
  ['#a8e8bf', '#ceffdf', '#b2f7ca', '#9dd9b2', '#7baa8c', '#699278', '#5f846c', '#b7fdd0', '#adf0c4', '#a2e1b8', '#83b594'],
  // Red
  ['#ffcece', '#fff0f0', '#ffe0e0', '#f2bfbf', '#be9696', '#a38181', '#937474', '#ffe8e8', '#ffd7d7', '#fac6c6', '#c9a0a0'],
]

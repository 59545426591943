import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { PartySizeLocales } from '../PartySize.locales'
import { PartySizeTestId } from '../PartySize.testIds'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function PartySizeReport({ accessRule, shortenIfEqual, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()

  const { min, max } = accessRule.partySize
  const partySize = (min: number, max: number): string => {
    if (min === max && shortenIfEqual) {
      return `${min}`
    }
    return `${min} - ${max}`
  }

  return (
    <ReportPart caption={formatMessage(PartySizeLocales.title)} data-test={PartySizeTestId.report} position={position}>
      <ReportLine name={formatMessage(PartySizeLocales.title)}>
        <Text>{min && max ? partySize(min, max) : formatMessage(accessRulesMessages.followShift)}</Text>
      </ReportLine>
    </ReportPart>
  )
}

import { useMemo, useState } from 'react'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import type { CountryCode } from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { z, type ZodSchema, useForm } from '@sevenrooms/core/form'
import { useLocales, getCountryData } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import {
  Checkbox,
  Form,
  FormTimeRangePicker,
  Label,
  type TimeRangePickerForm,
  useTimeRangePickerForm,
  Button,
} from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  VStack,
  HStack,
  Box,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Grid,
  DividerLine,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { EmailAddressForm } from '../../../components/EmailAddressForm/EmailAddressForm'
import { FirstNameForm } from '../../../components/FirstNameForm/FirstNameForm'
import { LastNameForm } from '../../../components/LastNameForm/LastNameForm'
import { PhoneNumberForm } from '../../../components/PhoneNumberForm/PhoneNumberForm'
import { useCreateRequestMutation } from '../../../domain/request'
import { useVenue, useVenueGroup, useWidgetSettings } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useModals, useReservationFormState } from '../../../store'
import { priorityAlertSubmitted } from '../../../utils'

export type CreatePriorityAlertForm = ZodSchema<typeof useCreatePriorityAlertFormSchema>
export const useCreatePriorityAlertFormSchema = () => {
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useVenue()
  const timeRange = useTimeRangePickerForm({ startOfDayTime, nullable: false, allowSameTime: true })
  const requiredErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldRequired)
  const invalidErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldInvalid)

  return useMemo(
    () =>
      z
        .object({
          emailAddress: z.string().min(1, { message: requiredErrorMessage }).email({ message: invalidErrorMessage }),
          firstName: z.string().min(1, { message: requiredErrorMessage }),
          lastName: z.string().min(1, { message: requiredErrorMessage }),
          phoneNumber: z.string().min(1, { message: requiredErrorMessage }),
          agreeToTextMessages: z.boolean(),
          phoneCountryCode: z.custom<CountryCode>(),
          phoneDialCode: z.string().min(1),
          timeRange,
        })
        .superRefine((val, ctx) => {
          if (!isPossiblePhoneNumber(val.phoneNumber)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: invalidErrorMessage,
              path: ['phoneNumber'],
            })
          }
        }),
    [invalidErrorMessage, requiredErrorMessage, timeRange]
  )
}

export function CreatePriorityAlert({ onClose, validRequestTimes }: { onClose: () => void; validRequestTimes: string[] }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const [createRequest, { isLoading }] = useCreateRequestMutation({
    fixedCacheKey: 'reservation-create-request',
  })
  const { id: venueId, urlKey } = useVenue()
  const { lockedFields } = useVenueGroup()

  const { formState, updateFormState } = useReservationFormState()
  const { phoneCountryCode, firstName, lastName, emailAddress, phoneNumber, startDate: startDateState, partySize, startTime } = formState
  const { showModal, showErrorModal } = useModals()

  const phoneDialCode = getCountryData(phoneCountryCode)?.dialCode ?? '1'
  const priorityAlertFormSchema = useCreatePriorityAlertFormSchema()

  const widgetSettings = useWidgetSettings()

  const timeRange: TimeRangePickerForm =
    startTime === ReservationWidget.AllTimesOption
      ? {
          startTime: null,
          endTime: null,
        }
      : {
          startTime: validRequestTimes[0] || null,
          endTime: validRequestTimes[validRequestTimes.length - 1] || null,
        }

  const form = useForm(priorityAlertFormSchema, {
    mode: 'onSubmit',
    defaultValues: {
      agreeToTextMessages: widgetSettings.reservationSmsOptInOn,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      phoneCountryCode,
      phoneDialCode,
      timeRange,
    },
  })
  const { field, getValues, setError } = form
  const disabled = isSubmitting || isLoading

  const saveOnClose = () => {
    updateFormState({ ...formState, ...getValues() })
    onClose()
  }

  const onSubmit = async (data: CreatePriorityAlertForm) => {
    try {
      setIsSubmitting(true)
      updateFormState({ ...formState, ...data })
      const timeRange = data.timeRange as { startTime: string; endTime: string }
      await createRequest({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.emailAddress,
        phoneNumber: data.phoneNumber,
        countryCode: data.phoneCountryCode,
        phoneDialCode: data.phoneDialCode,
        preferredLanguageCode: formState.preferredLanguage,
        date: startDateState,
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
        partySize,
        requestClass: 'table',
        requestSmsOptIn: data.agreeToTextMessages,
        venueId,
        // eslint-disable-next-line no-warning-comments
        // TODO GX-2914
        channel: 'SEVENROOMS_WIDGET',
        trackingSlug: formState.trackingSlug,
        clientId: undefined,
        experienceId: undefined,
        referralId: undefined,
      }).unwrap()
      showModal('createPriorityAlertSuccess')
      priorityAlertSubmitted(urlKey, startDateState, partySize, timeRange.startTime, timeRange.endTime)
    } catch (error) {
      if (typeof error === 'string') {
        setError('timeRange', {
          message: formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsAlreadyExistsError),
        })
      } else {
        showErrorModal()
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleInvalid = (data: unknown) => {
    // Remove before mvp, useful for debugging for now.
    // eslint-disable-next-line no-console
    console.log(data)
  }

  const startDate = DateOnly.fromSafe(startDateState)?.formatNYearFMonthNDayFWeek()
  return (
    <Form onSubmit={onSubmit} onInvalid={handleInvalid} {...form}>
      <Modal
        data-test="sr-create-priority-alert-modal"
        width="100%"
        ariaLabel={formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsSetAlertTitle)}
      >
        <ModalHeader onClose={saveOnClose}>
          <VStack spacing="s">
            <ModalTitle
              data-test="sr-create-priority-alert-modal-title"
              title={formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsDetailsHeader)}
            />
            <Text>{formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsHeader)}</Text>
          </VStack>
        </ModalHeader>
        <ModalBody>
          <VStack mb="m" mt="m" spacing="m">
            <HStack spacing="s" alignItems="center">
              <Icon name="GX-calendar-range" size="lg" />
              <Text data-test="sr-create-priority-alert-modal-date">{startDate}</Text>
            </HStack>
            <HStack spacing="s" alignItems="center">
              <Icon name="GX-account-multiple" size="lg" />
              <Text data-test="sr-create-priority-alert-modal-party-size">
                {`${partySize} ${
                  partySize === 1
                    ? formatMessage(reservationWidgetMessages.commonGuestLabel)
                    : formatMessage(reservationWidgetMessages.commonGuestsLabel)
                }`}
              </Text>
            </HStack>
          </VStack>
          <VStack spacing="m">
            <Label primary={`${formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsReservationTimeBetween)}*`}>
              <FormTimeRangePicker
                data-test="sr-create-priority-alert-modal-time-range"
                field={field.prop('timeRange')}
                disabled={disabled}
                timeSlots={validRequestTimes}
                selectProps={{ withNativeSupport: true, zIndexLayer: 'modal' }}
              />
            </Label>
            <DividerLine ml="none" mr="none" />
            <Grid
              gridAutoColumns="auto auto"
              gridTemplateColumns={`repeat(${isMobile ? 1 : 2}, minmax(0, 1fr))`}
              gap="m"
              alignItems="start"
            >
              <FirstNameForm firstNameField={field.prop('firstName')} disabled={lockedFields?.firstName || disabled} />
              <LastNameForm lastNameField={field.prop('lastName')} disabled={lockedFields?.lastName || disabled} />
              <EmailAddressForm emailAddressField={field.prop('emailAddress')} disabled={lockedFields?.email || disabled} />
              <PhoneNumberForm
                phoneNumberField={field.prop('phoneNumber')}
                disabled={lockedFields?.phoneNumber ?? disabled}
                phoneCountryCodeField={field.prop('phoneCountryCode')}
                phoneDialCodeField={field.prop('phoneDialCode')}
              />
            </Grid>
            {widgetSettings.displayReservationSmsOptIn && (
              <Checkbox
                data-test-id="sr-create-priority-alert-modal-sms-agreement"
                field={field.prop('agreeToTextMessages')}
                disabled={disabled}
              >
                {formatMessage(reservationWidgetMessages.policyUsTwilioSmsOptIn)}
              </Checkbox>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            data-test="alert-me-button"
            disabled={disabled}
            data-test-id="sr-create-priority-alert-modal-set-alert"
            type="submit"
            fullWidth
            size="l"
          >
            <Icon name="GX-bell-ring" size="lg" />
            <Box ml="xs">{formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsSetAlertButton)}</Box>
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  )
}

import {
  type AccessRule,
  type AudienceHierarchy,
  type ExperiencesData,
  type SeatingAreasTablesNew,
  type Shift,
  type Pacing,
  type Upsells,
  type AutomaticUpsells,
  type AccessRuleAudienceTier,
  type UpsellsInventory,
  UpsellCategoryMinQuantityTypeEnum,
  type ShiftCategory,
} from '@sevenrooms/core/domain'
import type { FormatMessage } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { notNullish } from '@sevenrooms/core/ui-kit/form'
import { accessRulesListCategoriesMessages } from '../locales'
import type { AccessListRowDuration, AccessListRowOffer, AccessListRowSelectableUpgrades } from '../views/AccessRulesList/rowInterfaces'

export function getCategoryName(category: string | undefined, formatFn: FormatMessage): string {
  const categoryString = (category ?? 'NONE').toLowerCase()
  return formatFn(accessRulesListCategoriesMessages[`${categoryString}Category` as keyof typeof accessRulesListCategoriesMessages])
}

export function getShiftCategoryName(category: ShiftCategory | undefined, formatFn: FormatMessage): string {
  return getCategoryName(category, formatFn)
}

export function getSeatingAreasMap(seatingAreas: SeatingAreasTablesNew) {
  return seatingAreas.venueSeatingAreas.reduce<Record<string, string>>((acc, area) => ({ ...acc, [area.id]: area.name }), {})
}

export function getTablesMap(seatingAreas: SeatingAreasTablesNew) {
  return seatingAreas.allTableInventoryItems.reduce<Record<string, string>>((acc, table) => ({ ...acc, [table.id]: table.itemCode }), {})
}

export function getAudienceMap(audienceHierarchy: AudienceHierarchy[]) {
  const flattenHierarchy = (items: AudienceHierarchy[]): { value: string; name: string }[] =>
    items.flatMap(item => [{ value: item.value, name: item.name }, ...(item.children?.length ? flattenHierarchy(item.children) : [])])

  return Object.fromEntries(flattenHierarchy(audienceHierarchy).map(({ value, name }) => [value, name]))
}

export function getExperiencesMap(experiences: ExperiencesData) {
  return experiences.results.reduce(
    (acc, experience) => ({
      ...acc,
      [experience.id]: {
        name: experience.name,
        isActive: experience.isActive,
      },
    }),
    {} as Record<string, AccessListRowOffer>
  )
}

interface CategoryMapping {
  isRequired: boolean
  inventoryIds: string[]
}
export interface UpsellsMap {
  categories: Record<string, CategoryMapping>
  inventory: Record<string, UpsellsInventory>
}

export function getUpsellsMap(upsells: Upsells): UpsellsMap {
  const result: UpsellsMap = {
    categories: {} as Record<string, CategoryMapping>,
    inventory: {} as Record<string, UpsellsInventory>,
  }

  for (const item of upsells.inventory) {
    result.inventory[item.id] = item

    const category = upsells.categories?.[item.categoryId]

    if (!result.categories[item.categoryId]) {
      if (category) {
        const isRequired = category.minQuantityType !== UpsellCategoryMinQuantityTypeEnum.NONE_REQUIRED
        result.categories[item.categoryId] = { isRequired, inventoryIds: [] }
      } else {
        result.categories[item.categoryId] = { isRequired: false, inventoryIds: [] }
      }
    }

    result.categories[item.categoryId]?.inventoryIds.push(item.id)
  }

  return result
}

export function getAccessRulesMap(accessRules: AccessRule[]) {
  return accessRules.reduce<Record<string, AccessRule>>((acc, rule) => ({ ...acc, [rule.id]: rule }), {})
}

export function transformAudienceTiersForRow(audienceTiers: AccessRuleAudienceTier[], audienceMap: Record<string, string>) {
  return audienceTiers.reduce(
    (acc, tier, index) => ({
      ...acc,
      [`Tier ${index + 1}`]: [
        ...tier.channels.map(channel => audienceMap[channel]).filter(notNullish),
        ...tier.thirdParties.map(thirdParty => audienceMap[thirdParty]).filter(notNullish),
        ...tier.concierges.map(concierge => audienceMap[concierge]).filter(notNullish),
      ],
    }),
    {} as Record<string, string[]>
  )
}

export function transformDurationsForRow(obj: AccessRule | Shift): AccessListRowDuration {
  const combineConsecutiveDurations = (partyDurations: Record<string, number>): Record<string, number> => {
    const combined: Record<string, number> = {}

    // Replace "-1" with "10+" and sort keys numerically
    const partySizes = Object.keys(partyDurations)
      .filter(size => size !== '0') // filter out 0 party size, no idea why we have this tbh
      .map(size => (size === '-1' ? '10+' : size)) // Transform -1 to 10+
      .sort((a, b) => {
        // Custom sort for numeric values and '10+' at the end
        const numA = a === '10+' ? 11 : parseInt(a)
        const numB = b === '10+' ? 11 : parseInt(b)
        return numA - numB
      })

    if (partySizes.length === 0) {
      return combined
    }

    let rangeStart = partySizes[0] as string
    let prevDuration = partyDurations[rangeStart === '10+' ? '-1' : rangeStart] as number // Handle 10+ case

    for (let i = 1; i <= partySizes.length; i += 1) {
      const currentSize = partySizes[i] as string
      const currentDuration = partyDurations[currentSize === '10+' ? '-1' : currentSize] as number

      if (currentDuration !== prevDuration || i === partySizes.length) {
        const rangeEnd = partySizes[i - 1]
        const rangeKey = rangeStart === rangeEnd ? rangeStart : `${rangeStart}-${rangeEnd}`
        combined[rangeKey] = prevDuration

        if (currentSize !== undefined) {
          rangeStart = currentSize
          prevDuration = currentDuration
        }
      }
    }

    return combined
  }

  if (obj.durationMinutesByPartySize) {
    if ('durationDictRanges' in obj && Object.keys(obj.durationDictRanges ?? {}).length > 1) {
      return Object.entries(obj.durationDictRanges ?? {}).reduce((newAcc, [time, partySizeDurations]) => {
        const combinedDurations = combineConsecutiveDurations(partySizeDurations)
        return {
          ...newAcc,
          [time]: combinedDurations,
        }
      }, {} as Record<string, Record<string, number>>)
    }

    // Process the "default" key
    const combinedDefault = combineConsecutiveDurations(obj.durationMinutesByPartySize)
    return {
      default: combinedDefault,
    }
  }
  return {}
}

export function transformCustomPacingForRow(pacing: Pacing) {
  return Object.entries(pacing).map(([mins, pace]) => {
    const totalMinutes = parseInt(mins, 10)
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    const timeOnly = TimeOnly.from({ hours, minutes })
    const timeString = timeOnly.formatSTime()

    return `${timeString} - ${pace}`
  })
}

export function transformAutomaticUpsellsForRow(automaticUpsells: AutomaticUpsells[] | undefined, upsellsMap: UpsellsMap): string[] {
  if (!automaticUpsells || automaticUpsells.length === 0) {
    return []
  }

  return automaticUpsells.flatMap(upsell =>
    upsell.ids.map(id => upsellsMap.inventory[id]?.name).filter((name): name is string => name !== undefined)
  )
}

export function transformSelectableUpsellsForRow(upsellCategories: string[], upsellsMap: UpsellsMap): AccessListRowSelectableUpgrades {
  const result: AccessListRowSelectableUpgrades = { required: [], optional: [] }

  upsellCategories.forEach(categoryId => {
    const categoryMapping = upsellsMap.categories[categoryId]
    if (!categoryMapping) {
      return
    }

    const upsells = categoryMapping.inventoryIds
      .map(inventoryId => upsellsMap.inventory[inventoryId]?.name)
      .filter((name): name is string => name !== undefined)

    if (categoryMapping.isRequired) {
      result.required.push(...upsells)
    } else {
      result.optional.push(...upsells)
    }
  })

  return result
}

export function transformBookStartTimesForRow(audienceTiers: AccessRuleAudienceTier[]): Record<string, string> {
  const result: Record<string, string> = {}
  audienceTiers.forEach((tier, index) => {
    result[`Tier ${index + 1}`] = `${tier.startNum} ${tier.startType.toLowerCase()}`
  })
  return result
}

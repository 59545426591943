import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, type ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignBuilderMessages, emailBuilderMessages } from '@sevenrooms/marketing'

interface OperationalEmailModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirmationClick: () => void
}

export function OperationalEmailModal({ closeHref, onConfirmationClick }: OperationalEmailModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle title={formatMessage(emailBuilderMessages.operationalEmailModalTitle)} />
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(emailBuilderMessages.operationalEmailModalBody)}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="operational-email-cancel">
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button variant="primary" href={closeHref} onClick={onConfirmationClick} data-test="operational-email-confirm">
            {formatMessage(emailBuilderMessages.operationalEmailModalConfirm)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}

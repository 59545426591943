import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { PreviewImage, ReportLine, ReportPart, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAccessRuleContext } from '../../shared'
import { GuestFacingLocales } from '../GuestFacing.locales'
import { GuestFacingTestId } from '../GuestFacing.testIds'
import { useUpgradesSummary } from './useUpgradesSummary'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function GuestFacingReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { experiences } = useAccessRuleContext()
  const upgradesSummary = useUpgradesSummary(accessRule.paymentPolicy.bundledUpgrades)

  const { timeslotDescription, title, description, image, offer, minSpend, allowUnsupported } = accessRule.guestFacing
  const noneMessage = formatMessage(commonMessages.none)

  return (
    <ReportPart caption={formatMessage(GuestFacingLocales.title)} data-test={GuestFacingTestId.report} position={position}>
      <ReportLine name={formatMessage(GuestFacingLocales.widgetDescriptionLabel)}>
        <Text>{timeslotDescription || noneMessage}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.titleLabel)}>
        <Text>{title || noneMessage}</Text>
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.descriptionLabel)}>
        {description ? <HTMLContent content={description} data-test={GuestFacingTestId.htmlDescription} /> : <Text>{noneMessage}</Text>}
      </ReportLine>
      <ReportLine name={formatMessage(GuestFacingLocales.photoLabel)}>
        {image ? <PreviewImage maxHeight="100px" alt={image.name} src={image.preview ?? image.rawUrl} /> : <Text>{noneMessage}</Text>}
      </ReportLine>
      {upgradesSummary && (
        <ReportLine name={formatMessage(GuestFacingLocales.includesLabel)}>
          <VStack spacing="s">
            {upgradesSummary.split('\n\n').map(group => (
              <VStack key={group}>
                {group.split('\n').map(line => (
                  <Text key={line}>{line}</Text>
                ))}
              </VStack>
            ))}
          </VStack>
        </ReportLine>
      )}
      {offer && (
        <ReportLine name={formatMessage(GuestFacingLocales.offerLabel)}>
          <Text>{experiences.filter(({ id }) => id === offer).map(({ name, status }) => `${name} (${status})`)[0]}</Text>
        </ReportLine>
      )}
      {minSpend && (
        <ReportLine name={formatMessage(GuestFacingLocales.minSpendLabel)}>
          <Text>{minSpend}</Text>
        </ReportLine>
      )}
      {allowUnsupported && (
        <ReportLine name={formatMessage(GuestFacingLocales.bookOnUnsupportedLabel)}>
          <Text>{formatMessage(commonMessages.yes)}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}

import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import RefundForm from 'mgr/actualslideout/components/payment/RefundForm'
import { LightButton, DeleteButton, DetailList, DetailItem, PaylinkUrl } from 'mgr/actualslideout/components/payment/ViewComponents'
import { PulsatingDotsRed } from 'mgr/layout/StyledComponentUtils'
import { TransactionTypes, TransactionStatus } from 'mgr/lib/utils/Constants'
import { sumPx } from 'mgr/lib/utils/Styles'
import { getVenueLocalTime } from 'svr/common/TimeUtil'
import { VmsIcons, StyledVmsIconS, VmsIconSizes } from 'svr/common/VmsIcons'
import { AccountTypes } from 'svr/lib/Payments/Constants'

const DateDisplay = styled.div`
  font-size: 12px;
  color: #9a9b9c;
`

const ShowCard = styled.div`
  min-height: 18px;
  max-width: 280px;
  margin: ${props => props.theme.gutter.standard} 0;
  color: #565c64;
  text-transform: ${props => (props.brand === 'iDEAL' ? '' : 'capitalize')};
  line-height: 1.4em;
`

const PriceDisplay = styled.div`
  position: absolute;
  right: 0px;
  top: 24px;
  color: #092238;
`

const PaymentContent = styled.div`
  padding-bottom: ${props => props.theme.gutter.standard};
  border-bottom: 1px solid #e8e9e9;
  margin-left: ${props => sumPx(VmsIconSizes.xsmall.fontSize, props.theme.gutter.double)};
`

const ToggleArrow = styled.div`
  cursor: pointer;
  left: -${props => props.theme.gutter.half};
  position: absolute;
  top: 23px;
  transform: rotate(90deg);
`

const PaymentLine = styled.div`
  font-size: 15px;
  margin-bottom: ${props => props.theme.gutter.double};
  position: relative;
  &:last-child {
    margin-bottom: 0;

    .payment-content {
      border: none;
      padding-bottom: 0;
    }
  }
`

const ChargeDescription = styled.div`
  margin-bottom: ${props => props.theme.gutter.standard};
  color: #565c64;
  font-weight: 200;
`

const ExpandedArrow = styled(ToggleArrow)`
  left: -${props => props.theme.gutter.half};
  transform: rotate(-90deg);
`

function Toggle({ togglePaymentDetail, id, expanded }) {
  const Arrow = expanded ? ExpandedArrow : ToggleArrow
  return (
    <Arrow data-test="sr-transaction-toggle" onClick={() => togglePaymentDetail(id)}>
      <StyledVmsIconS>{VmsIcons.Chevron}</StyledVmsIconS>
    </Arrow>
  )
}

function PaymentBreakdown({ transaction }) {
  const tax = Number.isNaN(transaction.tax) ? 0 : parseFloat(transaction.tax).toFixed(2)
  const gratuity = Number.isNaN(transaction.gratuity) ? 0 : parseFloat(transaction.gratuity).toFixed(2)

  return (
    <DetailList>
      <DetailItem label="Pre-payment" amount={transaction.base_amount_formatted} />
      {transaction.service_charge ? (
        <DetailItem label={`Service Charge (${transaction.service_charge}%)`} amount={transaction.service_charge_formatted} />
      ) : null}
      {transaction.tax_decimal ? <DetailItem label={`Tax (${tax}%)`} amount={transaction.tax_formatted} /> : null}
      {transaction.gratuity ? <DetailItem label={`Tip (${gratuity}%)`} amount={transaction.gratuity_formatted} /> : null}
      {transaction.additional_fee ? <DetailItem label="Fee" amount={transaction.additional_fee_formatted} /> : null}
      {transaction.additional_fee_tax ? <DetailItem label="Fee tax" amount={transaction.additional_fee_tax_formatted} /> : null}
    </DetailList>
  )
}

function PaymentDisplay({
  transaction,
  expanded,
  refundData,
  selectedClient,
  currencySymbol,
  actions,
  onSubmitRefundHandler,
  formErrors,
  validateFieldMap,
  canCharge,
  canRefund,
  order,
  payoutProfileAccountId,
}) {
  let mainTransactionText
  let { notes } = transaction
  if (transaction.brand && transaction.last_4) {
    mainTransactionText = `${transaction.brand} ...${transaction.last_4}`
    notes = notes ? `${notes} ${getPromoDiscountLabel(transaction)}` : getPromoDiscountLabel(transaction)
  } else if (transaction.brand) {
    mainTransactionText = `${transaction.brand}`
    notes = notes ? `${notes} ${getPromoDiscountLabel(transaction)}` : getPromoDiscountLabel(transaction)
  } else if (transaction.promo_discount_amount && !transaction.amount) {
    mainTransactionText = getPromoDiscountLabel(transaction)
  }
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">{mainTransactionText}</ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay>
      {notes ? <ChargeDescription data-test="sr-transaction-notes">{notes}</ChargeDescription> : null}
      {expanded ? <PaymentBreakdown transaction={transaction} /> : null}
      <LightButton
        data-test="sr-button-send_notification"
        onClick={() => {
          actions.toggleNotificationModal(transaction, 'charge')
        }}
        style={{ marginRight: '3%' }}
      >
        Send Notification
      </LightButton>
      {transaction.amount_remaining && canRefund ? (
        <LightButton
          data-test="sr-button-refund"
          onClick={() => {
            if (transaction.payout_profile_system === AccountTypes.STRIPE) {
              if (transaction.payout_profile_account_id && transaction.payout_profile_account_id !== payoutProfileAccountId) {
                actions.showRefundFromWrongAccountNotification()
                return false
              }
            }
            actions.setRefundId(transaction)
            return true
          }}
        >
          Refund
        </LightButton>
      ) : null}
      {refundData.refundingId === transaction.id ? (
        <RefundForm
          refundData={refundData}
          actions={actions}
          selectedClient={selectedClient}
          formErrors={formErrors}
          transaction={transaction}
          validateFieldMap={validateFieldMap}
          currencySymbol={currencySymbol}
          onSubmitRefundHandler={onSubmitRefundHandler}
        />
      ) : null}
    </div>
  )
}

function PaymentFailureDisplay({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">
        Failed {transaction.transaction_type && transaction.transaction_type === TransactionTypes.AUTO_INTERNAL && 'auto '}charge
      </ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay>
      {transaction.notes ? <ChargeDescription data-test="sr-transaction-notes">{transaction.notes}</ChargeDescription> : null}
    </div>
  )
}

function RefundDisplay({ transaction, actions }) {
  const getLabel = () => {
    if (transaction.transaction_type === TransactionTypes.REQUEST_REFUND) {
      return 'refund requested'
    }

    if (!_.isEmpty(transaction.redemption_raw_json_data)) {
      return 'refund recorded'
    }

    return 'refunded'
  }

  const label = getLabel()
  const brand = transaction.brand || ''
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name" brand={brand}>
        {!_.isEmpty(transaction.last_4) ? `${brand} ...${transaction.last_4} ${label}` : [brand, label].join(' ')}
      </ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">({transaction.amount_formatted})</PriceDisplay>
      {transaction.notes ? <ChargeDescription data-test="sr-transaction-notes">{transaction.notes}</ChargeDescription> : null}
      <LightButton
        onClick={() => {
          actions.toggleNotificationModal(transaction, 'refund')
        }}
      >
        Send Notification
      </LightButton>
    </div>
  )
}

function RefundFailureDisplay({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">Failed refund</ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">({transaction.amount_formatted})</PriceDisplay>
    </div>
  )
}

function SavedDisplay({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name" brand={transaction.brand}>
        {`${transaction.brand} ...${transaction.last_4}`}
      </ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">Card Saved</PriceDisplay>
    </div>
  )
}

function RemovedDisplay({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name" brand={transaction.brand}>{`${transaction.brand} ...${transaction.last_4}`}</ShowCard>
      <PriceDisplay data-test="sr-label-payment_amount">Card Deleted</PriceDisplay>
    </div>
  )
}

function RequestFulfilled({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">{transaction.amount ? 'Payment' : 'Card'} request fulfilled</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
      {transaction.notes ? <ChargeDescription data-test="sr-transaction-notes">{transaction.notes}</ChargeDescription> : null}
    </div>
  )
}

function ThreeDSecureStarted({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">3D secure Started</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
    </div>
  )
}
function ThreeDSecureAddCardStarted({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">3D secure add card Started</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
    </div>
  )
}

function ThreeDSecureAddCardFailed({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">3D secure add card failed</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
    </div>
  )
}

function PaymentStarted({ transaction }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">Payment Started</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
    </div>
  )
}

function PaylinkDisplay({ transaction, expanded, urlKey, deletingRequest, actions, venueTimezone, venueLocale }) {
  // eslint-disable-next-line no-restricted-globals
  const link = `${location.origin}/paylink/${urlKey}/${transaction.ndb_id}`
  let requestTitle = `${transaction.amount ? 'Payment' : 'Card'} Requested`

  const paylinkAutoCancel = getVenueLocalTime(venueTimezone, transaction.paylink_auto_cancel_datetime)
  const isAutocancelPaylink = transaction.transaction_type === TransactionTypes.REQUEST && transaction.paylink_auto_cancel_datetime
  const isOutstandingPaylink = isAutocancelPaylink && getVenueLocalTime(venueTimezone).isBefore(paylinkAutoCancel)
  if (isAutocancelPaylink) {
    const isGB = venueLocale === 'en_GB'
    const expiredLine = isOutstandingPaylink
      ? `Expires ${paylinkAutoCancel.format(isGB ? 'k:mm' : 'h:mma')} on ${paylinkAutoCancel.format(isGB ? 'D MMMM' : 'MMMM D')}`
      : 'Expired'
    requestTitle = (
      <>
        {requestTitle}
        <br />
        {expiredLine}
      </>
    )
  }

  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">{requestTitle}</ShowCard>
      {transaction.amount ? <PriceDisplay data-test="sr-label-payment_amount">{transaction.amount_formatted}</PriceDisplay> : null}
      {transaction.notes ? <ChargeDescription data-test="sr-transaction-notes">{transaction.notes}</ChargeDescription> : null}
      {expanded ? <PaylinkUrl data-test="sr-transaction-paylink-url">{link}</PaylinkUrl> : null}
      <DeleteButton
        data-test="sr-button-delete"
        onClick={() => {
          if (isOutstandingPaylink) {
            actions.toggleRemovePaylinkModal(transaction.id)
          } else {
            actions.deleteRequest(transaction.id)
          }
        }}
      >
        {deletingRequest === transaction.id ? <PulsatingDotsRed /> : 'Delete'}
      </DeleteButton>
      <LightButton
        data-test="sr-button-copy_link"
        style={{ width: '37%', marginLeft: '3%' }}
        onClick={() => {
          actions.copyLink(link)
        }}
      >
        Copy Link
      </LightButton>
      <LightButton
        style={{ width: '37%', marginLeft: '3%' }}
        onClick={() => {
          actions.toggleNotificationModal(transaction, 'paylink')
        }}
      >
        Resend
      </LightButton>
    </div>
  )
}

function Simple({ simpleNote }) {
  return (
    <div>
      <ShowCard data-test="sr-label-payment_name">{simpleNote}</ShowCard>
    </div>
  )
}

function getPromoDiscountLabel(transaction) {
  if (transaction.promo_discount_amount) {
    return `Promo discount: ${transaction.promo_discount_amount_formatted}`
  }
  return ''
}

function Transaction({
  actual,
  transaction,
  refundData,
  selectedClient,
  expandedRows,
  currencySymbol,
  urlKey,
  deletingRequest,
  actions,
  formErrors,
  validateFieldMap,
  onSubmitRefundHandler,
  onSubmitNotificationHandler,
  canCharge,
  canRefund,
  order,
  payoutProfileAccountId,
  venueTimezone,
  venueLocale,
}) {
  const expanded = expandedRows.indexOf(transaction.id) > -1
  let needsToggle =
    [TransactionTypes.REQUEST, TransactionTypes.INTERNAL, TransactionTypes.WEB_ORDER].indexOf(transaction.transaction_type) !== -1
  let simpleNote = ''
  let Vessel
  switch (transaction.transaction_type) {
    case TransactionTypes.REQUEST:
      Vessel = PaylinkDisplay
      break
    case TransactionTypes.INTERNAL:
    case TransactionTypes.WEB:
    case TransactionTypes.WEB_ORDER:
    case TransactionTypes.AUTO_INTERNAL:
    case TransactionTypes.THREE_D_SECURE_FAILED:
      if (transaction.status === TransactionStatus.FAILED) {
        Vessel = PaymentFailureDisplay
        needsToggle = false
      } else if (transaction.status === TransactionTypes.PAYMENT_STARTED) {
        Vessel = PaymentStarted
      } else {
        Vessel = PaymentDisplay
      }
      break
    case TransactionTypes.REQUEST_REFUND:
    case TransactionTypes.REFUND:
    case TransactionTypes.AUTO_REFUND:
      if (transaction.status === TransactionStatus.FAILED) {
        Vessel = RefundFailureDisplay
      } else {
        Vessel = RefundDisplay
      }
      break
    case TransactionTypes.SAVED:
      Vessel = SavedDisplay
      break
    case TransactionTypes.REMOVED:
      Vessel = RemovedDisplay
      break
    case TransactionTypes.REQUEST_REMOVED:
      simpleNote = 'Request Deleted'
      Vessel = Simple
      break
    case TransactionTypes.REQUEST_FULFILLED:
      Vessel = RequestFulfilled
      break
    case TransactionTypes.THREE_D_SECURE_STARTED:
      Vessel = ThreeDSecureStarted
      break
    case TransactionTypes.PAYMENT_STARTED:
      if (transaction.status === TransactionStatus.FAILED) {
        Vessel = PaymentFailureDisplay
      } else {
        Vessel = PaymentStarted
      }
      break
    case TransactionTypes.THREE_D_SECURE_ADD_CARD_STARTED:
      if (transaction.status === TransactionStatus.FAILED) {
        Vessel = ThreeDSecureAddCardFailed
      } else {
        Vessel = ThreeDSecureAddCardStarted
      }
      break
    default:
      Vessel = PaymentDisplay
  }

  return (
    <PaymentLine>
      {needsToggle ? <Toggle expanded={expanded} togglePaymentDetail={actions.togglePaymentDetail} id={transaction.id} /> : null}
      <PaymentContent className="payment-content">
        <DateDisplay>{transaction.charged_formatted}</DateDisplay>
        <Vessel
          actual={actual}
          transaction={transaction}
          refundData={refundData}
          selectedClient={selectedClient}
          expanded={expanded}
          currencySymbol={currencySymbol}
          urlKey={urlKey}
          deletingRequest={deletingRequest}
          simpleNote={simpleNote}
          actions={actions}
          formErrors={formErrors}
          validateFieldMap={validateFieldMap}
          onSubmitRefundHandler={onSubmitRefundHandler}
          onSubmitNotificationHandler={onSubmitNotificationHandler}
          canCharge={canCharge}
          canRefund={canRefund}
          order={order}
          payoutProfileAccountId={payoutProfileAccountId}
          venueTimezone={venueTimezone}
          venueLocale={venueLocale}
        />
      </PaymentContent>
    </PaymentLine>
  )
}

export default Transaction

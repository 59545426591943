import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, TimeInterval } from '@sevenrooms/core/timepiece'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListCategoriesMessages, accessRulesListMessages } from '../../../locales'

interface TimeFieldProps {
  timeSlots: TimeOnly[]
  timeRanges: TimeInterval[]
  shiftCategories?: string[]
  rowId: string
  restrictToShift?: boolean
}

function TimeField({ timeSlots, timeRanges, rowId, restrictToShift = false, shiftCategories }: TimeFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_SLOTS = 7
  const MAX_ROWS = 4

  // This means we are in the overview list and the access time type is ALL
  if (shiftCategories) {
    return (
      <Typography variant="body2" color="text.secondary">
        {shiftCategories
          .map(category =>
            formatMessage(
              accessRulesListCategoriesMessages[`${category.toLowerCase()}Category` as keyof typeof accessRulesListCategoriesMessages]
            )
          )
          .join(', ')}
      </Typography>
    )
  }

  if (timeRanges.length > 0) {
    const visibleRanges: TimeInterval[] = timeRanges.slice(0, MAX_ROWS)
    const remainingRanges = timeRanges.length - MAX_ROWS

    return (
      <Box display="flex" flexWrap="wrap" gap="4px" maxWidth="100%">
        {restrictToShift && (
          <Typography variant="body2" color="text.secondary">
            {formatMessage(accessRulesListMessages.restrictedByShift)}
          </Typography>
        )}
        {visibleRanges.map(range => {
          const rangeDisplay = range.formatSTime()

          return (
            <Box
              key={`${rowId}-${rangeDisplay}`}
              sx={{
                height: 22,
                width: 112,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 1,
                py: 0.5,
                borderRadius: '4px',
                backgroundColor: blueGrey[100],
              }}
            >
              <Typography variant="body2" color="text.primary">
                {rangeDisplay}
              </Typography>
            </Box>
          )
        })}
        {remainingRanges > 0 && (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ height: 22, display: 'inline-flex', alignItems: 'center', px: 1, py: 0.5 }}
          >
            {formatMessage(accessRulesListMessages.xMore, { count: remainingRanges })}
          </Typography>
        )}
      </Box>
    )
  }

  if (timeSlots.length > 0) {
    const visibleSlots: TimeOnly[] = timeSlots.slice(0, MAX_SLOTS)
    const remainingSlots = timeSlots.length - MAX_SLOTS

    return (
      <Box display="flex" flexWrap="wrap" gap="4px" maxWidth="100%">
        {restrictToShift && (
          <Typography variant="body2" color="text.secondary">
            {formatMessage(accessRulesListMessages.restrictedByShift)}
          </Typography>
        )}
        {visibleSlots.map(slot => {
          const slotDisplay = slot.formatSTime()
          return (
            <Box
              key={`${rowId}-${slotDisplay}}`}
              sx={{
                height: 22,
                width: 52,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 1,
                py: 0.5,
                borderRadius: '4px',
                backgroundColor: blueGrey[100],
              }}
            >
              <Typography variant="body2" color="text.primary">
                {slotDisplay}
              </Typography>
            </Box>
          )
        })}
        {remainingSlots > 0 && (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ height: 22, display: 'inline-flex', alignItems: 'center', px: 1, py: 0.5 }}
          >
            {formatMessage(accessRulesListMessages.xMore, { count: remainingSlots })}
          </Typography>
        )}
      </Box>
    )
  }

  return null
}

export { TimeField }

// This file was automatically generated from admin.neighborhood.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.NeighborhoodList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.NeighborhoodListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.NeighborhoodListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="neighborhoodlist-page"><div class="content-header">Neighborhoods</div><div class="right vpadding"><a id="neigh-add-link" class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  var muni_neighborhoodsList1272 = opt_data.content.neighborhoods_by_muni;
  var muni_neighborhoodsListLen1272 = muni_neighborhoodsList1272.length;
  for (var muni_neighborhoodsIndex1272 = 0; muni_neighborhoodsIndex1272 < muni_neighborhoodsListLen1272; muni_neighborhoodsIndex1272++) {
    var muni_neighborhoodsData1272 = muni_neighborhoodsList1272[muni_neighborhoodsIndex1272];
    output.append('<div class="entity-list-header">', soy.$$escapeHtml(muni_neighborhoodsData1272.muni_name), '</div>');
    var neighborhoodList1276 = muni_neighborhoodsData1272.neighborhoods;
    var neighborhoodListLen1276 = neighborhoodList1276.length;
    for (var neighborhoodIndex1276 = 0; neighborhoodIndex1276 < neighborhoodListLen1276; neighborhoodIndex1276++) {
      var neighborhoodData1276 = neighborhoodList1276[neighborhoodIndex1276];
      output.append((neighborhoodIndex1276 % 2 == 0) ? '<div class="container entity-record row-even"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData1276.id) + '">' + soy.$$escapeHtml(neighborhoodData1276.name) + '</a></div>' : '<div class="container entity-record row-odd"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData1276.id) + '">' + soy.$$escapeHtml(neighborhoodData1276.name) + '</a></div>');
    }
    output.append('<div class="right vpadding"><a class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};

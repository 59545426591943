import { useEffect, useState } from 'react'
import { useGetPoliciesQuery } from '@sevenrooms/core/api'
import type { Policy, Venue } from '@sevenrooms/core/domain'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label, FormNumberInput, Checkbox, FormSelect, FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack, HStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { PolicyPicker } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/components/PolicyPicker'
import { PaymentPolicyLocales } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/PaymentPolicy.locales'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { MinimumSpendMatrixComponent } from '@sevenrooms/mgr-offers/experiences/components/PDR/MinSpendMatrix'
import type { MinimumSpendMatrixFormData } from '@sevenrooms/mgr-offers/experiences/components/PDR/PDRDetailsForm.zod'
import { spacesMessages } from '../../spaces.locales'
import { CancellationDeadlines } from './CancellationDeadlines'
import { useEventTypesOptions } from './EventTypesOptions'
import { depositCalcFromOptions, pdrDeadlineTimeFromOptions, pdrDeadlineTimeUnitOptions } from './selectOptions'
import type { SpaceFormType } from './Space.zod'

interface DinewiseSettingsProps {
  isSubmitting: boolean
  field: Field<SpaceFormType>
  tabSelected: number
  watch: (name?: string | string[]) => void
  venue: Venue
}

function DinewiseSettings({ venue, isSubmitting, field, tabSelected, watch }: DinewiseSettingsProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()
  const eventTypesOptions = useEventTypesOptions()
  const { data } = useGetPoliciesQuery({ venueId: venue.id, policyCategories: ['PAYMENT', 'BOOKING'] })
  const [cancelPolicies, setCancelPolicies] = useState<Policy[]>([])

  useEffect(() => {
    if (data?.length) {
      setCancelPolicies(data.filter(policy => policy.policyCategory === 'PAYMENT'))
    }
  }, [data])

  const isVisible = !(isSubmitting || tabSelected === 0)

  return (
    <>
      {isVisible && (
        <VStack pl="lm" spacing="lm" pb="lm" maxHeight={isVisible ? 'unset' : 0}>
          <BaseSection title={formatMessage(spacesMessages.dineWiseOnlyTabWarning)}>
            <Box p="lm">
              <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
                <Text>{formatMessage(spacesMessages.dineWiseOnlyTabWarningDescription)}</Text>
              </Box>
            </Box>
          </BaseSection>
          <BaseSection title={formatMessage(spacesMessages.eventTypeSectionTitle)}>
            <VStack spacing="lm" p="lm">
              <VStack spacing="m">
                <FormMultiSelect field={field.prop('eventType')} useCheckboxItems options={eventTypesOptions} />
              </VStack>
            </VStack>
          </BaseSection>
          <BaseSection>
            <VStack spacing="lm" p="lm">
              <VStack spacing="m">
                <Text fontWeight="bold">{formatMessage(spacesMessages.dwMinSpendTable)}</Text>
                <Label
                  primary={formatMessage(spacesMessages.pdrDefaultMinSpend)}
                  secondary={formatMessage(spacesMessages.pdrDefaultMinSpendHelper)}
                  width="50%"
                >
                  <FormNumberInput field={field.prop('pdrMinSpend')} greaterThanZero />
                </Label>
                <MinimumSpendMatrixComponent
                  field={field.prop('pdrMinimumSpendMatrix') as Field<MinimumSpendMatrixFormData[]>}
                  watch={watch}
                />
              </VStack>
            </VStack>
          </BaseSection>
          {!!venueSettings?.is_triple_seat_dinewise_enabled && (
            <BaseSection title={formatMessage(spacesMessages.pdrTripleSeatIntegrationBoxTitle)}>
              <VStack spacing="lm" p="lm">
                <Label primary={formatMessage(spacesMessages.pdrTripleSeatRoomId)} />
                <FormInput field={field.prop('pdrTripleSeatRoomId')} />
              </VStack>
            </BaseSection>
          )}
          {!!venueSettings?.is_pdr_deposit_fee_enabled && (
            <BaseSection title={formatMessage(spacesMessages.pdrDepositFeeBoxTitle)}>
              <VStack p="lm">
                <HStack spacing="lm">
                  <Label primary={formatMessage(spacesMessages.pdrDepositFee)}>
                    <FormNumberInput field={field.prop('pdrDepositFee')} greaterThanZero max={100} />
                  </Label>
                  <Label primary={formatMessage(spacesMessages.pdrDepositFeeCalcFrom)}>
                    <FormSelect field={field.prop('depositCalcFrom')} options={depositCalcFromOptions} withEmpty />
                  </Label>
                </HStack>
                <HStack spacing="lm" pt="m">
                  <Checkbox field={field.prop('requirePreApproval')}>{formatMessage(spacesMessages.pdrRequirePreApproval)}</Checkbox>
                </HStack>
                <HStack spacing="lm" pt="m">
                  <Checkbox field={field.prop('requireContract')}>{formatMessage(spacesMessages.pdrRequireContract)}</Checkbox>
                </HStack>
                <DividerLine mt="lm" mb="lm" color="borders" ml="none" mr="none" />
                <Label primary={<Text fontWeight="bold">{formatMessage(spacesMessages.pdrMenuSelectionDeadlineTitle)}</Text>} />
                <HStack mt="m" spacing="lm">
                  <Box>
                    <FormNumberInput
                      field={(field as unknown as Field<{ pdrDeadlineNum: null | number }>).prop('pdrDeadlineNum')}
                      greaterThanZero
                      max={100}
                    />
                  </Box>
                  <FormSelect field={field.prop('pdrDeadlineTimeUnit')} options={pdrDeadlineTimeUnitOptions} withEmpty />
                  <FormSelect field={field.prop('pdrDeadlineTimeFrom')} options={pdrDeadlineTimeFromOptions} withEmpty />
                </HStack>
                <HStack mt="lm" spacing="lm">
                  <PolicyPicker
                    data-test="Pol-Picker_DinewiseSettings"
                    choice={(
                      field as unknown as Field<{
                        pdrCancellationPolicy: null | string
                      }>
                    ).prop('pdrCancellationPolicy')}
                    freeform={(
                      field as unknown as Field<{
                        pdrCustomCancellationPolicy: null | string
                      }>
                    ).prop('pdrCustomCancellationPolicy')}
                    policies={cancelPolicies}
                    label={formatMessage(PaymentPolicyLocales.cancellationPolicy)}
                  />
                </HStack>
                <HStack mt="lm" spacing="lm">
                  <CancellationDeadlines field={field.prop('cancellationDeadlines')} />
                </HStack>
              </VStack>
            </BaseSection>
          )}
        </VStack>
      )}
    </>
  )
}

export { DinewiseSettings }

import { promoCodeTypes } from './constantTypes'

export const roundMoney = amount => {
  const roundedAmount = Math.round(amount * 100) / 100
  return roundedAmount.toFixed(2)
}

export const makeCalc = (x, y) => {
  const total = x * y
  return roundMoney(total)
}

export const calcAdditionalFee = (subtotal, chargeAdditional, additionalFee, quantity) => {
  if (!chargeAdditional) {
    return 0
  }
  if (additionalFee.get('feeAmountType') === 'PERCENT_OF_BASE') {
    return Number(roundMoney((Number(additionalFee.get('feeAmount')) * subtotal) / 100))
  }
  if (additionalFee.get('feeAmountType') === 'PER_TICKET') {
    return Number(roundMoney(Number(additionalFee.get('feeAmount')) * quantity))
  }
  return Number(roundMoney(Number(additionalFee.get('feeAmount'))))
}

export const getDiscountedPrice = (basePrice, promoCodeEntity) => {
  if (!promoCodeEntity) {
    return 0
  }
  const discountedPrice = applyPromo(basePrice, promoCodeEntity)
  return Math.min(basePrice, discountedPrice)
}

export const calcCharges = (
  quantity,
  price,
  serviceChargePercentage,
  taxPercentage,
  gratuityPercentage,
  additionalFee,
  additionalFeeTaxPercentage,
  shouldChargeAdditionalFee
) => {
  const chargeBaseAmount = Number(roundMoney(price * quantity))

  const chargeServiceChargeAmount = Number(roundMoney(chargeBaseAmount * (serviceChargePercentage / 100)))

  const chargeGratuityAmount = Number(roundMoney(chargeBaseAmount * (gratuityPercentage / 100)))

  const chargeTaxAmount = Number(roundMoney((chargeBaseAmount + chargeServiceChargeAmount) * (taxPercentage / 100)))

  const chargeAdditionalFeeAmount = calcAdditionalFee(chargeBaseAmount, shouldChargeAdditionalFee, additionalFee, quantity)
  const chargeAdditionalFeeTaxAmount = Number(roundMoney(chargeAdditionalFeeAmount * (additionalFeeTaxPercentage / 100)))

  const subTotal = chargeBaseAmount + chargeServiceChargeAmount + chargeTaxAmount + chargeGratuityAmount
  const total = subTotal + chargeAdditionalFeeAmount + chargeAdditionalFeeTaxAmount

  return {
    charge_amount: Number(roundMoney(total)),
    charge_base_amount: chargeBaseAmount,
    charge_service_charge_amount: chargeServiceChargeAmount,
    charge_gratuity_amount: chargeGratuityAmount,
    charge_tax_amount: chargeTaxAmount,
    charge_additional_amount: chargeAdditionalFeeAmount,
    charge_additional_fee_tax_amount: chargeAdditionalFeeTaxAmount,
    charge_subtotal: subTotal,
  }
}

export const calcTotalFeePrice = (inventoryItem, quantity, price, defaultServiceCharge) => {
  let serviceChargeAmount = 0
  if (inventoryItem.applyServiceCharge) {
    if (inventoryItem.serviceChargeType === 'SPECIFIC_SERVICE_CHARGE') {
      serviceChargeAmount = inventoryItem.serviceChargeAmount
    } else {
      // Workaround for inventories that were saved with DEFAULT_SERVICE_CHARGE and serviceChargeAmount = 0
      serviceChargeAmount = defaultServiceCharge
    }
  }

  const charges = calcCharges(
    quantity,
    price,
    serviceChargeAmount,
    0,
    inventoryItem.chargeGratuity && inventoryItem.chargeGratuity !== 'CUSTOMER_VARIABLE' ? inventoryItem.gratuityAmount : 0,
    new Map(Object.entries(inventoryItem.additionalFee)),
    0,
    inventoryItem.chargeAdditionalFee
  )
  return charges.charge_gratuity_amount + charges.charge_additional_amount + charges.charge_service_charge_amount
}

export const applyPromo = (subTotal, promoCodeEntity) => {
  if (!promoCodeEntity) {
    return subTotal
  }
  switch (promoCodeEntity.promoType) {
    case promoCodeTypes.DOLLAR_DISCOUNT:
      return promoCodeEntity.promoValue / 100
    case promoCodeTypes.PERCENT_DISCOUNT:
      return subTotal * (promoCodeEntity.promoValue / 100)
    case promoCodeTypes.PERCENT_DISCOUNT_WITH_CAP: {
      const roundedDiscount = subTotal * (promoCodeEntity.promoValue / 100)
      const valueCap = promoCodeEntity.promoValueCap / 100
      return Math.min(roundedDiscount, valueCap)
    }
    default:
      return promoCodeEntity.promoValue / 100
  }
}

export const getTaxRate = (taxGroups, taxGroupId) => {
  const taxGroup = taxGroups.find(obj => obj.id === taxGroupId)
  return taxGroup ? taxGroup.tax_rate : 0
}

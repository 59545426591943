// This file was automatically generated from manager.manage.venuepromoters.view.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }


Nightloop.Templates.Manager.Manage.VenuePromotersView = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.VenuePromotersViewBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.VenuePromotersViewBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-venuepromoters"><div><div class="content-header float-left"><span class="header-text">Booked By Names</span>  <a id="contextual-link-bookedby" class="contextual-link" href="javascript:void(0);">(?)</a><div id="contextual-content-bookedby" class="contextual-content">Along with venue staff accounts, these names appear in the \'Booked by\' drop-down when venue staff book new reservations.</div></div><div class="float-end"></div></div><div id="main-area" class="float-left w50">', (opt_data.content.message) ? '<div class="white-box gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<div class="body-block white-box block-shadow"><div class="white-box-block"><div class="block-header"><div class="header-text float-left">Names</div><div id="edit-button" class="float-right"><a id="edit-bookedby-link" class="ajaxify" href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/bookedbynames/edit">');
  Nightloop.Templates.Widget.GoldButton({text: 'EDIT', size: 'medium'}, output);
  output.append('</a></div><div class="float-end"></div></div></div><div class="white-box-block">');
  var venue_promoterList10254 = opt_data.content.venue_promoters;
  var venue_promoterListLen10254 = venue_promoterList10254.length;
  for (var venue_promoterIndex10254 = 0; venue_promoterIndex10254 < venue_promoterListLen10254; venue_promoterIndex10254++) {
    var venue_promoterData10254 = venue_promoterList10254[venue_promoterIndex10254];
    output.append('<div class="venue-promoter-name">', soy.$$escapeHtml(venue_promoterData10254.name), '</div>');
  }
  output.append('</div></div></div><div class="float-end"></div></div><script type="text/javascript">$(document).ready( function() {Pmp.Manager.Manage.VenuePromotersView.initialize();})<\/script>');
  return opt_sb ? '' : output.toString();
};

/* eslint react/prop-types: 0, jsx-a11y/no-static-element-interactions: 0 */
/* global widgetInit */
import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import { Component } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { accessibleComponentWrapper } from 'svr/common/A11y'
import { selectLanguageStrings } from 'widget/events/selectors/languageSelectors'
import Button from '../../../../lib/Buttons/Button'
import PromoCodeButton from '../../../../lib/Buttons/PromoCodeButton'
import TextInput from '../../../../lib/TextInputs/TextInput'
import { changeFormField, verifyPromoCode, removePromoCode } from '../actions/forms'
import {
  toggleBookingAgreement,
  toggleVenueGroupMarketingOptIn,
  toggleVenueSpecificMarketingOptIn,
  toggleVenueSmsMarketingOptIn,
  toggleReservationSmsOptIn,
  toggleTailoredCommunicationOptIn,
  toggleAboveAgeConsent,
  updateCustomTipAmount,
  toggleVenueGroupMarketingOptInPolicyDisplay,
  toggleVenueSpecificMarketingOptInPolicyDisplay,
  toggleVenueSmsMarketingOptInPolicyDisplay,
  toggleReservationSmsOptInPolicyDisplay,
  toggleTailoredCommunicationOptInDisplay,
  toggleBookingPolicyDisplay,
  toggleModalDisplay,
} from '../actions/navigation'
import * as styles from '../assets/styles/checkout'
import * as CheckoutPolicySelector from '../selectors/CheckoutPolicySelector'
import { getSelectedInventoryCart, calculateSelectedInventoryPrices } from '../selectors/inventoryCartSelector'
import { modalTypes } from '../utils/constantTypes'
import { getDayName, getMonthName, getFormattedTime } from '../utils/date'
import { parseBoolean } from 'widget/events/utils/preloadedState'
import PricingDetails from '../components/PricingDetails'
import { RESERVATION_TYPE, REQUEST_TYPE, GUESTLIST_FREE_TYPE } from '../reducers/inventoryReducer'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const SpanComponent = props => <span role="button" tabIndex="0" {...props} />
const AccessibleSpanComponent = accessibleComponentWrapper(SpanComponent, false)
const IComponent = props => <i role="button" tabIndex="0" {...props} />
const AccessibleIComponent = accessibleComponentWrapper(IComponent, false)

class CheckoutSummary extends Component {
  constructor(props) {
    super(props)
    this.handlePromoCodeChange = this.props.changeFormField.bind(this, 'promoCode')

    this.toggleDietRestrictionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.DIET_TAG_SELECT)
    this.toggleSpecialOccasionsModal = this.props.toggleModalDisplay.bind(this, modalTypes.SPECIAL_OCCASION_SELECT)
    this.buildTagDisplay = this.buildTagDisplay.bind(this)
  }

  getBaseEventChargeLine(basePrice) {
    return (
      <div>
        <div style={styles.breakdownLeft}>{`Event (${this.props.type})`}</div>
        <div style={styles.breakdownRight}>
          {priceFormatter.format(basePrice, {
            code: this.props.currencyCode,
          })}
        </div>
      </div>
    )
  }

  getSubTotalLine(chargeTax, subTotal) {
    return chargeTax || this.props.chargeGratuity ? (
      <div>
        <div style={styles.breakdownLeft}>Order Subtotal</div>
        <div id="sr-price-subtotal" style={styles.breakdownRight}>
          {priceFormatter.format(subTotal, { code: this.props.currencyCode })}
        </div>
      </div>
    ) : null
  }

  getPromoLine(promoCodeEntity, discounted) {
    return promoCodeEntity ? (
      <div>
        <div style={styles.breakdownLeft}>{`Promo Code (${promoCodeEntity.code})`}</div>
        <div style={styles.breakdownRight}>
          {priceFormatter.format(-discounted, {
            code: this.props.currencyCode,
          })}
        </div>
      </div>
    ) : null
  }

  getServiceChargeLine(applyServiceCharge, serviceChargePercent, serviceCharge) {
    return applyServiceCharge ? (
      <div>
        <div id="sr-service-charge-percent" style={styles.breakdownLeft}>{`${this.props.textServiceFee} (${serviceChargePercent}%)`}</div>
        <div id="sr-service-charge-price" style={styles.breakdownRight}>
          {priceFormatter.format(serviceCharge, {
            code: this.props.currencyCode,
          })}
        </div>
      </div>
    ) : null
  }

  getTaxLine(chargeTax, taxPercent, tax) {
    return chargeTax ? (
      <div>
        <div id="sr-tax-percent" style={styles.breakdownLeft}>{`Tax (${taxPercent}%)`}</div>
        <div id="sr-tax-price" style={styles.breakdownRight}>
          {priceFormatter.format(tax, { code: this.props.currencyCode })}
        </div>
      </div>
    ) : null
  }

  getTipLine(subTotal, tip, customTip) {
    const customOptions = _.map(_.range(41), (val, index) => (
      <option key={index} value={val}>
        {val}%
      </option>
    ))
    return this.props.chargeGratuity ? (
      <div>
        {this.props.chargeGratuityType === 'CUSTOMER_VARIABLE' ? (
          <div id="sr-gratuity-line" style={styles.breakdownLeft}>
            <span>{'Tip '}</span>
            <select
              onChange={this.props.updateCustomTipAmount}
              style={{
                WebkitAppearance: 'inherit',
                MozAppearance: 'none',
                textIndent: 2,
                width: 55,
                height: 21,
                textOverflow: '',
                color: 'inherit',
                borderColor: this.props.formErrors.gratuity ? '#d01a21' : '#a6a6a6',
                borderRadius: 'inherit',
                backgroundColor: 'transparent',
                backgroundImage:
                  'linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%),linear-gradient(to right, #ccc, #ccc)',
                backgroundPosition: 'calc(100% - 10px) calc(.6em), calc(100% - 5px) calc(.6em), calc(100% - 5px) 2em',
                backgroundSize: '5px 5px, 5px 5px, 1px 1.5em',
                backgroundRepeat: 'no-repeat',
              }}
              defaultValue={customTip}
            >
              <option value="" selected disabled>
                Select
              </option>
              {customOptions}
            </select>
          </div>
        ) : (
          <div id="sr-tip-percent" style={styles.breakdownLeft}>{`Tip (${this.props.tipPercent}%)`}</div>
        )}
        <div id="sr-tip-price" style={styles.breakdownRight}>
          {priceFormatter.format(tip, { code: this.props.currencyCode })}
        </div>
      </div>
    ) : null
  }

  getAdditionalFee(subTotal, feeAmount) {
    return this.props.chargeAdditionalFee ? (
      <div>
        <div id="sr-custom-fee-type" style={styles.breakdownLeft}>{`${this.props.additionalFee.get('feeLabel') || 'Admin Fee'}`}</div>
        <div id="sr-custom-fee-price" style={styles.breakdownRight}>
          {priceFormatter.format(feeAmount, { code: this.props.currencyCode })}
        </div>
      </div>
    ) : null
  }

  getAdditionalFeeTax(additionalFeeTaxPercent, feeTaxAmount) {
    return this.props.chargeAdditionalFeeTax && this.props.chargeAdditionalFee ? (
      <div>
        <div id="sr-custom-fee-tax-type" style={styles.breakdownLeft}>{`${
          this.props.additionalFee.get('feeLabel') || 'Admin Fee'
        } Tax (${additionalFeeTaxPercent}%)`}</div>
        <div id="sr-custom-fee-tax-price" style={styles.breakdownRight}>
          {priceFormatter.format(feeTaxAmount, { code: this.props.currencyCode })}
        </div>
      </div>
    ) : null
  }

  getCheckBoxElement(value, required = false) {
    const icon = this.props[value] ? (
      <AccessibleIComponent style={styles.checkIcon} className="fa fa-check fa-fw" aria-hidden="true" />
    ) : null
    let clickFunc
    switch (value) {
      case 'agreedToBookingPolicy':
        clickFunc = this.props.onBookingPolicyClick
        break
      case 'agreedToVenueGroupMarketingOptIn':
        clickFunc = this.props.onVenueGroupMarketingOptInClick
        break
      case 'agreedToVenueSpecificMarketingOptIn':
        clickFunc = this.props.onVenueSpecificMarketingOptInClick
        break
      case 'agreedToVenueSmsMarketingOptIn':
        clickFunc = this.props.onVenueSmsMarketingOptInClick
        break
      case 'agreedToReservationSmsOptIn':
        clickFunc = this.props.onReservationSmsOptInClick
        break
      case 'agreedToTailoredCommunicationOptIn':
        clickFunc = this.props.onTailoredCommunicationOptInClick
        break
      default:
        clickFunc = this.props.onAboveAgeConsentClick
        break
    }
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div style={styles.checkbox} onClick={clickFunc} aria-required={required} aria-label={_.startCase(value)}>
        {icon}
      </div>
    )
  }

  getAgePolicyStyle() {
    return this.props.agePolicyStatus !== 'invalid'
      ? styles.policyLine
      : _.assign({}, styles.policyLine, {
          color: widgetInit.settings.fonts_color_error,
        })
  }

  buildTagDisplay(tagGroup, label) {
    const tagList = _.map(_.keys(tagGroup.selectedTags), tagName => (
      <div
        key={tagName}
        style={_.assign({}, styles.miniTag, {
          color: this.props.fontsColorButton,
          backgroundColor: this.props.colorPrimary,
        })}
        data-test="sr-selected-tag"
      >
        {this.props.tagTranslations[tagName] || tagGroup.tagNameDisplays[tagName] || tagName}
      </div>
    ))
    const labelElement = !label ? null : (
      <div style={_.assign({}, styles.labelElement, { color: this.props.fontsColorCheckoutInactive })}>{label}</div>
    )
    return _.isEmpty(tagList) ? null : (
      <div style={styles.selectedTagDisplay}>
        {labelElement}
        {tagList}
      </div>
    )
  }

  buildSummaryDetails() {
    const { month, year, formattedTime } = this.props
    let { day } = this.props
    if (day[0] === '0') {
      // eslint-disable-next-line prefer-destructuring
      day = day[1]
    }
    const dot = <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
    let dateStr = _.capitalize(getDayName('short', year, month - 1, day).toLowerCase())
    dateStr += `, ${getMonthName(month - 1)} ${day}`
    if (dateStr) {
      return (
        <div>
          <span style={{ display: 'inline-block' }}>
            {dateStr}
            {dot}
          </span>
          {formattedTime && <span style={{ display: 'inline-block' }}>{formattedTime}</span>}
        </div>
      )
    }
    return <span>{dateStr}</span>
  }

  buildGuestAndMinimumDetails() {
    if (this.props.hasReservationType) {
      const numGuests = this.props.quantity * this.props.entryPerReservation
      const minDollarText = this.props.minPrice
        ? `, ${priceFormatter.format(this.props.quantity * this.props.minPrice, {
            code: this.props.currencyCode,
          })} ${this.props.textMinimumSpend}`
        : ''
      if (numGuests === 1) {
        return `1 guest${minDollarText}`
      }
      return `Up to ${numGuests} guests${minDollarText}`
    }
    return this.props.quantity + (this.props.quantity > 1 ? ' guests' : ' guest')
  }

  getBookingPolicyStyle() {
    const baseStyle = { ...styles.policyLine, ...styles.bookingPolicy }
    return this.props.bookingPolicyStatus !== 'invalid' ? baseStyle : { ...baseStyle, color: this.props.textInputProps.errorColor }
  }

  render() {
    const {
      discount,
      basePrice,
      applyServiceCharge,
      serviceCharge,
      serviceChargePercent,
      taxPercent,
      chargeTax,
      tax,
      tip,
      additionalFeeAmount,
      additionalFeeTaxPercent,
      additionalFeeTaxAmount,
      subTotal,
      total,
      venueName,
      removePromoCode,
      verifyPromoCode,
      isVerifyingPromoCode,
      validPromoCode,
      onBookingPolicyClick,
      onVenueGroupMarketingOptInClick,
      onVenueSpecificMarketingOptInClick,
      onVenueSmsMarketingOptInClick,
      onReservationSmsOptInClick,
      onTailoredCommunicationOptInClick,
      onAboveAgeConsentClick,
      displayAboveAgeConsentPolicy,
      displayVenueGroupMarketingOptInButton,
      displayVenueSpecificMarketingOptInButton,
      displayVenueSmsMarketingOptInButton,
      displayReservationSmsOptInButton,
      displayTailoredCommunicationOptInButton,
      displayVenueGroupMarketingPolicyInfo,
      displayVenueSpecificMarketingPolicyInfo,
      displayVenueSmsMarketingPolicyInfo,
      displayReservationSmsOptInPolicyInfo,
      displayBookingPolicyInfo,
      colorLines,
      fontsColorPrimary,
      fontsColorCheckoutActive,
      colorCheckoutCellBackground,
      promoCode,
      promoCodeEntity,
      enablePromocodes,
      dietaryRestrictions,
      dietaryRestrictionsGuest,
      specialOccasions,
      textAddButtonLabel,
      textCancellationPolicy,
      textDietaryRestrictions,
      textInputProps,
      textSpecialOccasion,
      textTagLabelYours,
      textTagLabelYourGuests,
      textMinimumSpend,
      currencyCode,
      isMultiInventoryTypeEnabled,
      selectedInventoryItems,
    } = this.props

    const addButtonLabel = `+ ${textAddButtonLabel}`
    const displayDietRestrictions = dietaryRestrictions && !_.isEmpty(dietaryRestrictions.tags)
    const displayDietRestrictionsGuest = dietaryRestrictionsGuest && !_.isEmpty(dietaryRestrictionsGuest.tags)
    const displayOccasionTagLine = specialOccasions && !_.isEmpty(specialOccasions.tags)
    return (
      <div style={styles.summaryWrapper}>
        {!this.props.hasRequestType ? (
          <div style={styles.labelWrapper}>
            <span
              style={_.assign({}, styles.sectionLabel, {
                color: fontsColorPrimary,
              })}
            >
              Summary
            </span>
          </div>
        ) : null}
        <div style={styles.infoForm}>
          {!this.props.hasRequestType && (
            <div>
              <div
                style={_.assign({}, styles.summaryLine, styles.topBorderRadius, {
                  color: fontsColorCheckoutActive,
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <i style={styles.markerIcon} className="fa fa-map-marker fa-fw" aria-hidden="true" />
                <span id="sr-venue-name" style={styles.summaryText}>
                  {venueName}
                </span>
              </div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                style={_.assign({}, styles.summaryLine, {
                  color: fontsColorCheckoutActive,
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <i style={styles.calendarIcon} className="fa fa-calendar-o fa-fw" aria-hidden="true" />
                <span id="sr-event-name" style={styles.summaryText}>
                  {this.props.eventLabel}
                </span>
              </div>
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                style={_.assign({}, styles.summaryLine, {
                  color: fontsColorCheckoutActive,
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                <i style={styles.clockIcon} className="fa fa-time-8 fa-fw" aria-hidden="true" />
                <span id="sr-event-datetime" style={styles.summaryText}>
                  {this.buildSummaryDetails()}
                </span>
              </div>
              {!isMultiInventoryTypeEnabled && (
                <>
                  <hr
                    style={_.assign({}, styles.formLineSeperator, {
                      borderColor: colorLines,
                    })}
                  />
                  <div
                    style={_.assign({}, styles.summaryLine, {
                      color: fontsColorCheckoutActive,
                      backgroundColor: colorCheckoutCellBackground,
                    })}
                  >
                    <i style={styles.usersIcon} className="fa fa-users fa-fw" aria-hidden="true" />
                    <span id="sr-event-guests" style={{ ...styles.summaryText, paddingLeft: 7 }}>
                      {this.buildGuestAndMinimumDetails()}
                    </span>
                  </div>
                </>
              )}
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
              <div
                style={_.assign({}, styles.summaryLine, {
                  color: fontsColorCheckoutActive,
                  backgroundColor: colorCheckoutCellBackground,
                })}
              >
                {isMultiInventoryTypeEnabled ? (
                  selectedInventoryItems.map(
                    (
                      { inventoryId, inventoryName, basePrice, quantity, compText, inventoryType, minPrice, entryPerReservation },
                      index
                    ) => {
                      let text = ''
                      if (inventoryType === RESERVATION_TYPE) {
                        const numGuests = quantity * entryPerReservation
                        const minDollarText = minPrice
                          ? `, ${priceFormatter.format(quantity * minPrice, {
                              code: currencyCode,
                            })} ${textMinimumSpend}`
                          : ''
                        if (numGuests === 1) {
                          text = `1 guest${minDollarText}`
                        } else {
                          text = `Up to ${numGuests} guests${minDollarText}`
                        }
                      } else {
                        text = quantity + (quantity > 1 ? ' guests' : ' guest')
                      }

                      return (
                        <div
                          key={inventoryId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: selectedInventoryItems.length > 1 && index < selectedInventoryItems.length - 1 ? '8px' : '0',
                          }}
                        >
                          <div>
                            <i style={styles.moneyIcon} className="fa fa-money fa-fw" aria-hidden="true" />
                            <span id="sr-inventory-detail" style={styles.ticketText}>{`(${quantity}) ${inventoryName}, ${text}`}</span>
                          </div>
                          <span id="sr-inventory-price" style={styles.ticketPriceText}>
                            {inventoryType === GUESTLIST_FREE_TYPE
                              ? compText
                              : priceFormatter.format(basePrice, {
                                  code: currencyCode,
                                })}
                          </span>
                        </div>
                      )
                    }
                  )
                ) : (
                  <>
                    <i style={styles.moneyIcon} className="fa fa-money fa-fw" aria-hidden="true" />
                    <span id="sr-inventory-detail" style={styles.ticketText}>{`(${this.props.quantity}) ${this.props.type}`}</span>
                    <span id="sr-inventory-price" style={styles.ticketPriceText}>
                      {this.props.hasGuestlistFreeType
                        ? this.props.compText
                        : priceFormatter.format(basePrice, {
                            code: currencyCode,
                          })}
                    </span>
                  </>
                )}
              </div>
              {(displayDietRestrictions || displayDietRestrictionsGuest) && (
                <div>
                  <hr style={{ ...styles.formLineSeperator, borderColor: colorLines }} />
                  <div style={{ ...styles.summaryLine, color: fontsColorCheckoutActive, backgroundColor: colorCheckoutCellBackground }}>
                    <i style={styles.tagIcon} className="fa fa-tag fa-fw" aria-hidden="true" />
                    <span style={{ ...styles.summaryText, ...styles.tagDescriptionText }}>{textDietaryRestrictions}</span>
                    <Button
                      withDefaultOutline
                      innerElement={addButtonLabel}
                      onClick={this.toggleDietRestrictionsModal}
                      activeStyling
                      style={{
                        ...styles.addButton,
                        color: fontsColorCheckoutActive,
                        borderColor: fontsColorCheckoutActive,
                        textAlign: 'center',
                        lineHeight: '1em',
                      }}
                      testId="sr-dietary-tag-add-button"
                    />
                    {displayDietRestrictions && this.buildTagDisplay(dietaryRestrictions, textTagLabelYours)}
                    {displayDietRestrictionsGuest && this.buildTagDisplay(dietaryRestrictionsGuest, textTagLabelYourGuests)}
                  </div>
                </div>
              )}
              {displayOccasionTagLine && (
                <div>
                  <hr style={{ ...styles.formLineSeperator, borderColor: colorLines }} />
                  <div style={{ ...styles.summaryLine, color: fontsColorCheckoutActive, backgroundColor: colorCheckoutCellBackground }}>
                    <i style={styles.tagIcon} className="fa fa-tag fa-fw" aria-hidden="true" />
                    <span style={{ ...styles.summaryText, ...styles.tagDescriptionText }}>{textSpecialOccasion}</span>
                    <Button
                      withDefaultOutline
                      innerElement={addButtonLabel}
                      onClick={this.toggleSpecialOccasionsModal}
                      activeStyling
                      style={{
                        ...styles.addButton,
                        color: fontsColorCheckoutActive,
                        borderColor: fontsColorCheckoutActive,
                        textAlign: 'center',
                        lineHeight: '1em',
                      }}
                      testId="sr-special-occasion-tag-add-button"
                    />
                    {this.buildTagDisplay(specialOccasions)}
                  </div>
                </div>
              )}
              {total > 0 || basePrice > 0 ? (
                <div style={{ backgroundColor: colorCheckoutCellBackground }}>
                  {enablePromocodes ? (
                    <span>
                      <hr
                        style={_.assign({}, styles.formLineSeperator, {
                          borderColor: colorLines,
                        })}
                      />
                      <div style={_.assign({}, styles.promoCodeLine, { backgroundColor: colorCheckoutCellBackground })}>
                        <TextInput
                          placeholder="Promo Code"
                          value={promoCode || ''}
                          charLimit={50}
                          disabled={!!validPromoCode}
                          onChange={this.handlePromoCodeChange}
                          textColor={this.props.fontsColorPrimary}
                          {...textInputProps}
                          style={
                            this.props.promoCodeisInvalid ? _.assign({}, styles.promoCodeField, styles.errorText) : styles.promoCodeField
                          }
                        />
                        {promoCode && (
                          <PromoCodeButton
                            colorError={textInputProps.errorColor}
                            fontsColorCheckoutInactive={textInputProps.placeholderColor}
                            onApplyClick={verifyPromoCode}
                            onRemoveClick={removePromoCode}
                            validPromoCode={validPromoCode}
                            isVerifyingPromoCode={isVerifyingPromoCode}
                            mediaUrl={widgetInit.settings.mediaUrl}
                          />
                        )}
                      </div>
                    </span>
                  ) : null}
                  <hr
                    style={_.assign({}, styles.formLineSeperator, {
                      borderColor: colorLines,
                    })}
                  />
                  {isMultiInventoryTypeEnabled && (
                    <PricingDetails
                      onGratuityFieldChange={this.props.updateCustomTipAmount}
                      hasGratuityFieldError={this.props.formErrors.gratuity}
                    />
                  )}
                  {!isMultiInventoryTypeEnabled && (
                    <div style={styles.priceBreakdown}>
                      {this.getBaseEventChargeLine(basePrice)}
                      {this.getServiceChargeLine(applyServiceCharge, serviceChargePercent, serviceCharge)}
                      {this.getTaxLine(chargeTax, taxPercent, tax)}
                      {this.getTipLine(basePrice, tip, this.props.customTip)}
                      {this.getSubTotalLine(chargeTax, subTotal)}
                      {this.getPromoLine(promoCodeEntity, discount, subTotal)}
                      {this.getAdditionalFee(basePrice, additionalFeeAmount)}
                      {this.getAdditionalFeeTax(additionalFeeTaxPercent, additionalFeeTaxAmount)}
                      <div>
                        <div style={styles.lastLeft}>Order Total</div>
                        <div id="sr-price-total" style={styles.lastRight}>
                          {priceFormatter.format(total, {
                            code: currencyCode,
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <hr
                style={_.assign({}, styles.formLineSeperator, {
                  borderColor: colorLines,
                })}
              />
            </div>
          )}
          <div style={_.assign({}, styles.policySection, { backgroundColor: colorCheckoutCellBackground })}>
            {!this.props.hasRequestType && (
              <div>
                <div
                  style={{
                    ...styles.policy,
                    clear: 'both',
                    display: 'table',
                  }}
                >
                  {this.getCheckBoxElement('agreedToBookingPolicy', true)}
                  <AccessibleSpanComponent
                    style={this.getBookingPolicyStyle()}
                    onClick={onBookingPolicyClick}
                    dangerouslySetInnerHTML={{
                      __html: `${textCancellationPolicy} *`,
                    }}
                  />
                  {displayBookingPolicyInfo && (
                    <AccessibleIComponent
                      style={styles.infoIcon}
                      className="fa fa-info-circle fa-fw"
                      onClick={this.props.toggleBookingPolicyDisplay}
                      aria-label="booking policy info"
                    />
                  )}
                </div>
                {displayAboveAgeConsentPolicy && (
                  <div style={styles.policy}>
                    {this.getCheckBoxElement('agreedToAboveAgeConsentOn', true)}
                    <AccessibleSpanComponent style={this.getAgePolicyStyle()} onClick={onAboveAgeConsentClick}>
                      I certify I am above the age of {this.props.ageToConsent} *
                    </AccessibleSpanComponent>
                  </div>
                )}
              </div>
            )}
            {displayVenueSmsMarketingOptInButton && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToVenueSmsMarketingOptIn')}
                <AccessibleSpanComponent
                  style={{
                    ...styles.policyLine,
                    width: '78%',
                    height: 'auto',
                  }}
                  onClick={onVenueSmsMarketingOptInClick}
                >
                  {this.props.textVenueSmsMarketingOptIn}
                </AccessibleSpanComponent>
                {displayVenueSmsMarketingPolicyInfo && (
                  <AccessibleIComponent
                    style={styles.infoIcon}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.props.toggleVenueSmsMarketingOptInPolicyDisplay}
                    aria-label="venue marketing policy info"
                  />
                )}
              </div>
            )}
            {displayVenueSpecificMarketingOptInButton && (
              <div style={styles.policy}>
                {this.getCheckBoxElement('agreedToVenueSpecificMarketingOptIn')}
                <AccessibleSpanComponent
                  style={{
                    ...styles.policyLine,
                    width: '78%',
                    height: 'auto',
                  }}
                  onClick={onVenueSpecificMarketingOptInClick}
                >
                  {this.props.textVenueSpecificMarketingOptIn}
                </AccessibleSpanComponent>
                {displayVenueSpecificMarketingPolicyInfo && (
                  <AccessibleIComponent
                    style={styles.infoIcon}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.props.toggleVenueSpecificMarketingOptInPolicyDisplay}
                    aria-label="venue marketing policy info"
                  />
                )}
              </div>
            )}
            {displayVenueGroupMarketingOptInButton && (
              <div
                style={{
                  ...styles.policy,
                  clear: 'both',
                  display: 'table',
                }}
              >
                {this.getCheckBoxElement('agreedToVenueGroupMarketingOptIn')}
                <AccessibleSpanComponent
                  style={{
                    ...styles.policyLine,
                    width: '78%',
                    height: 'auto',
                  }}
                  onClick={e => {
                    // dont toggle the checkbox if its a link
                    if (e.target.tagName.toLowerCase() !== 'a') {
                      onVenueGroupMarketingOptInClick()
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.textVenueGroupMarketingOptIn,
                  }}
                />
                {displayVenueGroupMarketingPolicyInfo && (
                  <AccessibleIComponent
                    style={styles.infoIcon}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.props.toggleVenueGroupMarketingOptInPolicyDisplay}
                    aria-label="venue group marketing policy info"
                  />
                )}
              </div>
            )}
            {displayReservationSmsOptInButton && (
              <div
                style={{
                  ...styles.policy,
                  clear: 'both',
                  display: 'table',
                }}
              >
                {this.getCheckBoxElement('agreedToReservationSmsOptIn')}
                <AccessibleSpanComponent
                  style={{
                    ...styles.policyLine,
                    width: '78%',
                    height: 'auto',
                  }}
                  onClick={onReservationSmsOptInClick}
                >
                  <IntlProvider locale="en">
                    <FormattedMessage
                      id="textReservationSmsOptIn"
                      // eslint-disable-next-line formatjs/enforce-default-message
                      defaultMessage={this.props.textReservationSmsOptIn}
                      values={{
                        i: chunks => <i>{chunks}</i>,
                      }}
                    />
                  </IntlProvider>
                </AccessibleSpanComponent>
                {displayReservationSmsOptInPolicyInfo && (
                  <AccessibleIComponent
                    style={styles.infoIcon}
                    className="fa fa-info-circle fa-fw"
                    onClick={this.props.toggleReservationSmsOptInPolicyDisplay}
                    aria-label="SMS opt-in policy info"
                  />
                )}
              </div>
            )}

            {displayTailoredCommunicationOptInButton && (
              <div
                style={{
                  ...styles.policy,
                  clear: 'both',
                  display: 'table',
                }}
              >
                {this.getCheckBoxElement('agreedToTailoredCommunicationOptIn')}
                <AccessibleSpanComponent
                  style={{
                    ...styles.policyLine,
                    width: '78%',
                    height: 'auto',
                  }}
                  onClick={onTailoredCommunicationOptInClick}
                >
                  <IntlProvider locale="en">
                    <FormattedMessage
                      id="textTailoredCommunicationOptInLabel"
                      // eslint-disable-next-line formatjs/enforce-default-message
                      defaultMessage={this.props.textTailoredCommunicationOptInLabel}
                    />
                  </IntlProvider>
                </AccessibleSpanComponent>
                <AccessibleIComponent
                  style={styles.infoIcon}
                  className="fa fa-info-circle fa-fw"
                  onClick={this.props.toggleTailoredCommunicationOptInDisplay}
                  aria-label="Tailored Communication opt-in policy info"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { isMultiInventoryTypeEnabled } = state.featureFlags
  const selectedInventoryItems = getSelectedInventoryCart(state).map(selectedInventoryItem => {
    const inventory = state.entities.inventory.get(selectedInventoryItem.inventoryId).toJS()
    return {
      ...selectedInventoryItem,
      inventoryName: inventory.inventoryName,
      minPrice: inventory.minPrice,
      entryPerReservation: inventory.entryPerReservation,
      compText: inventory.compText,
    }
  })

  // Only used for single-inventory
  const { inventoryId, quantity, taxPercent, chargeTax, serviceChargePercent, additionalFeeTaxPercent } = selectedInventoryItems[0]

  const { basePrice, serviceCharge, tax, tip, subTotal, additionalFeeAmount, additionalFeeTaxAmount, discount, total } =
    calculateSelectedInventoryPrices(state)

  const { eventId, date } = state.userSelection.toJS()
  const currInventory = state.entities.inventory.get(inventoryId)
  const [year, month, day] = date.split('-')
  const formattedTime = getFormattedTime(state.entities.events.get(eventId).get('eventStartTime'))
  const tipPercent = currInventory.get('gratuityAmount')
  const { currencyCode } = state.venueInfo
  const entryPerReservation = currInventory.get('entryPerReservation')
  const chargeGratuity = currInventory.get('chargeGratuity')
  const chargeGratuityType = currInventory.get('gratuityAmountType')
  const chargeAdditionalFee = currInventory.get('chargeAdditionalFee')
  const chargeAdditionalFeeTax = currInventory.get('chargeAdditionalFeeTax')
  const additionalFee = currInventory.get('additionalFee')
  const chargeProcessing = currInventory.get('adminFeeType') === 'CONSUMER'
  const processingFee = state.venueInfo.eventWidgetProcessingFee
  const entryPer = currInventory.get('entryPerReservation')
  const eventLabel = state.entities.events.get(eventId).get('name')

  const hasRequestType = selectedInventoryItems.some(({ inventoryType }) => inventoryType === REQUEST_TYPE)
  const hasGuestlistFreeType = selectedInventoryItems.some(({ inventoryType }) => inventoryType === GUESTLIST_FREE_TYPE)
  const hasReservationType = selectedInventoryItems.some(({ inventoryType }) => inventoryType === RESERVATION_TYPE)

  const dietaryRestrictionsId = state.entities.tags.getIn(['clientTagGroups', 'dietaryPreference'])
  const dietaryRestrictions = state.entities.tags.getIn(['tagGroups', dietaryRestrictionsId])
  const dietaryRestrictionsGuestId = state.entities.tags.getIn(['reservationTagGroups', 'dietaryPreference'])
  const dietaryRestrictionsGuest = state.entities.tags.getIn(['tagGroups', dietaryRestrictionsGuestId])
  const specialOccasionsId = state.entities.tags.getIn(['reservationTagGroups', 'specialOccasion'])
  const specialOccasions = state.entities.tags.getIn(['tagGroups', specialOccasionsId])

  const languageStrings = selectLanguageStrings(state)

  let tagTranslations = !_.isEmpty(state.languages.selectedLanguage)
    ? state.languages.tagLanguageStrings[state.languages.selectedLanguage]
    : {}
  tagTranslations = _.isNil(tagTranslations) ? {} : tagTranslations

  const venueGroupMarketingPolicy = CheckoutPolicySelector.selectVenueGroupMarketingPolicy(state)
  const displayVenueGroupMarketingPolicyInfo = venueGroupMarketingPolicy && !_.isEmpty(venueGroupMarketingPolicy.trim())
  const venueSpecificMarketingPolicy = CheckoutPolicySelector.selectVenueSpecificMarketingPolicy(state)
  const displayVenueSpecificMarketingPolicyInfo = venueSpecificMarketingPolicy && !_.isEmpty(venueSpecificMarketingPolicy.trim())
  const venueSmsMarketingPolicy = CheckoutPolicySelector.selectVenueSmsMarketingPolicy(state)
  const displayVenueSmsMarketingPolicyInfo = venueSmsMarketingPolicy && !_.isEmpty(venueSmsMarketingPolicy)
  const reservationSmsOptInPolicy = CheckoutPolicySelector.selectReservationSmsOptInPolicy(state)
  const displayReservationSmsOptInPolicyInfo = reservationSmsOptInPolicy && !_.isEmpty(reservationSmsOptInPolicy.trim())
  const bookingPolicy = CheckoutPolicySelector.selectBookingPolicy(state)
  const displayBookingPolicyInfo = bookingPolicy && !_.isEmpty(bookingPolicy.trim())

  const promoCode = state.formFields.get('promoCode')
  const isFocusedPromoCode = state.formFields.get('isFocusedPromoCode')
  const validPromoCode = state.formFields.get('validPromoCode')
  const isVerifyingPromoCode = state.formFields.get('isVerifyingPromoCode')

  return {
    venueName: state.venueInfo.name,
    day,
    month,
    year,
    formattedTime,
    quantity,
    currencyCode,
    taxPercent,
    chargeTax,
    tax,
    basePrice,
    discount,
    subTotal,
    total,
    additionalFeeAmount,
    additionalFeeTaxAmount,
    additionalFeeTaxPercent,
    applyServiceCharge: currInventory.get('applyServiceCharge'),
    serviceCharge,
    serviceChargePercent,
    chargeGratuity,
    chargeGratuityType,
    chargeAdditionalFee,
    chargeAdditionalFeeTax,
    additionalFee,
    chargeProcessing,
    processingFee,
    eventLabel,
    entryPerReservation,
    minPrice: currInventory.get('minPrice'),
    tipPercent,
    tip,
    type: currInventory.get('inventoryName'),
    price: currInventory.get('price'),
    compText: currInventory.get('compText'),
    fontsColorPrimary: state.widgetSettings.fontsColorPrimary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    fontsColorCheckoutActive: state.widgetSettings.fontsColorCheckoutActive,
    fontsColorCheckoutInactive: state.widgetSettings.fontsColorCheckoutInactive,
    colorCheckoutCellBackground: state.widgetSettings.colorCheckoutBackground,
    colorLines: state.widgetSettings.colorLines,
    colorPrimary: state.widgetSettings.colorPrimary,
    agreedToBookingPolicy: state.formFields.get('agreedToBookingPolicy'),
    bookingPolicyStatus: state.formFields.get('bookingPolicyStatus'),
    agePolicyStatus: state.formFields.get('agePolicyStatus'),
    agreedToVenueGroupMarketingOptIn: state.formFields.get('agreedToVenueGroupMarketingOptIn'),
    agreedToVenueSpecificMarketingOptIn: state.formFields.get('agreedToVenueSpecificMarketingOptIn'),
    agreedToVenueSmsMarketingOptIn: state.formFields.get('agreedToVenueSmsMarketingOptIn'),
    agreedToReservationSmsOptIn: state.formFields.get('agreedToReservationSmsOptIn'),
    agreedToAboveAgeConsentOn: state.formFields.get('agreedToAboveAgeConsentOn'),
    agreedToTailoredCommunicationOptIn: state.formFields.get('agreedToTailoredCommunicationOptIn'),
    displayVenueGroupMarketingOptInButton: state.formFields.get('displayVenueGroupMarketingOptInButton'),
    displayVenueSpecificMarketingOptInButton: state.formFields.get('displayVenueSpecificMarketingOptInButton'),
    displayVenueSmsMarketingOptInButton: state.formFields.get('displayVenueSmsMarketingOptInButton'),
    displayReservationSmsOptInButton: state.formFields.get('displayReservationSmsOptInButton'),
    displayTailoredCommunicationOptInButton: state.formFields.get('displayTailoredCommunicationOptInButton'),
    displayVenueGroupMarketingOptInPolicy: state.formFields.get('displayVenueGroupMarketingOptInPolicy'),
    displayVenueSpecificMarketingOptInPolicy: state.formFields.get('displayVenueSpecificMarketingOptInPolicy'),
    displayVenueSmsMarketingOptInPolicy: state.formFields.get('displayVenueSmsMarketingOptInPolicy'),
    displayAboveAgeConsentPolicy: state.formFields.get('displayAboveAgeConsentPolicy'),
    ageToConsent: state.formFields.get('ageToConsent'),
    textCancellationPolicy: state.widgetSettings.textCancellationPolicy,
    textVenueGroupMarketingOptIn: state.formFields.get('textVenueGroupMarketingOptIn'),
    textVenueSpecificMarketingOptIn: state.formFields.get('textVenueSpecificMarketingOptIn'),
    textVenueSmsMarketingOptIn: state.formFields.get('textVenueSmsMarketingOptIn'),
    formErrors: state.formFields.get('formErrors').toJS(),
    textReservationSmsOptIn: state.formFields.get('textReservationSmsOptIn'),
    textAddButtonLabel: languageStrings.textAddButtonLabel,
    textSpecialOccasion: languageStrings.textSpecialOccasion,
    textTagLabelYourGuests: languageStrings.textTagLabelYourGuests,
    textTagLabelYours: languageStrings.textTagLabelYours,
    textDietaryRestrictions: languageStrings.textDietaryRestrictions,
    textServiceFee: state.widgetSettings.textServiceFee,
    textTailoredCommunicationOptInLabel: languageStrings.textTailoredCommunicationOptInLabel,
    dietaryRestrictions:
      parseBoolean(state.widgetSettings.enableDietaryRestrictions) && dietaryRestrictions ? dietaryRestrictions.toJS() : null,
    dietaryRestrictionsGuest:
      parseBoolean(state.widgetSettings.enableDietaryRestrictions) && dietaryRestrictionsGuest ? dietaryRestrictionsGuest.toJS() : null,
    specialOccasions: parseBoolean(state.widgetSettings.enableSpecialOccasions) && specialOccasions ? specialOccasions.toJS() : null,
    displayMap: parseBoolean(state.widgetSettings.displayMap),
    tagTranslations,
    displayVenueGroupMarketingPolicyInfo,
    displayVenueSpecificMarketingPolicyInfo,
    displayVenueSmsMarketingPolicyInfo,
    displayReservationSmsOptInPolicyInfo,
    displayBookingPolicyInfo,
    customTip: state.formFields.get('customTip'),
    defaultGratuity: state.venueInfo.venueDefaultGratuity,
    hasRequestType,
    hasGuestlistFreeType,
    hasReservationType,
    entryPer,
    isVerifyingPromoCode,
    validPromoCode,
    isFocusedPromoCode,
    promoCode,
    promoCodeEntity: state.entities.promoCode,
    enablePromocodes: currInventory.get('enablePromocodes'),
    textMinimumSpend: state.widgetSettings.textMinimumSpend,
    isMultiInventoryTypeEnabled,
    selectedInventoryItems,
  }
}

const mapDispatchToProps = dispatch => ({
  onBookingPolicyClick: () => {
    dispatch(toggleBookingAgreement())
  },
  onVenueGroupMarketingOptInClick: () => {
    dispatch(toggleVenueGroupMarketingOptIn())
  },
  onVenueSpecificMarketingOptInClick: () => {
    dispatch(toggleVenueSpecificMarketingOptIn())
  },
  onVenueSmsMarketingOptInClick: () => {
    dispatch(toggleVenueSmsMarketingOptIn())
  },
  onReservationSmsOptInClick: () => {
    dispatch(toggleReservationSmsOptIn())
  },
  onTailoredCommunicationOptInClick: () => {
    dispatch(toggleTailoredCommunicationOptIn())
  },
  onAboveAgeConsentClick: () => {
    dispatch(toggleAboveAgeConsent())
  },
  toggleBookingPolicyDisplay: () => {
    dispatch(toggleBookingPolicyDisplay())
  },
  toggleModalDisplay: modal => {
    dispatch(toggleModalDisplay(modal))
  },
  toggleVenueGroupMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueGroupMarketingOptInPolicyDisplay())
  },
  toggleVenueSpecificMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueSpecificMarketingOptInPolicyDisplay())
  },
  toggleVenueSmsMarketingOptInPolicyDisplay: () => {
    dispatch(toggleVenueSmsMarketingOptInPolicyDisplay())
  },
  toggleReservationSmsOptInPolicyDisplay: () => {
    dispatch(toggleReservationSmsOptInPolicyDisplay())
  },
  toggleTailoredCommunicationOptInDisplay: () => {
    dispatch(toggleTailoredCommunicationOptInDisplay())
  },
  updateCustomTipAmount: e => {
    dispatch(updateCustomTipAmount(e))
  },
  verifyPromoCode: () => {
    dispatch(verifyPromoCode())
  },
  removePromoCode: () => {
    dispatch(removePromoCode())
  },
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSummary)

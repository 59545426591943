import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ListIcon from '@mui/icons-material/List'
import React, { useCallback } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { ToggleButtonProps } from '@sevenrooms/react-components'
import { MenuItem } from '@sevenrooms/react-components/components/MenuItem'
import { Select, type SelectChangeEvent } from '@sevenrooms/react-components/components/Select'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ToggleButton } from '@sevenrooms/react-components/components/ToggleButton'
import { ToggleButtonGroup } from '@sevenrooms/react-components/components/ToggleButtonGroup'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'
import { AccessDatePicker } from './AccessDatePicker'

interface FilledToggleButtonProps extends ToggleButtonProps {
  'data-test'?: string
}

function FilledToggleButton({ value, children, ...props }: FilledToggleButtonProps) {
  return (
    <ToggleButton
      value={value}
      size="small"
      data-test={props['data-test']}
      sx={{
        width: '42px',
        backgroundColor: 'background.paper',
        color: 'text.primary',
        '&.Mui-selected': {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            cursor: 'default',
          },
        },
        '&.MuiToggleButtonGroup-lastButton': {
          m: 0,
        },
      }}
    >
      {children}
    </ToggleButton>
  )
}

function ActionsBar() {
  const [{ view, range }, setParams] = useAccessRulesUrlParams()
  const { formatMessage } = useLocales()

  const handleViewToggle = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: AccessRuleView) => {
      if (newView === AccessRuleView.CALENDAR && range === AccessRuleRange.OVERVIEW) {
        setParams({ view: newView, range: AccessRuleRange.WEEK })
      } else if (newView === AccessRuleView.LIST && range === AccessRuleRange.WEEK) {
        setParams({ view: newView, range: AccessRuleRange.OVERVIEW })
      } else {
        setParams({ view: newView })
      }
    },
    [range, setParams]
  )

  const handleRangeToggle = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newRange = event.target.value as AccessRuleRange
      if (newRange === AccessRuleRange.WEEK && view === AccessRuleView.LIST) {
        setParams({ view: AccessRuleView.CALENDAR, range: newRange })
      } else if (newRange === AccessRuleRange.OVERVIEW && view === AccessRuleView.CALENDAR) {
        setParams({ view: AccessRuleView.LIST, range: newRange })
      } else {
        setParams({ range: newRange })
      }
    },
    [view, setParams]
  )

  return (
    <Stack
      id="access-rules-actions-bar"
      direction="row"
      spacing={2}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack direction="row">
        <AccessDatePicker />
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <ToggleButtonGroup
          value={view}
          id="cal-list-switch"
          data-test="cal-list-switch"
          aria-label="Calendar/List Switch"
          exclusive
          color="primary"
          size="small"
          onChange={handleViewToggle}
          sx={{
            height: '36px',
            '& .MuiToggleButtonGroup-grouped': {
              minWidth: '42px',
              height: '36px',
            },
          }}
        >
          <FilledToggleButton size="small" value={AccessRuleView.CALENDAR} data-test="btn-cal">
            <CalendarMonthIcon fontSize="small" />
          </FilledToggleButton>
          <FilledToggleButton size="small" value={AccessRuleView.LIST} data-test="btn-list">
            <ListIcon fontSize="small" />
          </FilledToggleButton>
        </ToggleButtonGroup>
        <Select
          id="range-select"
          value={range}
          onChange={handleRangeToggle}
          displayEmpty
          sx={{
            height: '36px',
            width: '123px',
            minWidth: '123px',
            maxWidth: '123px',
            padding: 0,
            textAlign: 'center',
            '& .MuiSelect-select': {
              padding: '8px 12px',
              boxSizing: 'border-box',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
          }}
        >
          <MenuItem value={AccessRuleRange.DAY}>{formatMessage(accessRulesSettingsMessages.day)}</MenuItem>
          <MenuItem value={AccessRuleRange.WEEK}>{formatMessage(accessRulesSettingsMessages.week)}</MenuItem>
          <MenuItem value={AccessRuleRange.OVERVIEW}>{formatMessage(accessRulesSettingsMessages.overview)}</MenuItem>
        </Select>
      </Stack>
    </Stack>
  )
}

export { ActionsBar }

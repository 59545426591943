import { useMemo } from 'react'
import type { AccessRules, ShiftsByDate } from '@sevenrooms/core/domain'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { MultiDayScheduleGrid } from '../MultiDayScheduleGrid/MultiDayScheduleGrid'
import { AccessRuleBlock, type AccessRuleProps } from './AccessRuleBlock'
import { ShiftBlock, type ShiftBlockProps } from './ShiftBlock'

type ShiftData = Omit<ShiftBlockProps, 'column' | 'sortOrderOffset'>

function getShiftsBlocks(shiftsByDate: ShiftsByDate) {
  const shiftData = Object.entries(shiftsByDate).map(([date, shifts]) => ({ date: DateOnly.from(date), shifts }))

  const result: ShiftData[] = []
  for (const [i, { date, shifts }] of shiftData.entries()) {
    const previousDay = i > 0 ? shiftData.at(i - 1) : undefined
    const nextDay = i < shiftData.length ? shiftData.at(i + 1) : undefined

    for (const shift of shifts.values()) {
      if (!shift.startTimeSortOrder || !shift.endTimeSortOrder) {
        continue
      }

      const isLeft = previousDay?.shifts.find(x => x.id === shift.id) === undefined
      const isRight = nextDay?.shifts.find(x => x.id === shift.id) === undefined

      result.push({
        shift,
        date,
        isLeft,
        isRight,
        startSortOrder: shift.startTimeSortOrder,
        endSortOrder: shift.endTimeSortOrder,
      })
    }
  }

  return result
}

function accessRuleSort(a: AccessRuleProps, b: AccessRuleProps) {
  if (a.specificTimeSortOrders && !b.specificTimeSortOrders) {
    return 1
  }

  if (b.specificTimeSortOrders && !a.specificTimeSortOrders) {
    return -1
  }

  if (a.startSortOrder !== b.startSortOrder) {
    return a.startSortOrder - b.startSortOrder
  }

  const nameA = a.rule.name.toUpperCase()
  const nameB = b.rule.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

function getGroupedAccessRules(accessRules: AccessRules, shiftsByDate: ShiftsByDate): AccessRuleProps[][] {
  return Object.entries(accessRules).flatMap(([date, rules]) => {
    const jsDate = new Date(Date.parse(date))
    const dateOnly = DateOnly.fromDate(jsDate)

    const shifts = shiftsByDate[dateOnly.toIso()] ?? []

    const uniqueSpecificTimes = new Set<string>()

    const rulesWithSortOrders = rules
      .flatMap(rule => {
        const ruleData = { rule, date: dateOnly }

        const matchingShifts = shifts
          .filter(
            shift =>
              (!rule.restrictToShifts && rule.accessTimeType !== 'ALL') ||
              (shift.shiftCategory && rule.shiftCategories.includes(shift.shiftCategory))
          )
          .filter(shift => shift.startTimeSortOrder && shift.endTimeSortOrder)

        if (rule.accessTimeType === 'ALL') {
          return matchingShifts.map(shift => ({
            ...ruleData,
            startSortOrder: shift.startTimeSortOrder ?? 0,
            endSortOrder: shift.endTimeSortOrder ?? 0,
            shifts: [shift],
          }))
        }

        if (rule.accessTimeType === 'SPECIFIC' && rule.specificTimeSortOrders && !uniqueSpecificTimes.has(rule.id + dateOnly.toIso())) {
          uniqueSpecificTimes.add(rule.id + dateOnly.toIso())
          return {
            ...ruleData,
            startSortOrder: Math.min(...rule.specificTimeSortOrders),
            endSortOrder: Math.max(...rule.specificTimeSortOrders) + 1,
            specificTime: rule.startTimeSortOrder,
            specificTimeSortOrders: rule.specificTimeSortOrders,
            shifts: matchingShifts,
          }
        }

        if (rule.accessTimeType === 'TIME_RANGE' && rule.startTimeSortOrder !== undefined && rule.endTimeSortOrder !== undefined) {
          return { ...ruleData, startSortOrder: rule.startTimeSortOrder, endSortOrder: rule.endTimeSortOrder, shifts: matchingShifts }
        }

        return []
      })
      .sort((a, b) => a.startSortOrder - b.startSortOrder)

    const overlapGroups: AccessRuleProps[][] = []
    for (const accessRuleData of rulesWithSortOrders) {
      const previousGroup = overlapGroups.at(-1)

      if (
        previousGroup &&
        (previousGroup.at(-1)?.endSortOrder ?? -1) >= accessRuleData.startSortOrder &&
        (previousGroup.at(0)?.endSortOrder ?? -1) >= accessRuleData.startSortOrder
      ) {
        previousGroup.push(accessRuleData)
      } else {
        overlapGroups.push([accessRuleData])
      }
    }

    return overlapGroups
  })
}

interface AccessRulesScheduleGridProps {
  dates: DateOnly[]
  focusDate?: DateOnly
  venueStartOfDayHour: number
  shifts?: ShiftsByDate
  accessRules?: AccessRules
  onClickDayHeader: (date: DateOnly) => void
}

export function AccessRulesScheduleGrid({
  dates,
  focusDate,
  venueStartOfDayHour,
  shifts,
  accessRules,
  onClickDayHeader,
}: AccessRulesScheduleGridProps) {
  const getColumnIndex = (date: DateOnly) => (focusDate ? 1 : dates.findIndex(x => date.isEqualTo(x)) + 1)

  const { shiftsData, accessRuleData } = useMemo(() => {
    if (!shifts || !accessRules) {
      return {}
    }

    const shiftsData = shifts ? getShiftsBlocks(shifts) : []
    const overlapGroups = getGroupedAccessRules(accessRules, shifts)
    const accessRuleData = overlapGroups.flatMap(group =>
      group
        .sort(accessRuleSort)
        .map((accessRuleData, index, { length }) => ({ ...accessRuleData, overlapIndex: index, overlapCount: length }))
    )

    return { shiftsData, accessRuleData }
  }, [shifts, accessRules])

  const shiftMinSortOrder = shiftsData?.map(x => x.shift.startTimeSortOrder ?? Number.MAX_VALUE) ?? []
  const accessRuleMinSortOrder = accessRuleData?.map(x => x.startSortOrder) ?? []
  const minSortOrder = Math.min(...shiftMinSortOrder, ...accessRuleMinSortOrder)
  const maxSortOrder = Math.max(
    ...(shiftsData?.map(x => x.shift.endTimeSortOrder ?? Number.MAX_VALUE) ?? []),
    ...(accessRuleData?.map(x => x.endSortOrder) ?? [])
  )
  const startHour = Math.floor(venueStartOfDayHour + minSortOrder / 4)
  const startHourOffset = startHour - venueStartOfDayHour
  const sortOrderOffset = startHourOffset * 4
  const hourSpan = Math.ceil(maxSortOrder / 4) - startHourOffset

  const displayShifts = focusDate ? shiftsData?.filter(x => x.date.isEqualTo(focusDate)) : shiftsData

  return (
    <MultiDayScheduleGrid
      startHour={startHour}
      hourSpan={hourSpan ?? 24}
      dates={dates}
      onClickDayHeader={onClickDayHeader}
      rowMinHeight={44}
      minWidth={600}
    >
      {displayShifts?.map(props => (
        <ShiftBlock
          key={`shift-${props.date.toIso()}-${props.shift.name}`}
          column={getColumnIndex(props.date)}
          sortOrderOffset={sortOrderOffset}
          {...props}
        />
      ))}
      {accessRuleData?.map(props => (
        <AccessRuleBlock
          key={`rule-${props.date.toIso()}-${props.rule.id}-${props.startSortOrder}-${props.endSortOrder}`}
          column={getColumnIndex(props.date)}
          overlap={!focusDate}
          venueStartOfDayHour={venueStartOfDayHour}
          sortOrderOffset={sortOrderOffset}
          {...props}
        />
      ))}
    </MultiDayScheduleGrid>
  )
}

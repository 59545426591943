import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface SeatingAreasFieldProps {
  seatingAreas: string[]
  tables?: string[]
  isFieldShift: boolean
}

function SeatingAreasField({ seatingAreas, tables, isFieldShift }: SeatingAreasFieldProps) {
  const { formatMessage } = useLocales()
  let displayText = formatMessage(accessRulesListMessages.allSeatingAreas)

  if (seatingAreas && seatingAreas.length > 0) {
    displayText = seatingAreas.join(', ')
    if (tables && tables.length > 0) {
      displayText += `, ${tables.join(', ')}`
    }
  } else if (tables && tables.length > 0) {
    displayText = tables.join(', ')
  }

  return (
    <Box>
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
        {displayText}
      </Typography>
    </Box>
  )
}

export { SeatingAreasField }

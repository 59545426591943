import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import WarningIcon from '@mui/icons-material/Warning'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  type GridRowsProp,
  type GridRowProps,
  type GridColDef,
  type GridRenderCellParams,
  type GridSortModel,
  GridRow,
  DataGrid,
} from '@mui/x-data-grid'
import { useState, useCallback, useMemo, useEffect } from 'react'
import type { AccessRule } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { useParentWidth } from '../../hooks/useParentWidth'
import { accessRulesListMessages } from '../../locales'
import { getCategoryName } from '../../utils/accessRulesListUtils'
import { sortChildRows } from './customColumnSort'
import type { AccessRuleRow } from '../../views/AccessRulesList/AccessRulesList'

interface GroupedAccessListProps {
  columns: GridColDef[]
  rows: GridRowsProp
  groupBy: string
  groupByColumnMappings: GridColDef[]
  accessRulesMap: Record<string, AccessRule>
  sortModel: GridSortModel // sortModel is controlled outside because of the hacky way we are rendering the row groupings
  setSortModel: (sortModel: GridSortModel) => void
}

export interface GroupRow extends Partial<AccessRuleRow> {
  id: string
  isGroup: boolean
}

function AccessRulesDataGrid({
  columns: propColumns,
  rows: propRows,
  groupBy,
  groupByColumnMappings,
  accessRulesMap,
  sortModel,
  setSortModel,
}: GroupedAccessListProps) {
  const { formatMessage } = useLocales()
  const showAccessRuleSlideout = useAccessRuleSlideout()
  const [parentRef, parentWidth] = useParentWidth()

  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({})
  const [sortedChildRows, setSortedChildRows] = useState<Record<string, GroupRow[]>>({})

  const theme = useTheme()
  const grey100 = theme.palette.grey[100]
  const grey300 = theme.palette.grey[300]

  const getTogglableColumns = useMemo(
    () => (columns: GridColDef[]) => {
      const hiddenFields = ['shiftCategory', 'overviewCategory']
      return columns.filter(column => !hiddenFields.includes(column.field)).map(column => column.field)
    },
    []
  )

  // Memoized columns with the group column
  const columns = useMemo(() => {
    const colFieldProps = groupByColumnMappings.find(col => col.field === groupBy)
    const groupColumn: GridColDef = {
      field: groupBy,
      headerName: colFieldProps?.headerName ?? groupBy.charAt(0).toUpperCase() + groupBy.slice(1),
      width: colFieldProps?.width ?? 150,
      sortable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isGroup ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {expandedGroups[params.row.id] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            {params.row.overviewCategory === 'UNASSIGNED' ||
              (params.row.shiftCategory === 'NONE' && <WarningIcon color="warning" sx={{ marginRight: '5px' }} />)}
            {`${getCategoryName(params.row[groupBy], formatMessage)} (${sortedChildRows[params.row[groupBy]]?.length || 0})`}
          </Box>
        ) : null,
    }

    const updatedColumns = propColumns.filter(col => col.field !== groupBy)
    return [groupColumn, ...updatedColumns]
  }, [expandedGroups, formatMessage, groupBy, groupByColumnMappings, propColumns, sortedChildRows])

  useEffect(() => {
    // Recompute the sorted child rows based on the default sort order
    let initialSortedChildRows: Record<string, GroupRow[]> = {}
    const initialExpandedGroups: Record<string, boolean> = {}

    propRows.forEach(row => {
      if (!(row as GroupRow).isGroup) {
        const groupValue = row[groupBy]
        if (!initialSortedChildRows[groupValue]) {
          initialSortedChildRows[groupValue] = []
          // Mark the group as expanded by default
          initialExpandedGroups[`group-${groupValue}`] = true
        }
        initialSortedChildRows[groupValue]?.push(row as GroupRow)
      }
    })

    initialSortedChildRows = sortChildRows(sortModel, initialSortedChildRows)

    setSortedChildRows(initialSortedChildRows)
    setExpandedGroups(initialExpandedGroups)
  }, [propRows, groupBy, sortModel])

  // Memoized rows with grouping
  const rows = useMemo(() => {
    const groupedRows: Record<string, GroupRow> = {}
    propRows.forEach(row => {
      const groupValue = row[groupBy]
      if (!groupedRows[groupValue]) {
        groupedRows[groupValue] = {
          id: `group-${groupValue}`,
          [groupBy]: groupValue,
          isGroup: true,
        }
      }
    })

    const rowsWithGroups: GroupRow[] = []
    Object.values(groupedRows).forEach(groupRow => {
      rowsWithGroups.push(groupRow)
      const groupValue = groupRow[groupBy as keyof GroupRow]
      if (expandedGroups[groupRow.id] && typeof groupValue === 'string') {
        rowsWithGroups.push(...(sortedChildRows[groupValue] || []))
      }
    })
    return rowsWithGroups
  }, [expandedGroups, groupBy, propRows, sortedChildRows])

  // Toggle group expanded state
  const toggleGroup = useCallback((groupId: string) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }, [])

  // Handle sort model change
  const handleSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel)

      const newSortedChildRows = sortChildRows(newSortModel, sortedChildRows)

      setSortedChildRows(newSortedChildRows)
    },
    [setSortModel, sortedChildRows]
  )

  // Handle group row click
  const handleGroupRowClick = useCallback(
    (params: { row: GroupRow }) => {
      if (params.row.isGroup) {
        toggleGroup(params.row.id)
      } else if (params.row.accessId) {
        const ar = accessRulesMap[params.row.accessId]
        if (!ar) {
          throw new Error('Access rule not found')
        }
        showAccessRuleSlideout({
          accessRule: ar,
          mode: undefined,
        })
      }
    },
    [accessRulesMap, showAccessRuleSlideout, toggleGroup]
  )

  return (
    <Box
      ref={parentRef}
      sx={{
        // set pixel width dynamically. DataGrid is weird and causes layout issues if its parent is not a fixed width
        width: `${parentWidth}px`,
        height: '100%',
      }}
    >
      <DataGrid
        disableColumnSelector={false}
        disableColumnResize
        slots={{
          toolbar: () => (
            <Box id="datagrid-toolbar" sx={{ height: '66px' }}>
              <GridToolbarContainer sx={{ height: '66px' }}>
                <GridToolbarColumnsButton />
              </GridToolbarContainer>
            </Box>
          ),
          row: (props: GridRowProps) => {
            if (props.row.isGroup) {
              return <GridRow {...props} />
            }
            return <GridRow {...props} data-id={props.row.accessId} data-persistent-id={props.row.persistentId} />
          },
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'rule', sort: 'asc' }],
          },
        }}
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        sortingMode="server"
        sortModel={sortModel}
        getRowClassName={params => {
          const classes = []
          if (params.row.isGroup) {
            classes.push('group-header')
            if (params.row.overviewCategory === 'UNASSIGNED' || params.row.shiftCategory === 'NONE') {
              classes.push('group-header-unassigned')
            }
          }
          return classes.join(' ')
        }}
        sortingOrder={['asc', 'desc']}
        onSortModelChange={handleSortModelChange}
        onRowClick={handleGroupRowClick}
        isRowSelectable={() => false}
        hideFooter
        getRowHeight={params => {
          if (params.model.isGroup) {
            return 37.5
          }
          return 'auto'
        }}
        sx={{
          height: '100%',
          mx: 0,
          // Sticky first Column
          '& .MuiDataGrid-columnHeader:nth-of-type(2), & .MuiDataGrid-cell:nth-of-type(2)': {
            position: 'sticky',
            left: 0,
            zIndex: 100,
            backgroundColor: 'inherit',
          },
          // Sticky Second Column (Cells Only, Exclude Group Headers)
          '& .MuiDataGrid-row:not(.group-header) .MuiDataGrid-cell:nth-of-type(3)': {
            position: 'sticky',
            left: '150px', // Offset by the width of the first column
            zIndex: 100,
            backgroundColor: 'inherit',
            borderRight: '1px solid #e0e0e0',
          },
          // Sticky Second Column Header (No Border)
          '& .MuiDataGrid-columnHeader:nth-of-type(3)': {
            position: 'sticky',
            left: '150px',
            zIndex: 100,
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-row': {
            backgroundColor: 'common.white',
            // Group header rows
            '&.group-header': {
              backgroundColor: 'grey.100',
              fontWeight: 'bold',
              cursor: 'pointer',
            },
          },
          '& .MuiDataGrid-row.group-header-unassigned': {
            background: `linear-gradient(
          45deg,
          ${grey300} 10%, ${grey100} 10%, ${grey100} 50%, ${grey300} 50%, ${grey300} 60%, ${grey100} 60%, ${grey100} 100%
        )`,
            backgroundSize: '7.07px 7.07px',
          },
          '& .MuiDataGrid-row:not(.group-header):hover': {
            cursor: 'pointer',
            backgroundColor: 'common.white',
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus-visible':
            {
              outline: 'none !important',
            },
          '& .MuiDataGrid-row:hover .MuiDataGrid-cell': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell': {
            py: 2,
          },
        }}
      />
    </Box>
  )
}

function CustomNoRowsOverlay() {
  const { formatMessage } = useLocales()
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      {formatMessage(accessRulesListMessages.noAccessRules)}
    </Box>
  )
}

export { AccessRulesDataGrid }

/**
 */
var modules = modules || {}
modules.accessrulesWeekViewManager = (function () {
  var instantiated,
    venue_name,
    media_url,
    id,
    payment_setup,
    tax_rate,
    tax_groups,
    currency_symbol,
    venue_policy,
    venue_cancellation_policy,
    start_of_day_hour,
    can_save_card,
    tag_groups,
    experiences,
    venueSettings,
    can_access_rule_bulk_copy,
    audience_hierarchy,
    rules,
    shifts,
    seatingarea_tables,
    cellSize = 6,
    venue_default_booking_policy_id,
    venue_default_cancellation_policy_id,
    bulk_copy_in_progress,
    venue_group_id

  var upsells = {}
  var upsellsToInventory = {}
  var helpers = {
    backgroundOverlayInterval: React.createClass({
      onClick: function (e) {
        // Only create slideout if the date is future date
        var currentTarget = $(e.currentTarget).closest('.fraction'),
          start_time_parts = modules.weekViewManager.getHourFraction($(e.currentTarget).attr('data-starttime')),
          end_time_parts = modules.weekViewManager.getHourFraction($(e.currentTarget).attr('data-endtime')),
          intervalHours = end_time_parts.hour - start_time_parts.hour + (end_time_parts.fraction - start_time_parts.fraction) / 4,
          adjustedIntervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours
        // Remove all resizables
        $('.ui-resizable').remove()
        helpers.createResizable(currentTarget, adjustedIntervalHours, undefined, $(e.currentTarget).attr('data-category'))
        var targetOverlay = $(currentTarget).find('.overlay_interval').filter(':not([data-id])')
        onClickOverlay(targetOverlay)
      },

      render: function () {
        var calculatedZIndex = 100 + Number(this.props.index ? this.props.index : 0),
          calculatedHeight = this.props.height ? this.props.height : 4 * cellSize - 4,
          isFutureDate = new Date(this.props.day) >= Pmp.Manager.Global._venue_today_date,
          width = this.props.maxWidth + this.props.left >= 100 ? 100 - this.props.left : this.props.maxWidth,
          overlayIntervalCss = {
            position: 'absolute',
            zIndex: calculatedZIndex,
            backgroundColor: isFutureDate ? 'rgba(161, 190, 254, 0.2)' : 'rgba(161, 190, 254, 0.1)',
            right: '1px',
            height: calculatedHeight - 1,
            top: '0px',
            left: '0%',
            borderRadius: 3,
            cursor: isFutureDate ? 'pointer' : undefined,
            padding: '2px',
            overflow: 'hidden',
          }
        return (
          <div
            title={this.props.name + ' (' + this.props.startTime + ' - ' + this.props.endTime + ')'}
            className="backgroundoverlay_interval"
            style={overlayIntervalCss}
            data-starttime={this.props.startTime}
            data-endtime={this.props.endTime}
            data-day={this.props.day}
            data-category={this.props.category}
            onClick={isFutureDate ? this.onClick : undefined}
            data-originalwidth={width}
            data-id={this.props.id}
            data-backgroundcolor={'#A1BEFE'}
            data-zindex={calculatedZIndex}
          ></div>
        )
      },
    }),

    overlayInterval: React.createClass({
      onMouseOver: function (e) {
        $(e.currentTarget).css({
          border: '1px solid lightgrey',
        })
      },

      onMouseOut: function (e) {
        $(e.currentTarget).css({
          border: 1,
        })
      },

      shadeColor: function (color, percent) {
        var R = parseInt(color.substring(1, 3), 16),
          G = parseInt(color.substring(3, 5), 16),
          B = parseInt(color.substring(5, 7), 16)

        R = parseInt((R * (100 + percent)) / 100)
        G = parseInt((G * (100 + percent)) / 100)
        B = parseInt((B * (100 + percent)) / 100)

        R = R < 255 ? R : 255
        G = G < 255 ? G : 255
        B = B < 255 ? B : 255

        var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
        var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
        var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

        return '#' + RR + GG + BB
      },

      onMouseClick: function (e) {
        $(e.currentTarget).css({ 'background-color': '#CEDEFF', zIndex: 250 })
      },

      getDisplayBlock: function (startTime, endTime, name, calculatedHeight) {
        var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime)
        var endTimeDisplay = Pmp.Utils.timeWithLocale(endTime)
        return calculatedHeight >= 20 ? (
          <div>
            <div
              className="time"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                height: 10,
              }}
            >
              {startTimeDisplay + '-' + endTimeDisplay}
            </div>
            <div
              className="name"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                height: 10,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {name}
            </div>
          </div>
        ) : (
          <div>
            <div
              className="time"
              style={{
                width: '100%',
                fontSize: 9,
                textAlign: 'left',
                marginTop: '0%',
                overflow: 'hidden',
                height: 10,
              }}
            >
              <span key="1" style={{ fontWeight: 'bold' }}>
                {startTimeDisplay + ' '}
              </span>
              <span key="2">{name}</span>
            </div>
          </div>
        )
      },

      render: function () {
        var colors = [
            '#BA68C8',
            '#FDD835',
            '#FFF176',
            '#1DE9B6',
            '#B9F6CA',
            '#00BCD4',
            '#80DEEA',
            '#8C9EFF',
            '#A1BEFE',
            '#2C96FF',
            '#6AB8FF',
            '#FF5252',
            '#FF80AB',
            '#B0BEC5',
            '#CE93D8',
            '#EEEEEE',
          ],
          calculatedZIndex = 200 + Number(this.props.index ? this.props.index : 0),
          isPast = new Date(this.props.day) < Pmp.Manager.Global._venue_today_date,
          diffColors = _.difference(colors, _.values(helpers.colorsLookup)),
          colorsOptions = _.size(diffColors) ? diffColors : colors,
          calculatedHeight = this.props.height ? this.props.height : 4 * cellSize - 4,
          selectedColor = helpers.colorsLookup[this.props.id] ? helpers.colorsLookup[this.props.id] : _.sample(colorsOptions),
          width = this.props.maxWidth + this.props.left >= 90 ? 90 - this.props.left : this.props.maxWidth,
          overlayIntervalCss = {
            position: 'absolute',
            zIndex: calculatedZIndex,
            backgroundColor: this.props.isOverride ? selectedColor : this.shadeColor(selectedColor, 25),
            right: '1px',
            height: calculatedHeight - 1,
            top: '0px',
            width: width + '%',
            left: (this.props.left ? this.props.left : 0) + '%',
            padding: '2px',
            opacity: isPast ? 0.6 : 1,
            marginBottom: 1,
            boxShadow: '1px 1px 1px ' + this.shadeColor(selectedColor, -20),
            cursor: 'pointer',
            overflow: 'hidden',
          }
        helpers.colorsLookup[this.props.id] = selectedColor

        return (
          <div
            className="overlay_interval"
            style={overlayIntervalCss}
            data-starttime={this.props.startTime}
            data-endtime={this.props.endTime}
            data-day={this.props.day}
            data-originalwidth={width}
            data-category={this.props.category}
            data-id={this.props.id}
            data-backgroundcolor={this.props.isOverride ? selectedColor : this.shadeColor(selectedColor, 25)}
            data-zindex={calculatedZIndex}
            onMouseOver={this.onMouseOver}
            onResize={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            onClick={this.onMouseClick}
          >
            {this.getDisplayBlock(this.props.startTime, this.props.endTime, this.props.name, calculatedHeight)}
          </div>
        )
      },
    }),

    getNeighboringOverlayIntervals: function (currTarget, direction_down) {
      var currColumnNum = $(currTarget).prevAll('td').size(),
        effectiveRows = []
      if (direction_down) {
        effectiveRows = $(currTarget).closest('tr').nextAll('tr')
      } else {
        effectiveRows = $(currTarget).closest('tr').prevAll('tr')
      }
      return _.flatten(
        _.map(effectiveRows, function (row) {
          var currRow = $(row).find('td')[currColumnNum]
          return $(currRow).find('.overlay_interval')
        })
      )
    },

    // Specific to check if a target div gets overlapping any of the adjacent overlay
    doesOverlap: function (target, adjacentOverlay) {
      if (_.isEmpty(adjacentOverlay)) {
        return false
      }
      var divTop = $(target).offset().top,
        adjacentOverlayTop = $(adjacentOverlay).offset().top,
        adjacentOverlayHeight = adjacentOverlay.offsetHeight
      return divTop > adjacentOverlayTop && divTop < adjacentOverlayTop + adjacentOverlayHeight
    },

    resetResizableOverlays: function (startTime, endTime) {
      var startFractions = modules.weekViewManager.getHourFraction(startTime),
        endFractions = modules.weekViewManager.getHourFraction(endTime),
        calculatedDifference = endFractions.hour - startFractions.hour + (endFractions.fraction - startFractions.fraction) / 4

      calculatedDifference = calculatedDifference < 0 ? calculatedDifference + 24 : calculatedDifference
      var resizables = $('#' + helpers.id).find('.ui-resizable')
      $(resizables).each(function (i, overlay) {
        var selector = [
            "[data-day='",
            $(overlay).data('day'),
            "']",
            "[data-start='",
            startFractions.hour,
            "']",
            "[data-fraction='",
            startFractions.fraction,
            "']",
          ].join(''),
          targetNode = _.first($('#' + helpers.id).find(selector))
        $(overlay).detach().appendTo(targetNode)
        $(overlay).height(calculatedDifference * cellSize * 4 - 6)

        // HACK: Force stop -- there doesn't seem to be any other way
        if (i == resizables.length - 1) {
          var uiResizable = $(overlay).data('uiResizable')
          uiResizable.options.stop(event, uiResizable.ui())
        }
      })
    },

    onAddOverlay: function (id, d, hours, fraction, total_duration, isChecked) {
      if (isChecked) {
        var selector = ["[data-day='", d, "']", "[data-start='", hours, "']", "[data-fraction='", fraction, "']"].join(''),
          targetNode = $('#' + id).find(selector),
          anyResizable = !!$('#' + id)
            .find('.ui-resizable')
            .size(),
          anyNeighborResizable = !!$('#' + id)
            .find('.ui-resizable')
            .filter(":not([data-day='" + d + "'])")
            .size()
        if (targetNode.size() && (!anyResizable || anyNeighborResizable)) {
          helpers.createResizable(targetNode, total_duration)
        } else {
          console.log('invalid target')
        }
      } else {
        targetNode = $("[data-day='" + d + "']").filter('.ui-resizable')
        if (targetNode) {
          React.unmountComponentAtNode(targetNode.parent()[0])
        }
      }
    },

    createResizable: function (currentTarget, hours, name, category) {
      var totalRows = 25,
        day = $(currentTarget).attr('data-day'),
        startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
        endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4)

      $(currentTarget).append("<div data-id=''/>")
      var x = $(currentTarget).find("[data-id='']")

      React.render(
        <helpers.overlayInterval
          name={name}
          index="100"
          category={category}
          startTime={startTime}
          endTime={endTime}
          day={day}
          maxWidth={90}
        />,
        x[0]
      )

      var targetOverlay = $(currentTarget).find('.overlay_interval').filter(':not([data-id])')
      $(targetOverlay).resizable({
        handles: 'n, s',
        grid: [0, cellSize],
        axis: 'y',
        minHeight: cellSize,
        maxHeight: totalRows * cellSize * 4 - 6,
        create: function (event, ui) {
          $(event.target).height(hours * 4 * cellSize - 4)
        },
        resize: _.throttle(
          _.bind(function (event, ui) {
            var currentElement = $(ui.element),
              currentTarget = $(ui.element).closest('.fraction'),
              remainingCells = helpers.getNeighboringOverlayIntervals(currentTarget, true),
              previousCells = helpers.getNeighboringOverlayIntervals(currentTarget, false),
              nextInterval = _.first(
                _.filter(
                  _.map(remainingCells, function (x, k) {
                    return _.first($(x).find('.ui-resizable'))
                  })
                )
              ),
              prevInterval = _.first(
                _.filter(
                  _.map(previousCells, function (x, k) {
                    return _.first($(x).find('.ui-resizable'))
                  })
                )
              )

            // Restrict northward movement
            if (prevInterval && $(currentElement).offset().top < $(prevInterval).offset().top + $(prevInterval).outerHeight()) {
              ui.position.top = ui.originalPosition.top
            }
            // Restrict southward movement
            if (nextInterval && $(currentElement).offset().top + $(currentElement).outerHeight() > $(nextInterval).offset().top) {
              ui.position.top = ui.originalPosition.top
            }
          }, this),
          100
        ),

        stop: function (event, ui) {
          var currentElement = $(ui.element),
            currentTarget = $(ui.element).closest('.fraction'),
            currentStartHour = $(currentTarget).data('start'),
            currentStartFraction = $(currentTarget).data('fraction'),
            fractionFromStart = Math.round($(currentElement).position().top / cellSize),
            totalFractions = Math.round(currentElement.outerHeight() / cellSize),
            startTime = modules.weekViewManager.getDisplayInterval(currentStartHour, currentStartFraction, fractionFromStart),
            endTime = modules.weekViewManager.getDisplayInterval(currentStartHour, currentStartFraction, totalFractions + fractionFromStart)

          // HACK: set the flyout values
          $('[name=start_time]').val(startTime)
          $('[name=end_time]').val(endTime)

          // HACK: Invoke others
          $(currentElement)
            .closest('table')
            .find('.ui-resizable')
            .each(function (index, overlay) {
              helpers.replicateOverlay(overlay, currentElement, startTime, endTime)
            })
        },
      })

      var resizableCss = {
        'background-image': 'url(' + helpers.media_url + 'images/icons/icon-drag.png)',
        'background-size': '23px',
        left: '30%',
        width: '40%',
      }
      $(currentTarget)
        .find('div')
        .find('.ui-resizable-n')
        .css(_.extend({ top: '-2px' }, resizableCss))
      $(currentTarget)
        .find('div')
        .find('.ui-resizable-s')
        .css(_.extend({ bottom: '-2px' }, resizableCss))
    },
    replicateOverlay: function (element, sourceElement, startTime, endTime) {
      var startTimeDisplay = Pmp.Utils.timeWithLocale(startTime),
        endTimeDisplay = Pmp.Utils.timeWithLocale(endTime)
      // Reset the time
      $(element)
        .find('.time')
        .html(startTimeDisplay + ' - ' + endTimeDisplay)
      $(element).height($(sourceElement).height())
      $(element).offset({
        top: $(sourceElement).offset().top,
      })

      // Update data attributes
      $(element).attr('data-starttime', startTime)
      $(element).attr('data-endtime', endTime)
    },
    unMountOverlays: function (targetDiv) {
      // This needs to be done because overlays are mounted not as part of jsx render
      $(targetDiv)
        .find('[data-id]')
        .each(function (i, d) {
          React.unmountComponentAtNode(d)
        })
    },

    createBackgroundOverlay: function (currentTarget, hours, name, category, id, key, maxWidth, left) {
      var day = $(currentTarget).attr('data-day'),
        startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
        endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4)

      $(currentTarget).append('<div data-id=' + id + '/>')
      var x = $(currentTarget).find('[data-id=' + id.replace('.', '\\.') + ']')
      React.render(
        <helpers.backgroundOverlayInterval
          key={id}
          index={key}
          height={cellSize * hours * 4 - 4}
          maxWidth={maxWidth}
          left={left}
          name={name}
          category={category}
          startTime={startTime}
          endTime={endTime}
          day={day}
          id={id}
        />,
        x[0]
      )
    },

    createOverlay: function (currentTarget, hours, name, id, category, key, maxWidth, left, is_override) {
      var day = $(currentTarget).attr('data-day'),
        startTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction')),
        endTime = modules.weekViewManager.getDisplayInterval($(currentTarget).data('start'), $(currentTarget).data('fraction'), hours * 4)

      $(currentTarget).append('<div data-id=' + id + '/>')
      var x = $(currentTarget).find('[data-id=' + id.replace('.', '\\.') + ']')
      React.render(
        <helpers.overlayInterval
          key={id}
          index={key}
          height={cellSize * hours * 4 - 4}
          maxWidth={maxWidth}
          left={left}
          isOverride={is_override}
          name={name}
          startTime={startTime}
          endTime={endTime}
          day={day}
          id={id}
          category={category}
        />,
        x[0]
      )
    },

    getSelectedDays: function (day_of_week) {
      // Adjust mon -> sun => sun -> sat
      var rotated_day_of_week = [].concat(day_of_week)
      rotated_day_of_week.unshift(rotated_day_of_week.pop())
      var days = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']
      return _.map(
        _.reject(
          _.map(rotated_day_of_week, function (k, i) {
            return k ? i : undefined
          }),
          _.isUndefined
        ),
        function (i) {
          return days[i]
        }
      )
    },
    hourIntervalView: React.createClass({
      onClickHandler: function (e) {
        var target = $(e.target).closest('.overlay_interval')
        var isTargetOverlay = $(target).size()
        // If target element is clickable, proceed
        if (isTargetOverlay) {
          this.props.onClickOverlay(target)
          // Ensure that no resizable already exists
        } else {
          console.debug('resizable exists already')
        }
      },

      render: function () {
        var isFutureDate = new Date(this.props.day) >= Pmp.Manager.Global._venue_today_date,
          borderTop = this.props.start == start_of_day_hour && this.props.fraction == '0' ? '1px solid #DADADA' : 0,
          intervalCss = {
            width: '14.28%',
            height: cellSize,
            padding: 0,
            position: 'relative',
            borderTop: borderTop,
            borderBottom: 0,
            borderLeft: '1px solid #DADADA',
            borderRight: 0,
            backgroundColor: isFutureDate ? '#FAFAFA' : '#EAEAEA',
          },
          fractionCss = {
            position: 'relative',
            height: cellSize,
            width: '100%',
          }

        var fractions = _.times(
          4,
          function (fraction) {
            var applicableStyle = _.extend(
              {},
              fractionCss,
              fraction == 3
                ? {
                    borderBottom: '1px dotted #CACACA',
                    height: cellSize - 1,
                  }
                : fraction == 0 && this.props.start == start_of_day_hour
                ? {
                    borderTop: '1px solid #DADADA',
                  }
                : {}
            )
            return (
              <div
                className="fraction"
                style={applicableStyle}
                data-start={this.props.start}
                data-end={this.props.end}
                onClick={this.onClickHandler}
                data-day={this.props.day}
                data-fraction={fraction}
                key={fraction}
              />
            )
          },
          this
        )

        return (
          <td
            style={intervalCss}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
            data-start={this.props.start}
            data-end={this.props.end}
            data-day={this.props.day}
          >
            {fractions}
          </td>
        )
      },
    }),

    LeftMark: React.createClass({
      render: function () {
        var calculatedHeight = -(10 + cellSize)
        var topcellCss = {
            width: 44,
            padding: 0,
            top: calculatedHeight,
            position: 'relative',
            fontSize: 10,
            height: 8,
          },
          tdCss = {
            color: '#C1C1C1',
            border: 0,
          },
          bottomCss = {}

        _.extend(bottomCss, topcellCss, {
          top: 2,
          height: '5px',
        })
        var startInterval = modules.weekViewManager.getDisplayInterval(this.props.start)
        var endInterval = modules.weekViewManager.getDisplayInterval(this.props.end)
        var startTimeDisplay = Pmp.Utils.timeWithLocale(startInterval)
        var endTimeDisplay = Pmp.Utils.timeWithLocale(endInterval)
        return !this.props.is_last ? (
          <td style={tdCss}>
            <div style={topcellCss}>{startTimeDisplay}</div>
          </td>
        ) : (
          <td style={tdCss}>
            <div style={topcellCss}>{startTimeDisplay}</div>
            <div style={bottomCss}>{endTimeDisplay}</div>
          </td>
        )
      },
    }),

    RowView: React.createClass({
      render: function () {
        var intervals = _.map(
            this.props.days,
            function (x, k) {
              return (
                <helpers.hourIntervalView
                  start={this.props.start_interval}
                  isPassedDay={Pmp.Manager.Global._venue_today_date > new Date(x)}
                  end={this.props.end_interval}
                  index={k}
                  key={k}
                  day={x}
                  onClickOverlay={this.props.onClickOverlay}
                  intervals={this.props.intervals}
                />
              )
            },
            this
          ),
          leftMark = (
            <helpers.LeftMark
              start={this.props.start_interval}
              end={this.props.end_interval}
              index={0}
              key={0}
              is_last={this.props.is_last}
            />
          )

        return (
          <tr className="row">
            {leftMark}
            {intervals}
          </tr>
        )
      },
    }),

    bindCalendarBehavior: function (divElement, selectedDate, callback) {
      var jsDate = $.datepicker.parseDate('mm/dd/yy', selectedDate)
      $(divElement).datepicker({
        dateFormat: 'mm/dd/yy',
        minDate: $.datepicker.parseDate('mm/dd/yy', ''),
        closeText: 'Cancel',
        firstDay: 0,
        parentObj: this,
        showButtonPanel: true,

        beforeShow: function () {
          $('#ui-datepicker-div').addClass('customize')
        },
        onSelect: function (dateText, selObj) {
          callback(dateText)
        },
        prependZero: function (num) {
          return ('0' + num).slice(-2)
        },
      })
      $(divElement).datepicker('setDate', jsDate, true)
    },

    calendarView: React.createClass({
      render: function () {
        return (
          <input
            readOnly="true"
            type="text"
            name="date"
            className="date-selector hasDatePicker"
            style={{
              backgroundImage: 'url(' + helpers.media_url + 'images/icons/calendar-negative.png)',
              backgroundSize: 16,
              height: 16,
              backgroundColor: '#A7A7A7',
              width: 16,
              float: 'right',
              margin: '11px 5px 11px 5px',
              color: 'rgba(127, 127, 127, 0)',
              padding: 0,
              cursor: 'pointer',
            }}
          />
        )
      },
    }),

    controlView: React.createClass({
      componentDidMount: function () {
        var $accessRuleBulkCopyBtn = document.getElementById('access-rule-bulk-copy-btn')
        var $accessRuleBulkCopyText = document.getElementById('bulk-copy-text')
        if (bulk_copy_in_progress) {
          $accessRuleBulkCopyBtn.disabled = true
          window.SvrManager.MgrAccessRuleBulkCopy.startBulkCopyStatusPoll(venue_group_id, $accessRuleBulkCopyBtn, $accessRuleBulkCopyText)
        }
      },

      componentDidUpdate: function () {
        const elem = this.getDOMNode().querySelector('.week-view-action-buttons')
        window.SvrManager.MgrAccessRules.renderWeekViewActionButtons(elem, new Date().format('mm/dd/yyyy'), this.props.addSlide)
      },

      getSelectedWeekDisplay: function (dateText) {
        var selectedDate = new Date(dateText),
          saturday = selectedDate.addDays(-selectedDate.getDay()),
          sunday = new Date(saturday)
        saturday.addDays(6)

        var sameYear = sunday.getYear() == saturday.getYear(),
          sameMonth = sunday.getMonth() == saturday.getMonth()
        if (sameMonth) {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            '-',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        } else if (sameYear) {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            '-',
            saturday.toLocaleString('default', { month: 'short' }),
            ' ',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        } else {
          return [
            sunday.toLocaleString('default', { month: 'short' }),
            ' ',
            sunday.getDate(),
            ' ',
            sunday.getFullYear(),
            '-',
            saturday.toLocaleString('default', { month: 'short' }),
            ' ',
            saturday.getDate(),
            ' ',
            saturday.getFullYear(),
          ].join('')
        }
      },

      onThisWeek: function () {
        this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', new Date()))
      },

      onBackOneWeek: function () {
        this.updateDateByDays(-7)
      },

      onForwardOneWeek: function () {
        this.updateDateByDays(7)
      },

      isSameWeekAsCurrentWeek() {
        return new Date(this.props.selectedDate).getWeek() == new Date().getWeek()
      },

      updateDateByDays: function (n) {
        var d = new Date(this.props.selectedDate)
        d.addDays(n)
        this.props.onDateUpdate($.datepicker.formatDate('mm/dd/yy', d))
      },

      displayAccessRuleBulkCopyTool: function () {
        var $accessRuleBulkCopyBtn = document.getElementById('access-rule-bulk-copy-btn')
        if (!$accessRuleBulkCopyBtn.disabled) {
          var slideout = document.getElementById('access-rule-bulk-copy-tool')
          var bulkCopyTextElem = document.getElementById('bulk-copy-text')
          window.SvrManager.MgrAccessRuleBulkCopy.render(slideout, bulkCopyTextElem)
        }
      },

      render: function () {
        var backgroundColor = this.isSameWeekAsCurrentWeek() ? '#D8D9D9' : 'blue'
        return (
          <div
            style={{
              borderTop: '1px solid lightgrey',
              borderBottom: '1px solid lightgrey',
              overflow: 'hidden',
              color: '#D8D9D9',
              fontFamily: 'Roboto',
            }}
          >
            <div id="access-rule-bulk-copy-modal" />
            <div id="access-rule-bulk-copy-tool" />
            <div
              className="layout"
              style={{
                float: 'left',
                width: 20,
                height: 26,
                backgroundSize: '100% 100%',
                margin: 7,
              }}
            ></div>
            <div
              className="selected-date"
              style={{
                float: 'left',
                color: '#878787',
                paddingLeft: 10,
                fontSize: 14,
                height: 40,
                lineHeight: '40px',
                borderLeft: '1px solid lightgrey',
              }}
            >
              <b>{this.getSelectedWeekDisplay(this.props.selectedDate)}</b>
            </div>
            <helpers.calendarView />
            <div
              className="selection"
              onClick={this.onThisWeek}
              style={{
                float: 'right',
                color: backgroundColor,
                width: 70,
                height: 40,
                lineHeight: '40px',
                fontSize: 11,
                fontFamily: 'Roboto',
                marginLeft: 20,
                cursor: 'pointer',
                borderRight: '1px solid lightgrey',
              }}
            >
              This week
            </div>
            <div
              className="selection"
              style={{
                float: 'right',
                height: 40,
                fontSize: 14,
                color: 'black',
                cursor: 'pointer',
                lineHeight: '40px',
                borderLeft: '1px solid lightgrey',
                borderRight: '1px solid lightgrey',
              }}
            >
              <div
                onClick={this.onBackOneWeek}
                style={{
                  float: 'left',
                  width: 30,
                  textAlign: 'center',
                  height: 40,
                  lineHeight: '40px',
                }}
              >
                &lt;
              </div>
              <div
                onClick={this.onForwardOneWeek}
                style={{
                  float: 'left',
                  width: 30,
                  textAlign: 'center',
                  height: 40,
                  borderLeft: '1px solid lightgrey',
                  lineHeight: '40px',
                }}
              >
                &gt;
              </div>
            </div>
            <div className="week-view-action-buttons" style={{ float: 'right' }}></div>
            {can_access_rule_bulk_copy && (
              <button
                id="access-rule-bulk-copy-btn"
                data-test="access-rule-bulk-copy-tool"
                style={{
                  float: 'right',
                  color: bulk_copy_in_progress ? '#CCC' : '#347baf',
                  fontSize: 14,
                  height: 40,
                  lineHeight: '40px',
                  padding: '0 5px',
                  backgroundColor: '#fff',
                  border: 0,
                  cursor: 'pointer',
                }}
                onClick={this.displayAccessRuleBulkCopyTool}
              >
                <i className="VMSWeb VMSWeb-copy" />
                <span id="bulk-copy-text" style={{ padding: '0 8px' }}>
                  {bulk_copy_in_progress ? 'Bulk Copy Progressing' : 'Bulk Copy'}
                </span>
              </button>
            )}
          </div>
        )
      },
    }),

    HeaderView: React.createClass({
      getDateDisplay: function (d) {
        try {
          var dt = new Date(d)
          var dateDisplay = Pmp.Utils.isMonthDayDateFormat()
            ? dt.getMonth() + 1 + '/' + dt.getDate()
            : dt.getDate() + '/' + (dt.getMonth() + 1)
          return dt.toLocaleString('default', { weekday: 'long' }).toUpperCase() + ' ' + dateDisplay
        } catch (e) {
          // Korean formatted date doesn't parse back into new Date(d)
          return d
        }
      },

      render: function () {
        var days = (this.props.days || []).sort(function (a, b) {
            return new Date(a) > new Date(b) ? 1 : -1
          }),
          displayDates = _.map(
            days,
            function (d) {
              return [d, this.getDateDisplay(d)]
            },
            this
          ),
          headers = _.map(
            displayDates,
            function (d, k) {
              return <helpers.headerCellMenu key={k} day={d[0]} disp={d[1]} media_url={helpers.media_url} />
            },
            this
          )
        return (
          <thead>
            <tr>
              <th style={{ border: 0 }}></th>
              {headers}
            </tr>
          </thead>
        )
      },
    }),

    TableView: React.createClass({
      propTypes: {
        selectedDate: React.PropTypes.string,
        selectedIntervals: React.PropTypes.array,
        intervals: React.PropTypes.array,
        days: React.PropTypes.array,
        venueName: React.PropTypes.string.isRequired,
        startOfDayHour: React.PropTypes.number,
        venueSettings: React.PropTypes.object,
      },

      getDefaultProps: function () {
        return {
          intervals: [],
          selectedIntervals: [],
          days: [],
        }
      },

      componentWillMount: function () {
        var date = $.datepicker.formatDate('mm/dd/yy', new Date())
        this.props.intervals = helpers.generateIntervals(start_of_day_hour)
        this.props.days = helpers.generateDefaultDays(date)
        this.onDateUpdate(date)
        this.forceUpdate()
      },

      getInitialState: function () {
        var date = this.props.date ? this.props.date : $.datepicker.formatDate('mm/dd/yy', new Date())
        return {
          selectedDate: date,
          accessRuleCheck: false,
        }
      },

      postRenderHook: function (headDiv) {
        $(headDiv).find('.overlay_interval').remove()
        helpers.unMountOverlays(headDiv)
        var shiftTimeMap = helpers.createShiftTimeMap(this.props.additionalOverlays)
        helpers.renderAccessRules(headDiv, this.props.selectedIntervals, shiftTimeMap, this.props.startOfDayHour)
        helpers.renderShifts(headDiv, this.props.additionalOverlays)
      },

      postDataUpdate: function (intervalOverlays, date, additionalOverlays) {
        this.props.selectedIntervals = intervalOverlays
        this.props.days = _.keys(this.props.selectedIntervals)
        this.props.additionalOverlays = additionalOverlays
        this.setState({ selectedDate: date })
        if (!this.state.accessRuleCheck) {
          this.checkLoadAccessRule()
        }
      },

      checkLoadAccessRule: function () {
        const urlParams = new URLSearchParams(window.location.search)
        const ruleId = urlParams.get('selected_access_rule_id')
        const date = urlParams.get('date')
        if (ruleId && date) {
          this.props.onPageLoad(ruleId, date)
        }
        this.setState({ accessRuleCheck: true })
      },

      onDateUpdate: function (date) {
        this.props.fetchData(this.props.venueName, date, this.postDataUpdate)
      },

      componentDidMount: function () {
        this.postRenderHook($(this.getDOMNode()))
        helpers.bindCalendarBehavior($(this.getDOMNode()).find('.date-selector'), this.state.selectedDate, this.onDateUpdate)
        this.props.fetchUpsells(this.props.venueName)
        if (this.props.venueSettings.is_sizzle_enabled || this.props.venueSettings.is_thefork_integration_enabled) {
          this.props.fetchExperiences(this.props.venueName)
        }
      },

      componentDidUpdate: function () {
        this.postRenderHook($(this.getDOMNode()))
      },

      render: function () {
        var tableCss = {
            width: '98%',
            marginTop: '1%',
            marginRight: '3%',
            borderCollapse: 'separate',
          },
          rows = _.map(
            this.props.intervals,
            function (x, k) {
              var is_last = k == _.size(this.props.intervals) - 1
              return (
                <helpers.RowView
                  start_interval={_.first(x)}
                  end_interval={_.last(x)}
                  onClickOverlay={this.props.onClickOverlay}
                  key={k}
                  is_last={is_last}
                  days={this.props.days}
                  className="cell"
                  intervals={this.props.intervals}
                />
              )
            },
            this
          ),
          tHeader = <helpers.HeaderView days={this.props.days} media_url={this.props.media_url} onDateUpdate={this.onDateUpdate} />

        return (
          <div>
            <helpers.controlView
              onDateUpdate={this.onDateUpdate}
              selectedDate={this.state.selectedDate}
              addSlide={this.props.addSlide}
              additionalOverlays={this.props.additionalOverlays}
            />
            <table className="table" style={tableCss}>
              {tHeader}
              {rows}
            </table>
          </div>
        )
      },
    }),

    sortOverlays: function (d, overlays) {
      overlays.sort(function (a, b) {
        if (a.access_time_type === 'ALL' && b.access_time_type !== 'ALL') {
          return -1
        }
        if (b.access_time_type === 'ALL' && a.access_time_type !== 'ALL') {
          return 1
        }
        var firstStartDate = new Date(d + ' ' + a.start_time_display),
          firstEndDate = new Date(d + ' ' + a.end_time_display),
          secondStartDate = new Date(d + ' ' + b.start_time_display),
          secondEndDate = new Date(d + ' ' + b.end_time_display)
        a.start_date_time = firstStartDate
        a.end_date_time = firstEndDate
        b.start_date_time = secondStartDate
        b.end_date_time = secondEndDate
        return firstStartDate > secondStartDate
          ? 1
          : firstStartDate < secondStartDate
          ? -1
          : firstEndDate - firstStartDate - (secondEndDate - secondStartDate)
      })
      return overlays
    },

    renderShiftOverlay: function (shift, d, maxWidth, hasOverlappingShifts, totalOverlappingShifts, headDiv, key) {
      var start_time = shift['start_time_display'],
        end_time = shift['end_time_display'],
        category = shift['category'],
        start_time_parts = modules.weekViewManager.getHourFraction(start_time),
        end_time_parts = modules.weekViewManager.getHourFraction(end_time),
        name = shift['name'] || shift['category'],
        id = shift['id'],
        intervalHours = end_time_parts.hour - start_time_parts.hour + (end_time_parts.fraction - start_time_parts.fraction) / 4,
        left = key == 0 ? 0 : (key * 90) / totalOverlappingShifts
      intervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours
      var tdSelector = [
        "[data-day='",
        d,
        "']",
        "[data-start='",
        start_time_parts.hour,
        "']",
        "[data-fraction='",
        start_time_parts.fraction,
        "']",
      ].join('')
      helpers.createBackgroundOverlay(
        $(headDiv).find(tdSelector),
        intervalHours,
        name,
        category,
        id,
        key,
        key == 0 ? 80 : maxWidth,
        left,
        shift['is_override']
      )
    },

    renderAccessRuleOverlay: function (accessRule, d, category, maxWidth, hasOverlappingShifts, totalOverlappingShifts, headDiv, key) {
      var start_end_times_by_shift = accessRule.start_end_times_by_shift_display
      if (accessRule.access_time_type === 'ALL' && !start_end_times_by_shift.hasOwnProperty(category)) {
        return
      }
      var start_time, end_time
      if (accessRule.access_time_type === 'ALL') {
        start_time = start_end_times_by_shift[category][0]
        end_time = start_end_times_by_shift[category][1]
      } else {
        start_time = accessRule.start_time_display
        end_time = accessRule.end_time_display
      }
      var start_time_parts = modules.weekViewManager.getHourFraction(start_time),
        end_time_parts = modules.weekViewManager.getHourFraction(end_time),
        name = accessRule['name'] || accessRule['category'],
        id = accessRule['id'],
        intervalHours = end_time_parts.hour - start_time_parts.hour + (end_time_parts.fraction - start_time_parts.fraction) / 4,
        left = key === 0 ? 0 : (key * 90) / totalOverlappingShifts
      intervalHours = intervalHours < 0 ? intervalHours + 24 : intervalHours
      if (accessRule.access_time_type === 'SPECIFIC') {
        intervalHours = 0
      }
      var tdSelector = [
        "[data-day='",
        d,
        "']",
        "[data-start='",
        start_time_parts.hour,
        "']",
        "[data-fraction='",
        start_time_parts.fraction,
        "']",
      ].join('')
      helpers.createOverlay(
        $(headDiv).find(tdSelector),
        intervalHours,
        name,
        id,
        category,
        key,
        key === 0 ? 80 : maxWidth,
        left,
        accessRule['is_override']
      )
    },

    createShiftTimeMap: function (shiftsByDate) {
      var shiftTimeMap = {}
      for (var d in shiftsByDate) {
        if (!shiftTimeMap.hasOwnProperty(d)) {
          shiftTimeMap[d] = {}
        }
        for (var i = 0; i < shiftsByDate[d].length; i++) {
          var currentShift = shiftsByDate[d][i]
          if (!shiftTimeMap[d].hasOwnProperty(currentShift.category)) {
            shiftTimeMap[d][currentShift.category] = {}
          }
          shiftTimeMap[d][currentShift.category].start_time = currentShift.start_time_display
          shiftTimeMap[d][currentShift.category].end_time = currentShift.end_time_display
        }
      }
      return shiftTimeMap
    },

    getOverlaps: function (d, overlays) {
      // Overlaps can be either shifts or access rules
      var allOverlaps = []
      var currentOverlaps = []
      var prevParentOverlay = undefined
      overlays = helpers.sortOverlays(d, overlays)
      for (var i = 0; i < overlays.length; i++) {
        var currentOverlay = overlays[i]
        prevParentOverlay = prevParentOverlay ? prevParentOverlay : currentOverlay
        var doesOverlap = prevParentOverlay && currentOverlay.start_date_time < currentOverlay.end_date_time
        if (doesOverlap) {
          currentOverlaps.push(currentOverlay)
        } else {
          if (currentOverlaps.length) {
            allOverlaps.push(currentOverlaps)
            currentOverlaps = []
          }
          currentOverlaps.push(currentOverlay)
          prevParentOverlay = currentOverlay
        }
      }
      if (currentOverlaps.length) {
        allOverlaps.push(currentOverlaps)
      }
      return allOverlaps
    },

    renderShifts: function (headDiv, shiftsByDate) {
      for (var d in shiftsByDate) {
        if (_.isEmpty(shiftsByDate[d])) {
          continue
        }
        var allOverlaps = helpers.getOverlaps(d, shiftsByDate[d])
        for (var i = 0; i < allOverlaps.length; i++) {
          var currentOverlaps = allOverlaps[i],
            hasOverlappingShifts = currentOverlaps.length > 1,
            totalOverlappingShifts = currentOverlaps.length + 1,
            maxWidth = hasOverlappingShifts ? (90 / totalOverlappingShifts) * 2 : 90
          currentOverlaps.map(function (s, key) {
            helpers.renderShiftOverlay(s, d, maxWidth, hasOverlappingShifts, totalOverlappingShifts, headDiv, key)
          })
        }
      }
    },

    timeToVenueDt: function (d, startTime, endTime, venueStartDt) {
      var startDt = new Date(d + ' ' + startTime)
      var endDt = new Date(d + ' ' + endTime)
      if (venueStartDt > startDt) {
        startDt.setDate(startDt.getDate() + 1)
      }
      if (venueStartDt > endDt) {
        endDt.setDate(endDt.getDate() + 1)
      }
      return { startDt, endDt }
    },

    determineIfTimeRangesOverlap(startDtOne, endDtOne, startDtTwo, endDtTwo) {
      return startDtOne <= endDtTwo && endDtOne >= startDtTwo
    },

    renderAccessRules: function (headDiv, accessRulesByDate, shiftTimeMap, startOfDayHour) {
      for (var d in accessRulesByDate) {
        if (_.isEmpty(accessRulesByDate[d])) {
          continue
        }
        var venueStartDt = new Date(d + ' ' + startOfDayHour + ':00')
        var allOverlaps = helpers.getOverlaps(d, accessRulesByDate[d])
        var rulesByShiftCategory = {}
        var nonShiftRulesByTime = {}
        for (var i = 0; i < allOverlaps.length; i++) {
          var accessRules = allOverlaps[i]
          accessRules.map(function (rule, key) {
            if (rule.access_time_type === 'ALL') {
              rule.shift_categories.map(function (c, key) {
                if (!rulesByShiftCategory.hasOwnProperty(c)) {
                  rulesByShiftCategory[c] = []
                }
                rulesByShiftCategory[c].push(rule)
              })
            } else {
              var foundShift = false
              var ruleDatetimes = helpers.timeToVenueDt(d, rule.start_time_display, rule.end_time_display, venueStartDt)
              for (var category in shiftTimeMap[d]) {
                if (rule.restrict_to_shifts && !rule.shift_categories.includes(category)) {
                  continue
                }
                var shiftDatetimes = helpers.timeToVenueDt(
                  d,
                  shiftTimeMap[d][category].start_time,
                  shiftTimeMap[d][category].end_time,
                  venueStartDt
                )
                if (
                  helpers.determineIfTimeRangesOverlap(
                    ruleDatetimes.startDt,
                    ruleDatetimes.endDt,
                    shiftDatetimes.startDt,
                    shiftDatetimes.endDt
                  )
                ) {
                  if (!rulesByShiftCategory.hasOwnProperty(category)) {
                    rulesByShiftCategory[category] = []
                  }
                  rulesByShiftCategory[category].push(rule)
                  foundShift = true
                  break
                }
              }
              if (!foundShift && !rule.restrict_to_shifts) {
                if (!nonShiftRulesByTime.hasOwnProperty(rule.start_time_display)) {
                  nonShiftRulesByTime[rule.start_time_display] = []
                }
                nonShiftRulesByTime[rule.start_time_display].push(rule)
              }
            }
          })
        }
        for (var category in rulesByShiftCategory) {
          var shiftRules = rulesByShiftCategory[category],
            hasOverlappingRules = shiftRules.length > 1,
            totalOverlappingRules = shiftRules.length + 1,
            maxWidth = hasOverlappingRules ? (90 / totalOverlappingRules) * 2 : 90
          shiftRules.map(function (rule, key) {
            if (rule.shift_categories.includes(category) || !rule.restrict_to_shifts) {
              helpers.renderAccessRuleOverlay(rule, d, category, maxWidth, hasOverlappingRules, totalOverlappingRules, headDiv, key)
            }
          })
        }
        for (var ruleTime in nonShiftRulesByTime) {
          var nonShiftRules = nonShiftRulesByTime[ruleTime],
            hasOverlappingNonShiftRules = nonShiftRules.length > 1,
            totalOverlappingNonShiftRules = nonShiftRules.length + 1,
            maxNonShiftWidth = hasOverlappingRules ? (90 / totalOverlappingNonShiftRules) * 2 : 90
          nonShiftRules.map(function (rule, key) {
            helpers.renderAccessRuleOverlay(
              rule,
              d,
              '',
              maxNonShiftWidth,
              hasOverlappingNonShiftRules,
              totalOverlappingNonShiftRules,
              headDiv,
              key
            )
          })
        }
      }
    },

    generateIntervals: function (start_of_day_hour) {
      return _.times(24, function (n) {
        return [(n + start_of_day_hour) % 24, (n + start_of_day_hour + 1) % 24]
      })
    },

    generateDefaultDays: function (date) {
      var d = new Date(date)
      d.addDays(-d.getDay() - 1)
      return _.times(7, function (i) {
        return d
          .addDays(1)
          .toLocaleDateString()
          .replace(/\u200E/g, '')
        //Work around for MSIE (https://connect.microsoft.com/IE/feedback/details/837517/javascript-date-object-method-tolocaledatestring-and-unicode-character-u200e)
      })
    },

    generateGrid: function (
      id,
      venue_name,
      audience_hierarchy,
      media_url,
      date,
      HeaderCellMenu,
      onClickOverlay,
      onPageLoad,
      fetchData,
      fetchUpsells,
      fetchExperiences,
      venueSettings,
      addSlide
    ) {
      helpers.id = id
      helpers.venue_name = venue_name
      helpers.audience_hierarchy = audience_hierarchy
      helpers.media_url = media_url
      helpers.headerCellMenu = HeaderCellMenu
      helpers.colorsLookup = {}
      React.render(
        <helpers.TableView
          selectedDate={date}
          venueName={Pmp.Manager.Global._url_key_or_id}
          startOfDayHour={Pmp.Manager.Global.start_of_day_hour}
          onClickOverlay={onClickOverlay}
          onPageLoad={onPageLoad}
          fetchData={fetchData}
          fetchUpsells={fetchUpsells}
          fetchExperiences={fetchExperiences}
          venueSettings={venueSettings}
          addSlide={addSlide}
        />,
        document.getElementById(id)
      )
    },
  }

  var onClickOverlay = function (target) {
    var overlayElement = $(target) // Either an existing access rule or shift
    var ruleId = $(overlayElement).data('id')
    var selectedRule = _.findWhere(_.flatten(_.values(rules)), { id: ruleId })
    var newRuleDefaults = {
      id: $(overlayElement).data('id'),
      categories: [$(overlayElement).data('category')],
      selectedDay: $(overlayElement).data('day'),
      selectedDate: new Date($(overlayElement).data('day')),
      startTime: $(overlayElement).data('starttime'),
      endTime: $(overlayElement).data('endtime'),
    }
    var rule = _.extend({}, newRuleDefaults, selectedRule)

    $.when(
      rule.id
        ? $.ajax({
            url: '/api-yoa/booking_access/' + rule.id + '?date=' + rule.selectedDay + '&venue=' + venue_name,
          })
        : $.none,
      seatingarea_tables
    ).then(function (response1, response2) {
      var responseBody = response1 ? _.first(response1) : undefined,
        rule_data = responseBody ? responseBody['data'] : undefined
      if (responseBody && responseBody.status != '200') {
        UserNotificationInterop.error(responseBody.msg)
      } else {
        if (rule.id) {
          const earlyPerkId = rule.audience_tiers.find(tier => tier.early_access_perk_id)?.early_access_perk_id
          const exclusivePerkId = rule_data.exclusive_access_perk_id
          const hasMultiplePerks = earlyPerkId && exclusivePerkId

          $.when(
            !hasMultiplePerks && (exclusivePerkId || earlyPerkId)
              ? $.ajax({
                  url: `/api-yoa/venue/perks/${exclusivePerkId || earlyPerkId}?venue=${window.globalInit.venueId}`,
                })
              : $.none
          ).then(res => {
            const perksInfo = {
              data: res?.data?.venue_perk,
              earlyPerkId,
              exclusivePerkId,
              hasMultiplePerks,
            }

            modules.accessslideout.view(
              'flyout-form',
              helpers.audience_hierarchy,
              venue_name,
              media_url,
              rule.shift_categories,
              response2,
              rule.selectedDay,
              _.partial(helpers.onAddOverlay, rule.id),
              _.partial(onCloseSlideout, id),
              payment_setup,
              tax_rate,
              tax_groups,
              can_save_card,
              currency_symbol,
              venue_policy,
              venue_cancellation_policy,
              upsells,
              upsellsToInventory,
              experiences,
              venueSettings,
              rule_data,
              tag_groups,
              'WEEKVIEW',
              venue_default_booking_policy_id,
              venue_default_cancellation_policy_id,
              perksInfo
            )
          })
        } else {
          var existing_rule = {}
          modules.accessslideout.add(
            'flyout-form',
            helpers.audience_hierarchy,
            venue_name,
            media_url,
            response2,
            rule.name,
            rule.categories,
            rule.selectedDay,
            rule.startTime,
            rule.endTime,
            _.partial(helpers.onAddOverlay, id),
            _.partial(onCloseSlideout, id),
            venue_policy,
            venue_cancellation_policy,
            payment_setup,
            tax_rate,
            tax_groups,
            can_save_card,
            currency_symbol,
            venue_policy,
            venue_cancellation_policy,
            upsells,
            upsellsToInventory,
            experiences,
            venueSettings,
            existing_rule,
            tag_groups,
            'WEEKVIEW',
            venue_default_booking_policy_id,
            venue_default_cancellation_policy_id,
            window.globalInit.venueSettings.is_thefork_integration_enabled,
            window.globalInit.venueSettings.is_google_booking_enabled
          )
        }
      }
    })
  }

  var onPageLoad = function (selected_access_rule_id, date) {
    var selectedRule = _.findWhere(_.flatten(_.values(rules)), { id: selected_access_rule_id })
    var newRuleDefaults = {
      selectedDay: moment(date).format('MM/DD/YYYY'),
      selectedDate: moment(date).toDate(),
    }

    var rule = _.extend({}, newRuleDefaults, selectedRule)
    $.when(
      selected_access_rule_id
        ? $.ajax({
            url: '/api-yoa/booking_access/' + selected_access_rule_id + '?date=' + date + '&venue=' + venue_name,
          })
        : $.none,
      seatingarea_tables
    ).then(function (response1, response2) {
      var responseBody = response1 ? _.first(response1) : undefined,
        rule_data = responseBody ? responseBody['data'] : undefined
      if (responseBody && responseBody.status !== 200) {
        UserNotificationInterop.error(responseBody.msg)
      } else {
        modules.accessslideout.view(
          'flyout-form',
          helpers.audience_hierarchy,
          venue_name,
          media_url,
          rule.shift_categories,
          response2,
          rule.selectedDay,
          _.partial(helpers.onAddOverlay, rule.id),
          _.partial(onCloseSlideout, id),
          payment_setup,
          tax_rate,
          tax_groups,
          can_save_card,
          currency_symbol,
          venue_policy,
          venue_cancellation_policy,
          upsells,
          upsellsToInventory,
          experiences,
          venueSettings,
          rule_data,
          tag_groups,
          'WEEKVIEW',
          venue_default_booking_policy_id,
          venue_default_cancellation_policy_id
        )
      }
    })
  }

  var fetchData = function (venue_name, date, onFetchData) {
    var on_fetch_caller = function (response1, response2) {
      var accessRules = _.object(
        _.map(response1.data.access, function (v, d) {
          var inner_date = new Date(d),
            reformatted_date = inner_date.getMonth() + 1 + '/' + inner_date.getDate() + '/' + inner_date.getFullYear()
          return [reformatted_date, v]
        })
      )

      shifts = _.object(
        _.map(response2.data.shifts, function (v, d) {
          var inner_date = new Date(d),
            reformatted_date = inner_date.getMonth() + 1 + '/' + inner_date.getDate() + '/' + inner_date.getFullYear()
          return [reformatted_date, v]
        })
      )
      rules = accessRules
      onFetchData(accessRules, date, shifts)
    }

    modules.accessrulesShared.fetch_data(venue_name, date, on_fetch_caller)
  }

  var fetchUpsells = function (venue_name) {
    modules.accessrulesShared.fetch_upsells(venue_name, upsells, upsellsToInventory)
  }

  var fetchExperiences = function (venue_name) {
    var on_success = function (result) {
      experiences = result
    }
    modules.accessrulesShared.fetch_experiences(venue_name, experiences, on_success)
  }

  var resetStateOfAllOverlays = function (id) {
    $('#' + id)
      .find('.overlay_interval')
      .each(function (index, element) {
        var originalColor = $(element).data('backgroundcolor'),
          originalZIndex = $(element).data('zindex')
        $(element).css({ backgroundColor: originalColor, zIndex: originalZIndex })
      })
  }

  var onCloseSlideout = function (id) {
    $('#' + id)
      .find('.ui-resizable')
      .remove()
    resetStateOfAllOverlays(id)
  }

  var populateSeatingAreaData = function () {
    // HACK: Fixing it to current date to avoid multiple calls - devise a better strategy
    seatingarea_tables = $.Deferred()
    $.ajax({
      url: '/manager/' + venue_name + '/data/seatingareastables',
      success: _.bind(function (response) {
        seatingarea_tables.resolve(response['payload']['content'])
      }, this),
    })
  }

  var HeaderCellMenu = React.createClass({
    componentDidUpdate: function () {
      const dateString = btoa(moment(this.props.day).format('MM-DD-YYYY'))
      const elem = this.getDOMNode().querySelector('.date-link')
      window.SvrManager.MgrAccessRules.renderSwitchToListViewButton(elem, dateString)
    },

    render: function () {
      const isValidDate = moment(this.props.day).isValid()
      return (
        <th key={this.props.key} style={{ border: 0 }}>
          <span data-day={this.props.day} style={{ fontSize: 10 }}>
            {isValidDate ? this.props.disp : null}
            <div className="date-link"></div>
          </span>
        </th>
      )
    },
  })

  var renderInstance = function (date) {
    if (!instantiated) {
      console.debug('not instantiated')
      return
    }

    venueSettings = window.globalInit.venueSettings
    helpers.generateGrid(
      id,
      venue_name,
      audience_hierarchy,
      media_url,
      date,
      HeaderCellMenu,
      onClickOverlay,
      onPageLoad,
      fetchData,
      fetchUpsells,
      fetchExperiences,
      venueSettings,
      addSlide
    )
    populateSeatingAreaData()
  }

  var refreshInstance = function (date) {
    // HACK: refresh via calendar - since we can't change state from outside
    $('#' + id)
      .find('.date-selector')
      .data('datepicker')
      .settings.onSelect(date)
  }

  var addSlide = function (date) {
    var ruleName
    var category = null
    var selectedDay = date
    var startTime = null
    var endTime = null
    var existing_rule = {}

    $.when(seatingarea_tables).then(function (response) {
      modules.accessslideout.add(
        'flyout-form',
        helpers.audience_hierarchy,
        venue_name,
        media_url,
        response,
        ruleName,
        [category],
        selectedDay,
        startTime,
        endTime,
        _.partial(helpers.onAddOverlay, id),
        _.partial(onCloseSlideout, id),
        venue_policy,
        venue_cancellation_policy,
        payment_setup,
        tax_rate,
        tax_groups,
        can_save_card,
        currency_symbol,
        venue_policy,
        venue_cancellation_policy,
        upsells,
        upsellsToInventory,
        experiences,
        venueSettings,
        existing_rule,
        tag_groups,
        'WEEKVIEW',
        venue_default_booking_policy_id,
        venue_default_cancellation_policy_id,
        window.globalInit.venueSettings.is_thefork_integration_enabled,
        window.globalInit.venueSettings.is_google_booking_enabled
      )
    })
  }

  var init = function (
    domId,
    content_audience_hierarchy,
    name,
    url,
    date,
    _payment_setup,
    _tax_rate,
    _tax_groups,
    _currency_symbol,
    _start_of_day_hour,
    _can_save_card,
    _tag_groups,
    _can_access_rule_bulk_copy,
    _bulk_copy_in_progress,
    _venue_group_id
  ) {
    instantiated = true
    venue_name = Pmp.Manager.Global._url_key_or_id
    media_url = url
    id = domId
    payment_setup = _payment_setup
    tax_rate = _tax_rate
    tax_groups = _tax_groups
    currency_symbol = _currency_symbol
    can_save_card = _can_save_card
    start_of_day_hour = Number(_start_of_day_hour)
    audience_hierarchy = content_audience_hierarchy
    tag_groups = _tag_groups
    can_access_rule_bulk_copy = _can_access_rule_bulk_copy
    bulk_copy_in_progress = _bulk_copy_in_progress
    venue_group_id = _venue_group_id

    $.ajax({ url: '/booking/venue_info', data: { venue: name } }).then(
      response => {
        venue_policy = response.policy
        venue_cancellation_policy = response.cancellation_policy
        venue_default_booking_policy_id = response.booking_policy_id
        venue_default_cancellation_policy_id = response.cancellation_policy_id
        renderInstance(date)
      },
      error => {
        Interface._alert('Unknown error happened. Please try later.')
      }
    )
  }

  return {
    render: renderInstance,
    init: init,
    resetResizableOverlays: helpers.resetResizableOverlays,
    refresh: refreshInstance,
  }
})()

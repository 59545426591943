import { srGet, throwFetchError } from '@sevenrooms/core/api'
import { BookingAccessSeriesAdapter } from '../adapters'
import type { BookingAccessSeries } from '../api'

interface BookingAccessSeriesPayload {
  venueId: string
  accessRuleId: string
}

export namespace BookingAccessSeriesService {
  export async function getAccessRuleSeries({ venueId, accessRuleId }: BookingAccessSeriesPayload) {
    const { data } = await srGet<BookingAccessSeries>(`/api-yoa/booking_access/series/list`, {
      venue_id: venueId,
      access_rule_id: accessRuleId,
      skip_known_collisions: true,
    }).then(throwFetchError)
    return BookingAccessSeriesAdapter.toClient(data)
  }
}

import Slider from 'rc-slider'
import React, { useMemo } from 'react'
import 'rc-slider/assets/index.css'
import { useController, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { CheckboxGroup } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ShriftReportingPeriodSettingsMessages } from './ShiftReportingPeriods.locales'
import type { FormValues } from './ShiftReportingPeriods.zod'

enum MealPeriodEnum {
  OpenTime = 'OpenTime',
  Breakfast = 'breakfast',
  Brunch = 'brunch',
  Lunch = 'lunch',
  Dinner = 'dinner',
  NightTime = 'nightTime',
  CloseTime = 'CloseTime',
}
type MealPeriod = 'breakfast' | 'brunch' | 'lunch' | 'dinner' | 'nightTime'

type Ranges = {
  [key in MealPeriodEnum]?: number | null
}

interface MealRangeSelectorProps {
  field: Field<FormValues[number]>
}
export function MealRangeSelector({ field }: MealRangeSelectorProps) {
  const controller = useController(field)
  const { venueSettings } = useVenueSettingsContext()
  const formatTime = (totalMinutes: number, skipNonPairHours = true): React.ReactNode => {
    const [startHours, startMinutes, _unused] = (venueSettings?.start_of_day_time ?? '00:00:00').split(':').map(Number)
    const startTime = (startHours || 0) * 60 + (startMinutes || 0)

    const adjustedMinutes = (totalMinutes + startTime) % 1440

    const hours = Math.floor(adjustedMinutes / 60)
    const minutes = adjustedMinutes % 60
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12
    const formattedMinutes = minutes.toString().padStart(2, '0')
    if (!skipNonPairHours) {
      return <span style={{ whiteSpace: 'nowrap' }}>{`${formattedHour}:${formattedMinutes} ${ampm}`}</span>
    }
    return hours % 2 === 0 && minutes === 0 ? (
      <span style={{ whiteSpace: 'nowrap' }}>{`${formattedHour}:${formattedMinutes} ${ampm}`}</span>
    ) : (
      ''
    )
  }
  const generateMarks = (min: number, max: number, interval: number): Record<number, React.ReactNode> => {
    const marks: Record<number, React.ReactNode> = {}
    for (let i = min; i <= max; i += interval) {
      marks[i] = formatTime(i)
    }
    return marks
  }
  const ranges = useMemo<Ranges>(
    () => ({
      [MealPeriodEnum.OpenTime]: controller.field.value.breakfast.exists ? controller.field.value.breakfast.startTime : 0,
      [MealPeriodEnum.Breakfast]: controller.field.value.breakfast.exists ? controller.field.value.breakfast.endTime : undefined,
      [MealPeriodEnum.Brunch]: controller.field.value.brunch.exists ? controller.field.value.brunch.endTime : undefined,
      [MealPeriodEnum.Lunch]: controller.field.value.lunch.exists ? controller.field.value.lunch.endTime : undefined,
      [MealPeriodEnum.Dinner]: controller.field.value.dinner.exists ? controller.field.value.dinner.endTime : undefined,
      [MealPeriodEnum.NightTime]: controller.field.value.nightTime.exists ? controller.field.value.nightTime.endTime : undefined,
    }),
    [
      controller.field.value.breakfast.endTime,
      controller.field.value.breakfast.exists,
      controller.field.value.breakfast.startTime,
      controller.field.value.brunch.endTime,
      controller.field.value.brunch.exists,
      controller.field.value.dinner.endTime,
      controller.field.value.dinner.exists,
      controller.field.value.lunch.endTime,
      controller.field.value.lunch.exists,
      controller.field.value.nightTime.endTime,
      controller.field.value.nightTime.exists,
    ]
  )
  const { formatMessage } = useLocales()
  const makeReportingChoiceLabel = (period: MealPeriod): React.ReactNode => {
    if (!controller.field.value[period]?.exists) {
      return formatMessage(ShriftReportingPeriodSettingsMessages[period])
    }
    return (
      <span>
        {formatMessage(ShriftReportingPeriodSettingsMessages[period])} ({formatTime(controller.field.value[period]?.startTime || 0, false)}-
        {formatTime(controller.field.value[period]?.endTime || 1440, false)})
      </span>
    )
  }
  const reportingPeriodChoices = [
    {
      value: 'breakfast',
      label: makeReportingChoiceLabel('breakfast'),
    },
    {
      value: 'brunch',
      label: makeReportingChoiceLabel('brunch'),
    },
    {
      value: 'lunch',
      label: makeReportingChoiceLabel('lunch'),
    },
    {
      value: 'dinner',
      label: makeReportingChoiceLabel('dinner'),
    },
    {
      value: 'nightTime',
      label: makeReportingChoiceLabel('nightTime'),
    },
  ]
  const marks = generateMarks(0, 1440, 15)
  const handleRangeChange = (newValues: number[]): void => {
    // eslint-disable-next-line no-param-reassign
    newValues[0] = 0

    // eslint-disable-next-line no-param-reassign
    newValues[newValues.length - 1] = 1440
    let cursor = 0
    const reportingTimes = controller.field.value
    reportingPeriodChoices.forEach(period => {
      const periodValue = period.value as MealPeriod
      if (reportingTimes[periodValue]?.exists) {
        reportingTimes[periodValue].startTime = newValues[cursor] || 0
        reportingTimes[periodValue].endTime = newValues[cursor + 1] || 1440
        cursor += 1
      }
      controller.field.onChange(reportingTimes)
    })
  }

  const existingReportingPeriods = useMemo(() => {
    const existingReportingPeriods = []
    if (controller.field.value.breakfast.exists) {
      existingReportingPeriods.push('breakfast')
    }
    if (controller.field.value.brunch.exists) {
      existingReportingPeriods.push('brunch')
    }
    if (controller.field.value.lunch.exists) {
      existingReportingPeriods.push('lunch')
    }
    if (controller.field.value.dinner.exists) {
      existingReportingPeriods.push('dinner')
    }
    if (controller.field.value.nightTime.exists) {
      existingReportingPeriods.push('nightTime')
    }
    return existingReportingPeriods
  }, [
    controller.field.value.breakfast.exists,
    controller.field.value.brunch.exists,
    controller.field.value.dinner.exists,
    controller.field.value.lunch.exists,
    controller.field.value.nightTime.exists,
  ])
  const getNextPeriod = (period: MealPeriod): MealPeriod | undefined => {
    const periods: MealPeriod[] = ['breakfast', 'brunch', 'lunch', 'dinner', 'nightTime']
    const index = periods.indexOf(period)

    for (let i = index + 1; i < periods.length; i += 1) {
      const nextPeriod = periods[i]
      if (nextPeriod !== undefined && controller.field.value[nextPeriod]?.exists) {
        return nextPeriod
      }
    }
    return undefined
  }
  const getPrevPeriod = (period: MealPeriod): MealPeriod | undefined => {
    const periods: MealPeriod[] = ['breakfast', 'brunch', 'lunch', 'dinner', 'nightTime']
    const index = periods.indexOf(period)

    for (let i = index - 1; i >= 0; i -= 1) {
      const prevPeriod = periods[i]
      if (prevPeriod !== undefined && controller.field.value[prevPeriod]?.exists) {
        return prevPeriod
      }
    }
    return undefined
  }
  const addReportingPeriod = (period: MealPeriod) => {
    const nextReportingPeriod = getNextPeriod(period)
    const prevReportingPeriod = getPrevPeriod(period)
    if (nextReportingPeriod && prevReportingPeriod) {
      const startTime =
        ((controller.field.value[prevReportingPeriod]?.endTime || 1440) - (controller.field.value[prevReportingPeriod]?.startTime || 0)) /
          2 +
        (controller.field.value[prevReportingPeriod]?.startTime || 0)
      const endTime =
        (controller.field.value[nextReportingPeriod]?.startTime || 0) +
        ((controller.field.value[nextReportingPeriod]?.endTime || 1440) - (controller.field.value[nextReportingPeriod]?.startTime || 0)) / 2
      controller.field.onChange({
        ...controller.field.value,
        [prevReportingPeriod]: { ...controller.field.value[prevReportingPeriod], endTime: startTime },
        [period]: { exists: true, startTime, endTime },
        [nextReportingPeriod]: { ...controller.field.value[nextReportingPeriod], startTime: endTime },
      })
    }
    if (nextReportingPeriod && !prevReportingPeriod) {
      const endTime =
        (controller.field.value[nextReportingPeriod]?.startTime || 0) +
        ((controller.field.value[nextReportingPeriod]?.endTime || 0) - (controller.field.value[nextReportingPeriod]?.startTime || 0)) / 2
      const startTime = controller.field.value[nextReportingPeriod]?.startTime
      controller.field.onChange({
        ...controller.field.value,
        [period]: { exists: true, startTime, endTime },
        [nextReportingPeriod]: { ...controller.field.value[nextReportingPeriod], startTime: endTime },
      })
    }
    if (!nextReportingPeriod && prevReportingPeriod) {
      const startTime =
        (controller.field.value[prevReportingPeriod]?.startTime || 0) +
        ((controller.field.value[prevReportingPeriod]?.endTime || 0) - (controller.field.value[prevReportingPeriod]?.startTime || 0)) / 2
      const endTime = controller.field.value[prevReportingPeriod]?.endTime
      controller.field.onChange({
        ...controller.field.value,
        [prevReportingPeriod]: { ...controller.field.value[prevReportingPeriod], endTime: startTime },
        [period]: { exists: true, startTime, endTime },
      })
    }
  }
  const removeReportingPeriod = (period: MealPeriod) => {
    const nextReportingPeriod = getNextPeriod(period)
    const prevReportingPeriod = getPrevPeriod(period)
    const oldReportingPeriods = {
      ...controller.field.value,
      [period]: { exists: false, startTime: null, endTime: null },
    }
    if (nextReportingPeriod) {
      oldReportingPeriods[nextReportingPeriod] = {
        ...oldReportingPeriods[nextReportingPeriod],
        startTime: controller.field.value[period].startTime,
      }
    } else if (prevReportingPeriod) {
      oldReportingPeriods[prevReportingPeriod] = {
        ...oldReportingPeriods[prevReportingPeriod],
        endTime: 1440,
      }
    }
    controller.field.onChange(oldReportingPeriods)
  }
  const handleCheckboxChecked = (selected: string[]): void => {
    const oldReportingPeriods: string[] = []
    reportingPeriodChoices.forEach(period => {
      const periodValue = period.value as MealPeriod
      if (controller.field.value[periodValue]?.exists) {
        oldReportingPeriods.push(periodValue)
      }
    })
    const added = selected.filter(item => !oldReportingPeriods.includes(item)) // Newly added items
    const removed = oldReportingPeriods.filter(item => !selected.includes(item)) // Newly removed items
    if (added.length === 1) {
      addReportingPeriod(added[0] as MealPeriod)
    }
    if (removed.length === 1) {
      removeReportingPeriod(removed[0] as MealPeriod)
    }
  }
  const mealPeriods = Object.keys(ranges) as MealPeriodEnum[]

  return (
    <div style={{ width: '95%', margin: '30px auto' }}>
      <div>
        <div style={{ position: 'relative', height: '25px' }}>
          {mealPeriods.map(period => {
            if (period === MealPeriodEnum.OpenTime || period === MealPeriodEnum.CloseTime) {
              return null
            }
            const rangeValue = ranges[period]
            if (rangeValue == null) {
              return null
            }
            const leftValue = ((controller.field.value[period]?.startTime || 0) / 1440) * 100
            const width = (((controller.field.value[period]?.endTime || 0) - (controller.field.value[period]?.startTime || 0)) / 1440) * 100

            return (
              <div
                key={period}
                style={{
                  position: 'absolute',
                  left: `${leftValue}%`,
                  width: `${width}%`,
                  textAlign: 'center',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {formatMessage(ShriftReportingPeriodSettingsMessages[period])}
              </div>
            )
          })}
        </div>
        <Slider.Range
          min={0}
          max={1440}
          step={15}
          marks={marks}
          value={Object.values(ranges).filter(value => value !== undefined) as number[]}
          onChange={handleRangeChange}
          allowCross={false}
        />
        <Box mt="l">
          <CheckboxGroup
            onChange={handleCheckboxChecked}
            name="periods"
            data-test="existing-periods"
            choices={reportingPeriodChoices}
            selected={existingReportingPeriods}
            canNestingCheckboxBeSelected
          />
        </Box>
      </div>
    </div>
  )
}

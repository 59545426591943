import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import React from 'react'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { AccessListRowOffer } from '../../../views/AccessRulesList/rowInterfaces'

interface OfferFieldProps {
  offer: AccessListRowOffer
}

function OfferField({ offer }: OfferFieldProps) {
  if (!offer.name) {
    return <Typography variant="body2">No Offer</Typography>
  }
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <Box display="flex" alignItems="center">
        {offer.isActive ? (
          <CheckCircleOutlineIcon sx={{ fontSize: 16, color: 'success.main' }} />
        ) : (
          <DoDisturbAltIcon sx={{ fontSize: 16, color: 'error.main' }} />
        )}
      </Box>
      <Typography variant="body2">{offer.name}</Typography>
    </Box>
  )
}

export { OfferField }

import { useContext, useEffect } from 'react'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/offerTemplates.locales'
import { Button } from '@sevenrooms/react-components/components/Button'
import { StepperFlowActionsContext } from '../StepperFlow'
import { LinkToExistingAccessRule } from './LinkToExistingAccessRule'
import type { Availabilities, CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

interface AvailabilityProps {
  form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>>
  venueId: string
  setShowNextButton: (showMenu: boolean) => void
  setFlowCompleted: (isCompleted: boolean) => void
  selectedAvailabilityOption: Availabilities | undefined
  setSelectedAvailabilityOption: (option: Availabilities | undefined) => void
}

export function Availability({
  form,
  venueId,
  setShowNextButton,
  setFlowCompleted,
  selectedAvailabilityOption,
  setSelectedAvailabilityOption,
}: AvailabilityProps) {
  const { getValues } = form
  const values = getValues()
  const { handleNext } = useContext(StepperFlowActionsContext)

  useEffect(() => {
    setShowNextButton(!!selectedAvailabilityOption)
    if (selectedAvailabilityOption === 'linkToExistingAccessRule') {
      setFlowCompleted(true)
    } else {
      setFlowCompleted(false)
    }
  }, [selectedAvailabilityOption, setFlowCompleted, setShowNextButton])

  const onLinkToExistingAccessRule = () => {
    setSelectedAvailabilityOption('linkToExistingAccessRule')
  }

  const onCreateNewAccessRule = () => {
    setSelectedAvailabilityOption('createNewAccessRule')
    handleNext()
  }

  return (
    <VStack pt="xxl" pb="xxl" spacing="lm" width="496px" alignItems="center" data-test="availability-step-content">
      {selectedAvailabilityOption === 'linkToExistingAccessRule' ? (
        <LinkToExistingAccessRule form={form} venueId={venueId} />
      ) : (
        <AvailabilityOptions
          offerName={values.name}
          onLinkToExistingAccessRule={onLinkToExistingAccessRule}
          onCreateNewAccessRule={onCreateNewAccessRule}
        />
      )}
    </VStack>
  )
}

function AvailabilityOptions({
  offerName,
  onLinkToExistingAccessRule,
  onCreateNewAccessRule,
}: {
  offerName: string
  onLinkToExistingAccessRule: () => void
  onCreateNewAccessRule: () => void
}) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="l">
      <VStack spacing="s">
        <Text textStyle="h1" fontWeight="bold" textAlign="center">
          {formatMessage(messages.availabilityStepTitle, {
            offerName: (
              <Text textStyle="h1" fontWeight="bold">
                "{offerName}"
              </Text>
            ),
          })}
        </Text>
        <Text textStyle="body1" textAlign="center">
          {formatMessage(messages.availabilityStepDescription, { offerName })}
        </Text>
      </VStack>
      <VStack spacing="s">
        <Button
          data-test="create-new-access-rule-btn"
          variant="contained"
          size="medium"
          sx={{ width: '496px', boxShadow: 'none' }}
          onClick={onCreateNewAccessRule}
        >
          {formatMessage(messages.createNewAccessRuleButtonText)}
        </Button>
        <Button
          data-test="link-existing-access-rule-btn"
          variant="outlined"
          size="medium"
          sx={{ width: '496px' }}
          onClick={onLinkToExistingAccessRule}
        >
          {formatMessage(messages.linkToExistingAccessRuleButtonText)}
        </Button>
      </VStack>
    </VStack>
  )
}

import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useMemo, useState } from 'react'
import { ThemeProvider, type DefaultTheme } from 'styled-components'
import { getImageFromTimeSlot, type ReservationWidget as ReservationWidgetModel } from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { useWatchMany } from '@sevenrooms/core/form'
import { useLocales, Locale } from '@sevenrooms/core/locales'
import { legacyJSDateAdapter, Formats, DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, Window } from '@sevenrooms/core/ui-kit/layout'
import { generateGX } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationHoldModal, type OtherVenueInfo } from '../../../components'
import { AvailabilityResults } from '../../../components/AvailabilityResults'
import { ReservationDayPickerForm } from '../../../components/ReservationDayPickerForm'
import {
  useCachedCreateReservationHold,
  useLanguageStrings,
  useReservationsRoute,
  useVenue,
  useWidgetLanguage,
  useWidgetSettings,
  useReservationNavigation,
  useModifyReservationRoute,
  type AvailabilityTimeWithUpSellCost,
} from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { useGetGuestFacingUpgradeQuery, useReservationFormState, useModals } from '../../../store'
import {
  selectTimeslot,
  confirmTimeslotWithPaidUpgrades,
  finishSearchPage,
  mapAvailabilityUpgrades,
  updateTimeSearchParams,
  updatePartySizeSearchParams,
  updateDateSearchParams,
  trackTealiumChangeVenue,
  trackTealiumChangeQuantity,
  trackTealiumChangeDate,
} from '../../../utils'
import { useListAvailabilityDatesQuery, useGroupAvailabilityDatesQuery } from '../AvailabilityDateApi'
import { ConfirmOtherVenueNavigationModal } from '../ConfirmOtherVenueNavigationModal'
import { ExperienceDetailsModal } from '../ExperienceDetailsModal'
import { FilterTime } from '../FilterTime'
import { GroupWidget } from '../GroupWidget'
import { ReservationAvailabilityModal } from '../ReservationAvailabilityModal'
import { ReservationFilterButton } from '../ReservationFilterButton'
import { ReservationSearchInputGroup, StyledSearchInputGridContainer } from '../ReservationSearchInputGroup'
import { ReservationSelectForm } from '../ReservationSelectForm'
import { useSearchForm } from '../useSearchForm'
import { getPartySizeSelectOptions, shouldShowAvailabilityModal } from '../utils'
import type { PrivateEventsExperience } from '../../../hooks/useAvailability'

interface ReservationsTabProps {
  isGroupWidget: boolean
  showTabs: boolean
  venuesInfo?: ReservationWidgetModel.VenueInfo
}

export function ReservationsTab({ isGroupWidget, showTabs, venuesInfo }: ReservationsTabProps) {
  const { activeModal, showModal, hideModal, showErrorModal } = useModals()
  const { navigateNext: navigateNextStep, disableStep, enableStep } = useReservationNavigation()
  const {
    formState: { ...searchFormData },
    updateFormState,
  } = useReservationFormState()
  const venue = useVenue()
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<AvailabilityTimeWithUpSellCost>()
  const [selectedOtherVenue, setSelectedOtherVenue] = useState<OtherVenueInfo | undefined>()
  const [selectedExperience, setSelectedExperience] = useState<PrivateEventsExperience>()
  const widgetSettings = useWidgetSettings()
  const { formatMessage } = useLocales()
  const { updateQuery } = useReservationsRoute()
  const { selectedLanguage } = useWidgetLanguage()
  const { isFetching: isFetchingLanguage } = useLanguageStrings()
  const [createReservationHold] = useCachedCreateReservationHold()
  const { maxDaysOut, headerImgUrl } = widgetSettings
  const { locale, urlKey, name: venueName, currencyCode } = venue
  const venueLocale = Locale.getLocale(locale)
  const isSmallDesktop = useMaxWidthBreakpoint('l')
  const isSmallMobile = useMaxWidthBreakpoint('xs')

  const { isModifyRoute } = useModifyReservationRoute()

  const currentDateAtVenue = DateOnly.fromSafe(venue.venueToday)?.toJsDate() || new Date()

  const theme = useTheme()
  const brandColor = selectedOtherVenue?.colorPrimary ?? widgetSettings.colorPrimary
  const venueColor = selectedOtherVenue?.venueColor ?? venue.venueColor
  const mergedTheme = useMemo(
    () =>
      generateGX({
        ...theme,
        colors: {
          ...theme.colors,
          brandColor,
          venueColor,
        },
      }),
    [theme, brandColor, venueColor]
  )

  const partySizeOptions = useMemo(() => {
    const { minGuests, maxGuests } = widgetSettings
    return getPartySizeSelectOptions({
      minGuests,
      maxGuests,
    })
  }, [widgetSettings])

  const form = useSearchForm({
    partySize: searchFormData.partySize,
    startDate: searchFormData.startDate,
    startTime: searchFormData.startTime,
    haloTimeIntervalMinutes: searchFormData.haloTimeIntervalMinutes,
  })

  const { field, handleSubmit, setValue } = form
  const [partySize, startDate, startTime, haloTimeIntervalMinutes] = useWatchMany(field, [
    'partySize',
    'startDate',
    'startTime',
    'haloTimeIntervalMinutes',
  ])

  const { isFetching: isDatesFetching, data: availabilityDates = [] } = useListAvailabilityDatesQuery(
    isGroupWidget
      ? skipToken
      : {
          venue: venue.urlKey,
          numDays: maxDaysOut,
          startDate: legacyJSDateAdapter(new Date(), Formats.MonthDayYearByDash),
        }
  )

  const { data: groupWidgetAvailabilityDates } = useGroupAvailabilityDatesQuery(
    isGroupWidget && venuesInfo
      ? {
          venuesInfo,
          maxDaysOut,
        }
      : skipToken
  )

  const { data: upgradesData } = useGetGuestFacingUpgradeQuery(isGroupWidget ? skipToken : venue.id)

  const isDayBlocked = (date: Date) => {
    if (isGroupWidget && groupWidgetAvailabilityDates) {
      return !groupWidgetAvailabilityDates.includes(DateOnly.fromDate(date).toIso())
    } else if (isGroupWidget) {
      return date < currentDateAtVenue
    }

    return !availabilityDates.includes(DateOnly.fromDate(date).toIso())
  }

  const navigateNext = useCallback(
    async (timeslot: AvailabilityTimeWithUpSellCost, venueUrlKey?: string, query?: { [key: string]: string }) => {
      const { reservationHoldEnabled } = widgetSettings
      const { accessPersistentId, shiftPersistentId, time, timeIso } = timeslot
      const date = DateOnly.from(timeIso).toIso()
      finishSearchPage(urlKey)
      if (!venueUrlKey && reservationHoldEnabled) {
        try {
          showModal('reservationHold')
          await createReservationHold({
            accessPersistentId,
            channel: 'SEVENROOMS_WIDGET',
            date,
            partySize,
            shiftPersistentId,
            time,
            venueId: venue.id,
            trackingSlug: searchFormData.trackingSlug,
            clientId: searchFormData.clientId,
          }).unwrap()
          navigateNextStep()
        } catch (err) {
          const error = err as Error
          showErrorModal(error.message, undefined, true)
        }
        hideModal()
      } else {
        navigateNextStep(venueUrlKey, query)
      }
    },
    [
      widgetSettings,
      urlKey,
      showModal,
      createReservationHold,
      partySize,
      venue.id,
      searchFormData.trackingSlug,
      searchFormData.clientId,
      hideModal,
      navigateNextStep,
      showErrorModal,
    ]
  )

  const handleFilterTimeChange = ({ startTime, haloTimeIntervalMinutes }: { startTime: string; haloTimeIntervalMinutes: number }) => {
    setValue('startTime', startTime)
    setValue('haloTimeIntervalMinutes', haloTimeIntervalMinutes)
    updateFormState({ startTime, haloTimeIntervalMinutes })
    updateQuery({ time: startTime?.toString(), halo: haloTimeIntervalMinutes?.toString() })
    updateTimeSearchParams(venue.urlKey, startTime, haloTimeIntervalMinutes)
    hideModal()
  }

  const updateSelectedTimeslotInfo = useCallback(
    (timeSlot: AvailabilityTimeWithUpSellCost | undefined, venue: OtherVenueInfo | undefined) => {
      setSelectedTimeSlot(timeSlot)
      setSelectedOtherVenue(venue)
    },
    []
  )

  const onSelectedTimeslotModalClose = useCallback(() => {
    hideModal()
    updateSelectedTimeslotInfo(undefined, undefined)
  }, [hideModal, updateSelectedTimeslotInfo])

  const onAvailabilitySelect = useCallback(
    async (
      timeSlot: AvailabilityTimeWithUpSellCost,
      date?: string,
      selectedVenue?: OtherVenueInfo,
      experience?: PrivateEventsExperience
    ) => {
      let partySize
      let startDate
      await handleSubmit(data => {
        partySize = data.partySize
        startDate = date ? DateOnly.from(date).toIso() : DateOnly.fromDate(data.startDate).toIso()

        // update state when other availability is selected.
        updateFormState({
          partySize,
          startDate,
          selectedTimeSlot: timeSlot,
          selectedExperience: experience,
        })
        // eslint-disable-next-line no-warning-comments
        selectTimeslot(selectedVenue?.urlKey ?? venue.urlKey, DateOnly.fromDate(data.startDate).toIso(), timeSlot.time, data.partySize)
        if (timeSlot.upsellCategories && timeSlot.upsellCategories.length > 0) {
          enableStep('upgrades')
        } else {
          disableStep('upgrades')
        }
      })()
      if (!experience && shouldShowAvailabilityModal(timeSlot)) {
        updateSelectedTimeslotInfo(timeSlot, selectedVenue)
        showModal('reservationAvailability')
      } else if (experience) {
        setSelectedTimeSlot(timeSlot)
        setSelectedExperience(experience)
        showModal('moreExperienceDetails')
      } else if (isGroupWidget) {
        if (timeSlot.accessPersistentId) {
          navigateNext(timeSlot, selectedVenue?.urlKey, {
            date: timeSlot.timeIso,
            timeslot_id: timeSlot.accessPersistentId,
            timeslot_time: timeSlot.time,
            primary_venue_id: venue.id,
          })
        }
      } else if (selectedVenue && selectedVenue?.urlKey !== venue.urlKey) {
        updateSelectedTimeslotInfo(timeSlot, selectedVenue)
        showModal('confirmOtherVenueNavigation')
      } else {
        // update query params when other availability is selected.
        updateQuery({
          date: startDate,
          partySize,
          clientId: searchFormData.clientId,
        })
        navigateNext(timeSlot, selectedVenue?.urlKey)
      }
    },
    [
      handleSubmit,
      venue.urlKey,
      venue.id,
      isGroupWidget,
      updateFormState,
      enableStep,
      disableStep,
      updateSelectedTimeslotInfo,
      showModal,
      navigateNext,
      updateQuery,
      searchFormData.clientId,
    ]
  )
  const onConfirmAvailabilitySelect = (timeSlot: AvailabilityTimeWithUpSellCost) => {
    const startDate = DateOnly.from(timeSlot.timeIso).toIso()
    updateFormState({
      startDate,
    })
    confirmTimeslotWithPaidUpgrades(selectedOtherVenue?.urlKey ?? venue.urlKey, startDate, timeSlot.time, partySize)

    if (timeSlot.accessPersistentId && timeSlot?.time) {
      navigateNext(timeSlot, selectedOtherVenue?.urlKey, {
        date: timeSlot.timeIso,
        timeslot_id: timeSlot.accessPersistentId,
        timeslot_time: timeSlot.time,
        primary_venue_id: venue.id,
      })
    } else {
      // This should not happen. If so, there's a bug somewhere.
      // eslint-disable-next-line no-console
      console.error('A timeslot must be selected.')
    }
  }
  const selectedTimeSlotUpgrades = useMemo(
    () =>
      mapAvailabilityUpgrades(
        partySize,
        selectedTimeSlot?.selectedAutomaticUpsells,
        upgradesData?.inventories,
        upgradesData?.categories,
        selectedTimeSlot?.defaultServiceCharge,
        selectedTimeSlot?.defaultGratuity
      ),
    [
      selectedTimeSlot?.selectedAutomaticUpsells,
      selectedTimeSlot?.defaultServiceCharge,
      selectedTimeSlot?.defaultGratuity,
      upgradesData,
      partySize,
    ]
  )

  const onPrivateEventsExploreClick = useCallback(() => {
    updateFormState({ searchTab: 'group_bookings' })
  }, [updateFormState])

  const handleOnDateClick = (date: string) => {
    updateQuery({ date })
    setValue('startDate', DateOnly.from(date).toJsDate())
  }

  const onPrivateEventsMoreDetailsClick = useCallback(
    (experience: PrivateEventsExperience) => {
      setSelectedExperience(experience)
      showModal('moreExperienceDetails')
    },
    [showModal]
  )

  const onMoreDetailsModalClose = useCallback(() => {
    hideModal()
    setSelectedExperience(undefined)
    setSelectedTimeSlot(undefined)
  }, [hideModal])

  const { ref: reservationSearchInputGroupRef, width: reservationSearchInputGroup } = useThrottledResizeObserver(50, 'border-box')
  return (
    <>
      {isModifyRoute && (
        <Flex alignItems="center" justifyContent="center" pt="xxl" pb="lm">
          <Text textStyle="h1">
            {formatMessage(reservationWidgetMessages.resWidgetModifyReservationHeader, {
              venue: venueName,
            })}
          </Text>
        </Flex>
      )}
      <StyledSearchInputGridContainer>
        <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
          <ReservationSearchInputGroup ref={reservationSearchInputGroupRef}>
            <ReservationSelectForm
              id="reservation-party-size"
              label={formatMessage(reservationWidgetMessages.resWidgetGuestsLabel)}
              dataTest="sr-reservation-party-size"
              location="left"
              isLoading={isFetchingLanguage}
              selectOptions={partySizeOptions}
              field={field.prop('partySize')}
              onChange={value => {
                updateQuery({ partySize: value?.toString() })
                updatePartySizeSearchParams(venue.urlKey, value)
                updateFormState({ partySize: value })
                trackTealiumChangeQuantity(value)
              }}
            />
            <ReservationFilterButton
              isOpen={activeModal === 'filterTime'}
              isLoading={isFetchingLanguage}
              label={formatMessage(reservationWidgetMessages.resWidgetTimePickerLabel)}
              location="center"
              onClick={() => showModal('filterTime')}
              value={
                TimeOnly.fromSafe(startTime)?.formatSTime(venueLocale) || formatMessage(reservationWidgetMessages.resWidgetAllTimesLabel)
              }
            />
            <ReservationDayPickerForm
              label={formatMessage(reservationWidgetMessages.resWidgetDatePickerLabel)}
              isLoading={isFetchingLanguage || isDatesFetching}
              isDayBlocked={isDayBlocked}
              dataTest="sr-reservation-date"
              value={field.prop('startDate')}
              required
              today={currentDateAtVenue}
              locale={selectedLanguage}
              popoverWidth={isSmallDesktop && !isSmallMobile ? undefined : reservationSearchInputGroup}
              onChange={value => {
                if (value) {
                  updateQuery({ date: DateOnly.fromDate(value).toIso() })
                  updateDateSearchParams(venue.urlKey, DateOnly.fromDate(value).toIso())
                  updateFormState({ startDate: DateOnly.fromDate(value).toIso() })
                  trackTealiumChangeDate(DateOnly.fromDate(value).formatFMonthNDaySWeek())
                }
              }}
              scrollTopOnMobile={!!headerImgUrl}
            />
          </ReservationSearchInputGroup>
        </Form>
      </StyledSearchInputGridContainer>
      {isGroupWidget && venuesInfo ? (
        <GroupWidget
          venuesInfo={venuesInfo}
          partySize={partySize}
          startDate={startDate}
          startTime={startTime}
          haloTimeIntervalMinutes={haloTimeIntervalMinutes}
          onTimeSlotClick={(slot: AvailabilityTimeWithUpSellCost, venue: OtherVenueInfo) => onAvailabilitySelect(slot, undefined, venue)}
        />
      ) : (
        <AvailabilityResults
          venuesInfo={venuesInfo}
          onClearTimeFilter={() => {
            updateQuery({ time: ReservationWidget.AllTimesOption })
            setValue('startTime', ReservationWidget.AllTimesOption)
          }}
          handleOnDateClick={handleOnDateClick}
          onAvailabilitySelect={onAvailabilitySelect}
          venueId={venue.id}
          partySize={partySize}
          startTime={startTime}
          startDate={startDate}
          isDayBlocked={isDayBlocked}
          haloTimeIntervalMinutes={haloTimeIntervalMinutes}
          clientId={searchFormData.clientId}
          onPrivateEventsExploreClick={onPrivateEventsExploreClick}
          onPrivateEventsMoreDetailsClick={onPrivateEventsMoreDetailsClick}
          onPrivateEventsTimeSlotClick={(slot: AvailabilityTimeWithUpSellCost, experience: PrivateEventsExperience) =>
            onAvailabilitySelect(slot, undefined, undefined, experience)
          }
          showTabs={showTabs}
        />
      )}
      {activeModal === 'filterTime' && (
        <Window active>
          <FilterTime
            startTime={startTime}
            onChange={handleFilterTimeChange}
            onClose={hideModal}
            haloTimeIntervalMinutes={haloTimeIntervalMinutes}
            venueLocale={venueLocale}
          />
        </Window>
      )}
      {activeModal === 'reservationAvailability' && selectedTimeSlot && (
        <Window active>
          <ThemeProvider theme={mergedTheme as unknown as DefaultTheme}>
            <ReservationAvailabilityModal
              timeslot={selectedTimeSlot}
              currencyCode={venue.currencyCode}
              title={
                selectedTimeSlot.publicDescriptionTitle ||
                selectedTimeSlot.publicTimeSlotDescription ||
                formatMessage(reservationWidgetMessages.resWidgetReservationDetails)
              }
              description={selectedTimeSlot.publicLongFormDescription}
              cancellationPolicy={selectedTimeSlot.cancellationPolicy}
              timeIso={selectedTimeSlot.timeIso}
              chargeType={selectedTimeSlot.chargeType}
              duration={selectedTimeSlot.duration}
              showCost={selectedTimeSlot.showCost}
              cost={selectedTimeSlot.cost}
              fees={widgetSettings.isFeesInPriceDisplayed ? selectedTimeSlot.fees ?? 0 : 0}
              image={getImageFromTimeSlot(selectedTimeSlot, 'large')}
              upgrades={selectedTimeSlotUpgrades}
              onConfirm={timeslot => {
                if (selectedOtherVenue && selectedOtherVenue?.urlKey !== venue.urlKey && !isGroupWidget) {
                  showModal('confirmOtherVenueNavigation')
                } else {
                  onConfirmAvailabilitySelect(timeslot)
                }
              }}
              onClose={onSelectedTimeslotModalClose}
            />
          </ThemeProvider>
        </Window>
      )}
      {activeModal === 'confirmOtherVenueNavigation' && !!selectedOtherVenue && (
        <Window active>
          <ThemeProvider theme={mergedTheme as unknown as DefaultTheme}>
            <ConfirmOtherVenueNavigationModal
              fromVenue={venue.name}
              toVenue={selectedOtherVenue.venueName}
              onConfirm={() => {
                if (selectedTimeSlot) {
                  trackTealiumChangeVenue(selectedOtherVenue.urlKey, selectedOtherVenue.venueName, selectedOtherVenue.countryCode)
                  onConfirmAvailabilitySelect(selectedTimeSlot)
                }
              }}
              onClose={onSelectedTimeslotModalClose}
            />
          </ThemeProvider>
        </Window>
      )}
      {activeModal === 'moreExperienceDetails' && selectedExperience && (
        <Window active>
          <ExperienceDetailsModal
            experience={selectedExperience}
            currencyCode={currencyCode}
            timeSlot={selectedTimeSlot}
            onClose={onMoreDetailsModalClose}
            onConfirmSelect={onConfirmAvailabilitySelect}
          />
        </Window>
      )}
      {activeModal === 'reservationHold' && (
        <Window active>
          <ReservationHoldModal />
        </Window>
      )}
    </>
  )
}

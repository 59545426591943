import React, { useState } from 'react'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Image, Report, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import { BookingsChannelsReport } from '@sevenrooms/mgr-access-rules-slideout/components/BookingChannels/components'
import { BookingWindowReport } from '@sevenrooms/mgr-access-rules-slideout/components/BookingWindow/components'
import { DurationsReport } from '@sevenrooms/mgr-access-rules-slideout/components/Durations/components/DurationsReport'
import { GuestDurationPickerReport } from '@sevenrooms/mgr-access-rules-slideout/components/GuestDurationPicker/components'
import { GuestFacingReport } from '@sevenrooms/mgr-access-rules-slideout/components/GuestFacing/components'
import { PacingReport } from '@sevenrooms/mgr-access-rules-slideout/components/Pacing/components'
import { PartySizeReport } from '@sevenrooms/mgr-access-rules-slideout/components/PartySize/components'
import { PaymentPolicyReport } from '@sevenrooms/mgr-access-rules-slideout/components/PaymentPolicy/components'
import { ReservationCoverLimitReport } from '@sevenrooms/mgr-access-rules-slideout/components/ReservationCoverLimit/components'
import { ReservationTagsReport } from '@sevenrooms/mgr-access-rules-slideout/components/ReservationTags/components'
import { ScheduleReport } from '@sevenrooms/mgr-access-rules-slideout/components/Schedule/components'
import { SeatingAreasReport } from '@sevenrooms/mgr-access-rules-slideout/components/SeatingAreas/components'
import { UpgradesReport } from '@sevenrooms/mgr-access-rules-slideout/components/Upgrades/components'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { ExperiencesLocales } from '@sevenrooms/mgr-offers/experiences/Experiences.locales'
import { Table } from '@sevenrooms/react-components/components/Table'
import { TableBody } from '@sevenrooms/react-components/components/TableBody'
import { TableCell } from '@sevenrooms/react-components/components/TableCell'
import { TableHead } from '@sevenrooms/react-components/components/TableHead'
import { TableRow } from '@sevenrooms/react-components/components/TableRow'
import { messages } from '../../locales/offerTemplates.locales'
import type { CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

interface OfferAccessRuleSettings {
  propertyTitle: string
  propertyValue: React.ReactElement
}

export function NewAccessRuleReview({
  form,
  setFlowStep,
  setIsAccessRuleCustomizationInitialPart,
  setUseSaveButton,
}: {
  form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>>
  setFlowStep: (step: number | undefined) => void
  setIsAccessRuleCustomizationInitialPart: (isInitial: boolean) => void
  setUseSaveButton: (useSaveButton: boolean) => void
}) {
  const { formatMessage } = useLocales()
  const { getValues, setValue } = form

  const values = getValues()
  const { accessRule } = values
  const { data: accessRulesSlideoutData } = useAccessRulesSlideoutData()
  const [showAccessRuleSlideout, setShowAccessRuleSlideout] = useState(false)

  const offerDetailsSettingsMapping: OfferAccessRuleSettings[] = [
    { propertyTitle: formatMessage(messages.newAccessRuleReviewOfferName), propertyValue: <Text textStyle="body1">{values.name}</Text> },
    {
      propertyTitle: formatMessage(messages.newAccessRuleReviewOfferDescription),
      propertyValue: <div dangerouslySetInnerHTML={{ __html: values.description }} />,
    },
    { propertyTitle: formatMessage(messages.newAccessRuleReviewOfferPrice), propertyValue: <Text textStyle="body1">{values.price}</Text> },
    {
      propertyTitle: formatMessage(messages.newAccessRuleReviewOfferType),
      propertyValue: (
        <Text textStyle="body1">
          {values.accessType ? formatMessage(ExperiencesLocales.exclusiveAccess) : formatMessage(ExperiencesLocales.openAccess)}
        </Text>
      ),
    },
    {
      propertyTitle: formatMessage(messages.newAccessRuleReviewOfferImage),
      propertyValue: values.heroImage ? (
        <Image src={values.heroImage?.preview || values.heroImage.rawUrl} alt={values.heroImage.name} maxWidth="200px" />
      ) : (
        <></>
      ),
    },
  ]

  return (
    <>
      <VStack pt="xxl" pb="xxl" spacing="l" width="736px" data-test="create-new-access-rule-review-step-content">
        <VStack spacing="s" alignItems="center">
          <Text textStyle="h2" textAlign="center">
            {formatMessage(messages.newAccessRuleReviewStepTitle, {
              offerName: (
                <Text textStyle="h2" fontWeight="bold">
                  "{values.name}"
                </Text>
              ),
            })}
          </Text>
          <Button
            variant="tertiary"
            data-test="customize-access-rule-btn"
            onClick={() => {
              setShowAccessRuleSlideout(true)
            }}
          >
            {formatMessage(messages.newAccessRuleReviewCustomizeBtn)}
          </Button>
        </VStack>
        <VStack>
          <ReviewSectionHeader
            header={formatMessage(messages.newAccessRuleReviewOfferDetails)}
            onEdit={() => {
              setFlowStep(0)
              setUseSaveButton(true)
            }}
          />
          <Box pl="s">
            <ReviewSectionTable header="" settingsMapping={offerDetailsSettingsMapping} />
          </Box>
          <ReviewSectionHeader
            header={formatMessage(messages.newAccessRuleReviewAccessRule, {
              accessRule: values.accessRule.name,
            })}
            onEdit={() => {
              setFlowStep(2)
              setIsAccessRuleCustomizationInitialPart(true)
            }}
          />
          <Report>
            <ScheduleReport accessRule={accessRule} position="static" />
            <PartySizeReport accessRule={accessRule} shortenIfEqual position="static" />
            <SeatingAreasReport accessRule={accessRule} position="static" />
            <GuestFacingReport accessRule={accessRule} position="static" />
            <PaymentPolicyReport accessRule={accessRule} position="static" />
            <BookingsChannelsReport accessRule={accessRule} position="static" />
            <DurationsReport accessRule={accessRule} position="static" />
            <UpgradesReport accessRule={accessRule} position="static" />
            <ReservationTagsReport accessRule={accessRule} position="static" />
            <BookingWindowReport accessRule={accessRule} position="static" />
            <ReservationCoverLimitReport accessRule={accessRule} position="static" />
            <PacingReport accessRule={accessRule} position="static" />
            <GuestDurationPickerReport accessRule={accessRule} position="static" />
          </Report>
        </VStack>
      </VStack>
      {showAccessRuleSlideout && accessRulesSlideoutData && (
        <AccessRulesSlideout
          accessRulesSlideoutData={accessRulesSlideoutData}
          onClose={() => {
            setShowAccessRuleSlideout(false)
          }}
          onRuleDelete={() => {}}
          onRuleSave={() => {}}
          selectedDay={accessRule.schedule.dateRange.startDate?.toISOString() || new Date().toISOString()}
          shiftCategories={accessRule.schedule.shiftCategories}
          startDate={accessRule.schedule.dateRange.startDate || new Date()}
          mode="new-item"
          accessRuleFormDefaults={values.accessRule}
          slideoutHeader={formatMessage(messages.accessRule)}
          isCustomizeAccessRule
          onCustomizeAccessRule={accessRule => setValue('accessRule', accessRule)}
        />
      )}
    </>
  )
}

function ReviewSectionHeader({ header, onEdit }: { header: string; onEdit: () => void }) {
  const { formatMessage } = useLocales()

  return (
    <HStack justifyContent="space-between" pt="l" pb="s">
      <Text textStyle="h2">{header}</Text>
      <Button data-test="edit-btn" variant="secondary" size="s" onClick={onEdit}>
        {formatMessage(messages.editButton)}
      </Button>
    </HStack>
  )
}

function ReviewSectionTable({ header, settingsMapping }: { header: string; settingsMapping: OfferAccessRuleSettings[] }) {
  const theme = useTheme()

  const rows = []
  for (const item of settingsMapping) {
    rows.push(
      <TableRow key={item.propertyTitle.split(' ').join('-')}>
        <TableCell sx={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', paddingTop: '4px', paddingBottom: '4px' }}>
          <Text textStyle="body1">{item.propertyTitle}</Text>
        </TableCell>
        <TableCell sx={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', paddingTop: '4px', paddingBottom: '4px' }}>
          {item.propertyValue}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table padding="none">
      <TableHead>
        <TableRow key={header.split(' ').join('-')}>
          <TableCell
            sx={{
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: '2px solid',
              width: '44%',
              paddingTop: '2px',
              paddingBottom: '2px',
              borderColor: theme.colors.dividerLines,
            }}
          >
            <Text textStyle="body1Bold">{header}</Text>
          </TableCell>
          <TableCell
            sx={{
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderBottom: '2px solid',
              paddingTop: '2px',
              paddingBottom: '2px',
              borderColor: theme.colors.dividerLines,
            }}
          />
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  )
}

import { useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { UpgradesLocales } from '../Upgrades.locales'
import { UpgradesTestId } from '../Upgrades.testIds'
import { getMultiSelectOptions } from '../utils'
import { SelectedCategoriesView } from './SelectedCategoriesView'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function UpgradesReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { accessRule: rawRule, upsells } = useAccessRuleContext()
  const { venueCurrencyCode } = useAppContext()

  const { selection, edited } = accessRule.upgrades
  const options = getMultiSelectOptions(upsells, venueCurrencyCode, rawRule)

  return (
    <ReportPart caption={formatMessage(UpgradesLocales.title)} data-test={UpgradesTestId.report} position={position}>
      <ReportLine name={formatMessage(UpgradesLocales.reportLabel)}>
        {edited ? (
          <SelectedCategoriesView selection={selection} options={options} />
        ) : (
          <Text>{formatMessage(accessRulesMessages.followShift)}</Text>
        )}
      </ReportLine>
    </ReportPart>
  )
}

// This file was automatically generated from manager.reports.advertising.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Reports == 'undefined') { Nightloop.Templates.Manager.Reports = {}; }


Nightloop.Templates.Manager.Reports.Advertising = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Reports.AdvertisingBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.AdvertisingBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Reports.ReportSidebar(soy.$$augmentData(opt_data, {selected: 'advertising'}), output);
  output.append('<div id="page-advertising-report" class="page-report has-sidebar"><div id="main-area">');
  Nightloop.Templates.Manager.Reports.PrintHeader(soy.$$augmentData(opt_data, {name: 'Adwords campaigns', custom_display: soy.$$escapeHtml(opt_data.content.from_date_formats.currentday_formatted) + ' - ' + soy.$$escapeHtml(opt_data.content.to_date_formats.currentday_formatted)}), output);
  output.append('<div id="content-header" class="do-not-print">');
  Nightloop.Templates.Manager.Reports.ReportNav(soy.$$augmentData(opt_data, {enable_shifts: 'false', enable_export: 'false'}), output);
  output.append('</div>', (opt_data.content.error_msg) ? '<p class="block-error">' + soy.$$escapeHtml(opt_data.content.error_msg) + '</p>' : '', '<div class="float-end"></div><div id="report-blocks" class="report-content"><div class="row"><div class="col"><p class="title">Total Spend By Campaign</p><div id="spend-by-source-chart" class="chart"></div></div><div class="col last"><p class="title">No. Completed By Campaign</p><div id="completed-by-source-chart" class="chart"></div></div><div class="float-end"></div></div><div class="float-end"></div>');
  Nightloop.Templates.Manager.Reports.AdwordsList(opt_data, output);
  output.append('</div></div></div><script type="text/javascript">$(document).ready(function() {var completed_reservations_list = [');
  var campaignList10485 = opt_data.content.data;
  var campaignListLen10485 = campaignList10485.length;
  for (var campaignIndex10485 = 0; campaignIndex10485 < campaignListLen10485; campaignIndex10485++) {
    var campaignData10485 = campaignList10485[campaignIndex10485];
    output.append('{\'name\' : \'', soy.$$escapeJsString(campaignData10485.name), '\', \'num\' : ', soy.$$escapeHtml(campaignData10485.completed.num), ', \'amt\' : ', soy.$$escapeHtml(campaignData10485.completed.amt), '}', (! (campaignIndex10485 == campaignListLen10485 - 1)) ? ',' : '');
  }
  output.append(']; Pmp.Manager.Reports.Advertising.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", false, completed_reservations_list, "', soy.$$escapeHtml(opt_data.venue.currency_symbol), '" );});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Reports.AdwordsList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="report-block full-size tables row" style="height: auto"><p class="title">Campaigns</p><div class="table"><table class="report-table"><tbody><tr class="header-group-row"><td class="">&nbsp;</td><td class="right-sep" colspan="5">Total</td><td class="right-sep" colspan="2">Cancellations</td><td colspan="3">No Show</td></tr><tr class="header-row"><td class="left">Name</td><td>No. Reservations</td><td>No. Covers</td><td>Spend</td><td>Avg.</td><td class="right-sep">Avg./Cover</td><td>No. Reservations</td><td class="right-sep">No. Covers</td><td>No. Reservations</td><td>No. Covers</td><td></td></tr>');
  var rowList10505 = opt_data.content.data;
  var rowListLen10505 = rowList10505.length;
  for (var rowIndex10505 = 0; rowIndex10505 < rowListLen10505; rowIndex10505++) {
    var rowData10505 = rowList10505[rowIndex10505];
    output.append('<tr class="even-row"><td class="left">', soy.$$escapeHtml(rowData10505.name), '</td><td class="number">', soy.$$escapeHtml(rowData10505.completed.num), '</td><td class="number">', soy.$$escapeHtml(rowData10505.completed.covers), '</td><td class="number">', soy.$$escapeHtml(rowData10505.completed.amt_formatted), '</td><td class="number">', soy.$$escapeHtml(rowData10505.completed.avg_formatted), '</td><td class="number right-sep">', soy.$$escapeHtml(rowData10505.completed.avg_spend_cover_formatted), '</td><td class="number">', soy.$$escapeHtml(rowData10505.canceled.num), '</td><td class="number right-sep">', soy.$$escapeHtml(rowData10505.canceled.covers), '</td><td class="number">', soy.$$escapeHtml(rowData10505.noshow.num), '</td><td class="number">', soy.$$escapeHtml(rowData10505.noshow.covers), '</td></tr>');
  }
  output.append('<tr class="total-row"><td class="left">Total</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.completed.num), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.completed.covers), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.completed.amt_formatted), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.completed.avg_formatted), '</td><td class="number right-sep">', soy.$$escapeHtml(opt_data.content.totals.completed.avg_spend_cover_formatted), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.canceled.num), '</td><td class="number right-sep">', soy.$$escapeHtml(opt_data.content.totals.canceled.covers), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.noshow.num), '</td><td class="number">', soy.$$escapeHtml(opt_data.content.totals.noshow.covers), '</td></tr></tbody></table></div></div></div>');
  return opt_sb ? '' : output.toString();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global Stripe, widgetInit */
import { Map } from 'immutable'
import {
  CHANGE_PHONE_NUM,
  CHANGE_COUNTRY_FLAG,
  INVALIDATE_FIELD,
  VALIDATE_ALL_FIELDS,
  TRY_POST_CHECKOUT,
  POST_CHECKOUT_SUCCESS,
  POST_CHECKOUT_FAILURE,
  POST_CHECKOUT_RELOAD_ON_FAILURE,
  DISMISS_SPINNER,
  MANDATE_POLICIES,
  TOGGLE_BOOKING_AGREEMENT,
  TOGGLE_VENUE_GROUP_MARKETING_OPT_IN,
  TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN,
  TOGGLE_VENUE_SMS_MARKETING_OPT_IN,
  TOGGLE_RESERVATION_SMS_OPT_IN,
  TOGGLE_TAILORED_COMMUNICATION_OPT_IN,
  TOGGLE_ABOVE_AGE_CONSENT,
  TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_TAILORED_COMMUNICATION_OPT_IN_DISPLAY,
  TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY,
  TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY,
  TOGGLE_PROMO_CODE_ERROR_DISPLAY,
  TOGGLE_BOOKING_POLICY_DISPLAY,
  UPDATE_TIP_AMOUNT,
  SET_USER_DATA_FB,
  SET_USER_DATA_GP,
  SELECT_INVENTORY_ITEM,
  CLEAR_ERROR_SCROLLING,
  CHANGE_FORM_FIELD,
  CHANGE_CAPTCHA_FIELD,
  VALIDATE_CAPTCHA_FIELD,
  REVERT_STAGE,
  PASS_STRIPE_CARD_ELEMENT,
  ADD_PROMO_CODE,
  REMOVE_PROMO_CODE,
  INVALIDATE_PROMO_CODE_ATTEMPT,
  TRY_VERIFY_PROMO_CODE,
  VERIFY_PROMO_CODE_SUCCESS,
  VERIFY_PROMO_CODE_FAILURE,
  TOGGLE_SPECIAL_ATTENTION_MESSAGE,
  TOGGLE_CHECKOUT_ERROR_MODAL,
  DISPLAY_MODAL,
  DISMISS_MODAL,
  SAVE_TAG_GROUP,
} from '../actions/ActionTypes'

const formFields = (state = Map(), action) => {
  switch (action.type) {
    case CHANGE_FORM_FIELD: {
      let { changeTo } = action
      if (action.field === 'birthday') {
        changeTo = changeTo.replace('/', '')
        if (Number.isNaN(changeTo)) {
          return state
        }
        if (changeTo.length > 2) {
          changeTo = `${changeTo.substr(0, 2)}/${changeTo.substr(2)}`
        }
      }
      return state.merge({
        [action.field]: changeTo,
        formErrors: state.get('formErrors').delete(action.field),
      })
    }
    case CHANGE_CAPTCHA_FIELD:
      return state.set('recaptcha', action.recaptchaValue)
    case VALIDATE_CAPTCHA_FIELD: {
      if (!action.recaptchaValue) {
        return state.merge({
          completeReCaptcha: false,
          checkoutStatus: 'scrolling',
        })
      }
      return state.merge({
        completeReCaptcha: true,
        checkoutStatus: 'scrolling',
      })
    }
    case REVERT_STAGE:
      return state.set('recaptcha', null)
    case CHANGE_PHONE_NUM:
      return state.merge({
        phoneNumber: action.changeTo,
        formErrors: state.get('formErrors').delete('phoneNumber'),
      })
    case CHANGE_COUNTRY_FLAG:
      return state.merge({
        phoneNumber: action.changeTo,
        phoneNumberLocale: action.selectedCountry,
      })
    case INVALIDATE_FIELD: {
      let formField
      if (action.field === 'number') {
        formField = 'cardNum'
      } else if (action.field === 'exp_month') {
        formField = 'cardMonthExp'
      } else if (action.field === 'exp_year') {
        formField = 'cardYearExp'
      } else if (action.field === 'zip_code') {
        formField = 'cardZipCode'
      } else {
        formField = 'cardCvv'
      }
      return state.merge({
        [`${formField}Status`]: 'invalid',
        error: action.error,
        checkoutStatus: 'failure',
      })
    }
    case VALIDATE_ALL_FIELDS: {
      return state.merge({
        formErrors: action.formErrors,
      })
    }
    case TRY_POST_CHECKOUT:
      return state.set('checkoutStatus', 'processing')
    case POST_CHECKOUT_SUCCESS:
      return state.set('checkoutStatus', 'success')
    case POST_CHECKOUT_FAILURE: {
      let errorResp = (action.response || {}).errors || []
      if (typeof errorResp === 'object') {
        errorResp = errorResp.join(', ')
      }
      return state.merge({
        checkoutStatus: 'failure',
        error: errorResp,
      })
    }
    case POST_CHECKOUT_RELOAD_ON_FAILURE:
      return state.merge({
        checkoutStatus: 'reload',
        error: action.errorMessage,
        dismissModal: action.actionCallback || (() => null),
      })
    case TOGGLE_CHECKOUT_ERROR_MODAL:
      return state.merge({
        checkoutStatus: 'failure',
        error: action.response,
      })
    case DISMISS_SPINNER:
      return state.set('checkoutStatus', 'failed')
    case TOGGLE_BOOKING_AGREEMENT: {
      const bookingFlag = !state.get('agreedToBookingPolicy')
      return state.merge({
        agreedToBookingPolicy: bookingFlag,
        bookingPolicyStatus: 'edited',
      })
    }
    case SAVE_TAG_GROUP:
    case DISMISS_MODAL:
      return state.set('displayModalType', null)
    case DISPLAY_MODAL:
      return state.update('displayModalType', val => (val === action.modal ? null : action.modal))
    case MANDATE_POLICIES: {
      const { formErrors } = action
      return state.merge({
        bookingPolicyStatus: formErrors.agreedToBookingPolicy ? 'invalid' : 'valid',
        agePolicyStatus: formErrors.agreedToAboveAgeConsentOn ? 'invalid' : 'valid',
      })
    }
    case TOGGLE_VENUE_GROUP_MARKETING_OPT_IN: {
      const marketingFlag = !state.get('agreedToVenueGroupMarketingOptIn')
      return state.merge({
        agreedToVenueGroupMarketingOptIn: marketingFlag,
        agreedToVenueSpecificMarketingOptIn: marketingFlag ? true : state.get('agreedToVenueSpecificMarketingOptIn'),
      })
    }
    case TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN: {
      const marketingFlag = !state.get('agreedToVenueSpecificMarketingOptIn')
      const venueGroupMarketingFlag = state.get('agreedToVenueGroupMarketingOptIn')
      return state.merge({
        agreedToVenueSpecificMarketingOptIn: marketingFlag,
        agreedToVenueGroupMarketingOptIn: marketingFlag ? venueGroupMarketingFlag : false,
      })
    }
    case TOGGLE_VENUE_SMS_MARKETING_OPT_IN: {
      return state.merge({
        agreedToVenueSmsMarketingOptIn: !state.get('agreedToVenueSmsMarketingOptIn'),
      })
    }
    case TOGGLE_TAILORED_COMMUNICATION_OPT_IN: {
      return state.merge({
        agreedToTailoredCommunicationOptIn: !state.get('agreedToTailoredCommunicationOptIn'),
      })
    }
    case TOGGLE_RESERVATION_SMS_OPT_IN: {
      return state.merge({
        agreedToReservationSmsOptIn: !state.get('agreedToReservationSmsOptIn'),
      })
    }
    case TOGGLE_ABOVE_AGE_CONSENT: {
      const aboveAgeFlag = !state.get('agreedToAboveAgeConsentOn')
      return state.merge({
        agreedToAboveAgeConsentOn: aboveAgeFlag,
        agePolicyStatus: 'edited',
      })
    }
    case TOGGLE_SPECIAL_ATTENTION_MESSAGE:
      return state.update('displaySpecialAttentionMessage', flag => !flag)
    case TOGGLE_BOOKING_POLICY_DISPLAY:
      return state.update('displayBookingPolicy', flag => !flag)
    case TOGGLE_VENUE_GROUP_MARKETING_OPT_IN_DISPLAY:
      return state.update('displayVenueGroupMarketingOptInPolicy', flag => !flag)
    case TOGGLE_TAILORED_COMMUNICATION_OPT_IN_DISPLAY:
      return state.update('displayTailoredCommunicationOptInPolicy', flag => !flag)
    case TOGGLE_VENUE_SPECIFIC_MARKETING_OPT_IN_DISPLAY:
      return state.update('displayVenueSpecificMarketingOptInPolicy', flag => !flag)
    case TOGGLE_VENUE_SMS_MARKETING_OPT_IN_DISPLAY:
      return state.update('displayVenueSmsMarketingOptInPolicy', flag => !flag)
    case TOGGLE_RESERVATION_SMS_OPT_IN_POLICY_DISPLAY:
      return state.update('displayReservationSmsOptInPolicy', flag => !flag)
    case TOGGLE_PROMO_CODE_ERROR_DISPLAY:
      return state.update('displayPromoCodeError', flag => !flag)
    case UPDATE_TIP_AMOUNT:
      return state.merge({
        customTip: action.value,
        formErrors: state.get('formErrors').delete('gratuity'),
      })
    case SET_USER_DATA_FB: {
      const birthday = action.response.birthday ? action.response.birthday.substr(0, 5) : ''
      return state.merge({
        email: action.response.email,
        firstName: action.response.first_name,
        lastName: action.response.last_name,
        birthday,
      })
    }
    case SET_USER_DATA_GP: {
      let birthday = ''
      if (action.response.birthday) {
        const bDayArr = action.response.birthday.split('-')
        birthday = [bDayArr[1], bDayArr[2]].join('/')
      }
      return state.mergeWith((prev, next) => prev || next, {
        email: action.response.email,
        firstName: action.response.firstName,
        lastName: action.response.lastName,
        birthday,
      })
    }
    case SELECT_INVENTORY_ITEM:
      return state.merge({
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        phoneNumber: action.phoneNumber,
        phoneNumberLocale: action.phoneNumberLocale,
        dialCode: action.dialCode,
      })
    case CLEAR_ERROR_SCROLLING:
      return state.set('checkoutStatus', 'awaiting')
    case PASS_STRIPE_CARD_ELEMENT:
      return state.merge({ stripeCardElement: action.element })
    case TRY_VERIFY_PROMO_CODE:
      return state.set('isVerifyingPromoCode', true)
    case VERIFY_PROMO_CODE_SUCCESS:
      return state.merge({
        isVerifyingPromoCode: false,
        validPromoCode: action.data.id,
      })
    case VERIFY_PROMO_CODE_FAILURE:
      return state.merge({
        isVerifyingPromoCode: false,
        validPromoCode: null,
        promoCode: '',
        autoAddedCreditCardPromoCode: false,
        displayPromoCodeError: true,
        promoCodeErrorMsg: action.errorMessage,
      })
    case ADD_PROMO_CODE:
      return state.merge({
        validPromoCode: null,
        promoCode: action.promoCode.code,
        promoCodeInfo: action.promoCode,
        autoAddedCreditCardPromoCode: true,
      })
    case REMOVE_PROMO_CODE:
      return state.merge({
        validPromoCode: null,
        promoCode: '',
        promoCodeInfo: null,
      })
    case INVALIDATE_PROMO_CODE_ATTEMPT:
      return state.merge({
        validPromoCode: null,
        promoCode: '',
        promoCodeInfo: null,
        isVerifyingPromoCode: false,
      })
    default:
      return state
  }
}

export default formFields

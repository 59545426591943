import { AccessRuleAccessTimeEnum } from '@sevenrooms/core/domain'
import type { FormatMessage } from '@sevenrooms/core/locales'
import { AudienceField } from '../../components/AccessRulesDataGrid/GridFields/AudienceField'
import { BookEndTimeField } from '../../components/AccessRulesDataGrid/GridFields/BookEndTimeField'
import { BookStartTimeField } from '../../components/AccessRulesDataGrid/GridFields/BookStartTimeField'
import { BundledUpgradesField } from '../../components/AccessRulesDataGrid/GridFields/BundledUpgradesField'
import { CoverLimitField } from '../../components/AccessRulesDataGrid/GridFields/CoverLimitField'
import { DurationField } from '../../components/AccessRulesDataGrid/GridFields/DurationField'
import { FollowShift } from '../../components/AccessRulesDataGrid/GridFields/FollowShiftField'
import { OfferField } from '../../components/AccessRulesDataGrid/GridFields/OfferField'
import { PacingField } from '../../components/AccessRulesDataGrid/GridFields/PacingField'
import { PartySizeField } from '../../components/AccessRulesDataGrid/GridFields/PartySizeField'
import { PaymentField } from '../../components/AccessRulesDataGrid/GridFields/PaymentField'
import { RuleField } from '../../components/AccessRulesDataGrid/GridFields/RuleField'
import { ScheduleField } from '../../components/AccessRulesDataGrid/GridFields/ScheduleField'
import { SeatingAreasField } from '../../components/AccessRulesDataGrid/GridFields/SeatingAreasField'
import { SelectableUpgradesField } from '../../components/AccessRulesDataGrid/GridFields/SelectableUpgradesField'
import { SlotDescriptionField } from '../../components/AccessRulesDataGrid/GridFields/SlotDescriptionField'
import { TimeField } from '../../components/AccessRulesDataGrid/GridFields/TimeField'
import { accessListHeaderMessages } from '../../locales'
import type { GridColDef } from '@mui/x-data-grid'

type AccessListHeaderFields =
  | 'rule'
  | 'schedule'
  | 'time'
  | 'pax'
  | 'seatingAreas'
  | 'slotDescription'
  | 'paymentPolicy'
  | 'audience'
  | 'coverLimit'
  | 'duration'
  | 'bookStartTime'
  | 'bookEndTime'
  | 'offer'
  | 'bundledUpgrades'
  | 'selectableUpgrades'
  | 'pacing'
  | 'shiftCategory'
  | 'overviewCategory'

type AccessListHeaderMessagesKeys = `${AccessListHeaderFields}Header`

export function getGroupByColumnMapping(formatFn: FormatMessage) {
  return groupByColumnMapping.map(col => ({
    ...col,
    headerName: formatFn(accessListHeaderMessages[`${col.field}Header` as AccessListHeaderMessagesKeys]),
  }))
}

export function getColumnsMapping(formatFn: FormatMessage) {
  return columns.map(col => ({
    ...col,
    headerName: formatFn(accessListHeaderMessages[`${col.field}Header` as AccessListHeaderMessagesKeys]),
  }))
}

const groupByColumnMapping: GridColDef[] = [
  { field: 'shiftCategory', headerName: 'Shift Category' },
  { field: 'overviewCategory', headerName: 'Category' },
]

const columns: GridColDef[] = [
  {
    field: 'rule',
    headerName: 'Rule',
    width: 200,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { rule, color, spansMultipleShifts, shiftCategory, overviewCategory } = params.row
      const isInvalid = shiftCategory === 'NONE' || overviewCategory === 'UNASSIGNED'
      return <RuleField rule={rule} color={color} spansMultipleShifts={spansMultipleShifts ?? false} isInvalid={isInvalid} />
    },
  },
  {
    field: 'schedule',
    headerName: 'Schedule',
    width: 198,
    sortable: true,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const {
        schedule: { dayOfWeek, startDate, endDate },
        id,
        isOverride,
      } = params.row
      return <ScheduleField daysOfWeek={dayOfWeek} startDate={startDate} endDate={endDate} isOverride={isOverride} rowId={id} />
    },
  },
  {
    field: 'time',
    headerName: 'Time',
    width: 150,
    sortable: true,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const {
        time: { timeSlots, timeRanges, shiftCategories },
        isOverviewRow,
        accessTimeType,
        id,
      } = params.row

      return (
        <TimeField
          timeSlots={timeSlots}
          timeRanges={timeRanges}
          restrictToShift={isOverviewRow && shiftCategories.length > 0}
          shiftCategories={isOverviewRow && accessTimeType === AccessRuleAccessTimeEnum.ALL ? shiftCategories : undefined}
          rowId={id}
        />
      )
    },
  },
  {
    field: 'pax',
    headerName: 'Pax',
    width: 110,
    sortable: true,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.pax) {
        return <FollowShift />
      }
      const { pax, isFieldShift } = params.row
      return <PartySizeField pax={pax} isFieldShift={isFieldShift.pax} />
    },
  },
  {
    field: 'slotDescription',
    headerName: 'Slot Description',
    width: 150,
    sortable: true,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { slotDescription } = params.row
      return <SlotDescriptionField slotDescription={slotDescription} />
    },
  },
  {
    field: 'seatingAreas',
    headerName: 'Seating Areas',
    width: 150,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.seatingAreas) {
        return <FollowShift />
      }
      const { seatingAreas, tables, isFieldShift } = params.row
      return <SeatingAreasField seatingAreas={seatingAreas} tables={tables} isFieldShift={isFieldShift.seatingAreas} />
    },
  },
  {
    field: 'paymentPolicy',
    headerName: 'Payment',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.paymentPolicy) {
        return <FollowShift />
      }
      const { paymentPolicy, isFieldShift } = params.row
      return <PaymentField paymentPolicy={paymentPolicy} isFieldShift={isFieldShift.paymentPolicy} />
    },
  },
  {
    field: 'audience',
    headerName: 'Audience',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { audience, id } = params.row
      return <AudienceField tiers={audience} rowId={id} />
    },
  },
  {
    field: 'coverLimit',
    headerName: 'Limit',
    width: 150,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.coverLimit) {
        return <FollowShift />
      }
      const { coverLimit, isFieldShift } = params.row
      const { type, limit } = coverLimit
      return <CoverLimitField type={type} limit={limit} isFieldShift={isFieldShift.coverLimit} />
    },
  },
  {
    field: 'duration',
    headerName: 'Duration',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.duration) {
        return <FollowShift />
      }
      const { duration, guestDurationPicker, id, isFieldShift } = params.row
      return (
        <DurationField
          durations={duration}
          guestDurationPicker={guestDurationPicker}
          rowId={id}
          isFieldShift={isFieldShift.duration && !guestDurationPicker}
        />
      )
    },
  },
  {
    field: 'bookStartTime',
    headerName: 'Book Start Time',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { bookStartTime, id } = params.row
      return <BookStartTimeField startTimeTiers={bookStartTime} rowId={id} />
    },
  },
  {
    field: 'bookEndTime',
    headerName: 'Book End Time',
    width: 150,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { bookEndTime } = params.row
      return <BookEndTimeField bookEndTime={bookEndTime} />
    },
  },
  {
    field: 'offer',
    headerName: 'Offer',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { offer } = params.row
      return <OfferField offer={offer} />
    },
  },
  {
    field: 'bundledUpgrades',
    headerName: 'Bundled Upgrades',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      const { bundledUpgrades } = params.row
      return <BundledUpgradesField bundledUpgrades={bundledUpgrades} />
    },
  },
  {
    field: 'selectableUpgrades',
    headerName: 'Selectable Upgrades',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.selectableUpgrades) {
        return <FollowShift />
      }
      const { selectableUpgrades, id, isFieldShift } = params.row
      return <SelectableUpgradesField selectableUpgrades={selectableUpgrades} rowId={id} isFieldShift={isFieldShift.selectableUpgrades} />
    },
  },
  {
    field: 'pacing',
    headerName: 'Pacing',
    width: 200,
    sortable: false,
    renderCell: params => {
      if (params.row.isGroup) {
        return null
      }
      if (params.row.isOverviewRow && params.row.isFieldShift.pacing) {
        return <FollowShift />
      }
      const { pacing, id, isFieldShift } = params.row
      return <PacingField pacing={pacing} rowId={id} isFieldShift={isFieldShift.pacing} />
    },
  },
]

// This file was automatically generated from admin.venue.clients.import.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.VenueClientsImport = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.VenueClientsImportBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.VenueClientsImportBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-venue-clients-import"><div class="content-header">Venue Clients Import - ', soy.$$escapeHtml(opt_data.content.venue.name), ' BOOM</div>');
  if (opt_data.content.error_msgs) {
    output.append('<div class="error-message">');
    var errorList2301 = opt_data.content.error_msgs;
    var errorListLen2301 = errorList2301.length;
    for (var errorIndex2301 = 0; errorIndex2301 < errorListLen2301; errorIndex2301++) {
      var errorData2301 = errorList2301[errorIndex2301];
      output.append('<span style="display:block">', soy.$$escapeHtml(errorData2301), '</span>');
    }
    output.append('</div>');
  }
  output.append((opt_data.content.success) ? '<div class="gold-message"><span style="display:block">Clients successfully imported</span></div>' : '', '<form id="import-daily-clients-form" method="POST" action="/admin/venue/', soy.$$escapeHtml(opt_data.content.venue.id), '/clients/import"><div class="white-box">');
  if (opt_data.content.is_preview) {
    output.append('<input type="hidden" name="confirm" id="clients-import-confirm-input" value="true" /><div id="clients-import-confirm-display"><div class="white-box-block">Please confirm the following preview:</div><div class="white-box-block"><div>Name (status), Gender, Phone, Phone Locale, Email, Notes, Keywords</div>');
    var clients_entryList2316 = opt_data.content.clientspreview;
    var clients_entryListLen2316 = clients_entryList2316.length;
    for (var clients_entryIndex2316 = 0; clients_entryIndex2316 < clients_entryListLen2316; clients_entryIndex2316++) {
      var clients_entryData2316 = clients_entryList2316[clients_entryIndex2316];
      output.append('<div>', soy.$$escapeHtml(clients_entryData2316.name_display), ',', soy.$$escapeHtml(clients_entryData2316.gender), ',', soy.$$escapeHtml(clients_entryData2316.phone_number_formatted), ',', soy.$$escapeHtml(clients_entryData2316.phone_number_locale), ',', soy.$$escapeHtml(clients_entryData2316.email), ',', soy.$$escapeHtml(clients_entryData2316.notes), ',', soy.$$escapeHtml(clients_entryData2316.keywords), '</div>');
    }
    output.append('</div></div>');
  }
  output.append('<div id="clients-import-preview-display" class="', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="white-box-block"><div class="float-left w65">Use this tool to add many clients all at once. <br/>This will not override clients already entered.</div><div class="float-end"></div></div><div class="white-box-block"><div id="clients-batch-input-container"><div id="clients-batch-input-prompt">First,Last,Status,Gender,Phone,Phone Locale,Email,Notes,Keywords<br/>...</div><textarea name="clients_batch" id="clients-batch">', soy.$$escapeHtml(opt_data.content.clients_batch), '</textarea></div></div></div><div class="white-box-block">');
  if (opt_data.content.is_preview) {
    output.append('<div id="clients-import-confirm-buttons" class="float-right"><div class="link-next-to-button-div float-left"><a id="clients-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
    Nightloop.Templates.Widget.GoldButton({text: 'GO BACK', size: 'medium', id: 'clients-import-makechanges-btn'}, output);
    Nightloop.Templates.Widget.GoldButton({text: 'CONFIRM', size: 'medium', id: 'clients-import-confirm-btn'}, output);
    output.append('</div>');
  }
  output.append('<div id="clients-import-preview-buttons" class="float-right ', (opt_data.content.is_preview) ? 'no-display' : '', '"><div class="link-next-to-button-div float-left"><a id="clients-import-cancel-btn" class="close-main-colorbox-link" href="javascript:void(0);">cancel</a></div> ');
  Nightloop.Templates.Widget.GoldButton({text: 'PREVIEW', size: 'medium', id: 'clients-import-preview-btn'}, output);
  output.append('</div><div class="float-end"></div></div></div></form><script type="text/javascript">$(document).ready( function() {Pmp.Admin.Venue.Clients.Import.initialize();});<\/script></div>');
  return opt_sb ? '' : output.toString();
};

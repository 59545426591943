import { useEffect, useMemo, useState } from 'react'
import type { AccessRules, ShiftsByDate } from '@sevenrooms/core/domain'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { AccessRulesScheduleGrid } from '../../components/AccessRulesScheduleGrid/AccessRulesScheduleGrid'
import { AccessRuleRange } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'

interface AccessRulesCalendarProps {
  overviewDates: DateOnly[]
  shifts?: ShiftsByDate
  accessRules?: AccessRules
}

export function AccessRulesCalendar({ overviewDates, shifts, accessRules }: AccessRulesCalendarProps) {
  const { venue } = useVenueContext()
  const [{ range, date }, setParams] = useAccessRulesUrlParams()
  const focusDate = useMemo(() => (range === AccessRuleRange.DAY ? date : undefined), [date, range])

  const [displayDates, setDisplayDates] = useState<DateOnly[]>(overviewDates)
  useEffect(() => {
    if (focusDate) {
      setDisplayDates([date])
    } else {
      setDisplayDates(overviewDates)
    }
  }, [date, overviewDates, focusDate, range])

  const onClick = (date: DateOnly) => {
    setDisplayDates([date])
    setParams({ range: AccessRuleRange.DAY, date })
  }

  return (
    <AccessRulesScheduleGrid
      dates={displayDates}
      focusDate={focusDate}
      venueStartOfDayHour={venue.startOfDayHour}
      onClickDayHeader={onClick}
      shifts={shifts}
      accessRules={accessRules}
    />
  )
}

// This file was automatically generated from admin.squid.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.Squid = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.SquidBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SquidBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-squid"><h2>Generate SQUID</h2><p><form method="post" action="/admin/squid"><span style="padding-right:26px;">Squid Type:</span><select id="category" name="category" style="width:258px;" required><option disabled selected value="">Squid Type *</option><option value="VENUEGROUP">VenueGroup</option><option value="VENUE">Venue</option></select><br /><span style="padding-right:12px;">Salesforce ID:</span><input id="squid-salesforce-id-post" type="text" name="squid-salesforce-id" style="width:250px;" required/><br /><br /><input id="submit-search-squid" type="submit" value="SEARCH" /></form>');
  if (opt_data.has_searched) {
    output.append('<br /><br /><div id="squid-search-results">', (opt_data.has_squid) ? '<h3>Found SQUID for Squid Type \'' + soy.$$escapeHtml(opt_data.category) + '\' with Salesforce ID \'' + soy.$$escapeHtml(opt_data.salesforce_id) + '\'</h3>' : '<h3>SQUID NOT FOUND for Squid Type \'' + soy.$$escapeHtml(opt_data.category) + '\' with Salesforce ID \'' + soy.$$escapeHtml(opt_data.salesforce_id) + '\'</h3>', '<div id="squid-dropdown-wrapper">', (opt_data.category == 'VENUE' && opt_data.has_squid) ? 'Venues (select 0 or more to be associated with this SQUID):<br/>' : (opt_data.category == 'VENUE' && ! opt_data.has_squid) ? 'Venues (select 0 or more to be associated with a SQUID):<br/>' : (opt_data.category == 'VENUEGROUP' && opt_data.has_squid) ? 'VenueGroups (select 0 or more to be associated with this SQUID):<br/>' : (opt_data.category == 'VENUEGROUP' && ! opt_data.has_squid) ? 'VenueGroups (select 0 or more to be associated with a SQUID):<br/>' : '', '<select id="squid-dropdown" name="squid-dropdown">', (opt_data.category == 'VENUE') ? '<option disabled selected value="">Venue</option>' : '<option disabled selected value="">VenueGroup</option>');
    var categoryList1482 = opt_data.content.dropdown_data;
    var categoryListLen1482 = categoryList1482.length;
    for (var categoryIndex1482 = 0; categoryIndex1482 < categoryListLen1482; categoryIndex1482++) {
      var categoryData1482 = categoryList1482[categoryIndex1482];
      output.append('<option value="', soy.$$escapeHtml(categoryData1482.id), '">', soy.$$escapeHtml(categoryData1482.name), '</option>');
    }
    output.append('</select><br /><ul id="selected_list">');
    if (opt_data.has_squid) {
      var entityList1492 = opt_data.content.entity_list;
      var entityListLen1492 = entityList1492.length;
      for (var entityIndex1492 = 0; entityIndex1492 < entityListLen1492; entityIndex1492++) {
        var entityData1492 = entityList1492[entityIndex1492];
        output.append('<li id="', soy.$$escapeHtml(entityData1492.id), '">', soy.$$escapeHtml(entityData1492.name), '<img class="remove-venue" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/squid-minus.png" style="width:13px;height:13px;padding-left:10px;cursor:pointer;"/></li>');
      }
    }
    output.append('</ul></div>', (opt_data.category == 'VENUE') ? '<div id="parent-squid-error" style="color:red;display:none;">Parent SQUID ID does not exist</div><span style="padding-right:12px;">Parent SQUID ID (not required):</span><input id="squid-parent-id" type="text" name="squid-parent-id" value="' + soy.$$escapeHtml(opt_data.parent_id) + '" style="width:350px;" required/><br />' : '', '<input id="create-squid" type="submit" value="CREATE SQUID" /><input id="update-squid" type="submit" value="UPDATE SQUID\'s ENTITY LIST" style="display:none" />', (opt_data.has_squid) ? '<div id="squid-results">' : '<div id="squid-results" style="display:none">', '<br /><br /><span style="padding-right:26px;">SQUID:</span><input id="squid-value" type="text" name="squid-value" value="', soy.$$escapeHtml(opt_data.squid_id), '" style="width:350px;"><br /></div></div>');
  }
  output.append('</p></div><script type="text/javascript">var minus_img = \'', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/squid-minus.png\'; var create_squid_url = \'/admin/squid/createsquid\'; var update_squid_url = \'/admin/squid/updatesquid\'; var category = \'', soy.$$escapeHtml(opt_data.category), '\'; var salesforce_id = \'', soy.$$escapeHtml(opt_data.salesforce_id), '\'; var has_squid = \'', soy.$$escapeHtml(opt_data.has_squid), '\'; var squid_id = \'', soy.$$escapeHtml(opt_data.squid_id), '\'; var parent_id = \'', soy.$$escapeHtml(opt_data.parent_id), '\'; Pmp.Admin.Squid.initialize(minus_img, create_squid_url, update_squid_url, category, salesforce_id, has_squid, squid_id, parent_id);<\/script>');
  return opt_sb ? '' : output.toString();
};

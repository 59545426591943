import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { useAccessRuleContext } from '../../shared'
import { PaymentPolicyLocales } from '../PaymentPolicy.locales'
import { PaymentPolicyTestId } from '../PaymentPolicy.testIds'
import { getBundledUpgradesInfo, getPaymentPolicySummary } from './PaymentPolicyInfo'
import type { AccessRuleReportProps } from '../../shared/AccessRuleReportProps'

export function PaymentPolicyReport({ accessRule, position }: AccessRuleReportProps) {
  const { formatMessage } = useLocales()
  const { policies, accessRule: rawRule } = useAccessRuleContext()

  const { paymentPolicy, allowChannelsWithoutCCHolds } = accessRule

  if (rawRule?.useShiftPaymentAndPolicy) {
    return (
      <ReportPart caption={formatMessage(PaymentPolicyLocales.title)} data-test={PaymentPolicyTestId.report}>
        <ReportLine name={formatMessage(PaymentPolicyLocales.requirePayment)}>
          <Text>{formatMessage(accessRulesMessages.followShift)}</Text>
        </ReportLine>
        <ReportLine name={formatMessage(PaymentPolicyLocales.bundledUpgrades)}>
          <Text>{getBundledUpgradesInfo(formatMessage, paymentPolicy.bundledUpgrades)}</Text>
        </ReportLine>
        <ReportLine name={formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)}>
          {allowChannelsWithoutCCHolds ? formatMessage(commonMessages.yes) : formatMessage(commonMessages.no)}
        </ReportLine>
      </ReportPart>
    )
  }

  const ccHoldSummary = allowChannelsWithoutCCHolds
    ? formatMessage(PaymentPolicyLocales.bookOnUnsupportedLabel)
    : formatMessage(PaymentPolicyLocales.doNotBookOnUnsupported, {
        strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
      })
  const summary = getPaymentPolicySummary(paymentPolicy, policies, formatMessage)

  return (
    <ReportPart caption={formatMessage(PaymentPolicyLocales.title)} data-test={PaymentPolicyTestId.report} position={position}>
      {summary.map(([key, value]) => (
        <ReportLine key={key} name={key}>
          <Text>{value}</Text>
        </ReportLine>
      ))}
      {paymentPolicy.paymentRule === 'save_for_later' && (
        <ReportLine name={formatMessage(accessRulesMessages.additionalSettings)}>
          <Text>{ccHoldSummary}</Text>
        </ReportLine>
      )}
    </ReportPart>
  )
}

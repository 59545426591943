import { skipToken } from '@reduxjs/toolkit/dist/query'
import constate from 'constate'
import { useState, useMemo } from 'react'
import {
  type OneTimeEmailCampaignType,
  type OngoingEmailCampaignType,
  useGetEmailCampaignsQuery,
  useGetEmailCampaignActivityQuery,
  useGetClientTagGroupsQuery,
  type CampaignActivityItem,
} from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum, MarketingCampaignTypeEnum, isTagGlobal, type GenericTagGroup } from '@sevenrooms/core/domain'
import { startOfYear, format } from '@sevenrooms/core/timepiece'
import { useDateRange } from '@sevenrooms/core/ui-kit/form'
import type { CampaignDateFilters, CampaignPerformanceFiltersOption } from '@sevenrooms/marketing'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { formatAndFilterTags } from '@sevenrooms/mgr-marketing/utils'
import { useDateFilters } from './useDateFilters'

export interface OneTimeEmailCampaignWithActivity extends OneTimeEmailCampaignType {
  campaignActivity?: CampaignActivityItem
  formattedRecipientClientTags?: Array<GenericTagGroup | string>
  usesGlobalTag?: boolean
}

export interface OngoingEmailCampaignWithActivity extends OngoingEmailCampaignType {
  campaignActivity?: CampaignActivityItem
  formattedRecipientClientTags?: Array<GenericTagGroup | string>
  usesGlobalTag?: boolean
}

export type EmailCampaignWithActivity = OneTimeEmailCampaignWithActivity | OngoingEmailCampaignWithActivity

function useEmailCampaignFilters({ isOneTimeCampaignList = false }) {
  const dateFilters: { [U in CampaignDateFilters]: CampaignPerformanceFiltersOption } = useDateFilters()

  const [dateFilter, setDateFilter] = useState<CampaignPerformanceFiltersOption | null>(dateFilters.all)
  const { startDate: initialStartDate, endDate: initialEndDate } = { startDate: startOfYear(new Date(2000)), endDate: new Date() }
  const { startDate, endDate, onStartDateChange, onEndDateChange } = useDateRange(initialStartDate, initialEndDate)
  const { venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const [filteredCampaigns, setFilteredCampaigns] = useState<EmailCampaignWithActivity[] | undefined>(undefined)
  const [campaignIds, setCampaignIds] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState('')

  const isPOSActivated = venueSettings?.pos_activated
  const averageSpendPerCover = venueSettings?.average_spend_per_cover
  const averageSpendPerOrder = venueSettings?.average_spend_per_order
  const filterStatus = isOneTimeCampaignList
    ? [EmailCampaignStatusEnum.SENT, EmailCampaignStatusEnum.ACTIVE, EmailCampaignStatusEnum.DRAFT]
    : [EmailCampaignStatusEnum.ACTIVE, EmailCampaignStatusEnum.INACTIVE, EmailCampaignStatusEnum.DRAFT]
  const [campaignStatus, setCampaignStatus] = useState<string[]>(filterStatus)
  const {
    data: campaignsResults,
    isFetching: isCampaignQueryFetching,
    isSuccess: isCampaignQuerySuccess,
    isError: isCampaignsQueryError,
    refetch: campaignsRefetch,
  } = useGetEmailCampaignsQuery({
    venueId: venueId || '',
    campaignType: isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
  })
  const campaigns = campaignsResults?.results

  const {
    data: campaignActivityData,
    isFetching: isActivityQueryFetching,
    isSuccess: isActivityQuerySuccess,
    error: activityQueryError,
    isError: isActivityQueryError,
    refetch: activityQueryRefetch,
  } = useGetEmailCampaignActivityQuery({
    venueId: venueId || '',
    startDate: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
    endDate: endDate ? format(endDate as Date, 'yyyy-MM-dd') : undefined,
    campaignIds:
      !isOneTimeCampaignList && (campaignIds.length || searchValue.length) ? filteredCampaigns?.map(campaign => campaign.id) : undefined,
    campaignType: isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
    campaignStatus,
    searchValue,
  })

  const {
    data: autoTags,
    isFetching: isAutoTagsFetching,
    isError: isAutoTagsError,
  } = useGetClientTagGroupsQuery(venueKey ? { venueKey } : skipToken)

  const isFetching = isCampaignQueryFetching || isActivityQueryFetching || isAutoTagsFetching
  const isError = isFetching ? false : isCampaignsQueryError || isActivityQueryError || isAutoTagsError

  useMemo(() => {
    if (isOneTimeCampaignList) {
      const filteredCampaignsResults = campaigns
        ?.filter(
          campaign =>
            campaignStatus.includes(campaign.status) &&
            (searchValue.length > 0 ? campaign.campaignName.toLowerCase().includes(searchValue.toLowerCase()) : true)
        )
        .map(campaign => ({
          ...campaign,
          usesGlobalTag: isTagGlobal(campaign?.recipientClientTags, autoTags),
          formattedRecipientClientTags: formatAndFilterTags(campaign?.recipientClientTags, autoTags),
          campaignActivity: campaignActivityData?.campaignActivity[campaign.id],
        }))
      setFilteredCampaigns(filteredCampaignsResults)
    } else {
      const filteredCampaignsResults = campaigns?.filter(
        campaign => campaignStatus.includes(campaign.status) && (campaignIds.length > 0 ? campaignIds.includes(campaign.id) : true)
      )
      setFilteredCampaigns(filteredCampaignsResults)
    }
  }, [campaignStatus, campaigns, campaignIds, searchValue, isOneTimeCampaignList, campaignActivityData, autoTags])

  useMemo(() => {
    activityQueryRefetch()
  }, [activityQueryRefetch])

  return {
    setStartDate: onStartDateChange,
    setEndDate: onEndDateChange,
    campaigns,
    filteredCampaigns,
    setDateFilter,
    setCampaignStatus,
    setCampaignIds,
    dateFilters,
    dateFilter,
    isCampaignQueryFetching,
    isCampaignQuerySuccess,
    isPOSActivated,
    averageSpendPerCover,
    averageSpendPerOrder,
    campaignActivityData,
    isActivityQueryFetching,
    isActivityQuerySuccess,
    activityQueryError,
    activityQueryRefetch,
    startDate,
    endDate,
    campaignStatus,
    campaignsRefetch,
    searchValue,
    setSearchValue,
    isError,
    isFetching,
  }
}

export const [EmailCampaignPerformanceContext, useEmailCampaignPerformanceContext] = constate(useEmailCampaignFilters)

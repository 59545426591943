import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SideNavigationCategoryItem } from '@sevenrooms/core/ui-kit/layout'
import { marketingCommonMessages } from '@sevenrooms/marketing'
import { useAvailableUpgradeNavigationItems } from '@sevenrooms/mgr-acct-upgrades'
import {
  useNavigationPermissionsContext,
  useUserContext,
  useVenueContext,
  useVenueSettingsContext,
  AvailableUpgrades,
} from '@sevenrooms/mgr-core'
import { Space, GroupBookings } from '@sevenrooms/mgr-group-bookings'
import { EmailTemplate } from '@sevenrooms/mgr-marketing-email-template'
import { marketingHomeMessages } from '@sevenrooms/mgr-marketing-home/locales'
import { MarketingHome } from '@sevenrooms/mgr-marketing-home/MarketingHome/MarketingHome'
import {
  OneTimeEmailCampaignViewer,
  OneTimeEmailCampaignDeepDiveViewer,
  EmailCampaignTemplates,
  OneTimeEmailCampaign,
  OneTimeEmailCampaignCenter,
} from '@sevenrooms/mgr-marketing-one-time-email-center'
import { oneTimeEmailCenterMessages } from '@sevenrooms/mgr-marketing-one-time-email-center/locales'
import { oneTimeSMSCenterMessages } from '@sevenrooms/mgr-marketing-one-time-sms-center/locales'
import { OneTimeSMSCampaign } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaign'
import { OneTimeSMSCampaignCenter } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaignCenter'
import { OneTimeSMSCampaignViewer } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaignViewer'
import { SMSCampaignTemplates } from '@sevenrooms/mgr-marketing-one-time-sms-center/SMSCampaignTemplates'
import {
  EmailCenter,
  OngoingEmailCampaignFromScratch,
  OngoingEmailCampaignFromTemplate,
} from '@sevenrooms/mgr-marketing-ongoing-email-center'
import { ConfirmArchiveModal } from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import { emailCenterMessages } from '@sevenrooms/mgr-marketing-ongoing-email-center/locales'
import { CreateEditPromoCode } from '@sevenrooms/mgr-marketing-promo-code/CreateEditPromoCode/CreateEditPromoCode'
import { promoCodeManagementMessages } from '@sevenrooms/mgr-marketing-promo-code/locales'
import { PromoCodeManagement } from '@sevenrooms/mgr-marketing-promo-code/PromoCodeManagement/PromoCodeManagement'
import { Referrals, referralsMessages } from '@sevenrooms/mgr-marketing-referrals'
import { ReferralProgramEnableModal } from '@sevenrooms/mgr-marketing-referrals/components/ReferralProgramEnableModal'
import { VenueProfile as NewVenueProfile } from '@sevenrooms/mgr-marketing-venue-profile'
import { CreateEditExperience } from '@sevenrooms/mgr-offers/CreateEditExperience'
import { CreateExperienceFromTemplate } from '@sevenrooms/mgr-offers/CreateExperienceFromTemplate'
import { ExperiencesManagement } from '@sevenrooms/mgr-offers/ExperiencesManagement'
import { perksMessages } from '@sevenrooms/mgr-perks/locales'
import { CreateEditEarlyAccessPerk, CreateEditExclusivePerk, CreateEditPerk, Perks } from '@sevenrooms/mgr-perks/views'
import { routes } from '@sevenrooms/routes'
import Autotags from '../../../../application/site/static/app/manager/pages/src/single-venue/marketing/containers/Autotags'
import Reviews from '../../../../application/site/static/app/manager/pages/src/single-venue/marketing/containers/Reviews'

const MARKETING_UPGRADES = new Set([
  AvailableUpgrades.sms_marketing,
  AvailableUpgrades.sms_marketing_webstore,
  AvailableUpgrades.email_marketing,
  AvailableUpgrades.email_marketing_webstore,
])
/**
 * If you make changes to this file, please make sure to update the corresponding api file: venue_side_navigation_interactor.py
 */
export const useNavigationItems = (): Array<SideNavigationCategoryItem> => {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const user = useUserContext()
  const permissions = useNavigationPermissionsContext()
  const { formatMessage } = useLocales()
  const optionalUpgradesNav = useAvailableUpgradeNavigationItems(MARKETING_UPGRADES)

  if (!venue || !venue.urlKey || !user || !venueSettings || !permissions) {
    return []
  }

  const venueKeyParams = { params: { venueKey: venue.urlKey } }
  const isSizzleSuperhero = venueSettings.sizzle_superheroes_only && user.is_superhero
  const showSizzleContent = venueSettings.is_full_sizzle_package || venueSettings.is_sizzle_standalone || isSizzleSuperhero
  const canAccessLoyaltyAndPerks = permissions.isLoyaltyAndPerksEnabled && permissions.canAccessMarketingTools
  const canAccessExclusivePerks = venueSettings?.is_exclusive_access_perks_enabled && permissions.isLoyaltyAndPerksEnabled
  const canAccessEarlyAccessPerks = venueSettings?.is_early_access_perks_enabled && permissions.isLoyaltyAndPerksEnabled
  const canAccessDeepDiveView = venueSettings?.is_email_marketing_deep_dive_enabled

  /**
   * Redirects for events, upgrades, and tracking links are handled by the back-end routing.
   */
  return [
    {
      id: 'marketing',
      title: formatMessage(marketingCommonMessages.marketing),
      allowed: permissions.canViewMarketing,
      items: [
        {
          id: 'standalone-marketing-home',
          title: formatMessage(marketingHomeMessages.marketingHomeTitle),
          to: nav.href(routes.manager2.marketing.home, venueKeyParams),
          items: [],
          reload: true,
          routeConfigs: [{ path: routes.manager2.marketing.home.path, component: MarketingHome }],
          allowed: venue.isStandaloneMarketingVenue,
        },
        {
          id: 'events',
          title: formatMessage(marketingCommonMessages.events),
          to: nav.href(routes.manager.events, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.event_widget_enabled,
        },
        {
          id: 'profile-information',
          title: formatMessage(marketingCommonMessages.profileInformation),
          items: [],
          reload: true,
          to: nav.href(routes.manager2.marketing.profileInformation, venueKeyParams),
          routeConfigs: [{ path: routes.manager2.marketing.profileInformation.path, component: NewVenueProfile }],
        },
        {
          id: 'upgrades',
          title: formatMessage(marketingCommonMessages.upgrades),
          to: nav.href(routes.manager.upgrades, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.dining_widget_enabled,
        },
        {
          id: 'tracking-links',
          title: formatMessage(marketingCommonMessages.trackingLinks),
          to: nav.href(routes.manager.trackingLinks, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.dining_widget_enabled || venueSettings.event_widget_enabled,
        },
        {
          id: 'guest-satisfaction',
          title: formatMessage(marketingCommonMessages.guestSatisfaction),
          to: nav.href(routes.manager2.marketing.reviews, venueKeyParams),
          items: [],
          reload: true,
          allowed:
            venueSettings.is_full_sizzle_package ||
            venueSettings.is_sizzle_standalone ||
            venueSettings.is_guest_satisfaction_standalone_enabled ||
            (venueSettings.sizzle_superheroes_only && user.is_superhero),
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [{ path: routes.manager2.marketing.reviews.path, component: Reviews }],
        },
        {
          id: 'auto-tags',
          title: `${formatMessage(marketingCommonMessages.autoTagging)}${
            isSizzleSuperhero ? formatMessage(marketingCommonMessages.sizzleSuperheroSuffix) : ''
          }`,
          to: nav.href(routes.manager2.marketing.autotags, venueKeyParams),
          items: [],
          reload: true,
          allowed: showSizzleContent,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [{ path: routes.manager2.marketing.autotags.path, component: Autotags }],
        },
        {
          id: 'automated-email-campaigns',
          title: `${formatMessage(emailCenterMessages.emailCenterNavTitle)}${
            isSizzleSuperhero ? formatMessage(marketingCommonMessages.sizzleSuperheroSuffix) : ''
          }`,
          to: nav.href(routes.manager2.marketing.emailCenter.emails, venueKeyParams),
          items: [],
          reload: true,
          allowed: (venueSettings.is_custom_automated_emails_enabled || venueSettings.pre_built_campaigns_enabled) && showSizzleContent,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            {
              path: routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch.path,
              component: OngoingEmailCampaignFromScratch,
            },
            {
              path: routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromTemplate.path,
              component: OngoingEmailCampaignFromTemplate,
            },
            {
              path: routes.manager2.marketing.emailCenter.emails.emailBuilder.path,
              component: OngoingEmailCampaignFromTemplate,
            },
            {
              path: routes.manager2.marketing.emailCenter.emails.editEmailFromScratch.path,
              component: OngoingEmailCampaignFromScratch,
            },
            { path: routes.manager2.marketing.emailCenter.emails.editEmail.path, component: OngoingEmailCampaignFromTemplate },
            {
              path: routes.manager2.marketing.emailCenter.emails.archiveEmail.path,
              component: ConfirmArchiveModal,
            },
            { path: routes.manager2.marketing.emailCenter.emails.emailTemplate.path, component: EmailTemplate },
            {
              path: routes.manager2.marketing.emailCenter.path,
              component: EmailCenter,
            },
          ],
        },
        {
          id: 'email-campaigns',
          title: `${formatMessage(oneTimeEmailCenterMessages.oneTimeEmailCenterTitle)}${
            isSizzleSuperhero ? formatMessage(marketingCommonMessages.sizzleSuperheroSuffix) : ''
          }`,
          to: nav.href(routes.manager2.marketing.oneTimeEmailCenter, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.batch_emails_enabled && showSizzleContent,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            {
              path: routes.manager2.marketing.oneTimeEmailCenter.emailCampaignTemplates.path,
              component: EmailCampaignTemplates,
            },
            ...[
              canAccessDeepDiveView
                ? {
                    path: routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive.path,
                    component: OneTimeEmailCampaignDeepDiveViewer,
                  }
                : {
                    path: routes.manager2.marketing.oneTimeEmailCenter.viewOneTimeEmail.path,
                    component: OneTimeEmailCampaignViewer,
                  },
            ],
            {
              path: routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail.path,
              component: OneTimeEmailCampaign,
            },
            {
              path: routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail.path,
              component: OneTimeEmailCampaign,
            },
            {
              path: routes.manager2.marketing.oneTimeEmailCenter.path,
              component: OneTimeEmailCampaignCenter,
            },
          ],
        },
        {
          id: 'sms-campaigns',
          title: `${formatMessage(oneTimeSMSCenterMessages.oneTimeSMSCenterTitle)}${
            isSizzleSuperhero ? formatMessage(marketingCommonMessages.sizzleSuperheroSuffix) : ''
          }`,
          to: nav.href(routes.manager2.marketing.oneTimeSMSCenter, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.is_sms_marketing_enabled,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            {
              path: routes.manager2.marketing.oneTimeSMSCenter.smsCampaignTemplates.path,
              component: SMSCampaignTemplates,
            },
            {
              path: routes.manager2.marketing.oneTimeSMSCenter.viewOneTimeSMS.path,
              component: OneTimeSMSCampaignViewer,
            },
            {
              path: routes.manager2.marketing.oneTimeSMSCenter.createOneTimeSMS.path,
              component: OneTimeSMSCampaign,
            },
            {
              path: routes.manager2.marketing.oneTimeSMSCenter.editOneTimeSMS.path,
              component: OneTimeSMSCampaign,
            },
            {
              path: routes.manager2.marketing.oneTimeSMSCenter.path,
              component: OneTimeSMSCampaignCenter,
            },
          ],
        },
        {
          id: 'offers',
          title: `${formatMessage(marketingCommonMessages.offers)}${
            isSizzleSuperhero ? formatMessage(marketingCommonMessages.sizzleSuperheroSuffix) : ''
          }`,
          to: nav.href(routes.manager2.marketing.offers, venueKeyParams),
          items: [],
          reload: true,
          allowed: showSizzleContent || venueSettings.is_thefork_integration_enabled,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            { path: routes.manager2.marketing.offers.createExperience.path, component: CreateEditExperience },
            { path: routes.manager2.marketing.offers.createExperienceFromTemplate.path, component: CreateExperienceFromTemplate },
            { path: routes.manager2.marketing.offers.editExperience.path, component: CreateEditExperience },
            { path: routes.manager2.marketing.offers.path, component: ExperiencesManagement },
          ],
        },
        {
          id: 'group-bookings',
          title: formatMessage(marketingCommonMessages.groupBookings),
          to: nav.href(routes.manager2.marketing.groupBookings, venueKeyParams),
          items: [],
          reload: true,
          allowed: venueSettings.new_group_bookings_configuration_enabled,
          redirect: nav.href(routes.manager2.marketing.offers, venueKeyParams),
          routeConfigs: [
            { path: routes.manager2.marketing.groupBookings.createSpace.path, component: Space },
            { path: routes.manager2.marketing.groupBookings.editSpace.path, component: Space },
            { path: routes.manager2.marketing.groupBookings.path, component: GroupBookings },
          ],
        },
        {
          id: 'promo-code-management',
          title: formatMessage(promoCodeManagementMessages.promoCodesTitle),
          to: nav.href(routes.manager2.marketing.promoCodesManagement, venueKeyParams),
          items: [],
          reload: true,
          routeConfigs: [
            { path: routes.manager2.marketing.promoCodesManagement.path, component: PromoCodeManagement },
            { path: routes.manager2.marketing.promoCode.path, component: CreateEditPromoCode },
          ],
        },
        {
          id: 'perks-2',
          title: formatMessage(perksMessages.perks),
          to: nav.href(routes.manager2.marketing.perks2, venueKeyParams),
          items: [],
          reload: true,
          allowed: canAccessLoyaltyAndPerks,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            {
              path: routes.manager2.marketing.perks2.edit.path,
              component: CreateEditPerk,
            },
            {
              path: routes.manager2.marketing.perks2.create.path,
              component: CreateEditPerk,
            },
            ...(canAccessEarlyAccessPerks
              ? [
                  {
                    path: routes.manager2.marketing.perks2.createEarlyAccess.path,
                    component: CreateEditEarlyAccessPerk,
                  },
                  {
                    path: routes.manager2.marketing.perks2.editEarlyAccess.path,
                    component: CreateEditEarlyAccessPerk,
                  },
                ]
              : []),
            ...(canAccessExclusivePerks
              ? [
                  {
                    path: routes.manager2.marketing.perks2.createExclusive.path,
                    component: CreateEditExclusivePerk,
                  },
                  {
                    path: routes.manager2.marketing.perks2.editExclusive.path,
                    component: CreateEditExclusivePerk,
                  },
                ]
              : []),
            {
              path: routes.manager2.marketing.perks2.path,
              component: Perks,
            },
          ],
        },
        {
          id: 'referral-program',
          title: formatMessage(referralsMessages.referralProgram),
          to: nav.href(routes.manager2.marketing.referrals, venueKeyParams),
          items: [],
          reload: true,
          allowed: venue.groupReferralProgramEnabled,
          redirect: nav.href(routes.manager.events, venueKeyParams),
          routeConfigs: [
            { path: routes.manager2.marketing.referrals.path, component: Referrals },
            {
              path: routes.manager2.marketing.referrals.referralProgramEnableModal.path,
              component: ReferralProgramEnableModal,
            },
          ],
        },
      ],
    },
    ...optionalUpgradesNav,
  ]
}

import { cleanNullish, type AccessRuleInput } from '@sevenrooms/core/api'
import { type AccessRuleStartType, AccessRuleTagRestrictionEnum } from '@sevenrooms/core/domain'
import { getDurationMinutesByPartySizeFromField } from './components/Durations/utils'
import { transformPacingMinutesToDisplayTime } from './components/Pacing/utils'
import type { AccessRuleForm } from './AccessRule.zod'
import type { BookingChannelsForm } from './components/BookingChannels/BookingChannels.zod'
import type { BookingWindowForm } from './components/BookingWindow/BookingWindow.zod'
import type { Recurring } from './components/RecurringDecision'

export const WEEK_DAYS = ['M', 'T', 'W', 'TH', 'F', 'SA', 'S']

export const formatDate = (date: Date) => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

/**
 * Removes properties that are empty strings
 * @param obj - An object whose properties are all optional strings
 * @returns The same object without the properties that were empty strings
 */
export function removeEmpties<T extends { [key: string]: string | undefined | null }>(obj: T): Partial<T> {
  return Object.keys(obj).reduce((acc, curr) => (obj[curr] ? { ...acc, [curr]: obj[curr] } : acc), {} as Partial<T>)
}

export function toAccessRuleInput(
  fromDate: string,
  venue: string,
  isGoogleBookingEnabled: boolean,
  isTheforkIntegrationEnabled: boolean,
  decision?: Recurring
): (formData: AccessRuleForm) => AccessRuleInput {
  return (formData: AccessRuleForm) =>
    toAccessRuleInputInternal(formData, fromDate, venue, isGoogleBookingEnabled, isTheforkIntegrationEnabled, decision)
}

export function toAccessRuleInputInternal(
  formData: AccessRuleForm,
  fromDate: string,
  venue: string,
  isGoogleBookingEnabled: boolean,
  isTheforkIntegrationEnabled: boolean,
  decision?: Recurring
): AccessRuleInput {
  const { schedule, guestFacing, bookingChannels, bookingWindow, allowChannelsWithoutCCHolds, guestDurationPicker, durations } = formData
  const {
    offer: experienceID,
    timeslotDescription,
    title: publicTitle,
    description: publicDescription,
    image,
    allowUnsupported: allowUnsupportedChannels,
    minSpend,
  } = guestFacing

  const { rawUrl: publicPhotoUrl, fileData: publicPhotoFile } = image ?? {}

  // validations don't allow this to be null, but it "can be null"
  const startDate = formatDate(schedule.dateRange.startDate ?? new Date())
  const end = schedule.dateRange.endDate
  const endDate = end ? formatDate(end) : undefined
  // WEEK_DAYS[i] for i = 0..6 is not undefined
  const days = schedule.selectedDays.map(idx => WEEK_DAYS[idx] as string)
  const { accessTimeType, specificTimes, startTime, endTime } = schedule
  const shiftCategories = schedule.restrictToShifts || accessTimeType === 'ALL' ? schedule.shiftCategories : []

  const isRecurring = decision === 'following' || decision === 'all'
  const entireSeries = decision === 'all'

  const tableIds = formData.seatingAreas.selection.filter(({ value: { isTable } }) => isTable).map(({ id }) => id)
  const seatingAreaIds = formData.seatingAreas.selection.filter(({ value: { isTable } }) => !isTable).map(({ id }) => id)
  const upsellCategories = formData.upgrades.includeUpgrades === 'included' ? formData.upgrades.selection.map(({ id }) => id) : []
  const reservationTags = formData.reservationTags.map(item => item.id)
  const customPacing = formData.pacing.setCustomPacing ? transformPacingMinutesToDisplayTime(formData.pacing?.customPacing ?? {}) : {}

  const audienceTiers = buildAudienceTiers(bookingChannels, bookingWindow)

  const cutoffNum = bookingWindow.cutoffTime.count !== 0 ? bookingWindow.cutoffTime.count : undefined
  const cutoffType = cutoffNum ? bookingWindow.cutoffTime.unit : undefined
  const cutoffHour = cutoffNum ? getHourValue(bookingWindow.cutoffTime.beforeTime) : undefined

  const googleReserveSeatingArea = isGoogleBookingEnabled ? formData.seatingAreas.googleReserveSeatingArea : undefined
  const theForkSeatingArea = isTheforkIntegrationEnabled ? formData.seatingAreas.theForkSeatingArea : undefined

  const bundledUpgrades = formData.paymentPolicy.bundledUpgrades.map(({ quantity, quantityType, upgrades }) => ({
    ids: upgrades.map(({ id }) => id),
    quantity,
    quantityType,
  }))

  const {
    bookingPolicy,
    cancelPolicy,
    customBookingPolicy,
    customCancelPolicy,
    useShiftPaymentAndPolicy,
    paymentRule,
    chargeCutoff,
    setChargeCutoff,
    cancelCutoffChoice,
    cancelCutoff,
    autoCharge: { amount: autoChargeAmount, chargeType: autoChargeAmountType },
    bookingCharge: { amount: ccCost, chargeType: ccChargeType },
    partySizeMin,
    partySizeType,
    cancelCharge,
    refund,
    charges: {
      applyServiceCharge,
      serviceChargeType,
      applyTax: ccApplyTaxRate,
      taxId: taxGroupId,
      applyGratuity: applyGratuityCharge,
      serviceChargePercent: serviceCharge,
      gratuityType,
      gratuityPercent: ccGratuity,
      requireGratuity: requireGratuityCharge,
    },
  } = formData.paymentPolicy

  const hasCustomBookingPolicy = bookingPolicy === 'custom'
  const hasCustomCancelPolicy = cancelPolicy === 'custom'
  const [requireCreditCard, ccPaymentRule] = paymentRule === 'none' ? [false, null] : [true, paymentRule]
  const autoChargeType = paymentRule === 'save_for_later' ? cancelCharge : refund

  const {
    beforeTime: autoCutoffHour,
    count: autoCutoffNum,
    unit: autoCutoffType,
  }: Partial<AccessRuleForm['paymentPolicy']['chargeCutoff']> = setChargeCutoff ? chargeCutoff : {}

  const {
    unit: cancelCutoffType,
    count: cancelCutoffNum,
    beforeTime: cancelCutoffHour,
  }: Partial<AccessRuleForm['paymentPolicy']['cancelCutoff']> = cancelCutoffChoice === 'CUTOFF' ? cancelCutoff : {}

  const ignoreCcFor3PBookers = useShiftPaymentAndPolicy || paymentRule === 'save_for_later' ? allowChannelsWithoutCCHolds : false

  const durationMin =
    guestDurationPicker.guestMustSpecifyDuration && guestDurationPicker.durationMin !== null ? guestDurationPicker.durationMin : undefined
  const durationMax =
    guestDurationPicker.guestMustSpecifyDuration && guestDurationPicker.durationMax != null ? guestDurationPicker.durationMax : undefined

  const durationMinutesByPartySize = getDurationMinutesByPartySizeFromField(durations)

  return {
    // curried
    fromDate,
    venue,
    isRecurring,
    entireSeries,
    // formData
    audienceTiers,
    startDate,
    endDate,
    days,
    specificTimes,
    startTime,
    endTime,
    accessTimeType,
    name: formData.name,
    restrictToShifts: schedule.restrictToShifts,
    shiftCategories,
    partySizeMin: formData.partySize?.min,
    partySizeMax: formData.partySize?.max,
    inventoryCount: formData.reservationCoverLimit?.count,
    inventoryType: formData.reservationCoverLimit?.type,
    tableIds,
    seatingAreaIds,
    isHeld: formData.seatingAreas.treatAsBlocked,
    isUsingShiftUpsells: !formData.upgrades.edited,
    upsellCategories,
    reservationTags,
    allowUnsupportedChannels,
    defaultPacing: formData.pacing?.maxCoversPerSeatingInterval,
    isPacingHeld: formData.pacing?.isPacingHeld,
    customPacing,
    cutoffNum,
    cutoffType,
    cutoffHour,
    googleReserveSeatingArea,
    theForkSeatingArea,
    publicPhotoFile,
    useShiftPaymentAndPolicy,
    // eslint-disable-next-line no-nested-ternary
    policyType: hasCustomBookingPolicy ? 'custom' : bookingPolicy ? 'default' : null,
    bookingPolicyId: hasCustomBookingPolicy || bookingPolicy === 'default' ? null : bookingPolicy,
    bookingPolicy: hasCustomBookingPolicy ? customBookingPolicy : null,
    // eslint-disable-next-line no-nested-ternary
    cancellationPolicyType: hasCustomCancelPolicy ? 'custom' : cancelPolicy ? 'default' : null,
    cancellationPolicyId: hasCustomCancelPolicy || cancelPolicy === 'default' ? null : cancelPolicy,
    cancellationPolicy: hasCustomCancelPolicy ? customCancelPolicy : null,
    ignoreCcFor3PBookers,
    requireCreditCard,
    ccPaymentRule,
    ccPartySizeMin: partySizeType === 'gt' ? partySizeMin : null,
    ccChargeType,
    ccCost: paymentRule === 'advanced_payment' ? ccCost : 0,
    bundledUpgrades,
    autoChargeType,
    autoChargeAmount,
    autoChargeAmountType,
    autoCutoffType,
    autoCutoffNum,
    autoCutoffHour,
    cancelCutoffSelect: cancelCutoffChoice === 'NEVER' ? 'NEVER' : null,
    cancelCutoffType,
    cancelCutoffNum,
    cancelCutoffHour,
    applyServiceCharge,
    serviceChargeType,
    ccApplyTaxRate,
    taxGroupId,
    applyGratuityCharge,
    serviceCharge,
    gratuityType,
    ccGratuity: applyGratuityCharge ? ccGratuity : null,
    requireGratuityCharge,
    ...cleanNullish({ experienceID }),
    ...removeEmpties({
      timeslotDescription,
      publicTitle,
      publicDescription,
      publicPhotoUrl,
    }),
    durationMin,
    durationMax,
    durationMinutesByPartySize,
    guaranteeBookings: formData.reservationCoverLimit.guaranteeBookings,
    excludeFromShiftPacing: formData.pacing.excludeFromShiftPacing,
    minSpend,
    internalDisplayColor: formData.internalDisplayColor,
  }
}

function getHourValue(hour: string) {
  return !hour || hour === '0' ? undefined : hour
}

function buildAudienceTiers(bookingChannels: BookingChannelsForm, bookingWindow: BookingWindowForm) {
  if (bookingChannels.length === 1) {
    return [
      buildAudienceTier(
        bookingWindow.startTime,
        bookingChannels[0] ? bookingChannels[0].selected : [],
        bookingChannels[0] ? bookingChannels[0].audienceTierId : null,
        bookingChannels[0] ? bookingChannels[0].tagRestriction : AccessRuleTagRestrictionEnum.NONE,
        bookingChannels[0] ? bookingChannels[0].viewClientTags : { tags: [], hasDeletedTags: false },
        bookingChannels[0] ? bookingChannels[0].bookClientTags : { tags: [], hasDeletedTags: false }
      ),
    ]
  }
  return bookingChannels.map(({ startTime, selected, audienceTierId, tagRestriction, viewClientTags, bookClientTags }) =>
    buildAudienceTier(startTime, selected, audienceTierId, tagRestriction, viewClientTags, bookClientTags)
  )
}

function buildAudienceTier(
  startTime: BookingChannelsForm[number]['startTime'],
  selected: BookingChannelsForm[number]['selected'],
  audienceTierId: BookingChannelsForm[number]['audienceTierId'],
  tagRestriction: BookingChannelsForm[number]['tagRestriction'],
  viewClientTags: BookingChannelsForm[number]['viewClientTags'],
  bookClientTags: BookingChannelsForm[number]['bookClientTags']
) {
  let clientTags: string[] = []
  if (tagRestriction === AccessRuleTagRestrictionEnum.VIEW) {
    clientTags = viewClientTags.tags.map(tag => tag.id)
  } else if (tagRestriction === AccessRuleTagRestrictionEnum.BOOK) {
    clientTags = bookClientTags.tags.map(tag => tag.id)
  }
  return {
    startNum: startTime.count,
    startType: startTime.unit as AccessRuleStartType,
    startHour: getHourValue(startTime.beforeTime),
    channels: selected.map(option => option.id),
    tagRestriction,
    clientTags,
    concierges: [],
    thirdParties: [],
    audienceTierId,
    isEarlyAccess: startTime.isEarlyAccess,
  }
}

// This file was automatically generated from concierge.availability.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }


Nightloop.Templates.Concierge.Availability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="conciergeavailview"><div class="availselect guests"><select class=\'guests\' name="guests" onChange="this.blur()"><option value="">select</option>');
  var choiceList10471 = opt_data.guests_options;
  var choiceListLen10471 = choiceList10471.length;
  for (var choiceIndex10471 = 0; choiceIndex10471 < choiceListLen10471; choiceIndex10471++) {
    var choiceData10471 = choiceList10471[choiceIndex10471];
    output.append('<option value="', soy.$$escapeHtml(choiceData10471[0]), '"', (choiceData10471[0] == opt_data.guests) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData10471[1]), '</option>');
  }
  output.append('</select><div class="title">Party size</div></div><div class="availselect date"><div class="val"></div><div class="title">Date</div><input class=\'avail-date\' type="hidden" name="date" value="', soy.$$escapeHtml(opt_data.date), '" ></div><div class="avail-date-calendar-container no-display"><div  class=\'avail-date-calendar\'></div></div><div class="availselect time"><select class=\'time_slot\' name="time_slot" onChange="this.blur()"><option value="">select</option>');
  var choiceList10485 = opt_data.time_options;
  var choiceListLen10485 = choiceList10485.length;
  for (var choiceIndex10485 = 0; choiceIndex10485 < choiceListLen10485; choiceIndex10485++) {
    var choiceData10485 = choiceList10485[choiceIndex10485];
    output.append('<option value="', soy.$$escapeHtml(choiceData10485), '" ', (choiceData10485 == opt_data.time_slot) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData10485), '</option>');
  }
  output.append('</select><div class="title">Time</div></div><div class="availbutton">search</div><div class="float-end"></div><div class="results no-display"><div class="heading">AVAILABILITY ON <span class="searched-date"></span>&nbsp; AROUND <span class="searched-timeslot">', soy.$$escapeHtml(opt_data.time_slot), '</span>&nbsp; FOR <span class="searched-guests">', soy.$$escapeHtml(opt_data.guests), '</span> PEOPLE</div><div class="rows"><div class="slot s-0"></div><div class="slot s-1"></div><div class="slot s-2"></div><div class="slot s-3"></div><div class="slot s-4"></div><div class="slot s-5"></div><div class="slot s-6"></div></div><div><div class="no-results no-display float-left">NO AVAILABILITY FOUND</div>', (opt_data.can_custom_dining_request) ? '<div class="submit-request"><div class="desc">Can\'t find what you\'re looking for?</div><div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div><div class="float-end"></div></div>' : '', '<div class="float-end"></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="row"><div class="date ', (opt_data.is_search) ? 'searched' : '', '"><div>', soy.$$escapeHtml(opt_data.date_formatted), '</div></div><div class="times">');
  if (opt_data.is_empty_code == 'VENUE_CLOSED') {
    output.append('<div class="empty_code">', soy.$$escapeHtml(opt_data.venue_name), ' is closed on this date</div>');
  } else if (opt_data.is_empty_code == 'NO_RESULTS') {
    output.append('<div class="empty_code">No availability found</div>');
  } else {
    var timeslotList10525 = opt_data.time_slots;
    var timeslotListLen10525 = timeslotList10525.length;
    for (var timeslotIndex10525 = 0; timeslotIndex10525 < timeslotListLen10525; timeslotIndex10525++) {
      var timeslotData10525 = timeslotList10525[timeslotIndex10525];
      Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: opt_data.max_guests, reservation_id: opt_data.reservation_id, date_url: opt_data.date_url, timeslot: timeslotData10525, previous_reservation_id: opt_data.previous_reservation_id, isLast: timeslotIndex10525 == timeslotListLen10525 - 1}, output);
    }
  }
  output.append('<div class="float-end"></div></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.UpcomingAvailability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="upcoming-avail">');
  var party_sizeList10540 = soy.$$getMapKeys(opt_data.avail_by_party_size);
  var party_sizeListLen10540 = party_sizeList10540.length;
  for (var party_sizeIndex10540 = 0; party_sizeIndex10540 < party_sizeListLen10540; party_sizeIndex10540++) {
    var party_sizeData10540 = party_sizeList10540[party_sizeIndex10540];
    output.append('<div class="title">Next available tables for ', soy.$$escapeHtml(party_sizeData10540), '</div><div class="results">');
    var availList10544 = opt_data.avail_by_party_size[party_sizeData10540];
    var availListLen10544 = availList10544.length;
    for (var availIndex10544 = 0; availIndex10544 < availListLen10544; availIndex10544++) {
      var availData10544 = availList10544[availIndex10544];
      var timeslotList10545 = availData10544.search_result.timeslots;
      var timeslotListLen10545 = timeslotList10545.length;
      for (var timeslotIndex10545 = 0; timeslotIndex10545 < timeslotListLen10545; timeslotIndex10545++) {
        var timeslotData10545 = timeslotList10545[timeslotIndex10545];
        Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: availData10544.max_guests, reservation_id: availData10544.reservation_id, date_url: availData10544.date_url, timeslot: timeslotData10545, dateFormatted: availData10544.dateFormatted, isLast: timeslotIndex10545 == timeslotListLen10545 - 1}, output);
      }
    }
    output.append((opt_data.can_custom_dining_request) ? '<div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div>' : (! opt_data.has_avail_by_party_size[party_sizeData10540]) ? 'No availability' : '', '<div class="float-end"></div></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityOption = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="booking-option ', (! opt_data.timeslot.time_slot) ? 'empty' : '', ' ', (opt_data.isLast) ? 'last' : '', ' ', (opt_data.dateFormatted) ? 'has-date' : '', '">', (opt_data.timeslot.time_slot) ? '<form class="ajaxify" method="GET" action="' + soy.$$escapeHtml(opt_data.base_url) + '/res/enter-client-info/' + soy.$$escapeHtml(opt_data.reservation_id) + '"><input type="hidden" name="venue_class" value="' + soy.$$escapeHtml(opt_data.venue_class) + '" /><input type="hidden" name="venue_id" value="' + soy.$$escapeHtml(opt_data.venue_id) + '" /><input type="hidden" name="time_slot" value="' + soy.$$escapeHtml(opt_data.timeslot.time_slot) + '" /><input type="hidden" name="date" value="' + soy.$$escapeHtml(opt_data.date_url) + '" /><input type="hidden" name="max_guests" value="' + soy.$$escapeHtml(opt_data.max_guests) + '" /><input type="hidden" name="access_persistent_id" value="' + soy.$$escapeHtml(opt_data.timeslot.access_persistent_id) + '" />' + ((opt_data.previous_reservation_id) ? '<input type="hidden" name="previous_reservation_id" value="' + soy.$$escapeHtml(opt_data.previous_reservation_id) + '" />' : '') + '<a class="booking-option-link" href="javascript:void(0);" onclick="$(this).closest(\'form\').submit();">' + ((opt_data.dateFormatted) ? '<span class="book-date">' + soy.$$escapeHtml(opt_data.dateFormatted) + '</span>' : '') + '<span class="book-time">' + soy.$$escapeHtml(opt_data.timeslot.time_display) + '</span></a></form>' : '&nbsp;', '</div>');
  return opt_sb ? '' : output.toString();
};

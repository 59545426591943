import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import React from 'react'
import type { Pacing } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface PacingFieldProps {
  pacing: { custom?: Pacing; default: string }
  rowId: string
  isFieldShift: boolean
}

const hasCustomEntries = (customPacing?: Pacing): boolean => !!customPacing && Object.keys(customPacing).length > 0

export function convertMinutesToTimeString(minutes: number): string {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(mins).padStart(2, '0')

  const time = `${formattedHours}:${formattedMinutes}`

  return TimeOnly.fromSafe(time)?.formatSTime() ?? ''
}

function PacingField({ pacing, rowId, isFieldShift }: PacingFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_ROWS = 5

  const renderDefaultRow = () => {
    const showDefaultBox = hasCustomEntries(pacing.custom)

    return (
      <Box display="flex" alignItems="center" gap={2}>
        {showDefaultBox && (
          <Box
            sx={{
              height: 22,
              width: 52,
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1,
              py: 0.5,
              borderRadius: '4px',
              backgroundColor: blueGrey[50],
            }}
          >
            <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
              {formatMessage(accessRulesListMessages.default)}
            </Typography>
          </Box>
        )}

        <Box display="flex" alignItems="center" gap={1}>
          <PeopleOutlineOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
          <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
            {pacing.default}
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderCustomRows = (customPacing: Pacing) => {
    const timeSlots = Object.entries(customPacing).slice(0, MAX_ROWS)
    const remainingRows = Object.keys(customPacing).length - MAX_ROWS

    return (
      <>
        {timeSlots.map(([time, pax]) => (
          <Box key={`${rowId}-${time}`} display="flex" alignItems="center" gap={2}>
            <Box
              sx={{
                height: 22,
                width: 52,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 1,
                py: 0.5,
                borderRadius: '4px',
                backgroundColor: blueGrey[100],
              }}
            >
              <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
                {convertMinutesToTimeString(Number(time))}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <PeopleOutlineOutlinedIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
              <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
                {pax}
              </Typography>
            </Box>
          </Box>
        ))}

        {remainingRows > 0 && (
          <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
            {formatMessage(accessRulesListMessages.xMore, { count: remainingRows })}
          </Typography>
        )}
      </>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} maxWidth="100%">
      {renderDefaultRow()}
      {hasCustomEntries(pacing.custom) && renderCustomRows(pacing.custom ?? {})}
    </Box>
  )
}

export { PacingField }

import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  offerDetailsStepTitle: {
    id: 'offerTemplates.offerDetailsStepTitle',
    defaultMessage: "Let's get started! First we'll need a few guest-facing details.",
  },
  offerDetailsStepDescription: {
    id: 'offerTemplates.offerDetailsStepDescription',
    defaultMessage: 'Offer details have been prefilled by the template',
  },
  offerTitle: {
    id: 'offerTemplates.offerTitle',
    defaultMessage: 'What do you want to call your Offer?',
  },
  offerTitleRecommendation: {
    id: 'offerTemplates.offerTitleRecommendation',
    defaultMessage: 'Names with common phrases perform best since they are easily recognizable and searchable by guests.',
  },
  offerDescription: {
    id: 'offerTemplates.offerDescription',
    defaultMessage: 'How do you want to describe this Offer to guests?',
  },
  offerPreviewDescription: {
    id: 'offerTemplates.offerPreviewDescription',
    defaultMessage: 'What preview description do you want on your widget? (1-2 words)',
  },
  offerPreviewDescriptionPlaceholder: {
    id: 'offerTemplates.offerPreviewDescriptionPlaceholder',
    defaultMessage: 'Enter a timeslot description',
  },
  offerPreviewDescriptionRecommendation: {
    id: 'offerTemplates.offerPreviewDescriptionRecommendation',
    defaultMessage: 'Brief and informative descriptions will attract more bookings.',
  },
  offerPrice: {
    id: 'offerTemplates.offerPrice',
    defaultMessage: 'What price is your Offer?',
  },
  offerImage: {
    id: 'offerTemplates.offerImage',
    defaultMessage: 'What image would you like to use for this Offer?',
  },
  availabilityStepTitle: {
    id: 'offerTemplates.availabilityStepTitle',
    defaultMessage: "Next let's decide where you want {offerName} to be made available.",
  },
  availabilityStepDescription: {
    id: 'offerTemplates.availabilityStepDescription',
    defaultMessage: 'To make {offerName} available to book on your Reservation or Events widget, it must be linked to an Access Rule.',
  },
  createNewAccessRuleButtonText: {
    id: 'offerTemplates.createNewAccessRuleButtonText',
    defaultMessage: 'Create New Access Rule',
  },
  linkToExistingAccessRuleButtonText: {
    id: 'offerTemplates.linkToExistingAccessRuleButtonText',
    defaultMessage: 'Link to Existing Access Rule(s)',
  },
  linkToExistingAccessRuleStepTitle: {
    id: 'offerTemplates.linkToExistingAccessRuleStepTitle',
    defaultMessage: 'Link {offerName} to existing Access Rule(s)',
  },
  linkToExistingAccessRuleRecommendation: {
    id: 'offerTemplates.linkToExistingAccessRuleRecommendation',
    defaultMessage: 'You can select multiple access rules to link.',
  },
  selectAccessRulesToLinkTheOfferTo: {
    id: 'offerTemplates.selectAccessRulesToLinkTheOfferTo',
    defaultMessage: 'Select Access Rule(s) to link your Offer to',
  },
  newAccessRuleSchedulingStepTitle: {
    id: 'offerTemplates.newAccessRuleSchedulingStepTitle',
    defaultMessage: 'When do you want {offerName} to be available?',
  },
  newAccessRuleSchedulingHelpfulTip: {
    id: 'offerTemplates.newAccessRuleSchedulingHelpfulTip',
    defaultMessage: 'Only select an end date if you want your Offer to be available for a limited length of time.',
  },
  newAccessRuleSchedulingDate: {
    id: 'offerTemplates.newAccessRuleSchedulingDate',
    defaultMessage: 'Should this Offer be available for a limited length of time?',
  },
  newAccessRuleSchedulingDayOfTheWeek: {
    id: 'offerTemplates.newAccessRuleSchedulingDayOfTheWeek',
    defaultMessage: 'What days of the week should this Offer be available?',
  },
  newAccessRuleSchedulingShifts: {
    id: 'offerTemplates.newAccessRuleSchedulingShifts',
    defaultMessage: 'Which Shifts should this Access Rule apply to?',
  },
  newAccessRuleSchedulingStartDate: {
    id: 'offerTemplates.newAccessRuleSchedulingStartDate',
    defaultMessage: 'Start date',
  },
  newAccessRuleSchedulingEndDate: {
    id: 'offerTemplates.newAccessRuleSchedulingEndDate',
    defaultMessage: 'End date',
  },
  newAccessRulePaymentStepTitle: {
    id: 'offerTemplates.newAccessRulePaymentStepTitle',
    defaultMessage: "You're almost done. But first we need a few payment details for {offerName}",
  },
  newAccessRulePaymentBestPractices: {
    id: 'offerTemplates.newAccessRulePaymentBestPractices',
    defaultMessage:
      'Capture payment in advance to lock in revenue and help reduce no-shows and cancellations. <b>Venues average $5,435 in additional sales per month.</b>',
  },
  newAccessRulePaymentMethod: {
    id: 'offerTemplates.newAccessRulePaymentMethod',
    defaultMessage: 'How do you want to capture payment?',
  },
  newAccessRulePaymentDetails: {
    id: 'offerTemplates.newAccessRulePaymentDetails',
    defaultMessage: 'Who should be charged and how much?',
  },
  newAccessRuleCustomizationStepTitle: {
    id: 'offerTemplates.newAccessRuleCustomizationStepTitle',
    defaultMessage: 'Do you need to further customize the availability for {offerName}?',
  },
  newAccessRuleCustomizationStepDescription: {
    id: 'offerTemplates.newAccessRuleCustomizationStepDescription',
    defaultMessage: 'Skip if not necessary',
  },
  newAccessRuleCustomizationOfferType: {
    id: 'offerTemplates.newAccessRuleCustomizationOfferType',
    defaultMessage: 'This Offer is',
  },
  newAccessRuleCustomizationOfferTypePublic: {
    id: 'offerTemplates.newAccessRuleCustomizationOfferTypePublic',
    defaultMessage: 'Public',
  },
  newAccessRuleCustomizationOfferTypeExclusive: {
    id: 'offerTemplates.newAccessRuleCustomizationOfferTypeExclusive',
    defaultMessage: 'Exclusive',
  },
  newAccessRuleCustomizationPartySize: {
    id: 'offerTemplates.newAccessRuleCustomizationPartySize',
    defaultMessage: 'Add Party Size Restrictions to this Offer',
  },
  newAccessRuleCustomizationPartySizeExpanded: {
    id: 'offerTemplates.newAccessRuleCustomizationPartySizeExpanded',
    defaultMessage: 'Which party sizes should be able to book this Offer?',
  },
  newAccessRuleCustomizationPartySizeMin: {
    id: 'offerTemplates.newAccessRuleCustomizationPartySizeMin',
    defaultMessage: 'Min party size',
  },
  newAccessRuleCustomizationPartySizeMax: {
    id: 'offerTemplates.newAccessRuleCustomizationPartySizeMax',
    defaultMessage: 'Max party size',
  },
  newAccessRuleCustomizationSeatingAreas: {
    id: 'offerTemplates.newAccessRuleCustomizationSeatingAreas',
    defaultMessage: 'Which seating areas should this Offer be available to book in?',
  },
  newAccessRuleReviewStepTitle: {
    id: 'offerTemplates.newAccessRuleReviewStepTitle',
    defaultMessage: "Let's review your Access Rule for {offerName}",
  },
  newAccessRuleReviewCustomizeBtn: {
    id: 'offerTemplates.newAccessRuleReviewCustomizeBtn',
    defaultMessage: 'Customize Access Rule',
  },
  newAccessRuleReviewOfferDetails: {
    id: 'offerTemplates.newAccessRuleReviewOfferDetails',
    defaultMessage: 'Offer Details',
  },
  newAccessRuleReviewAccessRule: {
    id: 'offerTemplates.newAccessRuleReviewAccessRule',
    defaultMessage: 'Access Rule - {accessRule}',
  },
  newAccessRuleReviewOfferName: {
    id: 'offerTemplates.newAccessRuleReviewOfferName',
    defaultMessage: 'Name',
  },
  newAccessRuleReviewOfferDescription: {
    id: 'offerTemplates.newAccessRuleReviewOfferDescription',
    defaultMessage: 'Description',
  },
  newAccessRuleReviewOfferPrice: {
    id: 'offerTemplates.newAccessRuleReviewOfferPrice',
    defaultMessage: 'Price',
  },
  newAccessRuleReviewOfferType: {
    id: 'offerTemplates.newAccessRuleReviewOfferType',
    defaultMessage: 'Type',
  },
  newAccessRuleReviewOfferImage: {
    id: 'offerTemplates.newAccessRuleReviewOfferImage',
    defaultMessage: 'Image',
  },
  newAccessRuleReviewSchedule: {
    id: 'offerTemplates.newAccessRuleReviewSchedule',
    defaultMessage: 'Schedule',
  },
  newAccessRuleReviewDays: {
    id: 'offerTemplates.newAccessRuleReviewDays',
    defaultMessage: 'Days',
  },
  newAccessRuleReviewDateRange: {
    id: 'offerTemplates.newAccessRuleReviewDateRange',
    defaultMessage: 'Date Range',
  },
  newAccessRuleReviewEndDateIndefinite: {
    id: 'offerTemplates.newAccessRuleReviewEndDateIndefinite',
    defaultMessage: 'indefinite',
  },
  newAccessRuleReviewTimes: {
    id: 'offerTemplates.newAccessRuleReviewTimes',
    defaultMessage: 'Time(s)',
  },
  newAccessRuleReviewShifts: {
    id: 'offerTemplates.newAccessRuleReviewShifts',
    defaultMessage: 'Shift Categories',
  },
  newAccessRuleReviewPartySize: {
    id: 'offerTemplates.newAccessRuleReviewPartySize',
    defaultMessage: 'Party Size',
  },
  accessRule: {
    id: 'offerTemplates.accessRule',
    defaultMessage: 'Access Rule',
  },
  finalPageTitle: {
    id: 'offerTemplates.finalPageTitle',
    defaultMessage: 'Congratulations! Your new {offerName} offer is live.',
  },
  finalPageDescription: {
    id: 'offerTemplates.finalPageDescription',
    defaultMessage: 'Use this link to include on your website, send out an email blast or post to social media.',
  },
  viewOfferLandingPageButtonText: {
    id: 'offerTemplates.viewOfferLandingPageButtonText',
    defaultMessage: 'View Offer Landing Page',
  },
  viewOffersButtonText: {
    id: 'offerTemplates.viewOffersButtonText',
    defaultMessage: 'View Offers',
  },
  experienceAPIError: {
    id: 'offerTemplates.experienceAPIError',
    defaultMessage: 'Something went wrong, please try again or contact support team.',
  },
  recommendationTipBestPractice: {
    id: 'offerTemplates.recommendationTipBestPractice',
    defaultMessage: 'Best practice',
  },
  recommendationTipHelpfulTip: {
    id: 'offerTemplates.recommendationTipHelpfulTip',
    defaultMessage: 'Helpful tip',
  },
  addButton: {
    id: 'offerTemplates.addButton',
    defaultMessage: 'Add',
  },
  editButton: {
    id: 'offerTemplates.editButton',
    defaultMessage: 'Edit',
  },
})

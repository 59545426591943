import { useMemo, useState } from 'react'
import { useUpdateEmailPreferenceMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, Button, CheckboxGroup, type CheckboxChoice, type CheckboxChoiceValue } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Loader, VStack, Flex, notify, CardSection, Box, Root, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { emailPreferenceCenterMessages } from '../emailPreferenceCenterMessages'
import { useStoreSelector } from '../store'

interface EmailPreferencesPageProps {
  toggleConfirmationStatus: (value: boolean) => void
  setConfirmationText: (value: string) => void
}

export function EmailCampaignSubscription({ toggleConfirmationStatus, setConfirmationText }: EmailPreferencesPageProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const [marketingOptIns, setMarketingOptIns] = useState(useStoreSelector(state => state.marketingOptIns))
  const currentVenueOptIn = useStoreSelector(state => state.currentVenueOptIn)
  const allLocationOptIn = useStoreSelector(state => state.allLocationOptIn)
  const clientEmail = useStoreSelector(state => state.clientEmail)
  const clientId = useStoreSelector(state => state.clientId)
  const campaignId = useStoreSelector(state => state.campaignId)
  const venueInfo = useStoreSelector(state => state.venueInfo)
  const { preferenceOptions, venueOptIns, venueOptOuts, venueIds } = useMemo(
    () => generateSubscriptionData(marketingOptIns),
    [marketingOptIns]
  )
  const [updateEmailPreference, { isLoading }] = useUpdateEmailPreferenceMutation()
  const [selectedOptIns, setSelectedOptIns] = useState<Set<string>>(new Set(venueOptIns))
  const [selectedOptOuts, setSelectedOptOuts] = useState<Set<string>>(new Set(venueOptOuts))
  const [allLocationField, setAllLocationField] = useState(
    allLocationOptIn || (currentVenueOptIn && selectedOptIns.size === venueIds.length)
  )
  const [currentVenueField, setCurrentVenueField] = useState(currentVenueOptIn)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handlePreferenceChange = (selectedChoices: CheckboxChoiceValue[]) => {
    const selectedVenues = new Set(selectedChoices as string[])
    setSelectedOptIns(selectedVenues)
    setSelectedOptOuts(new Set(venueIds.filter(venueId => !selectedVenues.has(venueId))))
    setAllLocationField(selectedChoices.length === venueIds.length && currentVenueField)
  }

  const onPreferenceUpdate = async () => {
    setIsSubmitting(true)
    const preferenceUpdatePromise = updateEmailPreference({
      clientId,
      venueOptIns: currentVenueField ? [...selectedOptIns, venueInfo.id] : [...selectedOptIns],
      venueOptOuts: !currentVenueField ? [...selectedOptOuts, venueInfo.id] : [...selectedOptOuts],
      campaignId,
      sendDoubleOptInEmail: true,
    })
    try {
      await preferenceUpdatePromise
      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterConfirmationOptOut))
    } catch {
      notify({
        content: formatMessage(emailPreferenceCenterMessages.submissionError),
        type: 'error',
      })
    }
    setIsSubmitting(false)
  }

  const onChangeAllLocation = () => {
    const updatedAllLocation = !allLocationField
    setAllLocationField(updatedAllLocation)
    const updatedMarketingOptIns = { ...marketingOptIns }
    if (updatedAllLocation) {
      venueIds.forEach(venueId => {
        if (venueId in marketingOptIns) {
          const [name] = marketingOptIns[venueId] as [string, boolean]
          updatedMarketingOptIns[venueId] = [name, true]
        }
      })
      setCurrentVenueField(true)
      setMarketingOptIns(updatedMarketingOptIns)
      setSelectedOptIns(new Set(venueIds))
      setSelectedOptOuts(new Set([]))
    } else {
      venueIds.forEach(venueId => {
        if (venueId in marketingOptIns) {
          const [name] = marketingOptIns[venueId] as [string, boolean]
          updatedMarketingOptIns[venueId] = [name, false]
        }
      })
      setCurrentVenueField(false)
      setMarketingOptIns(updatedMarketingOptIns)
      setSelectedOptIns(new Set([]))
      setSelectedOptOuts(new Set(venueIds))
    }
  }
  const onUnsubscribeFromAll = async () => {
    setIsSubmitting(true)
    const preferenceUpdatePromise = updateEmailPreference({
      clientId,
      venueOptIns: [],
      venueOptOuts: [...venueIds, venueInfo.id],
      campaignId,
      sendDoubleOptInEmail: true,
    })
    try {
      await preferenceUpdatePromise
      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterConfirmationUnsubscribe))
    } catch {
      notify({
        content: formatMessage(emailPreferenceCenterMessages.submissionError),
        type: 'error',
      })
    }
    setIsSubmitting(false)
  }

  return (
    <Root
      theme="gx"
      themeOverride={{
        colors: {
          brandColor: '#119B93',
        },
      }}
    >
      {isSubmitting ? (
        <Loader />
      ) : (
        <CardSection maxWidth="500px" p="lm">
          <VStack spacing="lm">
            <VStack spacing="xs">
              <Text textStyle={isMobile ? 'h2' : 'h1'}>{formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterHeader)}</Text>
              <SecondaryText>{formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterDescription)}</SecondaryText>
            </VStack>
            <Box>
              <Box mb="s">
                <Checkbox checked={allLocationField} onChange={onChangeAllLocation}>
                  {formatMessage(emailPreferenceCenterMessages.allLocation)}
                </Checkbox>
              </Box>
              <Checkbox
                checked={currentVenueField}
                onChange={() => {
                  const updatedCurrentVenueField = !currentVenueField
                  setAllLocationField(selectedOptIns.size === venueIds.length && updatedCurrentVenueField)
                  setCurrentVenueField(updatedCurrentVenueField)
                }}
              >
                {venueInfo.name}
              </Checkbox>
            </Box>

            <DividerLine color="borders" margin="none" />

            <CheckboxGroup choices={preferenceOptions} onChange={handlePreferenceChange} selected={venueOptIns} />
            <SecondaryText>
              {formatMessage(emailPreferenceCenterMessages.clientEmailLabel)}
              {': '}
              {clientEmail}
            </SecondaryText>
            <Flex flexDirection="column" justifyContent="center" rowGap="m" columnGap="m">
              <Button onClick={onPreferenceUpdate} disabled={isLoading} fullWidth data-test="update-preference-button">
                {formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterSaveButton)}
              </Button>
              <Button
                onClick={onUnsubscribeFromAll}
                variant="tertiary"
                disabled={isLoading}
                fullWidth
                data-test="unsubscribe-from-all-button"
              >
                {formatMessage(emailPreferenceCenterMessages.unsubscribeFromAllLocation)}
              </Button>
            </Flex>
          </VStack>
        </CardSection>
      )}
    </Root>
  )
}

export const generateSubscriptionData = (marketingOptIns: { [key: string]: [string, boolean | null] }) => {
  const preferenceOptions: CheckboxChoice<string>[] = []
  const venueOptIns: string[] = []
  const venueOptOuts: string[] = []
  const venueIds = Object.keys(marketingOptIns)
  const selectedVenueId = new URLSearchParams(window.location.search).get('v')
  venueIds.forEach(venueId => {
    const [venueName, isSubscribed] = marketingOptIns[venueId] as [string, boolean]
    const preferenceOption = {
      value: venueId,
      label: venueName,
    }
    preferenceOptions.push(preferenceOption)
    if (isSubscribed || selectedVenueId === venueId) {
      venueOptIns.push(venueId)
    } else {
      venueOptOuts.push(venueId)
    }
  })

  return {
    preferenceOptions,
    venueOptIns,
    venueOptOuts,
    venueIds,
  }
}

import { defineMessages } from '@sevenrooms/core/locales'

export const ShriftReportingPeriodSettingsMessages = defineMessages({
  generalSettings: {
    id: 'settings.general.shriftReportingPeriods.generalSettings',
    defaultMessage: 'General Settings',
  },
  reportingPeriods: {
    id: 'settings.general.shriftReportingPeriods.reportingPeriods',
    defaultMessage: 'Reporting Periods',
  },
  reportingPeriodDefault: {
    id: 'settings.general.shriftReportingPeriods.reportingPeriodDefault',
    defaultMessage: 'Reporting period template default',
  },
  addReportingPeriod: {
    id: 'settings.general.shriftReportingPeriods.addReportingPeriod',
    defaultMessage: '+ Add reporting period template',
  },
  enterReportingPeriodsName: {
    id: 'settings.general.shriftReportingPeriods.enterReportingPeriodsName',
    defaultMessage: 'Enter reporting period name',
  },
  defaultReportingPeriod: {
    id: 'settings.general.shriftReportingPeriods.defaultReportingPeriod',
    defaultMessage: 'Default Reporting Period',
  },
  breakfast: {
    id: 'settings.general.shriftReportingPeriods.breakfast',
    defaultMessage: 'Breakfast',
  },
  brunch: {
    id: 'settings.general.shriftReportingPeriods.brunch',
    defaultMessage: 'Brunch',
  },
  lunch: {
    id: 'settings.general.shriftReportingPeriods.lunch',
    defaultMessage: 'Lunch',
  },
  dinner: {
    id: 'settings.general.shriftReportingPeriods.dinner',
    defaultMessage: 'Dinner',
  },
  nightTime: {
    id: 'settings.general.shriftReportingPeriods.nightTime',
    defaultMessage: 'Night',
  },
})

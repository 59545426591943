// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList15556 = opt_data.frozenFollowers;
  var followerListLen15556 = followerList15556.length;
  for (var followerIndex15556 = 0; followerIndex15556 < followerListLen15556; followerIndex15556++) {
    var followerData15556 = followerList15556[followerIndex15556];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData15556.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData15556.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList15564 = opt_data.followers;
  var followerListLen15564 = followerList15564.length;
  for (var followerIndex15564 = 0; followerIndex15564 < followerListLen15564; followerIndex15564++) {
    var followerData15564 = followerList15564[followerIndex15564];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData15564.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData15564.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData15564.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData15564.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
